import React, { Component } from 'react';

export default class KycModal extends Component {
  render() {
    let { propsData } = this.props,
      { photos } = propsData,
      { aadharDocuments = [], panDocuments } = photos;
    return (
      <div className='compare-details-wrapper'>
        <h3 className='card_heading'>KYC Documents</h3>
        <div className='row'>
          <div className='col-md-6'>
            <h5 className='my-4'>Aadhaar Card - Front View</h5>
            <img
              src={aadharDocuments[0]?.fileUrl}
              alt='image'
              className='kyc_img'
            />
          </div>
          <div className='col-md-6'>
            <h5 className='my-4'>Aadhaar Card - Back View</h5>
            <img
              src={aadharDocuments[1]?.fileUrl}
              alt='image'
              className='kyc_img'
            />
          </div>
        </div>
        <h5 className='my-4'>PAN Card</h5>
        <img src={panDocuments?.fileUrl} alt='image' className='kyc_img' />
      </div>
    );
  }
}
