import React, { Component } from 'react';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { Helmet } from 'react-helmet';
import Upload from '../../../assets/images/upload 1.png';
import BulkUpload from '../../../assets/images/Bulkupload.png';
import CredoModal from '../../../components/CredoModal';
import OutstandingInvoiceModal from './outstandingInvoiceModal';
import SettledInvoiceModal from './settledInvoiceModal.js';
import SingleInvoiceModal from './singleInvoiceModal';
import BulkInvoiceUpload from './bulkInvoiceModal';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ConfirmationModal } from './confirmationModal';
import {
  getInvoices,
  getLimitSummary,
  getRepayments,
} from './invoices.actions';
import { rupeeDecimalFormatter } from '../../../utils/utility';
import { POST } from '../../../utils/webAPI.service';
import {
  API_POST_INVOICE_DETAILS,
  API_POST_MARGIN_RELEASE,
} from '../../../utils/APIUrls';
import DownArrow from '../../../assets/images/DownArrow.svg';
import UpArrow from '../../../assets/images/UpArrow.svg';
import { loaderModal } from '../../../store/actions';
import CheckBox from '../../../components/CheckBox/CheckBox';
import { STATUS_PARAMS, STATUS_PARAMS_VALUE_MAP } from './Invoice.config';

class Invoices extends Component {
  state = {
    activeFigure: 'Outstanding',
    isModalOpen: false,
    outstandingInvoiceDetails: {},
    settledInvoiceDetails: {},
    currentPageNum: 1,
    sortInvoiceDate: -1,
    sortInvoiceAmount: -1,
    sortSettledDate: -1,
    sortingOn: '',
    settleInvoiceData: [],
    isAllSelected: false,
    invoiceId: '',
  };

  componentDidMount = async () => {
    let { actions, match } = this.props,
      { userId } = match.params,
      { getInvoices, getLimitSummary, loaderModal } = actions,
      token = localStorage.getItem('token');
    loaderModal(true);
    if (token) {
      await getLimitSummary(userId);
      getInvoices(userId, false, 1); // outstanding invoices
      loaderModal(false);
    } else {
      let { history } = this.props;
      alert('Unauthorized access');
      actions.loaderModal(false);
      history.push('/');
    }

    await this.setState({
      settleInvoiceData: this.props.settledInvoices?.invoices,
    });
  };

  invoiceHandler = async (type) => {
    let { actions, match } = this.props,
      { settleInvoiceData } = this.state,
      { userId } = match.params,
      { getInvoices, getRepayments, loaderModal } = actions;
    loaderModal(true);
    if (type === 'Settled') {
      (await getInvoices(userId, true, 1)) && getRepayments(userId, 1); // settled invoices
    } else {
      await getInvoices(userId, false, 1); // outstanding invoices
    }
    loaderModal(false);
    await this.setState({
      activeFigure: type,
      sortInvoiceDate: -1,
      sortInvoiceAmount: -1,
      sortSettledDate: -1,
      currentPageNum: 1,
      sortingOn: '',
      settleInvoiceData: this.props?.settledInvoices?.invoices, //setting settle invoice when tab changes
    });
  };

  getPageData = async (type, list, invoiceType) => {
    let { currentPage, totalPages } = list,
      { actions, match } = this.props,
      { userId } = match.params,
      { sortingOn, sortInvoiceDate, sortInvoiceAmount, sortSettledDate } =
        this.state;
    actions.loaderModal(true);
    if (type === 'prev' && currentPage > 1) {
      await this.setState({
        currentPageNum: currentPage - 1,
      });
    } else if (type === 'next' && currentPage < totalPages) {
      await this.setState({
        currentPageNum: currentPage + 1,
      });
    }
    await actions.getInvoices(
      userId,
      invoiceType,
      this.state.currentPageNum,
      sortingOn === 'invoiceDate'
        ? sortInvoiceDate
        : sortingOn === 'invoiceAmountWithTaxes'
        ? sortInvoiceAmount
        : sortSettledDate,
      sortingOn
    );
    actions.loaderModal(false);
    await this.setState({
      settleInvoiceData: this.props.settledInvoices?.invoices, //setting settle invoice when page changes
    });
  };

  getTheadThProps = (state, rowInfo, column) => ({
    onClick: async () => {
      let {
          sortInvoiceDate,
          sortInvoiceAmount,
          sortSettledDate,
          activeFigure,
          currentPageNum,
          sortingOn,
        } = this.state,
        { actions, match } = this.props,
        { userId } = match.params;

      if (column?.id === 'invoiceDate') {
        await this.setState({
          sortInvoiceDate: sortInvoiceDate === -1 ? 1 : -1,
          sortInvoiceAmount: -1,
          sortSettledDate: -1,
          sortingOn: 'invoiceDate',
        });
      } else if (column?.id === 'invoiceAmount') {
        await this.setState({
          sortInvoiceAmount: sortInvoiceAmount === -1 ? 1 : -1,
          sortInvoiceDate: -1,
          sortSettledDate: -1,
          sortingOn: 'invoiceAmountWithTaxes',
        });
      } else if (column?.id === 'settledDate') {
        await this.setState({
          sortSettledDate: sortSettledDate === -1 ? 1 : -1,
          sortInvoiceDate: -1,
          sortInvoiceAmount: -1,
          sortingOn: 'settledDate',
        });
      }

      actions.getInvoices(
        userId,
        activeFigure === 'Outstanding' ? false : true,
        currentPageNum,
        this.state.sortingOn === 'invoiceDate'
          ? this.state.sortInvoiceDate
          : this.state.sortingOn === 'invoiceAmountWithTaxes'
          ? this.state.sortInvoiceAmount
          : this.state.sortSettledDate,
        this.state.sortingOn
      );
    },
  });
  onReleaseMargin = () => {
    let { closeAction, match, actions } = this.props || {};
    let { settleInvoiceData } = this.state;
    let { userId } = match.params;
    let checkedInvoices = settleInvoiceData
      ?.filter((item) => item?.isChecked === true)
      .map((e) => e?.invoiceId);
    const dataToSend = {
      invoiceIds: checkedInvoices,
      userId: userId,
    };
    if (checkedInvoices.length === 0) {
      alert('Please Select Invoice Id');
      return;
    }
    POST(API_POST_MARGIN_RELEASE, dataToSend)
      .then(async ({ data }) => {
        if (data.code === 200) {
          await actions.getInvoices(userId, true, 1);
          alert('Margin release succesfully');
          await this.setState({
            settleInvoiceData: this.props.settledInvoices?.invoices,
          });
          closeAction('');
        } else {
          alert(data?.message);
        }
      })
      .catch((err) => {
        throw err;
      });
  };
  toggleModal = (modalType, invoiceId, type, InvoiceData) => {
    this.setState({
      isModalOpen: !this.state.isModalOpen,
      modalType,
      invoiceId,
      InvoiceData,
    });
    let { match } = this.props,
      { userId } = match.params,
      dataToSend = {
        invoiceId,
        userId,
      };

    if (invoiceId) {
      POST(API_POST_INVOICE_DETAILS, dataToSend)
        .then(({ data }) => {
          if (data.code === 200) {
            if (data.data && type === 'outstanding') {
              this.setState({
                outstandingInvoiceDetails: data.data,
              });
            } else if (data.data && type === 'settled') {
              this.setState({
                settledInvoiceDetails: data.data,
              });
            }
          } else if (data.code === 999 || data.code === 401) {
            alert(data?.message);
          } else {
            alert('Something went wrong');
          }
        })
        .catch((err) => {
          alert('Something went wrong');
          throw err;
        });
    }
  };

  toggleIsChecked = () => {
    const { settleInvoiceData } = this.state;
    let isAllSelected = settleInvoiceData?.some(
      (invoice) =>
        invoice.marginStatus === 'pending' && invoice?.isChecked !== true
    );
    let checkedValue = false;
    if (isAllSelected) {
      checkedValue = true;
    }
    let updatedSettleInvoiceData = settleInvoiceData.map((invoice) => {
      if (invoice.marginStatus === 'pending') {
        return { ...invoice, isChecked: checkedValue };
      }
      return invoice;
    });

    this.setState({
      settleInvoiceData: updatedSettleInvoiceData,
    });
  };

  render() {
    let {
        activeFigure,
        modalType,
        sortInvoiceDate,
        sortInvoiceAmount,
        sortSettledDate,
        settleInvoiceData,
        invoiceId,
        isAllSelected,
      } = this.state,
      { limitSummary, outstandingInvoices, settledInvoices, repayments } =
        this.props,
      { totalLimit, usedLimit, balanceLimit } = limitSummary;

    const outstandingColumns = [
      {
        Header: 'Transaction Date',
        accessor: 'transactionDate',
      },
      {
        Header: () => (
          <span>
            Invoice Date
            <img
              src={sortInvoiceDate === -1 ? UpArrow : DownArrow}
              alt='arrow'
              className='sort_arrow'
            />
          </span>
        ),
        accessor: 'invoiceDate',
      },
      {
        Header: 'Invoice ID',
        accessor: 'invoiceId',
        Cell: ({ original }) => {
          let { invoiceId, status } = original;
          return (
            <>
              <div>{invoiceId}</div>
              {status === STATUS_PARAMS.IN_REVIEW ? (
                <span className='capsule ml-4 capsule_orange'>
                  {STATUS_PARAMS_VALUE_MAP.IN_REVIEW}
                </span>
              ) : status === STATUS_PARAMS.PROCESSING ? (
                <span className='capsule  ml-4  capsule_orange'>
                  {STATUS_PARAMS_VALUE_MAP.PROCESSING}
                </span>
              ) : status === STATUS_PARAMS.PROCESSED ? (
                <span className='capsule ml-4 capsule_new_green'>
                  {STATUS_PARAMS_VALUE_MAP.PROCESSED}
                </span>
              ) : status === STATUS_PARAMS.ON_HOLD ? (
                <span className='capsule ml-4 capsule_grey'>
                  {STATUS_PARAMS_VALUE_MAP.ON_HOLD}
                </span>
              ) : status === STATUS_PARAMS.FAILED ? (
                <span className='capsule ml-4 capsule_red'>
                  {STATUS_PARAMS_VALUE_MAP.FAILED}{' '}
                </span>
              ) : status === STATUS_PARAMS.REJECTED ? (
                <span className='capsule ml-4 capsule_red'>
                  {STATUS_PARAMS_VALUE_MAP.REJECTED}
                </span>
              ) : status === STATUS_PARAMS.BANK_FAILED ? (
                <span className='capsule ml-4 capsule_red'>
                  {STATUS_PARAMS_VALUE_MAP.BANK_FAILED}
                </span>
              ) : status === STATUS_PARAMS.DRAFT ? (
                <span className='capsule ml-4 capsule_blue'>
                  {STATUS_PARAMS_VALUE_MAP.DRAFT}
                </span>
              ) : (
                <span className='capsule ml-4 capsule_blue'>{status}</span>
              )}
            </>
          );
        },
      },

      {
        Header: () => (
          <span className='pr-5'>
            Invoice Amount
            <img
              src={sortInvoiceAmount === -1 ? UpArrow : DownArrow}
              alt='arrow'
              className='sort_arrow'
            />
          </span>
        ),
        accessor: 'invoiceAmount',
        headerStyle: { textAlign: 'right' },
        Cell: (props) => {
          return (
            <div className='right-aligned pr-5'>
              {rupeeDecimalFormatter(props?.value, '₹')}
            </div>
          );
        },
      },
      {
        Header: () => (
          <>
            <span className='pr-5'>Subvention </span>
            <div className='pr-5 mr-3'>Amount</div>
          </>
        ),
        accessor: 'subventionAmount',
        headerStyle: { textAlign: 'right' },
        Cell: (props) => {
          return (
            <div className='right-aligned pr-5'>
              {rupeeDecimalFormatter(props?.value, '₹')}
            </div>
          );
        },
      },
      {
        Header: () => (
          <>
            <span className='pr-5 mr-2'>Margin </span>
            <div className='pr-5'>Amount</div>
          </>
        ),
        accessor: 'marginAmount',
        headerStyle: { textAlign: 'right' },
        Cell: (props) => {
          return (
            <div className='right-aligned pr-5'>
              {rupeeDecimalFormatter(props?.value, '₹')}
            </div>
          );
        },
      },

      {
        Header: () => (
          <div className='pr-5 mr-4'>
            <div>Payment </div>
            <div>Amount</div>
          </div>
        ),
        accessor: 'fundingAmount',
        headerStyle: { textAlign: 'right' },
        Cell: (props) => {
          return (
            <div className='right-aligned pr-5'>
              {rupeeDecimalFormatter(props?.value, '₹')}
            </div>
          );
        },
      },
      {
        Header: 'Due Date',
        accessor: 'dueDate',
        Cell: ({ original }) => {
          let { dueDate, dueDays = 'NA', dueStatus } = original;
          return (
            <>
              <div>{dueDate}</div>
              {dueStatus === 'grace' ? (
                <span className='capsule capsule_green'>In Grace Period</span>
              ) : dueStatus === 'due' ? (
                <span className='capsule capsule_orange'>
                  Due in {dueDays} days
                </span>
              ) : dueStatus === 'overdue' ? (
                <span className='capsule capsule_red'>
                  Overdue by {dueDays} days
                </span>
              ) : null}
            </>
          );
        },
      },
      {
        Header: 'Details',
        width: 80,
        accessor: '',
        Cell: ({ original }) => {
          let { invoiceId } = original;
          return (
            <button
              className='details_button'
              onClick={() =>
                this.toggleModal(
                  OutstandingInvoiceModal,
                  invoiceId,
                  'outstanding'
                )
              }
            >
              Details
            </button>
          );
        },
      },
    ];

    const settledColumns = [
      {
        Header: () => {
          const { settleInvoiceData } = this.state;
          const isAllSelected = settleInvoiceData?.some(
            (invoiceData) =>
              invoiceData?.marginStatus === 'pending' && !invoiceData?.isChecked
          );

          return (
            <CheckBox
              checkboxColor={'#4687F4'}
              htmlFor={'selectAllPending'}
              inputClass={''}
              labelClass={''}
              checked={!isAllSelected}
              onChange={this.toggleIsChecked}
            />
          );
        },
        accessor: 'isChecked',
        width: 50,
        Cell: ({ value, index, original }) => {
          const { marginStatus } = original || {};
          const onCheckChange = () => {
            const settleInvoiceDataCopy = [...this.state.settleInvoiceData];
            settleInvoiceDataCopy[index] = {
              ...settleInvoiceDataCopy[index],
              isChecked: !value,
            };

            this.setState({ settleInvoiceData: settleInvoiceDataCopy });
          };

          return (
            <div>
              {marginStatus === 'pending' ? (
                <CheckBox
                  checkboxColor={'#4687F4'}
                  htmlFor={'filter1'}
                  inputClass={''}
                  labelClass={''}
                  checked={value}
                  onChange={onCheckChange}
                />
              ) : null}
            </div>
          );
        },
      },
      {
        // Header: 'Transaction Date',
        Header: () => (
          <div className='mr-2'>
            <div>Transaction </div>
            <div>Date</div>
          </div>
        ),
        accessor: 'transactionDate',
        width: 120,
      },
      {
        Header: 'Invoice ID',
        accessor: 'invoiceId',
        width: 130,
      },
      {
        Header: () => (
          <span>
            <div className='pr-3'>
              <div>Invoice Amount </div>
            </div>

            {/* <img
              src={sortInvoiceAmount === -1 ? UpArrow : DownArrow}
              alt='arrow'
              className='sort_arrow'
            /> */}
          </span>
        ),
        accessor: 'invoiceAmount',
        width: 180,
        headerStyle: { textAlign: 'right' },
        Cell: (props) => {
          return (
            <div className='right-aligned pr-5'>
              {rupeeDecimalFormatter(props?.value, '₹')}
            </div>
          );
        },
      },
      {
        Header: () => (
          <div className='mr-2'>
            <div>Subvention Amount </div>
          </div>
        ),
        accessor: 'subventionAmount',
        width: 160,
        Cell: (props) => {
          return (
            <div className=''>{rupeeDecimalFormatter(props?.value, '₹')}</div>
          );
        },
      },
      {
        Header: () => (
          <>
            <span className='pr-5'>Margin Amount</span>
          </>
        ),
        accessor: 'marginAmount',
        width: 150,
        // headerStyle: { textAlign: 'right' },
        Cell: (props) => {
          let { original } = props || {};
          let { marginStatus } = original || {};
          return (
            <>
              <div className=''>{rupeeDecimalFormatter(props?.value, '₹')}</div>
              {marginStatus === 'pending' ? (
                <span className='capsule   capsule_grey ml-1'>On hold</span>
              ) : marginStatus === 'under_processing' ? (
                <span className='capsule    capsule_green ml-1'>
                  Under processing
                </span>
              ) : marginStatus === 'closed' ? (
                <span className='capsule   capsule_blue ml-1'>Released</span>
              ) : null}
            </>
          );
        },
      },
      {
        Header: () => (
          <div className='pr-5 mr-4'>
            <div>Payment </div>
            <div>Amount</div>
          </div>
        ),
        width: 180,
        accessor: 'fundingAmount',
        headerStyle: { textAlign: 'right' },
        Cell: (props) => {
          return (
            <div className='right-aligned pr-5'>
              {rupeeDecimalFormatter(props?.value, '₹')}
            </div>
          );
        },
      },
      {
        Header: () => (
          <span>
            Settled Date
            <img
              src={sortSettledDate === -1 ? UpArrow : DownArrow}
              alt='arrow'
              className='sort_arrow'
            />
          </span>
        ),
        accessor: 'settledDate',
        width: 130,
      },
      {
        Header: 'Details',
        width: 80,
        accessor: '',
        Cell: ({ original }) => {
          let { invoiceId } = original;
          return (
            <button
              className='details_button'
              onClick={() =>
                this.toggleModal(
                  SettledInvoiceModal,
                  invoiceId,
                  'settled',
                  original
                )
              }
            >
              Details
            </button>
          );
        },
      },
    ];

    return (
      <>
        <div className='container invoices-wrapper'>
          <Helmet>
            <meta charSet='utf-8' />
            <title> Invoices</title>
          </Helmet>
          <div className='card'>
            <h3 className='card_heading'> Limit</h3>
            <div className='row'>
              <div className='col-md-8'>
                <div className='row'>
                  <div className='col-md-2 text-grey'>
                    Total Limit
                    <div className='text-bold'>
                      {rupeeDecimalFormatter(totalLimit, '₹')}
                    </div>
                  </div>
                  <div className='col-md-2 text-grey'>
                    Used Limit
                    <div className='text-bold'>
                      {rupeeDecimalFormatter(usedLimit, '₹')}
                    </div>
                  </div>
                  <div className='col-md-2 text-grey'>
                    Balance limit
                    <div className='text-bold'>
                      {rupeeDecimalFormatter(balanceLimit, '₹')}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='card'>
            <div className='row '>
              <h3 className='col-md-8 card_heading_secondary m-heading'>
                Invoices
              </h3>
              {activeFigure === 'Outstanding' ? (
                <>
                  <h5
                    className='col-md-2 button add_button'
                    onClick={() => this.toggleModal(SingleInvoiceModal)}
                  >
                    <img src={Upload} alt='icon' />
                    <span>Upload Single Invoice</span>
                  </h5>
                  <h5
                    className='col-md-2 button add_bulk_button'
                    onClick={() => this.toggleModal(BulkInvoiceUpload)}
                  >
                    <img src={BulkUpload} alt='icon' />
                    Upload Bulk Invoices
                  </h5>
                </>
              ) : null}
            </div>

            <div className='row generic_button_box'>
              <div
                onClick={() => this.invoiceHandler('Outstanding')}
                className={
                  activeFigure === 'Outstanding'
                    ? 'col-md-1 generic_button_active'
                    : 'col-md-1 generic_button'
                }
              >
                Outstanding
              </div>
              <div
                onClick={() => this.invoiceHandler('Settled')}
                className={
                  activeFigure === 'Settled'
                    ? 'col-md-1 generic_button_active'
                    : 'col-md-1 generic_button'
                }
              >
                Settled
              </div>
            </div>
            {activeFigure === 'Outstanding' ? (
              <div className='row'>
                <div className='col-md-8'>
                  <div className='row'>
                    <div className='col-md-3 text-grey'>
                      Invoices Outstanding
                      <div className='text-bold'>
                        {outstandingInvoices?.totalSuccessfulInvoices}
                      </div>
                    </div>
                    <div className='col-md-3 text-grey'>
                      Outstanding amount
                      <div className='text-bold'>
                        {rupeeDecimalFormatter(
                          outstandingInvoices?.outstandingAmount,
                          '₹'
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
            {activeFigure === 'Settled' ? (
              <div className='row'>
                <div className='col-md-8'>
                  <div className='row'>
                    <div className='col-md-3 text-grey'>
                      Invoices Settled
                      <div className='text-bold'>
                        {settledInvoices?.totalSuccessfulInvoices}
                      </div>
                    </div>
                    <div className='col-md-3 text-grey'>
                      Funded amount
                      <div className='text-bold'>
                        {rupeeDecimalFormatter(
                          settledInvoices?.fundedAmount,
                          '₹'
                        )}
                      </div>
                    </div>
                    <div className='col-md-3 text-grey'>
                      Repayment amount
                      <div className='text-bold'>
                        {rupeeDecimalFormatter(repayments?.totalAmount, '₹')}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
          </div>

          <div className='card'>
            {activeFigure === 'Outstanding' ? (
              <>
                <div className='row'>
                  <h3 className='col-md-10 card_heading_secondary'>
                    Outstanding Invoices
                  </h3>
                  <h5 className='col-md-2  card_heading_secondary_right'>
                    All amounts in ₹
                  </h5>
                </div>

                <ReactTable
                  data={outstandingInvoices?.invoices || []}
                  columns={outstandingColumns}
                  defaultPageSize={10}
                  showPagination={false}
                  getTheadThProps={this.getTheadThProps}
                  sortable={false}
                />
                <ul className={'datatable-pagination'}>
                  <li>
                    <button
                      disabled={outstandingInvoices?.currentPage <= 1}
                      className={'prev-btn'}
                      type={'button'}
                      onClick={() =>
                        this.getPageData('prev', outstandingInvoices, false)
                      }
                    >
                      Previous
                    </button>
                  </li>
                  <li>
                    <span>
                      {` Page No. ${outstandingInvoices?.currentPage || 1} of
                      ${outstandingInvoices?.totalPages || 1}`}
                    </span>
                  </li>

                  <li>
                    <button
                      className={'next-btn'}
                      type={'button'}
                      disabled={
                        !(
                          outstandingInvoices?.currentPage <
                          outstandingInvoices?.totalPages
                        )
                      }
                      onClick={() =>
                        this.getPageData('next', outstandingInvoices, false)
                      }
                    >
                      Next
                    </button>
                  </li>
                </ul>
              </>
            ) : null}
            {activeFigure === 'Settled' ? (
              <>
                <div className='row'>
                  <h3 className='col-md-10 card_heading_secondary'>
                    Settled Invoices
                  </h3>
                  <h5 className='col-md-2  card_heading_secondary_right'>
                    All amounts in ₹
                  </h5>
                </div>
                <div className='row  mr-4 d-flex justify-content-end'>
                  <h5
                    className='release_margin button add_button'
                    onClick={() => this.toggleModal(ConfirmationModal)}
                  >
                    <span>Release Margin</span>
                  </h5>
                </div>

                <ReactTable
                  data={settleInvoiceData || []}
                  columns={settledColumns}
                  defaultPageSize={10}
                  showPagination={false}
                  sortable={false}
                  getTheadThProps={this.getTheadThProps}
                />
                <ul className={'datatable-pagination'}>
                  <li>
                    <button
                      disabled={settledInvoices?.currentPage <= 1}
                      className={'prev-btn'}
                      type={'button'}
                      onClick={() =>
                        this.getPageData('prev', settledInvoices, true)
                      }
                    >
                      Previous
                    </button>
                  </li>
                  <li>
                    <span>
                      {`Page No. ${settledInvoices?.currentPage} of
                      ${settledInvoices?.totalPages}`}
                    </span>
                  </li>

                  <li>
                    <button
                      className={'next-btn'}
                      type={'button'}
                      disabled={
                        !(
                          settledInvoices?.currentPage <
                          settledInvoices?.totalPages
                        )
                      }
                      onClick={() =>
                        this.getPageData('next', settledInvoices, true)
                      }
                    >
                      Next
                    </button>
                  </li>
                </ul>
              </>
            ) : null}
          </div>
          <CredoModal
            isOpen={this.state.isModalOpen}
            styles={
              modalType === ConfirmationModal
                ? {
                    content: {
                      width: '60rem',
                      height: '30rem',
                    },
                  }
                : {
                    content: {
                      minWidth: '60vw',
                      height: 'max-content',
                    },
                  }
            }
            closeAction={() => this.toggleModal('')}
            RenderingComponent={modalType}
            stateData={this.state}
            propsData={this.props}
            settleInvoiceData={settleInvoiceData}
            invoiceId={invoiceId}
            toggleModal={this.toggleModal}
            onReleaseMargin={this.onReleaseMargin}
            InvoiceData={this.state.InvoiceData}
          />
        </div>
      </>
    );
  }
}

const mapStateToProps = ({ invoicesReducer, offerReducer }) => ({
  settledInvoices: invoicesReducer.settledInvoices,
  outstandingInvoices: invoicesReducer.outstandingInvoices,
  limitSummary: invoicesReducer.limitSummary,
  activeLoans: offerReducer.activeLoans,
  repayments: invoicesReducer.repayments,
});
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      getInvoices,
      getLimitSummary,
      getRepayments,
      loaderModal,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(Invoices);
