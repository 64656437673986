import * as invoicesConstants from './invoice.action.constant';

const initialState = {
  paymentInvoices: {},
  invoicesList: {},
};
const supplierInvoicesReducer = (state = initialState, action) => {
  let { type, data } = action;
  switch (type) {
    case invoicesConstants.SET_PAYMENT_INVOICES:
      return { ...state, paymentInvoices: data };
    case invoicesConstants.SET_INVOICES:
      return { ...state, invoicesList: data };
    default:
      return state;
  }
};

export default supplierInvoicesReducer;
