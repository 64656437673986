import * as ACTION_CONSTANTS from './gst.action.constant';
const initialState = {
  gstAnalysisData: [],
  gstData: [],
  gstSearchDetails: {},
  gstManualTunover: {},
};
const gstReducer = (state = initialState, action) => {
  const { data, type } = action;
  switch (type) {
    case ACTION_CONSTANTS.FETCH_GST_ANNUAL_ANALYSIS:
      return {
        ...state,
        gstAnalysisData: data,
      };
    case ACTION_CONSTANTS.GET_GST_ANALYSIS:
      return {
        ...state,
        gstAnalysisData: data.mergedGstData,
        gstData: data.gstData,
      };
    case ACTION_CONSTANTS.FETCH_GST_DETAILS:
      return {
        ...state,
        gstSearchDetails: data,
      };
    case ACTION_CONSTANTS.FETCH_GST_MANUAL_TURNOVER:
      return {
        ...state,
        gstManualTunover: data,
      };
    default:
      return state;
  }
};
export default gstReducer;
