import React, { Component } from 'react';
import { rupeeCommaFormatter } from '../../../../utils/utility';
import Correct from '../../../../assets/images/Greentik.png';
export default class SalesByPartyModal extends Component {
  bgColor = (color) => {
    if (color === 'GREEN') {
      return '#ECF9F1';
    } else if (color === 'RED') {
      return '#FFEFEE';
    } else {
      return;
    }
  };

  render() {
    let { gstinAnalysis = {} } = this.props,
      { annualAnalysis = {} } = gstinAnalysis,
      { gstr1SalesAnalysisResponse } = annualAnalysis;
    let { salesAnalysisData = [] } = gstr1SalesAnalysisResponse || {};
    return (
      <div className='sales_form_container'>
        <div className=' box-wrapper'>
          <div className='box-header'>
            <h4>Sales Analysis</h4>
          </div>
          {salesAnalysisData?.length ? (
            <>
              <div className={'table-box'}>
                <table>
                  <thead>
                    <tr>
                      <th>Party </th>
                      <th>Approved Status</th>
                      <th>GST</th>
                      <th>Invoices</th>
                      <th>Avg. monthly fig</th>
                      <th>Median</th>
                      {salesAnalysisData[0]?.monthlySalesData?.map((e) => {
                        return <th>{e?.month.replace('-', '  ')}</th>;
                      })}
                    </tr>
                  </thead>
                  <tbody>
                    {salesAnalysisData?.map((item, index) => {
                      return (
                        <>
                          <tr key={index}>
                            <td className=' sub_column'>{item?.party} </td>
                            <td className='sub_column'>
                              <img src={Correct} alt='icon' />
                            </td>
                            <td className=' sub_column'>{item?.gstNumber}</td>
                            <td>
                              <div className='border_bottom sub_column'>
                                <b>Amount</b>
                              </div>
                              <div className='sub_column'>
                                <b>Count</b>
                              </div>
                            </td>
                            <td>
                              <div className='border_bottom sub_column'>
                                ₹{' '}
                                {rupeeCommaFormatter(item?.avgMonthlyFig) ||
                                  '-'}
                              </div>
                              <div className='sub_column'>
                                &nbsp;&nbsp;&nbsp;
                                {rupeeCommaFormatter(item?.totalInvoiceCount) ||
                                  '-'}
                              </div>
                            </td>
                            <td>
                              <div className='border_bottom sub_column'>
                                ₹{' '}
                                {rupeeCommaFormatter(item?.salesAmountMedian) ||
                                  '-'}
                              </div>
                              <div className='sub_column'>
                                &nbsp;&nbsp;&nbsp;
                                {item?.invoiceCountMedian?.toString() || '-'}
                              </div>
                            </td>
                            {item?.monthlySalesData?.map((e) => {
                              return (
                                <>
                                  <td>
                                    <div
                                      className='border_bottom sub_column'
                                      style={{
                                        backgroundColor: this.bgColor(
                                          e?.category
                                        ),
                                      }}
                                    >
                                      ₹ {rupeeCommaFormatter(e?.value) || '-'}
                                    </div>
                                    <div
                                      className='sub_column'
                                      style={{
                                        backgroundColor: this.bgColor(
                                          e?.category
                                        ),
                                      }}
                                    >
                                      &nbsp;&nbsp;&nbsp;
                                      {e?.txnCount.toString() || '-'}
                                    </div>
                                  </td>
                                </>
                              );
                            })}
                          </tr>
                        </>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              <div className='row d-flex flex-column ml-3'>
                <div className='color-information'>
                  <div class='color-box lesser-median '></div>
                  <div className='ml-2'>
                    Month - Sales is Less than 15% from Median / Mean
                  </div>
                </div>
                <div className='color-information'>
                  <div class='color-box greater-median'></div>
                  <div className='ml-2'>
                    Sales is Greater than 15% from Median / Mean
                  </div>
                </div>
              </div>
            </>
          ) : (
            'No Data Available'
          )}
        </div>
      </div>
    );
  }
}
