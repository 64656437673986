import * as holdInvoicesConstant from './onHoldInvoices.action.constant';
const initialState = {
  holdInvoicesList: {},
  holdInvoicesSummary: {}
};

const HoldInvoicesReducer = (state = initialState, action) => {
  const { type, data } = action;
  switch (type) {
    case holdInvoicesConstant.SET_HOLD_INVOICES_LIST:
      return { ...state, holdInvoicesList: data };
    case holdInvoicesConstant.SET_HOLD_INVOICES_SUMMARY:
      return { ...state, holdInvoicesSummary: data };
    default:
      return state;
  }
};
export default HoldInvoicesReducer;
