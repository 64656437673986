import React, { Component, Fragment } from 'react';

import Icon from 'react-icons-kit';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ContentLoader from 'react-content-loader';
import { SlideDown } from 'react-slidedown';
import 'react-slidedown/lib/slidedown.css';
import { angleUp } from 'react-icons-kit/fa/angleUp';
import { angleDown } from 'react-icons-kit/fa/angleDown';
import { Helmet } from 'react-helmet';
import DocumentBox from './Documentbox';

export class Documents extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
    };
  }

  expandHandler = (type) => {
    this.setState((prevState) => ({
      ...prevState,
      [type]: !prevState[type],
    }));
  };

  render() {
    const { documentList, loanId } = this.props,
      { businessDocs = [], profileDocs = [] } = documentList;

    var dataLoad = null;

    if (documentList) {
      let counter = 0;
      dataLoad = () => {
        return (
          <Fragment>
            <Helmet>
              <meta charSet='utf-8' />
              <title>Documents</title>
            </Helmet>
            <div className={'documents-box'}>
              <div
                className='row'
                onClick={() => this.expandHandler(businessDocs)}
              >
                <div className=''>
                  <h5 className='applicant'>{businessDocs?.businessName}</h5>
                </div>
                <div className='col-xs-1'>
                  <span className={'icon'}>
                    <Icon
                      icon={this.state[businessDocs] ? angleUp : angleDown}
                      size={22}
                    />
                  </span>
                </div>
              </div>
              <SlideDown
                className={'my-dropdown-slidedown'}
                closed={!this.state[businessDocs]}
              >
                <div className='row'>
                  {businessDocs?.documents?.map((doc, index) => {
                    counter = counter + 1;
                    return (
                      <div className=' col-md-4 my-2'>
                        <DocumentBox
                          slidedownNumber={index}
                          key={index}
                          name={'registration_proof'}
                          labelHead={doc?.docType}
                          type={doc?.docName}
                          uniqueId={counter}
                          {...this.props}
                          docType={doc?.docType}
                        />
                      </div>
                    );
                  })}
                </div>
              </SlideDown>
            </div>
            {profileDocs?.map((profileDoc, index) => {
              return (
                <div className={'documents-box'} key={`profile-doc-${index}`}>
                  <div
                    className='row'
                    onClick={() => this.expandHandler(profileDoc?.name)}
                  >
                    <div className='col-xs-11'>
                      <h5>{profileDoc?.name}</h5>
                    </div>
                    <div className='col-xs-1'>
                      <span className={'icon'}>
                        <Icon
                          icon={
                            this.state[profileDoc?.name] ? angleUp : angleDown
                          }
                          size={22}
                        />
                      </span>
                    </div>
                  </div>
                  <SlideDown
                    className={'my-dropdown-slidedown'}
                    closed={!this.state[profileDoc?.name]}
                  >
                    <div className='row'>
                      {profileDoc?.documents?.map((doc, idx) => {
                        counter = counter + 1;
                        return (
                          <div className='col-xs-12 col-md-4'>
                            <DocumentBox
                              slidedownNumber={idx}
                              key={idx}
                              name={'registration_proof'}
                              labelHead={doc?.docType}
                              type={doc?.docName}
                              {...this.props}
                              refreshPage={() => this.fetchDetails()}
                              loanId={loanId}
                              docType={doc?.docType}
                            />
                          </div>
                        );
                      })}
                    </div>
                  </SlideDown>
                </div>
              );
            })}
          </Fragment>
        );
      };
    }

    return (
      <div className={'documents-wrapper cpo-documents-wrapper'}>
        <div className={'cpo-content-box  content-box'}>
          {documentList ? dataLoad() : <ContentLoader type={'facebook'} />}
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      // loaderModal,
      // getdocument,
      // fetchDocuments,
    },
    dispatch
  ),
});

export default connect(mapDispatchToProps)(Documents);
