import React, { Component } from 'react';
import Highcharts from 'highcharts';
import AppCard from '../../../../../components/AppCard';

export default class AreaChart extends Component {
  componentDidMount = () => {
    let { data = {} } = this.props;
    this.HighChartRenderer(data);
  };

  componentDidUpdate(prevProps) {
    let { data } = this.props;
    if (prevProps.data !== data) {
      data && this.HighChartRenderer(data);
    }
  }
  HighChartRenderer(data) {
    let { assetCreation, businessFunding, consumption } = data;
    Highcharts.chart('container', {
      chart: {
        type: 'area'
      },
      title: false,
      xAxis: {
        categories: assetCreation?.map(({ monthAndYear }) => monthAndYear),
        title: {
          text: 'Months'
        }
      },
      yAxis: {
        gridLineWidth: 0,
        title: {
          text: 'Live Loans Count'
        },
        labels: {
          formatter: function() {
            return this.value;
          }
        }
      },
      tooltip: {
        pointFormat: '{series.name}<br/>Live Loans Count : {point.y}'
      },
      plotOptions: {
        area: {
          pointStart: 0,
          marker: {
            enabled: false,
            symbol: 'circle',
            radius: 2,
            states: {
              hover: {
                enabled: true
              }
            }
          }
        }
      },
      legend: {
        layout: 'vertical',
        align: 'right',
        verticalAlign: 'top',
        floating: true,
        backgroundColor: '#FFFFFF'
      },
      series: [
        {
          name: 'Asset Creation',
          color: {
            linearGradient: {
              x1: 0,
              x2: 0,
              y1: 0,
              y2: 1
            },
            stops: [
              [0, '#A490F1'],
              [1, '#ffffff']
            ]
          },
          data:
            assetCreation &&
            assetCreation?.map(({ liveLoanCount }) => liveLoanCount)
        },
        {
          name: 'Business Funding',
          color: {
            linearGradient: {
              x1: 0,
              x2: 0,
              y1: 0,
              y2: 1
            },
            stops: [
              [0, '#1DE9B6'],
              [1, '#ffffff']
            ]
          },
          data:
            businessFunding &&
            businessFunding?.map(({ liveLoanCount }) => liveLoanCount)
        },
        {
          name: 'Consumption',
          color: {
            linearGradient: {
              x1: 0,
              x2: 0,
              y1: 0,
              y2: 1
            },
            stops: [
              [0, '#4687F4'],
              [1, '#ffffff']
            ]
          },
          data:
            consumption &&
            consumption?.map(({ liveLoanCount }) => liveLoanCount)
        }
      ]
    });
  }
  render() {
    let { data = {} } = this.props,
      { assetCreation, businessFunding, consumption } = data;

    return (
      <AppCard
        className='personal_wrapper live-loan-and-outstanding my-5'
        heading='Trade line split by use'
      >
        <figure class='my-5'>
          <div id='container'></div>
        </figure>
        <div className='trade_line_container my-5 '>
          <div>
            {assetCreation ? (
              <div className={'table-box'}>
                <table>
                  <thead>
                    <tr>
                      <th className='px-5'>End of Month</th>
                      {assetCreation?.map(({ monthAndYear }) => {
                        return (
                          <th className='px-3'>
                            {monthAndYear.replace('-', '  ')}
                          </th>
                        );
                      })}
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className='px-5'>Asset Creation</td>
                      {assetCreation?.map(e => {
                        return (
                          <>
                            <td>
                              <div className='border_bottom sub_column px-3'>
                                {e?.liveLoanCount}
                              </div>
                            </td>
                          </>
                        );
                      })}
                    </tr>
                    <tr>
                      <td className='px-5'>Business Funding</td>
                      {businessFunding?.map(e => {
                        return (
                          <>
                            <td>
                              <div className='border_bottom sub_column px-3'>
                                {e?.liveLoanCount}
                              </div>
                            </td>
                          </>
                        );
                      })}
                    </tr>
                    <tr>
                      <td className='px-5'>Consumption</td>
                      {consumption?.map(e => {
                        return (
                          <>
                            <td>
                              <div className='border_bottom sub_column px-3'>
                                {e?.liveLoanCount}
                              </div>
                            </td>
                          </>
                        );
                      })}
                    </tr>
                  </tbody>
                </table>
              </div>
            ) : (
              'No Data Available'
            )}
          </div>
        </div>
      </AppCard>
    );
  }
}
