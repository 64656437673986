const SET_SOA = Symbol('setSoa'),
  SET_LIMIT_ACCOUNT_NUMBER = Symbol('setLimitAccountNumber'),
  SET_ADMIN_SOA_SUMMARY = Symbol('setAdminSoaSummary'),
  SET_SOA_SUMMARY = Symbol('setSoaSummary'),
  SET_SMA_DETAILS = Symbol('setSmaDetails');
export {
  SET_SOA,
  SET_SOA_SUMMARY,
  SET_LIMIT_ACCOUNT_NUMBER,
  SET_ADMIN_SOA_SUMMARY,
  SET_SMA_DETAILS,
};
