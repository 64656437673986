import * as underReviewInvoicesConstant from './underReviewInvoices.action.constant';
const initialState = {
  underReviewInvoicesList: {},
  underReviewInvoicesSummary: {}
};

const UnderReviewInvoicesReducer = (state = initialState, action) => {
  const { type, data } = action;
  switch (type) {
    case underReviewInvoicesConstant.SET_UNDER_REVIEW_INVOICES_LIST:
      return { ...state, underReviewInvoicesList: data };
    case underReviewInvoicesConstant.SET_UNDER_REVIEW_INVOICES_SUMMARY:
      return { ...state, underReviewInvoicesSummary: data };
    default:
      return state;
  }
};
export default UnderReviewInvoicesReducer;
