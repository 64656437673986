import {
  API_GET_INVOICE_LIST,
  API_GET_INVOICE_SUMMARY_LIST,
} from '../../../utils/APIUrls';
import { POST } from '../../../utils/webAPI.service';

import * as draftedConstant from './draftedInvoices.action.constant';

const getDraftedList = (data) => {
  return (dispatch) => {
    return POST(API_GET_INVOICE_LIST, data)
      .then(({ data }) => {
        if (data.code === 200) {
          if (data.data) {
            dispatch({
              type: draftedConstant.SET_DRAFTED_LIST,
              data: data?.data,
            });
          }
        } else {
          alert(data?.message);
        }
        return data;
      })
      .catch((err) => {
        throw err;
      });
  };
};

const getDraftedSummary = (data) => {
  return (dispatch) => {
    return POST(API_GET_INVOICE_SUMMARY_LIST, data)
      .then(({ data }) => {
        if (data.code === 200) {
          if (data.data) {
            dispatch({
              type: draftedConstant.SET_DRAFTED_SUMMARY,
              data: data?.data,
            });
          }
        } else {
          alert(data?.message);
        }
        return data;
      })
      .catch((err) => {
        throw err;
      });
  };
};

export { getDraftedList, getDraftedSummary };
