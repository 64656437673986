import * as leadManagementNavsConstants from './LeadManagementNavs.actions.constants';

const initialState = {
  promoterData: {},
  applicationId: null
};

const leadManagementNavsReducer = (state = initialState, action) => {
  let { type, data } = action;
  switch (type) {
    case leadManagementNavsConstants.SET_PROMOTER_DATA:
      return { ...state, promoterData: data };
    case leadManagementNavsConstants.SET_APPLICATION_ID:
      return { ...state, applicationId: data };
    default:
      return state;
  }
};

export default leadManagementNavsReducer;
