import React, { Component } from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import HeaderTop from '../../../components/HeaderTop';
import { activeDashboard } from '../../../store/actions';
import { Helmet } from 'react-helmet';

class InvoiceManagementNavs extends Component {
  clickHandle = (e, name) => {
    let { history } = this.props;
    if (e.ctrlKey) {
      if ((e.ctrlKey || e.metaKey) && name === 'all') {
        window.open(`/invoice-dashboard/all-invoices`);
      } else if ((e.ctrlKey || e.metaKey) && name === 'disbursed') {
        window.open(`/invoice-dashboard/disbursed-invoices`);
      } else if ((e.ctrlKey || e.metaKey) && name === 'fund-pending') {
        window.open(`/invoice-dashboard/fund-pending-invoices`);
      } else if ((e.ctrlKey || e.metaKey) && name === 'under-review') {
        window.open(`/invoice-dashboard/under-review-invoices`);
      } else if ((e.ctrlKey || e.metaKey) && name === 'on-hold') {
        window.open(`/invoice-dashboard/on-hold-invoices`);
      } else if ((e.ctrlKey || e.metaKey) && name === 'failed') {
        window.open(`/invoice-dashboard/failed-invoices`);
      } else if ((e.ctrlKey || e.metaKey) && name === 'margin') {
        window.open(`/invoice-dashboard/margin-invoices`);
      } else if ((e.ctrlKey || e.metaKey) && name === 'drafted') {
        window.open(`/invoice-dashboard/drafted-invoices`);
      }
    } else if (e.button === 0) {
      if (name === 'all') {
        history.push(`/invoice-dashboard/all-invoices`);
      } else if (name === 'disbursed') {
        history.push(`/invoice-dashboard/disbursed-invoices`);
      } else if (name === 'fund-pending') {
        history.push(`/invoice-dashboard/fund-pending-invoices`);
      } else if (name === 'under-review') {
        history.push(`/invoice-dashboard/under-review-invoices`);
      } else if (name === 'on-hold') {
        history.push(`/invoice-dashboard/on-hold-invoices`);
      } else if (name === 'failed') {
        history.push(`/invoice-dashboard/failed-invoices`);
      } else if (name === 'margin') {
        history.push(`/invoice-dashboard/margin-invoices`);
      } else if (name === 'drafted') {
        history.push(`/invoice-dashboard/drafted-invoices`);
      }
    }
  };

  render() {
    let { active, activeDashboard } = this.props;

    return (
      <div className='dashboard-wrapper'>
        <Helmet>
          <meta charSet='utf-8' />
          <title>Invoice Dashboard</title>
        </Helmet>
        <HeaderTop {...this.props}>
          <div className='buttons-box'>
            <div className='toggle-buttons'>
              <div
                onClick={() => {
                  let { history, actions } = this.props;
                  actions.activeDashboard('user_dashboard');
                  history.push(`/pay-app/dashboard`);
                }}
                className={
                  activeDashboard === 'user_dashboard'
                    ? 'active-button button-left '
                    : 'button-left '
                }
              >
                User Dashboard
              </div>
              <div
                onClick={() => {
                  let { history, actions } = this.props;
                  actions.activeDashboard('invoice_dashboard');
                  history.push(`/invoice-dashboard/all-invoices`);
                }}
                className={
                  activeDashboard === 'invoice_dashboard'
                    ? 'active-button button-left button-center '
                    : 'button-left button-center '
                }
              >
                Invoice Dashboard
              </div>
              <div
                onClick={() => {
                  let { history, actions } = this.props;
                  actions.activeDashboard('anchor_dashboard');
                  history.push(`/anchor-dashboard`);
                }}
                className={
                  activeDashboard === 'anchor_dashboard'
                    ? 'active-button button-left button-right '
                    : 'button-left button-right '
                }
              >
                Anchor Dashboard
              </div>
            </div>
          </div>
        </HeaderTop>
        <section className={'leadmanager-navs-wrapper '}>
          <Navbar>
            <Nav className='credit-navs  container' activeKey={active}>
              <Nav.Link
                eventKey='all-invoices'
                onClick={(e) => this.clickHandle(e, 'all')}
              >
                ALL INVOICES
              </Nav.Link>
              <Nav.Link
                eventKey='disbursed-invoices'
                onClick={(e) => this.clickHandle(e, 'disbursed')}
              >
                DISBURSED INVOICES
              </Nav.Link>
              <Nav.Link
                eventKey='fund-pending-invoices'
                onClick={(e) => this.clickHandle(e, 'fund-pending')}
              >
                FUND PENDING INVOICES
              </Nav.Link>
              <Nav.Link
                eventKey='under-review-invoices'
                onClick={(e) => this.clickHandle(e, 'under-review')}
              >
                UNDER REVIEW INVOICES
              </Nav.Link>
              <Nav.Link
                eventKey='on-hold-invoices'
                onClick={(e) => this.clickHandle(e, 'on-hold')}
              >
                ON-HOLD INVOICES
              </Nav.Link>
              <Nav.Link
                eventKey='failed-invoices'
                onClick={(e) => this.clickHandle(e, 'failed')}
              >
                FAILED INVOICES
              </Nav.Link>
              <Nav.Link
                eventKey='drafted-invoices'
                onClick={(e) => this.clickHandle(e, 'drafted')}
                className='width'
              >
                DRAFT INVOICES
              </Nav.Link>
              <Nav.Link
                eventKey='margin-invoices'
                onClick={(e) => this.clickHandle(e, 'margin')}
                className='width'
              >
                MARGIN
              </Nav.Link>
            </Nav>
          </Navbar>
        </section>
      </div>
    );
  }
}

InvoiceManagementNavs.defaultProps = {
  active: '',
};

const mapStateToProps = ({ data }) => ({
  activeDashboard: data.activeDashboard,
});
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      activeDashboard,
    },
    dispatch
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InvoiceManagementNavs);
