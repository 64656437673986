import React, { Component } from 'react';
import {
  API_SUBMIT_BOUNCE_RATIO,
  API_SUBMIT_BOUNCE_RATIO_ACC
} from '../../../../utils/APIUrls';
import { POST } from '../../../../utils/webAPI.service';
import get from 'lodash/get';
import { ErrorMessage } from '../../../../components/ErrorMessage/ErrorMessage';
import { connect } from 'react-redux';

class EditRatioForm1 extends Component {
  state = {
    chequeBounces: 0,
    totalChequesIssued: 0,
    bounceRatio: 0,
    status: 'Outward',
    error: ''
  };

  handleChange = event => {
    const { value, name } = event.target;
    this.setState({ [name]: value });
  };

  submit = event => {
    event.preventDefault();
    this.props.closeAction();
  };

  bounceRatioFun = br => {
    this.setState({
      bounceRatio: br
    });
    this.props.sendData1(br);
  };

  submit = async event => {
    event.preventDefault();
    let { props, applicationId } = this.props,
      { status, chequeBounces, totalChequesIssued } = this.state,
      accountNumber = props.analysisData.accountNumber,
      denominator = parseInt(totalChequesIssued),
      numerator = parseInt(chequeBounces);
    if (
      applicationId &&
      status &&
      numerator.toString() &&
      denominator.toString() &&
      (accountNumber === 'ALL' ||
        accountNumber === null ||
        accountNumber === '')
    ) {
      let data = { denominator, numerator };
      const headers = { 'Content-Type': 'application/json' };
      await POST(API_SUBMIT_BOUNCE_RATIO(applicationId, status), data, {
        headers
      })
        .then(({ data }) => {
          let { status, message } = data;
          if (status === 'success') {
            let br = get(data, 'data.bounceRatio');
            this.bounceRatioFun(br);
            this.props.closeAction();
          } else {
            this.setState({ error: message });
          }
        })
        .catch(err => {
          this.setState({
            error: 'Something went wrong please try again later.'
          });
        });
    } else if (
      applicationId &&
      status &&
      numerator.toString() &&
      denominator.toString() &&
      accountNumber !== 'ALL' &&
      accountNumber !== null &&
      accountNumber !== ''
    ) {
      let data = { denominator, numerator };
      const headers = { 'Content-Type': 'application/json' };
      await POST(
        API_SUBMIT_BOUNCE_RATIO_ACC(applicationId, status, accountNumber),
        data,
        { headers }
      )
        .then(({ data }) => {
          let { status, message } = data;
          if (status === 'success') {
            let br = get(data, 'data.bounceRatio');
            this.bounceRatioFun(br);
            this.props.closeAction();
          } else {
            this.setState({ error: message });
          }
        })
        .catch(err => {
          this.setState({
            error: 'Something went wrong please try again later.'
          });
        });
    } else {
      this.setState({ error: 'please enter Numerator and Denominator.' });
    }
  };

  async componentDidMount() {
    let { props, applicationId } = this.props,
      { status } = this.state;
    let accountNumber = get(props.analysisData, 'accountNumber', '');
    if (applicationId && status && accountNumber === 'ALL') {
      await props.actions
        .getEditRatio(applicationId, status)
        .then(({ data }) => {
          data &&
            this.setState({
              chequeBounces: data.numerator,
              totalChequesIssued: data.denominator
            });
        });
    } else if (applicationId && status && accountNumber !== 'ALL') {
      await props.actions
        .getEditRatioAcc(applicationId, status, accountNumber)
        .then(({ data }) => {
          data &&
            this.setState({
              chequeBounces: data.numerator,
              totalChequesIssued: data.denominator
            });
        });
    } else {
      this.setState({ error: 'something wrong went' });
    }
  }

  render() {
    const { totalChequesIssued, chequeBounces } = this.state;
    return (
      <form onSubmit={this.submit} className='edit-ratio-form'>
        <div className='input-container'>
          <input
            className='input credit-input'
            name='chequeBounces'
            value={chequeBounces}
            placeholder='Cheques Bounces'
            type='text'
            onChange={this.handleChange}
          />
          <span>/</span>
          <input
            className='input debit-input'
            name='totalChequesIssued'
            placeholder='Total Cheques issued'
            value={totalChequesIssued}
            type='text'
            onChange={this.handleChange}
          />
        </div>
        {this.state.error ? (
          <ErrorMessage>{this.state.error}</ErrorMessage>
        ) : null}
        <div className='row center_align'>
          <button className='submit' type='submit'>
            Submit
          </button>
        </div>
      </form>
    );
  }
}
const mapStateToProps = ({ leadManagementNavsReducer }) => ({
  applicationId: leadManagementNavsReducer?.applicationId
});

export default connect(mapStateToProps, null)(EditRatioForm1);
