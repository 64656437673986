import React, { Component } from 'react';

import { rupeeDecimalFormatter } from '../../../utils/utility';

export default class SubProgramModal extends Component {
  render() {
    let { subProgramDetails } = this.props;
    return (
      <div className='modal-wrapper'>
        <h3 className='card_heading'>Sub Program</h3>

        <h2 className='card_heading_sub ml-4 pl-4 mt-4'>
          {' '}
          Limit Configuration
        </h2>
        <div className='kyc_modal_wrapper'>
          <div className='row ckyc-wrapper'>
            <div className='card__wrapper col-12'>
              <div className='card-heading text-dark '>
                <div className='col-4'></div>
                <div className='col-4'>
                  <h2 className='card_heading_sub text-dark font-weight-normal ml-n3  mt-1'>
                    Min
                  </h2>
                </div>
                <div className='col-4'>
                  <h2 className='card_heading_sub text-dark font-weight-normal  mt-1'>
                    Max
                  </h2>
                </div>
              </div>
              <div className='row m-10  application-wrapper ckyc-wrapper'>
                <div className='col-4 '>
                  <h2 className='card_heading_sub  text-secondary font-weight-normal  '>
                    Limit Amount
                  </h2>
                </div>
                <div className='col-4 d-flex align-items-end'>
                  <h5 className='text-dark  font-weight-normal'>
                    {rupeeDecimalFormatter(subProgramDetails?.minLimit, '₹')}
                  </h5>
                </div>
                <div className='col-4 d-flex align-items-end'>
                  <h5 className='text-dark font-weight-normal'>
                    {rupeeDecimalFormatter(subProgramDetails?.maxLimit, '₹')}
                  </h5>
                </div>
                <div className='col-4'>
                  <h2 className='card_heading_sub  text-secondary font-weight-normal  '>
                    Invoice Amount
                  </h2>
                </div>
                <div className='col-4 d-flex d-flex align-items-end'>
                  <h5 className=' text-dark font-weight-normal'>
                    {rupeeDecimalFormatter(
                      subProgramDetails?.minInvoiceAmount,
                      '₹'
                    )}
                  </h5>
                </div>
                <div className='col-4 d-flex align-items-end'>
                  <h5 className=' text-dark font-weight-normal'>
                    {rupeeDecimalFormatter(
                      subProgramDetails?.maxInvoiceAmount,
                      '₹'
                    )}
                  </h5>
                </div>
                <div className='col-4 '>
                  <h2 className='card_heading_sub  text-secondary font-weight-normal  '>
                    Limit Validity (Days)
                  </h2>
                </div>

                <div className='col-4 d-flex align-items-end'>
                  <h5 className=' text-dark font-weight-normal  '>
                    {subProgramDetails?.minLimitTenureInDays ?? '-'}
                  </h5>
                </div>
                <div className='col-4 d-flex align-items-end'>
                  <h5 className=' text-dark font-weight-normal  '>
                    {subProgramDetails?.maxLimitTenureInDays ?? '-'}
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* StandarCard */}
        <h2 className='card_heading_sub ml-4 pl-4 mt-2'>
          Standard Interest & Terms
        </h2>
        <div className='kyc_modal_wrapper'>
          <div className='row ckyc-wrapper'>
            <div className='card__wrapper col-12'>
              <div className='card-heading text-dark '>
                <div className='col-4'></div>
                <div className='col-4'>
                  <h2 className='card_heading_sub text-dark font-weight-normal ml-n3  mt-1'>
                    Min
                  </h2>
                </div>
                <div className='col-4'>
                  <h2 className='card_heading_sub text-dark font-weight-normal  mt-1'>
                    Max
                  </h2>
                </div>
              </div>
              <div className='row m-10 mt-4 application-wrapper ckyc-wrapper'>
                <div className='col-4 '>
                  <h2 className='card_heading_sub   text-secondary font-weight-normal  '>
                    Interest Rate (APR%)
                  </h2>
                </div>

                <div className='d-flex align-items-end col-4'>
                  <h5 className=' text-dark font-weight-normal  '>
                    {subProgramDetails?.minIntRate ?? '-'}
                  </h5>
                </div>
                <div className='d-flex align-items-end col-4'>
                  <h5 className=' text-dark font-weight-normal  '>
                    {subProgramDetails?.maxIntRate ?? '-'}
                  </h5>
                </div>
                <div className='col-4 '>
                  <h2 className='card_heading_sub   text-secondary font-weight-normal  '>
                    Extension Interest Rate (APR%)
                  </h2>
                </div>
                <div className='d-flex align-items-end col-4'>
                  <h5 className=' text-dark font-weight-normal  '>
                    {subProgramDetails?.minExtensionInterestRate ?? '-'}
                  </h5>
                </div>
                <div className='d-flex align-items-end col-4'>
                  <h5 className=' text-dark font-weight-normal  '>
                    {subProgramDetails?.maxExtensionInterestRate ?? '-'}
                  </h5>
                </div>

                <div className='col-4'>
                  <h2 className='card_heading_sub   text-secondary font-weight-normal  '>
                    Overdue Interest Rate (APR%)
                  </h2>
                </div>

                <div className='d-flex align-items-end col-4'>
                  <h5 className=' text-dark font-weight-normal  '>
                    {subProgramDetails?.minOverDueIntRate ?? '-'}
                  </h5>
                </div>
                <div className='d-flex align-items-end col-4'>
                  <h5 className='  text-dark font-weight-normal  '>
                    {subProgramDetails?.maxOverDueIntRate ?? '-'}
                  </h5>
                </div>
                <div className='col-4'>
                  <h2 className='card_heading_sub  text-secondary font-weight-normal  '>
                    Total Tenure (Days)
                  </h2>
                </div>
                <div className='d-flex align-items-end col-4'>
                  <h5 className='  text-dark font-weight-normal  '>
                    {subProgramDetails?.minTenure ?? '-'}
                  </h5>
                </div>
                <div className='d-flex align-items-end col-4'>
                  <h5 className='  text-dark font-weight-normal  '>
                    {subProgramDetails?.maxTenure ?? '-'}
                  </h5>
                </div>
                <div className='col-4'>
                  <h2 className='card_heading_sub  text-secondary font-weight-normal  '>
                    Extension (Days)
                  </h2>
                </div>
                <div className='d-flex align-items-end col-4'>
                  <h5 className='text-dark font-weight-normal  '>
                    {subProgramDetails?.minExtension ?? '-'}
                  </h5>
                </div>
                <div className='d-flex align-items-end col-4'>
                  <h5 className=' text-dark font-weight-normal  '>
                    {subProgramDetails?.maxExtension ?? '-'}
                  </h5>
                </div>
                <div className='col-4'>
                  <h2 className='card_heading_sub  text-secondary font-weight-normal  '>
                    Grace Period (Days)
                  </h2>
                </div>
                <div className='d-flex align-items-end col-4'>
                  <h5 className=' text-dark font-weight-normal  '>
                    {subProgramDetails?.minGracePeriodDays ?? '-'}
                  </h5>
                </div>
                <div className='d-flex align-items-end col-4'>
                  <h5 className=' text-dark font-weight-normal  '>
                    {subProgramDetails?.maxGracePeriodDays ?? '-'}
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Subvention */}
        <h2 className='card_heading_sub ml-4 pl-4 mt-1'>Subvention</h2>
        <div className='kyc_modal_wrapper mt-1'>
          <div className='row ckyc-wrapper '>
            <div className=' row card__wrapper  col-12'>
              <div className=' col-4'>
                <div className=' mt-4 col-12'>
                  <h2 className='card_heading_sub ml-3 text-secondary font-weight-normal  mt-5'>
                    Applicable
                  </h2>
                  <div className='ml-3 mt-3 mb-5'>
                    {subProgramDetails?.subvention?.applicable === true
                      ? `Yes`
                      : subProgramDetails?.subvention?.applicable === false
                      ? `No`
                      : null}
                  </div>
                </div>
              </div>
              {/* Interest Borne By */}
              <div className=' col-4'>
                <div className=' mt-4 col-12'>
                  <h2 className='card_heading_sub ml-3 text-secondary font-weight-normal  mt-5'>
                    Interest Borne By
                  </h2>
                  <div className='ml-3 mt-3 mb-5'>
                    {subProgramDetails?.subvention?.bearer === 'seller'
                      ? `Seller`
                      : subProgramDetails?.subvention?.bearer === 'buyer'
                      ? `Buyer`
                      : subProgramDetails?.subvention?.bearer === 'third_party'
                      ? `Third Party`
                      : null}
                  </div>
                </div>
              </div>
              <div className=' col-4'>
                <div className=' mt-4 col-12'>
                  <h2 className='card_heading_sub ml-3 text-secondary font-weight-normal  mt-5'>
                    Interest Collected
                  </h2>
                  <div className='ml-3 mt-3 mb-5'>
                    {subProgramDetails?.subvention?.collection === 'upfront'
                      ? `Upfront`
                      : subProgramDetails?.subvention?.collection ===
                        'rear_ended'
                      ? `Rear-Ended`
                      : null}
                  </div>
                </div>
              </div>
              <div className='kyc_modal_wrapper mt-n3 mr-5'>
                <div className='row ckyc-wrapper'>
                  <div className='card__wrapper col-12'>
                    <div className='card-heading  '>
                      <div className='col-4'></div>
                      <div className='col-4'>
                        <h2 className='card_heading_sub text-dark font-weight-normal ml-n3   mt-1'>
                          Min
                        </h2>
                      </div>
                      <div className='col-4'>
                        <h2 className='card_heading_sub text-dark font-weight-normal  mt-1'>
                          Max
                        </h2>
                      </div>
                    </div>
                    <div className='row m-10 application-wrapper ckyc-wrapper'>
                      <div className='col-4 '>
                        <h2 className='card_heading_sub   text-secondary font-weight-normal'>
                          Interest Rate (APR%)
                        </h2>
                      </div>
                      <div className='d-flex align-items-end col-4'>
                        <h5 className=' text-dark font-weight-normal  '>
                          {subProgramDetails?.subvention?.rateOfInt?.min ?? '-'}
                        </h5>
                      </div>
                      <div className='d-flex align-items-end col-4'>
                        <h5 className='text-dark font-weight-normal  '>
                          {subProgramDetails?.subvention?.rateOfInt?.max ?? '-'}
                        </h5>
                      </div>
                      <div className='col-4 '>
                        <h2 className='card_heading_sub  text-secondary font-weight-normal  '>
                          Period (Days)
                        </h2>
                      </div>
                      <div className='d-flex align-items-end col-4'>
                        <h5 className='text-dark font-weight-normal  '>
                          {subProgramDetails?.subvention?.periodInDays?.min ??
                            '-'}
                        </h5>
                      </div>
                      <div className='d-flex align-items-end col-4'>
                        <h5 className='text-dark font-weight-normal  '>
                          {subProgramDetails?.subvention?.periodInDays?.max ??
                            '-'}
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Margin */}
        <h2 className='card_heading_sub ml-4 pl-4 mt-1'>Margin</h2>
        <div className='kyc_modal_wrapper'>
          <div className='row ckyc-wrapper '>
            <div className=' row card__wrapper  col-12'>
              <div className=' col-4'>
                <div className=' mt-4 col-12'>
                  <h2 className='card_heading_sub ml-3 text-secondary font-weight-normal  mt-5'>
                    Applicable
                  </h2>
                  <div className='ml-3 mt-3 mb-5'>
                    {subProgramDetails?.margin?.applicable === true
                      ? `Yes`
                      : subProgramDetails?.margin?.applicable === false
                      ? `No`
                      : null}
                  </div>
                </div>
              </div>
              <div className='kyc_modal_wrapper mt-n5'>
                <div className='row ckyc-wrapper'>
                  <div className='card__wrapper col-12'>
                    <div className='card-heading text-dark '>
                      <div className='col-4'></div>
                      <div className='col-4'>
                        <h2 className='card_heading_sub text-dark font-weight-normal ml-n3  mt-1'>
                          Min
                        </h2>
                      </div>
                      <div className='col-4'>
                        <h2 className='card_heading_sub text-dark font-weight-normal  mt-1'>
                          Max
                        </h2>
                      </div>
                    </div>
                    <div className='row m-10 mt-4 application-wrapper ckyc-wrapper'>
                      <div className='col-4 '>
                        <h2 className='card_heading_sub   text-secondary font-weight-normal  '>
                          Margin (%)
                        </h2>
                      </div>

                      <div className='d-flex align-items-end col-4'>
                        <h5 className=' text-dark font-weight-normal  '>
                          {subProgramDetails?.margin?.perMargin?.min ?? '-'}
                        </h5>
                      </div>
                      <div className='d-flex align-items-end col-4'>
                        <h5 className='text-dark font-weight-normal  '>
                          {subProgramDetails?.margin?.perMargin?.max ?? '-'}
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* SeTup Fee */}
        <div className='kyc_modal_wrapper'>
          <div className='row ckyc-wrapper'>
            <div className='card__wrapper col-12'>
              <div className='card-heading text-dark '>
                <div className='col-4'></div>
                <div className='col-4'>
                  <h2 className='card_heading_sub text-dark font-weight-normal ml-n3  mt-1'>
                    Min
                  </h2>
                </div>
                <div className='col-4'>
                  <h2 className='card_heading_sub text-dark font-weight-normal  mt-1'>
                    Max
                  </h2>
                </div>
              </div>
              <div className='row m-10 mt-4 application-wrapper ckyc-wrapper'>
                <div className='col-4'>
                  <h2 className='card_heading_sub   text-secondary font-weight-normal  '>
                    Setup fees(Rupees)
                  </h2>
                </div>
                <div className='d-flex align-items-end col-4'>
                  <h5 className='text-dark font-weight-normal'>
                    {rupeeDecimalFormatter(
                      subProgramDetails?.minSetupFees,
                      '₹'
                    )}
                  </h5>
                </div>
                <div className='d-flex align-items-end col-4'>
                  <h5 className='text-dark font-weight-normal'>
                    {rupeeDecimalFormatter(
                      subProgramDetails?.maxSetupFees,
                      '₹'
                    )}
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* settlementSuborder */}

        <div className=' row ckyc-wrapper   card__wrapper  col-12'>
          <div className=' col-12'>
            <div className='card__wrapper mt-4 col-12'>
              <h2 className='card_heading_sub ml-3 text-secondary font-weight-normal  mt-5'>
                Settlement Suborder
              </h2>
              <div className='ml-3 mt-3 mb-5'>
                {subProgramDetails?.settlementSubOrder === 'vertical_ipc'
                  ? `Vertical IPC`
                  : subProgramDetails?.settlementSubOrder === 'vertical_pic'
                  ? `Vertical PIC`
                  : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
