import axios from 'axios';

const createHeader = (_URL, options = {}) => {
  const token = localStorage.getItem('token'),
    userId = localStorage.getItem('userId');

  options = {
    ...options,
    headers: {
      'X-CLIENT-NAME': 'PDU',
      'X-USER-ID': userId,
      Authorization: `Bearer ${token}`,
    },
  };

  return { URL: `${_URL}`, options };
};

const POST = (_URL, data = null, _options) => {
  let { URL, options } = createHeader(_URL, _options);
  return axios.post(URL, data, options);
};

const GET = (_URL, API_KEY, _options) => {
  let { URL, options } = createHeader(_URL, _options);
  return axios.get(URL, options);
};

const PUT = (_URL, data = null, API_KEY, _options) => {
  let { URL, options } = createHeader(_URL, _options);
  return axios.put(URL, data, options);
};

export { POST, GET, PUT };
