import React, { Component } from 'react';
import Icon from 'react-icons-kit';
import { rupee } from 'react-icons-kit/fa/rupee';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Highcharts from 'highcharts';
import FunnelChart from '../../../../components/FunnelChart/FunnelChart';
import list from '../../../../assets/images/list.png';
import gstManual from '../../../../assets/images/manualGstUpdate.png';
import GstManualUpdateModal from './GstManualUpdateModal';
import {
  setHeaderFixed,
  rupeeCommaFormatter,
  integerToWordFormatter,
  integerToWordFormatterLabels,
  roundUpDecimalConverter,
  titleCase,
} from '../../../../utils/utility';
import { getGSTINAnnualAnalysis, getGstAnalysis } from './gst.action';
import { map, some, uniq, get } from 'lodash';
import LoaderModal from '../../../../components/LoaderModal';
import * as config from '../Banking/banking.config';
import { Helmet } from 'react-helmet';
import ReactTable from 'react-table';
import { loaderModal } from '../../../../store/actions';
import { createBrowserHistory } from 'history';
import { Tabs, Tab } from 'react-bootstrap';
import CredoModal from '../../../../components/CredoModal';
import SalesByPartyModal from './SalesByPartyModal';
import kycComparisionModal from './kyccomparisonModal';
import BusinessSummaryModal from './B2bSummaryModal';
import { getApplicationId } from '../../LeadManagementNavs/LeadManagementNavs.actions';
export const hist = createBrowserHistory();
class GstAnalysis extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
      modalType: '',
      charts: {
        turnover: {
          response: true,
          render: false,
          data: [
            {
              name: 'GST',
              data: [
                49.9, 71.5, 106.4, 129.2, 144.0, 176.0, 135.6, 148.5, 216.4,
                194.1, 95.6, 54.4,
              ],
              color: '#36ddd1',
            },
            {
              name: 'Banking',
              data: [
                83.6, 78.8, 98.5, 93.4, 106.0, 84.5, 105.0, 104.3, 91.2, 83.5,
                106.6, 92.3,
              ],
              color: '#3670cc',
            },
          ],
        },
        inter_intra: {
          response: true,
          render: false,
          data: [
            {
              name: 'B2B',
              data: [5, 3, 4, 7, 2, 5, 3, 4, 7, 2, 5, 3],
              color: '#3670cc',
            },
            {
              name: 'B2CS',
              data: [2, 2, 3, 2, 1, 2, 2, 3, 2, 12, 2, 10],
              color: '#36ddd1',
            },
            {
              name: 'B2CL',
              data: [2, 2, 3, 2, 1, 2, 2, 3, 2, 12, 2, 10],
              color: 'rgb(194, 255, 251)',
            },
            {
              name: 'Exports',
              data: [2, 2, 3, 2, 1, 2, 2, 3, 2, 12, 2, 10],
              color: 'rgb(102, 201, 242)',
            },
            {
              name: 'NIL',
              data: [2, 2, 3, 2, 1, 2, 2, 3, 2, 12, 2, 10],
              color: 'rgb(14,117,159)',
            },
          ],
        },
        domestic_exports: {
          response: true,
          render: false,
          data: [
            {
              name: 'Exports',
              data: [5, 3, 4, 7, 2, 5, 3, 4, 7, 2, 5, 3],
              color: '#3670cc',
            },
            {
              name: 'Domestic',
              data: [2, 2, 3, 2, 1, 2, 2, 3, 2, 12, 2, 10],
              color: '#36ddd1',
            },
          ],
        },
        tax: {
          response: true,
          render: false,
          data: [
            {
              name: 'Tax Liability',
              data: [5, 9, 10, 4, 6, 5, 9, 10, 4, 6, 5, 9],
              color: 'rgb(0, 16, 97)',
              stack: 'tax',
            },
            {
              name: 'ITC',
              data: [3, 4, 4, 2, 5, 3, 4, 4, 2, 5, 3, 4],
              color: '#a4a1fb',
              stack: 'itc',
            },
            {
              name: 'Cash',
              data: [2, 5, 6, 2, 1, 2, 5, 6, 2, 1, 2, 5],
              color: '#36ddd1',
              stack: 'itc',
            },
          ],
        },
        sales_purchase: {
          response: true,
          render: false,
          data: [
            {
              name: 'Turnover',
              data: [
                49.9, 71.5, 106.4, 129.2, 144.0, 176.0, 135.6, 148.5, 216.4,
                194.1, 95.6, 54.4,
              ],
              color: '#56d9fe',
              type: 'column',
            },
            {
              name: 'Purchase',
              data: [
                83.6, 78.8, 98.5, 93.4, 106.0, 84.5, 105.0, 104.3, 91.2, 83.5,
                106.6, 92.3,
              ],
              color: '#3670cc',
              type: 'column',
            },
            {
              type: 'spline',
              name: 'Ratio',
              data: [
                230, 200.67, 239, 260.33, 293.33, 230, 200.67, 239, 260.33,
                293.33, 230, 200.67,
              ],
              marker: {
                lineWidth: 2,
                lineColor: '#001061',
                fillColor: '#001061',
              },
            },
          ],
        },
      },
    };
  }

  componentWillMount() {
    window.scroll(0, 0);
  }

  componentDidMount = async () => {
    window.addEventListener('scroll', setHeaderFixed);
    let { actions, applicationId, match } = this.props;

    actions.loaderModal(true);
    if (!applicationId) {
      await actions.getApplicationId(match?.params?.userId);
      actions.getGstAnalysis(this.props.applicationId);
    } else {
      await actions.getGstAnalysis(applicationId);
    }
    actions.loaderModal(false);
  };
  chartViewHandler = (checked) => {
    this.setState({ checked });
  };

  toggleModal = (name) => {
    this.setState((prevState) => ({
      modalOpen: !prevState.modalOpen,
      modalType: name,
    }));
  };
  handleChange = (e) => {
    let selectedQuarter = e.target.value;
    const { gstinAnalysis } = this.props;
    const analysisData = get(gstinAnalysis, 'annualAnalysis');
    let creditInfoChartConfig = null;
    switch (selectedQuarter) {
      case 'wholeYear':
        creditInfoChartConfig = {
          name: 'gst-sales',
          categories: map(analysisData && analysisData.monthWiseTurnover, (v) =>
            v.month.replace(' ', '<br/>')
          ),
          series: {
            name: (uniq(
              map(analysisData && analysisData.monthWiseTurnover, 'category')
            ) || ['ALL'])[0],
            data: map(analysisData && analysisData.monthWiseTurnover, 'value'),
          },
        };
        break;
      case 'firstQuarter':
        creditInfoChartConfig = {
          name: 'gst-sales',
          categories: map(analysisData && analysisData.monthWiseTurnover, (v) =>
            v.month.replace(' ', '<br/>')
          ).slice(0, 3),
          series: {
            name: (uniq(
              map(analysisData && analysisData.monthWiseTurnover, 'category')
            ).slice(0, 3) || ['ALL'])[0],
            data: map(
              analysisData && analysisData.monthWiseTurnover,
              'value'
            ).slice(0, 3),
          },
        };
        break;
      case 'secondQuarter':
        creditInfoChartConfig = {
          name: 'gst-sales',
          categories: map(analysisData && analysisData.monthWiseTurnover, (v) =>
            v.month.replace(' ', '<br/>')
          ).slice(3, 6),
          series: {
            name: (uniq(
              map(analysisData && analysisData.monthWiseTurnover, 'category')
            ).slice(3, 6) || ['ALL'])[0],
            data: map(
              analysisData && analysisData.monthWiseTurnover,
              'value'
            ).slice(3, 6),
          },
        };
        break;
      case 'thirdQuarter':
        creditInfoChartConfig = {
          name: 'gst-sales',
          categories: map(analysisData && analysisData.monthWiseTurnover, (v) =>
            v.month.replace(' ', '<br/>')
          ).slice(6, 9),
          series: {
            name: (uniq(
              map(analysisData && analysisData.monthWiseTurnover, 'category')
            ).slice(6, 9) || ['ALL'])[0],
            data: map(
              analysisData && analysisData.monthWiseTurnover,
              'value'
            ).slice(6, 9),
          },
        };
        break;
      case 'fourthQuarter':
        creditInfoChartConfig = {
          name: 'gst-sales',
          categories: map(analysisData && analysisData.monthWiseTurnover, (v) =>
            v.month.replace(' ', '<br/>')
          ).slice(9, 12),
          series: {
            name: (uniq(
              map(analysisData && analysisData.monthWiseTurnover, 'category')
            ).slice(9, 12) || ['ALL'])[0],
            data: map(
              analysisData && analysisData.monthWiseTurnover,
              'value'
            ).slice(9, 12),
          },
        };
        break;
      default:
        return null;
    }
    config.generateAreaChart(creditInfoChartConfig);
  };
  componentDidUpdate() {
    let self = this;
    let { charts } = this.state;
    const { loader, gstinAnalysis } = this.props;
    const analysisData = get(gstinAnalysis, 'annualAnalysis');
    if (!loader) {
      const creditInfoChartConfig = {
        name: 'gst-sales',
        categories: map(analysisData && analysisData.monthWiseTurnover, (v) =>
          v.month.replace(' ', '<br/>')
        ),
        series: {
          name: (uniq(
            map(analysisData && analysisData.monthWiseTurnover, 'category')
          ) || ['ALL'])[0],
          data: map(analysisData && analysisData.monthWiseTurnover, 'value'),
        },
      };
      config.generateAreaChart(creditInfoChartConfig);

      window.onscroll = function () {
        if (!charts.turnover.render) {
          let charts = Object.assign({}, self.state.charts);
          charts.turnover.render = true;
          self.setState({ charts }, () => {
            Highcharts.chart(
              'turnover-chart',
              {
                chart: {
                  type: 'column',
                  backgroundColor: 'transparent',
                  height: 250,
                },
                title: false,
                xAxis: {
                  categories: map(
                    analysisData && analysisData.monthWiseGSTBankingTurnover,
                    (v) => v.month.replace(' ', '<br/>')
                  ),
                  minPadding: 0,
                  maxPadding: 0,
                  tickWidth: 0,
                },
                credits: {
                  enabled: false,
                },
                yAxis: {
                  title: false,
                  labels: {
                    formatter: function () {
                      return integerToWordFormatterLabels(this.value);
                    },
                  },
                },
                plotOptions: {
                  series: {
                    pointWidth: 25,
                  },
                },
                tooltip: {
                  formatter: function () {
                    return (
                      '<p>' +
                      this.x +
                      '<br/>' +
                      this.series.name +
                      '<b>:  </b>' +
                      integerToWordFormatter(this.y) +
                      '</p>'
                    );
                  },
                },
                legend: {
                  align: 'center',
                  verticalAlign: 'top',
                  layout: 'horizontal',
                },
                series: [
                  {
                    name: 'GST',
                    data: map(
                      analysisData && analysisData.monthWiseGSTBankingTurnover,
                      'gstValue'
                    ),
                    color: '#36ddd1',
                  },
                  {
                    name: 'Banking',
                    data: map(
                      analysisData && analysisData.monthWiseGSTBankingTurnover,
                      'bankingValue'
                    ),
                    color: '#3670cc',
                  },
                ],
              },
              function (chart) {
                if (chart.series.length === 0) {
                  chart.renderer
                    .text('No Data Available', 140, 120)
                    .css({
                      color: '#001061',
                      fontSize: '16px',
                    })
                    .add();
                }
              }
            );
          });
        }
        if (!charts.inter_intra.render) {
          let charts = Object.assign({}, self.state.charts);
          charts.inter_intra.render = true;
          self.setState({ charts }, () => {
            Highcharts.chart(
              'inter-intra-chart',
              {
                chart: {
                  type: 'column',
                  backgroundColor: 'transparent',
                  height: 250,
                },
                title: false,
                xAxis: {
                  categories: map(
                    analysisData && analysisData.turnoverBreakup,
                    (v) => v.month.replace(' ', '<br/>')
                  ),
                },
                credits: false,
                yAxis: {
                  labels: {
                    formatter: function () {
                      return integerToWordFormatterLabels(this.value);
                    },
                  },
                },
                legend: {
                  align: 'center',
                  verticalAlign: 'top',
                  layout: 'horizontal',
                },
                tooltip: {
                  shared: true,
                  formatter: function () {
                    var s =
                      '<span style="font-size: 10px">' +
                      this.x +
                      '</span><br/>';
                    for (var i = 0; i < this.points.length; i++) {
                      var myPoint = this.points[i];
                      if (myPoint.y !== 0) {
                        s +=
                          '<br/><span style="color:' +
                          myPoint.series.color +
                          ';font-size:20px">\u25CF</span>' +
                          '<span>' +
                          titleCase(myPoint.series.name) +
                          '</span>' +
                          ': ';
                        if (myPoint.point.low && myPoint.point.high) {
                          s += myPoint.point.low + ' - ' + myPoint.point.high;
                        } else {
                          s += integerToWordFormatter(myPoint.y);
                        }
                      }
                    }
                    s +=
                      '<br/><div style="font-size: 15px; margin-top:10px"> Total:' +
                      integerToWordFormatter(this.points[0].total) +
                      '</div><br/>';
                    return s;
                  },
                  outside: true,
                },
                plotOptions: {
                  column: {
                    stacking: 'normal',
                  },
                },
                series: [
                  {
                    name: 'B2B',
                    data: map(
                      analysisData && analysisData.turnoverBreakup,
                      'b2b'
                    ),
                    color: '#3670cc',
                  },
                  {
                    name: 'B2CS',
                    data: map(
                      analysisData && analysisData.turnoverBreakup,
                      'b2cs'
                    ),
                    color: '#36ddd1',
                  },
                  {
                    name: 'B2CL',
                    data: map(
                      analysisData && analysisData.turnoverBreakup,
                      'b2cl'
                    ),
                    color: 'rgb(194, 255, 251)',
                  },
                  {
                    name: 'Exports',
                    data: map(
                      analysisData && analysisData.turnoverBreakup,
                      'month'
                    ),
                    color: 'rgb(102, 201, 242)',
                  },
                  {
                    name: 'NIL',
                    data: map(
                      analysisData && analysisData.turnoverBreakup,
                      'nil'
                    ),
                    color: 'rgb(14,117,159)',
                  },
                ],
              },
              function (chart) {
                if (chart.series.length === 0) {
                  chart.renderer
                    .text('No Data Available', 140, 120)
                    .css({
                      color: '#001061',
                      fontSize: '16px',
                    })
                    .add();
                }
              }
            );
          });
        }
        if (!charts.domestic_exports.render) {
          let charts = Object.assign({}, self.state.charts);
          charts.domestic_exports.render = true;
          self.setState({ charts }, () => {
            Highcharts.chart(
              'domestic-exports-chart',
              {
                chart: {
                  type: 'column',
                  backgroundColor: 'transparent',
                  height: 250,
                },
                title: false,
                xAxis: {
                  categories: map(
                    analysisData && analysisData.monthwiseDomExpTurnover,
                    (v) => v.month.replace(' ', '<br/>')
                  ),
                },
                credits: false,
                yAxis: {
                  title: false,
                  labels: {
                    formatter: function () {
                      return integerToWordFormatterLabels(this.value);
                    },
                  },
                },
                legend: {
                  align: 'center',
                  verticalAlign: 'top',
                  layout: 'horizontal',
                },
                tooltip: {
                  formatter: function (tooltip) {
                    return (
                      '<p>' +
                      this.x +
                      '<br/>' +
                      this.series.name +
                      '<b>:  </b>' +
                      integerToWordFormatter(this.y) +
                      '</p>'
                    );
                  },
                },
                plotOptions: {
                  column: {
                    stacking: 'normal',
                  },
                },
                series: [
                  {
                    name: 'Exports',
                    data: map(
                      analysisData && analysisData.monthwiseDomExpTurnover,
                      'export'
                    ),
                    color: '#3670cc',
                    stack: 'exports',
                  },
                  {
                    name: 'Domestic',
                    data: map(
                      analysisData && analysisData.monthwiseDomExpTurnover,
                      'domestic'
                    ),
                    color: '#36ddd1',
                    stack: 'domestic',
                  },
                ],
              },
              function (chart) {
                if (chart.series.length === 0) {
                  chart.renderer
                    .text('No Data Available', 140, 120)
                    .css({
                      color: '#001061',
                      fontSize: '16px',
                    })
                    .add();
                }
              }
            );
          });
        }
        if (!charts.tax.render) {
          let charts = Object.assign({}, self.state.charts);
          charts.tax.render = true;
          self.setState({ charts }, () => {
            Highcharts.chart(
              'tax-chart',
              {
                chart: {
                  type: 'column',
                  backgroundColor: 'transparent',
                  height: 250,
                },
                title: false,
                xAxis: {
                  categories: map(
                    analysisData && analysisData.monthwiseTaxCashItcTurnover,
                    (v) => v.month.replace(' ', '<br/>')
                  ),
                },
                credits: {
                  enabled: false,
                },
                yAxis: {
                  title: false,
                  labels: {
                    formatter: function () {
                      return integerToWordFormatterLabels(this.value);
                    },
                  },
                },
                plotOptions: {
                  series: {
                    pointWidth: 25,
                  },
                  column: {
                    stacking: 'normal',
                    dataLabels: {
                      enabled: false,
                      style: {
                        textOutline: 'none',
                      },
                    },
                  },
                },
                tooltip: {
                  formatter: function () {
                    return (
                      '<p>' +
                      this.x +
                      '<br/>' +
                      this.series.name +
                      '<b>:  </b>' +
                      integerToWordFormatter(this.y) +
                      '</p>'
                    );
                  },
                },
                legend: {
                  align: 'center',
                  verticalAlign: 'top',
                  layout: 'horizontal',
                },
                series: [
                  {
                    name: 'Tax Liability',
                    data: map(
                      analysisData && analysisData.monthwiseTaxCashItcTurnover,
                      'taxLiability'
                    ),
                    color: 'rgb(54, 112, 204)',
                    stack: 'tax',
                  },
                  {
                    name: 'ITC',
                    data: map(
                      analysisData && analysisData.monthwiseTaxCashItcTurnover,
                      'itcTuronver'
                    ),
                    color: '#a4a1fb',
                    stack: 'itc',
                  },
                  {
                    name: 'Cash',
                    data: map(
                      analysisData && analysisData.monthwiseTaxCashItcTurnover,
                      'cashTurnover'
                    ),
                    color: '#36ddd1',
                    stack: 'itc',
                  },
                ],
              },
              function (chart) {
                if (chart.series.length === 0) {
                  chart.renderer
                    .text('No Data Available', 140, 120)
                    .css({
                      color: '#001061',
                      fontSize: '16px',
                    })
                    .add();
                }
              }
            );
          });
        }
        if (!charts.sales_purchase.render) {
          let charts = Object.assign({}, self.state.charts);
          charts.sales_purchase.render = true;
          self.setState({ charts }, () => {
            Highcharts.chart(
              'sales-purchase-chart',
              {
                chart: {
                  zoomType: 'xy',
                  backgroundColor: 'transparent',
                  height: 250,
                },
                title: false,
                xAxis: {
                  categories: map(
                    analysisData && analysisData.monthwiseGstPurchaseTurnover,
                    (v) => v.month.replace(' ', '<br/>')
                  ),
                },
                credits: {
                  enabled: false,
                },
                yAxis: [
                  {
                    labels: {
                      formatter: function () {
                        return integerToWordFormatterLabels(this.value);
                      },
                    },
                    title: {
                      text: 'Value',
                    },
                  },
                  {
                    gridLineWidth: 0,
                    title: {
                      text: 'Ratio',
                    },
                    labels: {
                      format: '{value} %',
                    },
                    opposite: true,
                  },
                ],
                tooltip: {
                  shared: true,
                  formatter: function () {
                    return (
                      '<div>' +
                      this.x +
                      '<br/><span style="color:' +
                      this.points[0].color +
                      ';font-size:20px">\u25CF</span>' +
                      'Ratio : ' +
                      this.points[0].y?.toFixed(2) +
                      '%' +
                      '<br/><span style="color:' +
                      this.points[1].color +
                      ';font-size:20px">\u25CF</span>' +
                      'Turnover : ' +
                      integerToWordFormatter(this.points[1].y) +
                      '<br/><span style="color:' +
                      this.points[2].color +
                      ';font-size:20px">\u25CF</span>' +
                      'Purchase : ' +
                      integerToWordFormatter(this.points[2].y)
                    );
                  },
                },

                plotOptions: {
                  series: {
                    pointWidth: 25,
                  },
                },
                series: [
                  {
                    type: 'spline',
                    name: 'Ratio',
                    yAxis: 1,
                    data: map(
                      analysisData && analysisData.ratioTurnoverOverPurchases,
                      'value'
                    ),
                    tooltip: {
                      valueSuffix: ' %',
                    },
                  },
                  {
                    name: 'Turnover',
                    data: map(
                      analysisData && analysisData.monthwiseGstPurchaseTurnover,
                      'gst'
                    ),
                    color: '#56d9fe',
                    type: 'column',
                  },
                  {
                    name: 'Purchase',
                    data: map(
                      analysisData && analysisData.monthwiseGstPurchaseTurnover,
                      'purchase'
                    ),
                    color: '#3670cc',
                    type: 'column',
                  },
                ],
              },
              function (chart) {
                if (chart.series.length === 0) {
                  chart.renderer
                    .text('No Data Available', 140, 120)
                    .css({
                      color: '#001061',
                      fontSize: '16px',
                    })
                    .add();
                }
              }
            );
          });
        }
      };
    }
  }
  handleTabChange = async (type, gstin) => {
    let { loaderModal } = this.props.actions;
    let charts = Object.assign({}, this.state.charts);
    for (let key in charts) {
      charts[key].render = false;
      this.setState({ charts });
    }
    loaderModal(true);
    if (type === 'mergedGst') {
      let { actions, applicationId } = this.props;
      if (applicationId) {
        await actions.getGstAnalysis(applicationId);
      }
    } else {
      let { actions, applicationId } = this.props,
        { getGSTINAnnualAnalysis } = actions;
      await getGSTINAnnualAnalysis(applicationId, gstin);
    }
    loaderModal(false);
  };
  componentWillUnmount() {
    window.removeEventListener('scroll', setHeaderFixed);
  }
  render() {
    let { modalOpen, modalType } = this.state;
    const { loader, gstinAnalysis, gstData, loaderModalStatus } = this.props;
    const analysisData = get(gstinAnalysis, 'annualAnalysis') || {},
      comparativeTotalBanking =
        get(gstinAnalysis, 'comparativeTotalBanking') || '0',
      comparativeTotalGst = get(gstinAnalysis, 'comparativeTotalGst') || '0',
      _turnOverGrowthPast12Months = get(analysisData, 'turnoverGrowth') || 0,
      _currentYear = new Date().getFullYear(),
      _currentMonth = new Date().getMonth() + 1,
      _FY = _currentMonth > 4 ? _currentYear : _currentYear - 1,
      hsnDetails = get(gstinAnalysis, 'hsnDetails') || [],
      servicesPurchased = analysisData.servicesPurchased
        ? analysisData.servicesPurchased
        : [],
      servicesSold = analysisData.servicesSold ? analysisData.servicesSold : [],
      contributionByTop10Customers = analysisData.contributionByTop10Customers
        ? analysisData.contributionByTop10Customers
        : [],
      contributionByTop10Products = analysisData.contributionByTop10Products
        ? analysisData.contributionByTop10Products
        : [],
      contributionByTop10Vendors = analysisData.contributionByTop10Vendors
        ? analysisData.contributionByTop10Vendors
        : [],
      isQuarterData = get(analysisData, 'isQuarterData') || false,
      totalPurchase = get(analysisData, 'totalPurchase') || 0,
      businesstype = get(gstinAnalysis, 'businesstype') || '',
      primaryIndustry = get(gstinAnalysis, 'primaryIndustry') || '',
      secondaryIndustry = get(gstinAnalysis, 'secondaryIndustry') || '',
      turnoverTillDate = get(analysisData, 'turnoverTillDate') || 0,
      totalTurnOver = get(analysisData, 'totalTurnOver') || 0,
      averageMonthlyTO = get(analysisData, 'averageMonthlyTO') || 0,
      deviationInRange = get(gstinAnalysis, 'deviationInRange') || false,
      deviationGstOverBanking =
        get(gstinAnalysis, 'deviationGstOverBanking') || 0,
      comparativeMonthAvailableForGSTAndBanking =
        get(gstinAnalysis, 'comparativeMonthAvailableForGSTAndBanking') || 0,
      gstGreaterThanBanking = get(gstinAnalysis, 'gstGreaterThanBanking') || 0,
      averageMonthlyPurchase = get(analysisData, 'averageMonthlyPurchase') || 0,
      averageMonthlyTaxLiability =
        get(analysisData, 'averageMonthlyTaxLiability') || 0,
      effectiveGSTRate = get(analysisData, 'effectiveGSTRate') || 0,
      ratioITCToTaxLiability = get(analysisData, 'ratioITCToTaxLiability') || 0,
      niltoTotalRatio = get(analysisData, 'nilToTotalRatio') || 0,
      b2CLToTotalRatio = get(analysisData, 'b2clToTotalRatio') || 0,
      b2CSToTotalRatio = get(analysisData, 'b2csToTotalRatio') || 0,
      b2BToTotalRatio = get(analysisData, 'b2bToTotalRatio') || 0,
      domesticToTotalRatio = get(analysisData, 'domesticToTotalRatio') || 0,
      exportToTotalRatio = get(analysisData, 'exportToTotalRatio') || 0,
      ratioTaxPaidToTaxLiability =
        get(analysisData, 'ratioTaxPaidToTaxLiability') || 0,
      purchaseToTurnover = get(analysisData, 'purchaseToTurnover') || 0,
      totalExports = get(analysisData, 'totalExports') || 0,
      maxExportMonth = get(analysisData, 'maxExportMonth') || 0,
      totalContributionByTop10Customers =
        get(analysisData, 'totalContributionByTop10Customers') || 0,
      totalContributionByTop10Vendors =
        get(analysisData, 'totalContributionByTop10Vendors') || 0,
      totalContributionByTop10Products =
        get(analysisData, 'totalContributionByTop10Products') || 0,
      highestPurchaseBySingleCustomer =
        get(analysisData, 'highestPurchaseBySingleCustomer') || 0,
      hsnContributionList = get(analysisData, 'hsnContributionList') || [],
      highestToBySingleVendor =
        get(analysisData, 'highestToBySingleVendor') || 0,
      highestContributionBySingleProduct =
        get(analysisData, 'highestContributionBySingleProduct') || 0;

    const HSNTable = [
      {
        Header: 'HSN/SAC Codes',
        accessor: 'hsnCode',
        width: 150,
      },
      {
        Header: 'Primary Industry',
        accessor: 'primaryIndustry',
        width: 200,
      },
      {
        Header: 'Secondary Industry',
        accessor: 'secondaryIndustry',
      },
      {
        Header: 'Sales %',
        width: 100,
        Cell: ({ original }) => {
          return original?.salesPercentage + ' %';
        },
      },
    ];
    return loader ? (
      <LoaderModal status={loaderModalStatus} />
    ) : (
      <div className={'gst-wrapper container'}>
        <Helmet>
          <meta charSet='utf-8' />
          <title>Gst Analysis</title>
        </Helmet>
        <div className='row p-0 upper-section'>
          <div className='col-md-12 p-0'>
            <Tabs
              defaultActiveKey={'mergedGst'}
              id='adminTabs'
              onSelect={this.handleSelect}
            >
              <Tab
                eventKey={'mergedGst'}
                title={
                  <div
                    className='tabs-heading'
                    onClick={() => this.handleTabChange('mergedGst')}
                  >
                    Merged GSTIN
                  </div>
                }
              >
                <div className='box-wrapper'>
                  <div
                    className='box-header bottom-border'
                    style={{ border: 'none' }}
                  >
                    <div className='row'>
                      <h4 className=' col-6'>
                        HSN Codes contributing to 90% Turnover or more:
                      </h4>
                      <div
                        className='heading hover col-6 text-right'
                        onClick={() => this.toggleModal(kycComparisionModal)}
                      >
                        <img src={list}></img>
                        Kyc Comparison
                      </div>
                    </div>
                  </div>
                  <div className='row'>
                    <div className={'col-md-8'} style={{ padding: '0px 10px' }}>
                      <ReactTable
                        className={'no-data no-pagination'}
                        columns={HSNTable}
                        data={hsnDetails ? hsnDetails : []}
                        showPagination={false}
                        resizable={false}
                        minRows={0}
                      />
                    </div>
                  </div>
                </div>
              </Tab>
              {gstData &&
                gstData?.map((el, index) => {
                  return (
                    <Tab
                      eventKey={el.gstin}
                      title={
                        <div
                          className='tabs-heading'
                          onClick={() =>
                            this.handleTabChange('singleGst', el.gstin)
                          }
                        >
                          {el.gstin}
                          <div className='bold-text__smaller'>
                            {el.stateName && el.stateName.length > 15
                              ? `${el.stateName.slice(0, 15)}...`
                              : el.stateName}
                          </div>
                        </div>
                      }
                    >
                      <div className='box-wrapper'>
                        <div className='box-header' style={{ border: 'none' }}>
                          <h4>
                            HSN Codes contributing to 90% Turnover or more:
                          </h4>
                        </div>
                        <div className='row'>
                          <div
                            className={'col-md-12'}
                            style={{ padding: '0px 10px' }}
                          >
                            <ReactTable
                              className={'no-data no-pagination'}
                              columns={HSNTable}
                              data={hsnDetails ? hsnDetails : []}
                              showPagination={false}
                              resizable={false}
                              minRows={0}
                            />
                          </div>
                        </div>
                      </div>
                    </Tab>
                  );
                })}
            </Tabs>
          </div>
        </div>

        <div className={'box-wrapper'}>
          <div className={'row box-header'}>
            <div className={'col-8 p-0'}>
              <h4>
                GST Turnover Trend - Overall Growth In Past 12 Months
                {isQuarterData ? (
                  <small className='quarterDataFlag'>
                    GST Turnover Filed Quarterly
                  </small>
                ) : null}
              </h4>
            </div>
            <div className={'col-4 d-flex justify-content-end p-0'}>
              <div
                className='row hover'
                onClick={() => this.toggleModal(GstManualUpdateModal)}
              >
                <img src={gstManual} className='img-icon' alt='icon'></img>
                <h2 className='manual-gst'>Manual Update</h2>
              </div>
            </div>
          </div>
          {analysisData.monthWiseTurnover &&
          analysisData.monthWiseTurnover.length !== 0 ? (
            <div className={'text-right'}>
              <select className={'select-box'} onChange={this.handleChange}>
                <option value={'wholeYear'}>Set to Default</option>
                {analysisData.monthWiseTurnover.length > 2 ? (
                  <option value={'firstQuarter'}>
                    {analysisData.monthWiseTurnover[0].month +
                      ' - ' +
                      analysisData.monthWiseTurnover[2].month}
                  </option>
                ) : null}

                {analysisData.monthWiseTurnover.length > 5 ? (
                  <option value={'secondQuarter'}>
                    {analysisData.monthWiseTurnover[3].month +
                      ' - ' +
                      analysisData.monthWiseTurnover[5].month}
                  </option>
                ) : null}

                {analysisData.monthWiseTurnover.length > 8 ? (
                  <option value={'thirdQuarter'}>
                    {analysisData.monthWiseTurnover[6].month +
                      ' - ' +
                      analysisData.monthWiseTurnover[8].month}
                  </option>
                ) : null}

                {analysisData.monthWiseTurnover.length > 11 ? (
                  <option value={'fourthQuarter'}>
                    {analysisData.monthWiseTurnover[9].month +
                      ' - ' +
                      analysisData.monthWiseTurnover[11].month}
                  </option>
                ) : null}
              </select>
            </div>
          ) : null}

          <div className={'chart-box'} id={'gst-sales'} />
          <div className={'box-footer'}>
            <ul className={'list-unstyled bottom-border '}>
              <li>
                <label>
                  Turnover in last {analysisData.monthAvailable} months
                </label>
                <span>
                  <span className={'icon'}>
                    <Icon icon={rupee} size={28} />
                  </span>
                  {rupeeCommaFormatter(totalTurnOver?.toFixed(2))}
                </span>
              </li>
              <li>
                <label>Imputed Turnover in last 12 months</label>
                <span>
                  <span className={'icon'}>
                    <Icon icon={rupee} size={28} />
                  </span>
                  {rupeeCommaFormatter(
                    analysisData.imputedTotalTurnoverInLast12Months
                  )}
                </span>
              </li>
              <li>
                <label>Avg. Monthly turnover</label>
                <span>
                  <span className={'icon'}>
                    <Icon icon={rupee} size={28} />
                  </span>
                  {rupeeCommaFormatter(averageMonthlyTO?.toFixed(2))}
                </span>
              </li>
            </ul>
            <ul className={'list-unstyled'}>
              <li>
                <label>Turnover till from April 2022</label>
                <span>
                  <span className={'icon'}>
                    <Icon icon={rupee} size={28} />
                  </span>
                  Na
                </span>
              </li>
              <li>
                <label> Turnover growth in last 12 months</label>
                <span>
                  <span className={'icon'}></span>
                  {roundUpDecimalConverter(analysisData?.turnoverGrowth) ||
                    'NA'}
                </span>
              </li>
              <li>
                <label>Min Turnover &gt; 50lakh</label>
                <span>
                  <span className={'icon'}></span>
                  {analysisData?.minimumTurnOverGreaterThan50Lakhs || 'NA'}
                </span>
              </li>
            </ul>
            <ul className={'list-unstyled'}>
              <li>
                <label>YDy Growth % &gt; 5%</label>
                <span>
                  <span className={'icon'}>
                    <Icon icon={rupee} size={28} />
                  </span>
                  Na
                </span>
              </li>
              <li>
                <label>
                  No of Months where Sales is less than 15% of Median &lt; 2
                </label>
                <span className='ml-5'>
                  <span className={'icon'}>
                    {/* <Icon icon={rupee} size={28} /> */}
                  </span>
                  {analysisData?.salesLessThan15PercOfMedian?.monthsCount ||
                    'NA'}
                </span>
              </li>
              <li></li>
            </ul>
          </div>
        </div>
        <div className={'box-wrapper'}>
          <div className={'box-header'}>
            <h4>
              Turnover Comparison (GST and Banking){' '}
              {isQuarterData ? (
                <small className='quarterDataFlag'>
                  GST Turnover Filed Quarterly
                </small>
              ) : null}
            </h4>
          </div>
          <div className={'chart-box'} id={'turnover-chart'} />
          <div className={'box-footer'}>
            <ul className={'list-unstyled'}>
              <li>
                <label>
                  GST Turnover and Bank Turnover mismatch within &plusmn; 15%
                </label>
                <span>{deviationInRange ? 'Yes' : 'No'}</span>
              </li>
              <li>
                <label>
                  Mismatch of GST from Banking Turnover (Negative indicates
                  higher Banking Turnover)
                </label>
                <span>{deviationGstOverBanking?.toFixed(2)}%</span>
              </li>
              <li>
                <label>Banking sales &lt; Gst sales</label>
                <span>{gstGreaterThanBanking} Times</span>
              </li>
            </ul>

            <ul className={'list-unstyled'}>
              <li>
                <label>Comparative months available for GST and Banking </label>
                <span>{comparativeMonthAvailableForGSTAndBanking} Months</span>
              </li>
              <li>
                <label>Sales as per GST for Common Months</label>
                <span>
                  <span className={'icon'}>
                    <Icon icon={rupee} size={28} />
                  </span>
                  {rupeeCommaFormatter(comparativeTotalGst)}
                </span>
              </li>
              <li>
                <label>Sales as per Banking for Common Months</label>
                <span>
                  <span className={'icon'}>
                    <Icon icon={rupee} size={28} />
                  </span>
                  {rupeeCommaFormatter(comparativeTotalBanking)}
                </span>
              </li>
            </ul>
          </div>
        </div>
        <div className={'box-wrapper'}>
          <div className={'row box-header'}>
            <div className={'col-12 '}>
              <div className='row'>
                <h4 className=' col-8'>
                  {' '}
                  Turnover Breakup (B2B - GST Registered, B2C and Exports -
                  Unregistered)
                </h4>
                <div
                  className='heading hover col-4 text-right'
                  onClick={() => this.toggleModal(BusinessSummaryModal)}
                >
                  <img src={list}></img>
                  B2B Summary
                </div>
              </div>
            </div>
          </div>
          <div className={'chart-box'} id={'inter-intra-chart'} />
          <div className={'box-footer'}>
            <ul className={'list-unstyled'}>
              <li>
                <label>B2B to Total Turnover</label>
                <span>{b2BToTotalRatio.toFixed(2)}%</span>
              </li>
              <li>
                <label>B2CS to Total Turnover</label>
                <span>{b2CSToTotalRatio.toFixed(2)}%</span>
              </li>
              <li>
                <label>B2CL to Total Turnover</label>
                <span>{b2CLToTotalRatio.toFixed(2)}%</span>
              </li>

              <li>
                <label>NIL Rated to Total Turnover</label>
                <span>
                  {niltoTotalRatio ? niltoTotalRatio.toFixed(2) : '0'}%
                </span>
              </li>
            </ul>
          </div>
        </div>
        <div className={'box-wrapper'}>
          <div className={'row box-header'}>
            <div className={'col-xs-12 p-0'}>
              <h4>Turnover Break-up (Domestic & Exports)</h4>
            </div>
          </div>
          <div className={'chart-box'} id={'domestic-exports-chart'} />
          <div className={'box-footer'}>
            <ul className={'list-unstyled'}>
              <li>
                <label>Domestic Turnover Ratio</label>
                <span>{domesticToTotalRatio.toFixed(2)}%</span>
              </li>
              <li>
                <label>Exports Turnover Ratio</label>
                <span>{exportToTotalRatio.toFixed(2)}%</span>
              </li>
              <li>
                <label>Total Exports</label>
                <span>
                  <Icon icon={rupee} size={28} />{' '}
                  {rupeeCommaFormatter(totalExports)}
                </span>
              </li>
              <li>
                <label>Max Export Month</label>
                <span>
                  <Icon icon={rupee} size={28} />
                  {maxExportMonth
                    ? rupeeCommaFormatter(maxExportMonth.split(':')[0])
                    : null}
                  <div
                    style={{ fontSize: '14px', margin: '10px 0px 0px 10px' }}
                  >
                    {maxExportMonth ? maxExportMonth.split(':')[1] : null}
                  </div>
                </span>
              </li>
            </ul>
          </div>
        </div>
        <div className={'box-wrapper'}>
          <div className={' box-header '}>
            <div className='row'>
              <h4 className=' col-10'>Business Concentration Risk</h4>
              <div
                className='heading hover col-2 text-right'
                onClick={() => this.toggleModal(SalesByPartyModal)}
              >
                <img src={list}></img>
                Sales Analysis
              </div>
            </div>
          </div>
          <div className={'row'}>
            <div className={'col-md-4'}>
              <FunnelChart
                circularData={
                  totalContributionByTop10Customers
                    ? totalContributionByTop10Customers.toFixed(1)
                    : '0'
                }
                data={contributionByTop10Customers}
                chartFor={'customer'}
                color={'#36ddd1'}
                title={'Turnover'}
                total={totalTurnOver}
              />
            </div>
            <div className={'col-md-4'}>
              <FunnelChart
                circularData={
                  totalContributionByTop10Vendors
                    ? totalContributionByTop10Vendors.toFixed(1)
                    : '0'
                }
                data={contributionByTop10Vendors}
                chartFor={'vendor'}
                color={'#56d9fe'}
                title={'Purchases'}
                total={totalPurchase}
              />
            </div>
            <div className={'col-md-4'}>
              <FunnelChart
                circularData={
                  totalContributionByTop10Products
                    ? totalContributionByTop10Products.toFixed(1)
                    : '0'
                }
                data={contributionByTop10Products}
                chartFor={'product'}
                color={'#3670cc'}
                title={'Product/Services'}
              />
            </div>
          </div>
          <div className={'box-footer'}>
            <ul className={'list-unstyled'}>
              <li>
                <label>Contribution by Top 10 Customers</label>
                <span>{totalContributionByTop10Customers.toFixed(2)}%</span>
              </li>
              <li>
                <label>Contribution by Top 10 Vendors</label>
                <span>
                  <span className={'icon'}>
                    {totalContributionByTop10Vendors.toFixed(2)}%
                  </span>
                </span>
              </li>
              <li>
                <label>Contribution by Top 10 Products</label>
                <span>
                  <span className={'icon'}>
                    {totalContributionByTop10Products.toFixed(2)}%
                  </span>
                </span>
              </li>
            </ul>
            <ul className={'list-unstyled'}>
              <li>
                <label>Highest Turnover to a Single Customer</label>
                <span>
                  <span className={'icon'}>
                    <Icon icon={rupee} size={28} />
                  </span>
                  {rupeeCommaFormatter(highestPurchaseBySingleCustomer)}
                </span>
              </li>
              <li>
                <label>Highest contribution from a Single Vendor</label>
                <span>
                  <span className={'icon'}>
                    <Icon icon={rupee} size={28} />
                  </span>
                  {highestToBySingleVendor
                    ? rupeeCommaFormatter(highestToBySingleVendor.toFixed(2))
                    : '0'}
                </span>
              </li>
              <li>
                <label>Highest Contribution by a Single Product in %</label>
                <span>
                  {highestContributionBySingleProduct
                    ? highestContributionBySingleProduct.toFixed(2)
                    : '0'}
                  %
                </span>
              </li>
            </ul>
          </div>
        </div>
        <div className={'box-wrapper'}>
          <div className={'row box-header'}>
            <div className={'col-xs-6 p-0'}>
              <h4>Tax Liability & Tax Paid (Cash Deposits & ITC)</h4>
            </div>
          </div>
          <div className={'chart-box'} id={'tax-chart'} />
          <div className={'box-footer'}>
            <ul className={'list-unstyled'}>
              <li>
                <label>Input Tax Credit / Total Tax Liability Ratio</label>
                <span>
                  {ratioITCToTaxLiability
                    ? ratioITCToTaxLiability.toFixed(2)
                    : '0'}
                  %
                </span>
              </li>
              <li>
                <label>Cash Tax Paid / Total Tax Liability Ratio</label>
                <span>
                  {ratioTaxPaidToTaxLiability
                    ? ratioTaxPaidToTaxLiability.toFixed(2)
                    : '0'}
                  %
                </span>
              </li>
              <li>
                <label>Average Monthly Tax Liability</label>
                <span>
                  <span className={'icon'}>
                    <Icon icon={rupee} size={28} />
                  </span>
                  {rupeeCommaFormatter(averageMonthlyTaxLiability)}
                </span>
              </li>
              <li>
                <label>Overall Effective GST Rate</label>
                <span>
                  {effectiveGSTRate ? effectiveGSTRate.toFixed(2) : '0'}%
                </span>
              </li>
            </ul>
          </div>
        </div>
        <div className={'box-wrapper'}>
          <div className={'row box-header'}>
            <div className={'col-xs-6 p-0'}>
              <h4>Turnover & Purchases Comparative Analysis</h4>
            </div>
          </div>
          <div className={'chart-box'} id={'sales-purchase-chart'} />
          <div className={'box-footer'}>
            <ul className={'list-unstyled'}>
              <li>
                <label>Total Purchase / Total Turnover Ratio</label>
                <span>{purchaseToTurnover.toFixed(2)}%</span>
              </li>
              <li>
                <label>Average Monthly Turnover</label>
                <span>
                  <span className={'icon'}>
                    <Icon icon={rupee} size={28} />
                  </span>
                  {rupeeCommaFormatter(averageMonthlyTO.toFixed(2))}
                </span>
              </li>
              <li>
                <label>Average Monthly Purchase</label>
                <span>
                  <span className={'icon'}>
                    <Icon icon={rupee} size={28} />
                  </span>
                  {rupeeCommaFormatter(averageMonthlyPurchase.toFixed(2))}
                </span>
              </li>
            </ul>
          </div>
        </div>
        <div className={'box-wrapper'}>
          <div className={'box-header'}>
            <h4>Sales Purchase Analysis</h4>
          </div>
          <div className={'row'}>
            <div className={'col-md-6'}>
              <div className={'box-wrapper analysis-box'}>
                <table>
                  <thead>
                    <tr>
                      <th>Product / Services Sold</th>
                      <th />
                    </tr>
                  </thead>
                  <tbody>
                    {servicesSold.length ? (
                      servicesSold.map((service, key) => {
                        return (
                          <tr key={key}>
                            <td>{service.hsn_sc}</td>
                            <td>{service.ccMapping}</td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td>No Data Found</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
            <div className={'col-md-6'}>
              <div className={'box-wrapper analysis-box'}>
                <table>
                  <thead>
                    <tr>
                      <th>Product / Services Purchased</th>
                      <th />
                    </tr>
                  </thead>
                  <tbody>
                    {servicesPurchased.length ? (
                      servicesPurchased.map((service) => {
                        return (
                          <tr>
                            <td>{service.hsn_sc}</td>
                            <td>{service.cc_mapping[0]}</td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td>No Data Found</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <CredoModal
          isOpen={modalOpen}
          styles={
            modalType === kycComparisionModal
              ? {
                  content: {
                    minWidth: '75%',
                    height: 'auto',
                  },
                }
              : modalType === BusinessSummaryModal
              ? {
                  content: {
                    width: '90vw',
                    height: 'auto',
                    overflow: 'auto',
                  },
                }
              : modalType === SalesByPartyModal
              ? {
                  content: {
                    width: '90vw',
                    height: 'auto',
                  },
                }
              : {
                  content: {
                    width: '80%',
                    height: 'auto',
                  },
                }
          }
          closeAction={() => this.toggleModal('')}
          RenderingComponent={modalType}
          {...this.props}
        />
      </div>
    );
  }
}
const mapStateToProps = ({ gstReducer, leadManagementNavsReducer }) => ({
  loader: some(gstReducer?.loader),
  gstinAnalysis: gstReducer?.gstAnalysisData,
  gstData: gstReducer?.gstData,
  loaderModalStatus: loaderModal,
  applicationId: leadManagementNavsReducer?.applicationId,
});
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      getGSTINAnnualAnalysis,
      getGstAnalysis,
      loaderModal,
      getApplicationId,
    },
    dispatch
  ),
});
export default connect(mapStateToProps, mapDispatchToProps)(GstAnalysis);
