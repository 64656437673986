import React, { Component } from 'react';
import { API_TAG_LENDER } from '../../../../utils/APIUrls';
import { POST } from '../../../../utils/webAPI.service';

export default class TagBuyerModal extends Component {
  state = {
    amount: null,
  };

  onInputChange = (event) => {
    const { value, name } = event.target;
    this.setState({
      [name]: value?.trim(),
    });
  };

  tagLender = () => {
    let { propsData, stateData, toggleModal, actions } = this.props,
      { match } = propsData,
      { lenderData } = stateData,
      { loanId } = match.params;

    if (!this.state.amount) {
      alert('Please add valid limit amount');
    }
    const data = {
      amount: this.state.amount,
      lenderId: lenderData?.lenderId,
      lenderName: lenderData?.lenderName,
      loanId,
    };
    POST(API_TAG_LENDER, data)
      .then(({ data }) => {
        if (data.code === 200) {
          alert('Buyer tagged successfully ');
          toggleModal('');
          actions.getLendersList(loanId);
        } else if (data.code === 999 || data.code === 401) {
          alert(data?.message);
        } else {
          alert('Something went wrong');
        }
      })
      .catch((err) => {
        throw err;
      });
  };

  render() {
    let { toggleModal } = this.props;

    return (
      <div className='compare-details-wrapper services-wrapper '>
        <h3 className='card_heading'>Tag Buyer With Lender</h3>
        <div className='input_heading mt-5'>
          Limit Amount
          <input
            placeholder='Limit Amount'
            value={this.state?.amount}
            name='amount'
            required
            type='number'
            onChange={this.onInputChange}
          />
        </div>
        <div className='row buttons_wrapper mt-5'>
          <button
            className='col-md-2 button cancel_button mr-4'
            onClick={() => toggleModal('')}
          >
            Cancel
          </button>
          <button
            className='col-md-2 button add_button'
            onClick={this.tagLender}
          >
            Tag
          </button>
        </div>
      </div>
    );
  }
}
