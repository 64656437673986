import React from 'react';
import Highcharts from 'highcharts';
import Icon from 'react-icons-kit';
import {
  integerToWordFormatter,
  integerToWordFormatterLabels,
  rupeeCommaFormatter,
  titleCase
} from '../../../../utils/utility.js';
import { ic_check } from 'react-icons-kit/md/ic_check';
import { monthsColumn } from './banking.selector';
import { ic_close } from 'react-icons-kit/md/ic_close';
import { get } from 'lodash';

const generateAreaChart = config => {
  return Highcharts.chart(
    config.name,
    {
      chart: {
        type: 'area',
        backgroundColor: 'transparent',
        height: 250
      },
      title: false,
      xAxis: { categories: config.categories },
      yAxis: {
        title: false,
        labels: {
          formatter: function() {
            return integerToWordFormatterLabels(this.value);
          }
        }
      },
      credits: { enabled: false },
      plotOptions: {
        area: {
          marker: {
            enabled: false
          }
        }
      },
      legend: false,
      tooltip: {
        formatter: function() {
          return (
            '<p>' +
            this.x +
            '<br/>' +
            this.series.name +
            '<b>:  </b>' +
            integerToWordFormatter(this.y) +
            '</p>'
          );
        }
      },
      series: [
        {
          name: config.series.name,
          data: config.series.data,
          color: {
            linearGradient: {
              x1: 0,
              x2: 0,
              y1: 0,
              y2: 1
            },
            stops: [
              [0, '#00cdbe'],
              [1, 'rgba(139, 213, 245, 0)']
            ]
          }
        }
      ]
    },
    function(chart) {
      if (chart.series.length === 0) {
        chart.renderer
          .text('No Data Available', 140, 120)
          .css({
            color: '#001061',
            fontSize: '16px'
          })
          .add();
      }
    }
  );
};

const generateAreaChartInPercentage = config => {
  return Highcharts.chart(
    config.name,
    {
      chart: {
        type: 'area',
        backgroundColor: 'transparent',
        height: 250
      },
      title: false,
      xAxis: { categories: config.categories },
      yAxis: {
        title: false,
        labels: {
          formatter: function() {
            return this.value + '%';
          }
        }
      },
      credits: { enabled: false },
      plotOptions: {
        area: {
          marker: {
            enabled: false
          }
        }
      },
      legend: false,
      tooltip: {
        formatter: function() {
          if (this.y) {
            return (
              '<p>' +
              this.x +
              '<br/>' +
              this.series.name +
              '<b>:  </b>' +
              this.y.toFixed(2) +
              '%' +
              '</p>'
            );
          }
        }
      },
      series: [
        {
          name: config.series.name,
          data: config.series.data,
          color: {
            linearGradient: {
              x1: 0,
              x2: 0,
              y1: 0,
              y2: 1
            },
            stops: [
              [0, '#00cdbe'],
              [1, 'rgba(139, 213, 245, 0)']
            ]
          }
        }
      ]
    },
    function(chart) {
      if (chart.series.length === 0) {
        chart.renderer
          .text('No Data Available', 140, 120)
          .css({
            color: '#001061',
            fontSize: '16px'
          })
          .add();
      }
    }
  );
};
const generateColumnChart = config => {
  Highcharts.chart(
    config.name,
    {
      chart: {
        type: 'column',
        backgroundColor: 'transparent',
        height: 250
      },
      title: false,
      xAxis: {
        categories: config.categories
      },
      credits: false,
      yAxis: {
        title: false,
        labels: {
          formatter: function() {
            return integerToWordFormatterLabels(this.value);
          }
        }
      },
      legend: get(config, 'legends', true)
        ? {
            align: 'center',
            verticalAlign: 'top',
            layout: 'horizontal'
          }
        : false,
      tooltip: {
        formatter: function() {
          return (
            '<p>' +
            this.x +
            '<br/>' +
            this.series.name +
            '<b>:  </b>' +
            integerToWordFormatter(this.y) +
            '</p>'
          );
        },
        outside: true
      },
      plotOptions: config.plotOptions,
      series: config.series
    },
    function(chart) {
      if (chart.series.length === 0) {
        chart.renderer
          .text('No Data Available', 140, 120)
          .css({
            color: '#001061',
            fontSize: '16px'
          })
          .add();
      }
    }
  );
};
const generateStackColumnChart = config => {
  Highcharts.chart(
    config.name,
    {
      chart: {
        type: 'column',
        backgroundColor: 'transparent',
        height: 250
      },
      title: false,
      xAxis: {
        categories: config.categories
      },
      credits: false,
      yAxis: {
        title: false,
        labels: {
          formatter: function() {
            return integerToWordFormatterLabels(this.value);
          }
        }
      },
      legend: get(config, 'legends', true)
        ? {
            align: 'center',
            verticalAlign: 'top',
            layout: 'horizontal'
          }
        : false,
      tooltip: {
        shared: true,
        formatter: function() {
          var s = '<span style="font-size: 10px">' + this.x + '</span><br/>';
          for (var i = 0; i < this.points.length; i++) {
            var myPoint = this.points[i];
            if (myPoint.y !== 0) {
              s +=
                '<br/><span style="color:' +
                myPoint.series.color +
                ';font-size:20px">\u25CF</span>' +
                '<span>' +
                titleCase(myPoint.series.name) +
                '</span>' +
                ': ';
              if (myPoint.point.low && myPoint.point.high) {
                s += myPoint.point.low + ' - ' + myPoint.point.high;
              } else {
                s += integerToWordFormatter(myPoint.y);
              }
            }
          }
          s +=
            '<br/><div style="font-size: 15px; margin-top:10px"> Total:' +
            integerToWordFormatter(this.points[0].total) +
            '</div><br/>';
          return s;
        },
        outside: true
      },
      plotOptions: config.plotOptions,
      series: config.series
    },
    function(chart) {
      if (chart.series.length === 0) {
        chart.renderer
          .text('No Data Available', 140, 120)
          .css({
            color: '#001061',
            fontSize: '16px'
          })
          .add();
      }
    }
  );
};
const creditTransactionColumns1 = [
  {
    Header: 'Highest Value',
    accessor: 'seqNo'
  },
  {
    Header: 'Value',
    accessor: 'amount',
    Cell: props => {
      return props.value ? rupeeCommaFormatter(props.value) : '0';
    }
  },
  {
    Header: '% of Total Credit',
    accessor: 'percentageOfSales',
    Cell: props => {
      return props.value ? Number(props.value).toFixed(2) + '%' : '0%';
    }
  },
  {
    Header: 'Counterparty',
    accessor: 'description',
    Cell: ({ original }) => {
      let { description } = original;
      return <span title={description}>{description}</span>;
    }
  }
];
const creditTransactionColumns2 = [
  {
    Header: 'Repeat TXN',
    accessor: 'repeat_txn'
  },
  {
    Header: 'Frequency',
    accessor: 'frequency'
  },
  {
    Header: 'Total Value',
    accessor: 'total_value'
  },
  {
    Header: 'Counterparty',
    accessor: 'counterparty',
    Cell: item => {
      return (
        <label style={{ display: 'inline' }} title={item.value}>
          {item.value}
        </label>
      );
    }
  }
];
const debitTransactionColumns1 = [
  {
    Header: 'Highest Value',
    accessor: 'seqNo'
  },
  {
    Header: 'Value',
    accessor: 'amount',
    Cell: props => {
      return props.value ? rupeeCommaFormatter(props.value) : '0';
    }
  },
  {
    Header: '% of Total Debit',
    accessor: 'percentageOfSales',
    Cell: props => {
      return props.value ? Number(props.value).toFixed(2) + '%' : '0%';
    }
  },
  {
    Header: 'Counterparty',
    accessor: 'description',
    Cell: item => {
      return (
        <label style={{ display: 'inline' }} title={item.value}>
          {item.value}
        </label>
      );
    }
  }
];
const debitTransactionColumns2 = [
  {
    Header: 'Repeat TXN',
    accessor: 'repeat_txn'
  },
  {
    Header: 'Frequency',
    accessor: 'frequency'
  },
  {
    Header: 'Total Value',
    accessor: 'total_value'
  },
  {
    Header: 'Counterparty',
    accessor: 'counterparty',
    Cell: item => {
      return (
        <label style={{ display: 'inline' }} title={item.value}>
          {item.value}
        </label>
      );
    }
  }
];

const bankingColumns = details => [
  {
    Cell: item => {
      return (
        <div>
          <label>{item.original.identifier}</label>
          <div className={'subIdentifier'}>{item.original.subIdentifier}</div>
        </div>
      );
    },
    Header: 'Identifier',
    accessor: '',
    width: 180,
    className: 'sticky',
    headerClassName: 'sticky'
  },
  ...monthsColumn(details),
  {
    Header: 'Total',
    accessor: 'total',
    width: 100,
    Cell: props => {
      if (props.value) {
        return (
          <div>
            <div className='bold'>{props.value.split('-')[0]}</div>
            <div>{props.value.split('-')[1]}</div>
          </div>
        );
      } else {
        return 'No Data Available';
      }
    }
  },
  {
    Header: 'Average',
    accessor: 'average',
    width: 102,
    Cell: props => {
      if (props.value) {
        return (
          <div>
            <div className='bold'>{props.value.split('-')[0]}</div>
            <div>{props.value.split('-')[1]}</div>
          </div>
        );
      } else {
        return 'No Data Available';
      }
    }
  }
];
const emiAndServicing = [
  {
    Header: 'No.',
    accessor: 'seqNo',
    width: 50
  },
  {
    Header: 'Transaction',
    accessor: 'narration',
    Cell: ({ original }) => {
      let { narration } = original;
      return <span title={narration}>{narration}</span>;
    }
  },
  {
    Header: 'EMI Amount',
    accessor: 'emiAmount',
    Cell: props => {
      return props.value ? rupeeCommaFormatter(props.value) : '0';
    }
  },
  {
    Header: 'First Date of Debit',
    accessor: 'emiStartDate'
  },
  {
    Header: 'Last Date of Debit',
    accessor: 'emiEndDate'
  },
  {
    Header: 'Date of Debit',
    accessor: 'emiEndDate'
  },
  {
    Header: 'Bank Account Details',
    accessor: 'accountNumber'
  },
  {
    Header: 'Total count of EMIs',
    accessor: 'emiEndDate',
    Cell: ({ original }) => {
      return <div>{original?.emiDetails?.length}</div>;
    }
  }
];
const taxTransactionColumns = [
  {
    Cell: item => {
      return <label>{item.index + 1}</label>;
    },
    Header: 'No.',
    accessor: 'seqNo',
    width: 50
  },
  {
    Header: 'Tax',
    accessor: 'amount',
    Cell: props => {
      return props.value ? rupeeCommaFormatter(props.value) : '0';
    }
  },
  {
    Header: 'Payment',
    accessor: 'percentageOfSales'
  },
  {
    Header: 'Date of Debit',
    accessor: 'valueDate'
  },
  {
    Header: 'GST Match',
    accessor: '',
    Cell: item => {
      if (item.value.isMatch) {
        return (
          <span className={'icons check-icon'}>
            <Icon icon={ic_check} size={18} />
          </span>
        );
      } else {
        return (
          <span className={'icons close-icon'}>
            <Icon icon={ic_close} size={18} />
          </span>
        );
      }
    }
  }
];
const customerConcentration = [
  {
    Header: 'Highest Value',
    accessor: 'seqNo'
  },
  {
    Header: 'Value',
    accessor: 'amount',
    Cell: props => {
      return rupeeCommaFormatter(props.value);
    }
  },
  {
    Header: '% of Sales ',
    accessor: 'percentageOfSales',
    Cell: props => {
      return props.value && Number(props.value).toFixed(2) + '%';
    }
  },
  {
    Header: 'Description',
    accessor: 'description'
  }
];
const vendorConcentration = [
  {
    Header: 'Highest Value',
    accessor: 'seqNo'
  },
  {
    Header: 'Value',
    accessor: 'amount',
    Cell: props => {
      return rupeeCommaFormatter(props.value);
    }
  },
  {
    Header: '% of Sales',
    accessor: 'percentageOfSales',
    Cell: props => {
      return props.value && Number(props.value).toFixed(2) + '%';
    }
  },
  {
    Header: 'Description',
    accessor: 'description'
  }
];
const TransactionTableHeaders = [
  {
    Header: 'S.No.',
    Cell: row => {
      return <div key={row.index + 1}>{row.index + 1}</div>;
    },
    filterable: false,
    width: 60
  },
  {
    Header: 'Date',
    accessor: 'dateTime',
    width: 120,
    Cell: ({ original }) => {
      let { inconsistent, previous } = original;
      return (
        <div>
          <div>{previous.dateTime}</div>
          <div className='mt-2'>{inconsistent.dateTime}</div>
        </div>
      );
    }
  },
  {
    Header: 'Narration',
    accessor: 'description',
    Cell: ({ original }) => {
      let { inconsistent, previous } = original;
      return (
        <div>
          <div>{previous.description}</div>
          <div className='mt-2'>{inconsistent.description}</div>
        </div>
      );
    }
  },
  {
    Header: 'Debit',
    width: 200,
    Cell: ({ original }) => {
      let { inconsistent, previous } = original;
      return (
        <div>
          <div>{previous.type === 'DEBIT' ? previous.amount : '-'}</div>
          <div className='mt-2'>
            {inconsistent.type === 'DEBIT' ? previous.amount : '-'}
          </div>
        </div>
      );
    }
  },
  {
    Header: 'Credit',
    width: 200,
    Cell: ({ original }) => {
      let { inconsistent, previous } = original;
      return (
        <div>
          <div>{previous.type === 'CREDIT' ? previous.amount : '-'}</div>
          <div className='mt-2'>
            {inconsistent.type === 'CREDIT' ? inconsistent.amount : '-'}
          </div>
        </div>
      );
    }
  },
  {
    Header: 'Closing Balance',
    accessor: 'balanceAfterTransaction',
    width: 200,
    Cell: ({ original }) => {
      let { inconsistent, previous } = original;
      return (
        <div>
          <div>{previous.balanceAfterTransaction}</div>
          <div className='mt-2'>{inconsistent.balanceAfterTransaction}</div>
        </div>
      );
    }
  },
  {
    Header: 'Mismatch / Difference',
    id: 'amount',
    width: 250,
    Cell: ({ original }) => {
      let { inconsistent, previous } = original,
        balanceAfterTransaction =
          inconsistent.balanceAfterTransaction -
          previous.balanceAfterTransaction;
      return (
        <div>
          <div style={{ transform: 'translateY(15px)' }}>
            {inconsistent.type === 'CREDIT'
              ? Math.abs(balanceAfterTransaction - inconsistent.amount)
              : Math.abs(balanceAfterTransaction + inconsistent.amount)}
          </div>
        </div>
      );
    }
  }
];
const UploadTableHeaders = [
  {
    Header: 'S.No.',
    Cell: row => {
      return <div key={row.index + 1}>{row.index + 1}</div>;
    },
    filterable: false,
    width: 80
  },
  {
    Header: 'File Name',
    accessor: 'fileName',
    width: 420
  },
  {
    Header: 'Suspicious File',
    width: 170,
    id: 'isSuspicious',
    accessor: d => d.isSuspicious,
    Cell: ({ original }) => {
      let { isSuspicious } = original;

      return <div>{JSON.stringify(isSuspicious)} </div>;
    }
  },
  {
    Header: 'File modified using',
    accessor: 'reason'
  }
];

const InFlowAnalysis=[
  {
    Header:'Credit Cash Deposit',
    accessor:"Na",
    width: 170
  },
  {
    Header:'Jan',
    accessor:"Na"

  },
  {
    Header:'Feb',
    accessor:"Na"

  },
  {
    Header:'March',
    accessor:"Na"

  },
  {
    Header:'April',
    accessor:"Na"

  },
]

const OutFlowAnalysis=[
  {
    Header:'Debit salary Group',
    accessor:"Na",
    width: 170
  },
  {
    Header:'Jan',
    accessor:"Na"

  },
  {
    Header:'Feb',
    accessor:"Na"

  },
  {
    Header:'March',
    accessor:"Na"

  },
  {
    Header:'April',
    accessor:"Na"

  },
]

const ABB=[
  {
    Header:"",
  },
  {
    Header:"Jan",
  },
  {
    Header:"Feb",
  },
  {
    Header:"March",
  },
  {
    Header:"April",
  },
  {
    Header:"May",
  },
]

const Frequency=[
  {
    Header:"Day in Month"
  },
  {
    Header:"Frequency in Last 12 Months"
  }
]

const Inward=[
  {
    Header:""
  },
  {
    Header:"Jan22"
  },
  {
    Header:"Feb22"
  },
  {
    Header:"March22"
  },
  {
    Header:"April22"
  },
  {     
    Header:"May22"
  },
  {     
    Header:"June22"
  },
]

const NachBounce=[
  {
    Header:""
  },
  {
    Header:"Jan22"
  },
  {
    Header:"Feb22"
  },
  {
    Header:"March22"
  },
  {
    Header:"April22"
  },
  {     
    Header:"May22"
  },
  {     
    Header:"June22"
  },
]

const OutWard=[
  {
    Header:""
  },
  {
    Header:"Jan22"
  },
  {
    Header:"Feb22"
  },
  {
    Header:"March22"
  },
  {
    Header:"April22"
  },
  {     
    Header:"May22"
  },
  {     
    Header:"June22"
  },
]


export {
  OutWard,
  NachBounce,
  Inward,
  Frequency,
  ABB,
OutFlowAnalysis,
InFlowAnalysis,
  generateAreaChart,
  generateColumnChart,
  creditTransactionColumns1,
  creditTransactionColumns2,
  debitTransactionColumns1,
  debitTransactionColumns2,
  bankingColumns,
  emiAndServicing,
  taxTransactionColumns,
  generateStackColumnChart,
  customerConcentration,
  vendorConcentration,
  generateAreaChartInPercentage,
  TransactionTableHeaders,
  UploadTableHeaders
};
