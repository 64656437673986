import * as marginConstant from './marginInvoices.action.constant';
const initialState = {
  marginList: {},
  marginSummary: {},
};

const MarginReducer = (state = initialState, action) => {
  const { type, data } = action;
  switch (type) {
    case marginConstant.SET_MARGIN_LIST:
      return { ...state, marginList: data };
    case marginConstant.SET_MARGIN_SUMMARY:
      return { ...state, marginSummary: data };
    default:
      return state;
  }
};
export default MarginReducer;
