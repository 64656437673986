import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import CredoModal from '../../../components/CredoModal';
import {
  LimitStatus,
  MpinGeneration,
  LimitActivation,
  CloseLoanAccount,
} from './Modal';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getLimitAccountNumber } from '../InterestAndCharges/interestAndCharges.actions';
import { getBusinessDetails } from '../Application/Overview/application.actions';
import { getLimitSummary } from '../Invoices/invoices.actions';
import { loaderModal } from '../../../store/actions';
import ConfigureConfirmation from './ConfigureConfirmation';
import ConfigureTransactionRule from './ConfigureTransactionRule';
import PreviewLimit from './PreviewLimit';

class Services extends Component {
  state = {
    isModalOpen: false,
  };

  componentDidMount = async () => {
    let { actions, match } = this.props,
      { userId, loanId } = match.params,
      {
        getLimitAccountNumber,
        getLimitSummary,
        loaderModal,
        getBusinessDetails,
      } = actions;
    loaderModal(true);
    await getLimitAccountNumber(userId); // MAKE THIS API CALL
    await getLimitSummary(userId);
    await getBusinessDetails(loanId, userId);
    loaderModal(false);
  };

  toggleModal = (modalType, e) => {
    this.setState({
      isModalOpen: !this.state.isModalOpen,
      modalType,
      limitUpdatedStatus: e?.target?.value,
    });
  };
  onConfigure = () => {
    this.setState({
      modalType: ConfigureTransactionRule,
    });
  };
  render() {
    let { limitAccountNumber, limitSummary } = this.props,
      statusArray = [
        { status: 'Active' },
        { status: 'On Hold' },
        { status: 'Blocked' },
      ],
      newStatusArray = statusArray.filter(
        ({ status }) => status !== limitSummary?.currentLimitStatus
      );
    const isPreview = limitAccountNumber?.data;
    return (
      <>
        <div className='services-wrapper container'>
          <Helmet>
            <meta charSet='utf-8' />
            <title> Services</title>
          </Helmet>
          <div className='row'>
            <div className='col-md-5 card mr-5'>
              <h3 className='card_heading'> Limit Activation</h3>
              <p className='mt-4'>Activate the latest accepted limit</p>
              <button
                className='card_add_button'
                onClick={() =>
                  this.toggleModal(
                    isPreview ? PreviewLimit : ConfigureConfirmation
                  )
                }
              >
                {isPreview ? 'Preview Limit' : 'Activate Limit'}
              </button>
            </div>
            <div className='col-md-5 card'>
              <h3 className='card_heading'> Generate Mpin</h3>
              <p className='mt-4'>
                Send the mpin as message to the registered mobile number
              </p>
              <button
                className='card_add_button'
                onClick={() => this.toggleModal(MpinGeneration)}
                disabled={limitAccountNumber?.data ? false : true}
              >
                Send Mpin
              </button>
            </div>
            {limitSummary?.currentLimitStatus ? (
              <div className='col-md-5 card mr-5'>
                <h3 className='card_heading'> Limit Status </h3>
                <p className='mt-4'>Current Status of the Limit</p>
                <select
                  onChange={(e) => this.toggleModal(LimitStatus, e)}
                  className={`user_select  ${limitSummary?.currentLimitStatus}`}
                >
                  <option
                    selected
                    className={` ${limitSummary?.currentLimitStatus}`}
                  >
                    {limitSummary?.currentLimitStatus}
                  </option>
                  {newStatusArray?.map((e) => {
                    return (
                      <option className={`  ${e.status}`}>{e.status}</option>
                    );
                  })}
                </select>
              </div>
            ) : null}
            <div className='col-md-5 card'>
              <h3 className='card_heading'>Close Account</h3>
              <p className='mt-4'>Close the account permanently</p>
              <button
                className='card_add_button'
                onClick={() => this.toggleModal(CloseLoanAccount)}
              >
                Close Account
              </button>
            </div>
          </div>

          <CredoModal
            isOpen={this.state.isModalOpen}
            styles={
              this.state.modalType === MpinGeneration ||
              this.state.modalType === CloseLoanAccount
                ? {
                    content: {
                      width: '60rem',
                      height: '30rem',
                    },
                  }
                : {
                    content: {
                      minWidth: '60vw',
                      height:
                        this.state.modalType === ConfigureConfirmation &&
                        '40vh',
                    },
                  }
            }
            closeAction={() => this.toggleModal('')}
            RenderingComponent={this.state.modalType}
            stateData={this.state}
            propsData={this.props}
            toggleModal={this.toggleModal}
            onConfigure={this.onConfigure}
            limitAccountNumber={limitAccountNumber?.data}
            getLimitAccountNumber={this.props.actions.getLimitAccountNumber}
            getLimitSummary={this.props.actions.getLimitSummary}
          />
        </div>
      </>
    );
  }
}

const mapStateToProps = ({
  offerReducer,
  interestAndChargesReducer,
  invoicesReducer,
  ApplicationReducer,
}) => ({
  activeLoans: offerReducer.activeLoans,
  limitAccountNumber: interestAndChargesReducer.limitAccountNumber,
  limitSummary: invoicesReducer.limitSummary,
  promoterData: ApplicationReducer.promoterData,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    { getLimitAccountNumber, getLimitSummary, loaderModal, getBusinessDetails },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(Services);
