import * as interestAndChargesConstant from './interestAndCharges.actions.constants';
import {
  API_GET_DASHBOARD_SOA,
  API_GET_SOA_SUMMARY,
  API_GET_ADMIN_SOA_SUMMARY,
  API_GET_LIMIT_ACCOUNT_NUMBER,
  API_GET_SMA_DETAILS,
  API_GET_BUSINESS_DETAILS,
} from '../../../utils/APIUrls';
import { GET } from '../../../utils/webAPI.service';
import * as applicationConstants from '../Application/Overview/application.actions.constants';

const getSoa = (userId, sortParameter = 0, sortingOn = '') => {
  return (dispatch) => {
    return GET(
      API_GET_DASHBOARD_SOA,
      {},
      {
        params: {
          userId,
          sortParameter,
          sortingOn,
        },
      }
    )
      .then(({ data }) => {
        if (data.code === 200) {
          if (data.data) {
            const dataArray = [];
            data.data.map((item) => {
              const { status, transactionDescription, parentId } = item;
              if (
                status === 'pending' &&
                transactionDescription === 'Due: Principal' &&
                parentId !== null
              ) {
                return;
              } else {
                dataArray.push(item);
              }
            });

            dispatch({
              type: interestAndChargesConstant.SET_SOA,
              data: dataArray,
            });
          }
        } else {
          alert(data?.message);
        }
        return data;
      })
      .catch((err) => {
        throw err;
      });
  };
};

const getSoaSummary = (userId) => {
  return (dispatch) => {
    return GET(API_GET_SOA_SUMMARY(userId))
      .then(({ data }) => {
        if (data.code === 200) {
          if (data.data) {
            dispatch({
              type: interestAndChargesConstant.SET_SOA_SUMMARY,
              data: data.data,
            });
          }
        } else if (data.code === 999 || data.code === 401) {
          alert(data?.message);
        } else {
          alert('Something went wrong');
        }
      })
      .catch((err) => {
        throw err;
      });
  };
};

const getBusinessDetails = (loanId, userId) => {
  return (dispatch) => {
    return GET(API_GET_BUSINESS_DETAILS(loanId, userId))
      .then(({ data }) => {
        if (data.code === 200) {
          if (data.data) {
            dispatch({
              type: applicationConstants.SET_BUSINESS_DETAILS,
              data: data.data,
            });
          }
        } else if (data.code === 999 || data.code === 401) {
          alert(data?.message);
        } else {
          alert('Something went wrong');
        }
      })
      .catch((err) => {
        throw err;
      });
  };
};

const getAdminSoaSummary = (userId) => {
  return (dispatch) => {
    return GET(API_GET_ADMIN_SOA_SUMMARY(userId))
      .then(({ data }) => {
        if (data.code === 200) {
          if (data.data) {
            dispatch({
              type: interestAndChargesConstant.SET_ADMIN_SOA_SUMMARY,
              data: data.data,
            });
          }
        } else if (data.code === 999 || data.code === 401) {
          alert(data?.message);
        } else {
          alert('Something went wrong');
        }
      })
      .catch((err) => {
        throw err;
      });
  };
};

const getSmaDetails = (userId) => {
  return (dispatch) => {
    return GET(API_GET_SMA_DETAILS(userId))
      .then(({ data }) => {
        if (data.code === 200) {
          if (data.data) {
            dispatch({
              type: interestAndChargesConstant.SET_SMA_DETAILS,
              data: data.data,
            });
          }
        } else {
          alert(data?.message);
        }
      })
      .catch((err) => {
        throw err;
      });
  };
};

const getLimitAccountNumber = (userId) => {
  return (dispatch) => {
    return GET(API_GET_LIMIT_ACCOUNT_NUMBER(userId))
      .then(({ data }) => {
        if (data.code === 200) {
          if (data.data) {
            dispatch({
              type: interestAndChargesConstant.SET_LIMIT_ACCOUNT_NUMBER,
              data: data,
            });
          }
        }
      })
      .catch((err) => {
        throw err;
      });
  };
};

export {
  getSoa,
  getSoaSummary,
  getLimitAccountNumber,
  getAdminSoaSummary,
  getSmaDetails,
  getBusinessDetails,
};
