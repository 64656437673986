import * as homeConstants from './home.action.constant';

const initialState = {
  latestPaymentsData: {},
  supplierSummaryData: {},
};

const homeReducer = (state = initialState, action) => {
  let { type, data } = action;
  switch (type) {
    case homeConstants.SET_LATEST_PAYMENTS:
      return { ...state, latestPaymentsData: data };
    case homeConstants.SET_SUPPLIER_SUMMARY:
      return { ...state, supplierSummaryData: data };
    default:
      return state;
  }
};

export default homeReducer;
