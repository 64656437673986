import * as disbursedInvoicesConstant from './disbursedInvoices.action.constant';
const initialState = {
  disbursedInvoicesListData: {},
  disbursedInvoicesSummary: {}
};

const DisbursedInvoicesReducer = (state = initialState, action) => {
  const { type, data } = action;
  switch (type) {
    case disbursedInvoicesConstant.SET_DISBURSED_INVOICES_LIST:
      return { ...state, disbursedInvoicesListData: data };
    case disbursedInvoicesConstant.SET_DISBURSED_INVOICES_SUMMARY:
      return { ...state, disbursedInvoicesSummary: data };
    default:
      return state;
  }
};
export default DisbursedInvoicesReducer;
