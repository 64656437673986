export const STATUS_PARAMS = {
  PROCESSED: 'processed',
  PROCESSING: 'processing',
  BANK_FAILED: 'bank_Failed',
  FAILED: 'failed',
  REJECTED: 'rejected',
  IN_REVIEW: 'in_review',
  ON_HOLD: 'on_hold',
  DRAFT: 'draft',
};

export const STATUS_PARAMS_VALUE_MAP = {
  PROCESSED: 'Processed',
  PROCESSING: 'Processing',
  BANK_FAILED: 'Bank-Failed',
  FAILED: 'Failed',
  REJECTED: 'Rejected',
  IN_REVIEW: 'In-Review',
  ON_HOLD: 'On-Hold',
  DRAFT: 'Draft',
};
