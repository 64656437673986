import React from 'react';
import KycNewModal from './KycNewModal';
import OfflineVerification from './OffilineVerificationModal';
import Digilocker from './DigilockerModal';
import { useState } from 'react';

function App(props) {
  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabClick = index => {
    setSelectedTab(index);
  };
  let { propsData = {} } = props;

  let { digiLocker = {} } = propsData;

  return (
    <div>
      <div className=' tab-wrapper'>
        <div className='tab-container '>
          <div
            className={`tab ${selectedTab === 0 ? 'selected' : ''}`}
            onClick={() => handleTabClick(0)}
          >
            CKYC
          </div>
          <div
            className={`tab ${selectedTab === 1 ? 'selected' : ''}`}
            onClick={() => handleTabClick(1)}
          >
            Digilocker
            {/* <Digilocker digiLocker={digiLocker} propsData={propsData} /> */}
          </div>
          <div
            className={`tab ${selectedTab === 2 ? 'selected' : ''}`}
            onClick={() => handleTabClick(2)}
          >
            Offline Verification
          </div>
        </div>
      </div>
      {selectedTab === 0 && <KycNewModal />}
      {selectedTab === 1 && (
        <Digilocker digiLocker={digiLocker} propsData={propsData} />
      )}
      {selectedTab === 2 && <OfflineVerification propsData={propsData} />}
    </div>
  );
}

export default App;
