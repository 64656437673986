import Button from '../Button/index';
import React, { useState, useEffect } from 'react';
import AppCard from '../AppCard';
import AppInput from '../AppInput';
import AppSelect from '../AppSelect';
import ReactTable from 'react-table';
import { chargeHistoryColumns } from './manageCharges.config';
import { connect } from 'react-redux';
import { getList } from '../../store/listData.actions';
import { bindActionCreators } from 'redux';
import {
  API_GET_FETCH_CHARGES,
  API_POST_FETCH_GST_CHARGE,
  API_POST_FETCH_CHARGE,
  API_POST_DASHBOARD_FETCH_CHARGES,
  API_POST_DASHBOARD_CHARGE_POST,
} from '../../utils/APIUrls';
import { GET, POST } from '../../utils/webAPI.service';
import DatePicker from '../DatePicker';
import { getDMY, todayDate } from '../../utils/utility';
import Switch from 'react-switch';
import CancelCharge from '../../assets/images/cancel-charge.svg';
import { getLimitAccountNumber } from '../../views/LeadManagement/InterestAndCharges/interestAndCharges.actions';

function ManageCharges(props) {
  const [isAddCharges, setIsAddCharges] = useState(false);
  const [gstPercentage, setGstPercentage] = useState(0);
  const [chargeHistory, setChargeHistory] = useState([]);
  const [effectiveDate, setEffectiveDate] = useState('');
  const [isEditable, setIsEditable] = useState(true);
  const [manualChargeData, setManualChargeData] = useState({
    chargeAmt: 0,
    chargeDesc: '',
    chargeName: '',
    isGstApplicable: true,
    isChargeUpfront: false,
  });
  useEffect(() => {
    props.actions.getList(
      'GET_MANUAL_CHARGES',
      API_GET_FETCH_CHARGES,
      'Select Manual Charge'
    );
    props.actions.getLimitAccountNumber(props?.match?.params?.userId);
    GET(API_POST_FETCH_GST_CHARGE).then(({ data }) => {
      if (data?.data) {
        setGstPercentage(data?.data?.gstPercentage);
      }
    });
    fetchCharges();
  }, [props?.actions]);

  const fetchCharges = (type, list = {}) => {
    let { currentPage = 1, totalPages = 1 } = list;

    if (type === 'prev' && currentPage > 1) {
      currentPage = currentPage - 1;
    } else if (type === 'next' && currentPage < totalPages) {
      currentPage = currentPage + 1;
    }
    POST(API_POST_DASHBOARD_FETCH_CHARGES, {
      isManual: null,
      pageNumber: currentPage,
      userId: props?.match?.params?.userId,
    }).then(({ data }) => {
      if (data?.code === 200) {
        setChargeHistory(data?.data);
      } else if (data?.code === 401) {
        alert(data?.message);
      }
    });
  };
  const addCharges = () => {
    const onSelectChange = (e) => {
      setManualChargeData((prevData) => ({
        ...prevData,
        chargeName: e.target.value,
      }));
      POST(API_POST_FETCH_CHARGE, { chargeName: e.target.value })
        .then(({ data }) => {
          if (data?.code === 200) {
            setManualChargeData(data?.data);
          } else {
            alert(data?.message);
          }
        })
        .catch((err) => {
          throw err;
        });
    };
    const dateHandler = (e) => {
      if (e !== null) {
        let value = getDMY(e);
        setEffectiveDate(value);
      }
    };

    const handleChange = (toggleType, checked) => {
      setManualChargeData((prevData) => ({
        ...prevData,
        [toggleType]: checked,
      }));
    };
    const gstValue = () => {
      if (manualChargeData.isGstApplicable) {
        return (manualChargeData.chargeAmt * gstPercentage) / 100;
      } else {
        return '-';
      }
    };
    const totalChargeValue = () => {
      if (manualChargeData?.isGstApplicable) {
        return gstValue() + Number(manualChargeData.chargeAmt);
      } else {
        return manualChargeData?.chargeAmt;
      }
    };

    const submitDetails = () => {
      const dataToObject = {
        chargeAmount: manualChargeData?.chargeAmt,
        chargeName: manualChargeData?.chargeName,
        isChargeUpfront: manualChargeData?.isChargeUpfront || false,
        chargeTotalAmount: totalChargeValue(),
        effectiveDate: effectiveDate?.split('-').join('/'),
        gstAmount: gstValue(),
        gstPercentage,
        isGstApplicable: true,
        isManualCharge: true,
        offerId: props.activeLoans?.offerId,
        userId: props.match?.params?.userId,
      };
      POST(API_POST_DASHBOARD_CHARGE_POST, dataToObject)
        .then(({ data }) => {
          if (data?.code === 200) {
            setIsAddCharges(false);
            alert('Data submitted successfully');
            setGstPercentage(0);
            fetchCharges();
            setEffectiveDate('');
            setIsEditable(true);
            setManualChargeData({
              chargeAmt: 0,
              chargeDesc: '',
              chargeName: '',
              isGstApplicable: true,
            });
          } else {
            alert(data?.message);
          }
        })
        .catch((err) => {
          throw err;
        });
    };
    return (
      <>
        <div className='row'>
          <div className='col-md-1 text-center'>
            <img
              src={CancelCharge}
              alt=''
              className='cursor-pointer'
              onClick={() => setIsAddCharges(false)}
            />
          </div>
          <div className='col-md-11'>
            <p>Add Manual Charges</p>
            <div className='row'>
              <div className='col-md-3'>
                <div className='charges-heading'>Charge Name</div>
                {isEditable ? (
                  <AppSelect
                    options={props?.getManualChargesList || []}
                    onChange={onSelectChange}
                    value={manualChargeData?.chargeName}
                  />
                ) : (
                  <div className='mt-4'>{manualChargeData?.chargeName}</div>
                )}
              </div>
              {manualChargeData?.chargeName ? (
                <>
                  <div className='col-md-2'>
                    <div className='charges-heading'>Effective Date</div>
                    {isEditable ? (
                      <DatePicker
                        placeholderText={'Enter the Invoice Date'}
                        required={true}
                        defaultValue={null}
                        name={'invoiceDate'}
                        maxDate={new Date()}
                        selected={
                          effectiveDate
                            ? new Date(effectiveDate?.split('/').reverse())
                            : null
                        }
                        onChange={dateHandler}
                      />
                    ) : (
                      <div className='mt-4'>{effectiveDate}</div>
                    )}
                  </div>
                  <div className='col-md-2'>
                    <div className='charges-heading'>Charge Amount (₹)</div>
                    {isEditable ? (
                      <AppInput
                        name='chargeAmt'
                        value={manualChargeData?.chargeAmt}
                        onChange={(e) =>
                          setManualChargeData((prevData) => ({
                            ...prevData,
                            chargeAmt: e.target.value,
                          }))
                        }
                      />
                    ) : (
                      <div className='mt-4'>{manualChargeData?.chargeAmt}</div>
                    )}
                  </div>
                  <div className='col-md-1'>
                    <div className='charges-heading'>GST</div>
                    <div className='bold-text mt-4'>{gstValue()}</div>
                  </div>
                  <div className='col-md-3'>
                    <div className='charges-heading'>Total Charge Amount</div>
                    <div className='bold-text mt-4'>₹ {totalChargeValue()}</div>
                  </div>
                </>
              ) : null}
            </div>
            {manualChargeData.chargeName ? (
              <>
                <div className='row mt-5'>
                  <div className={isEditable ? 'col-md-4' : 'col-md-2'}>
                    <div className='charges-heading'>Description</div>
                    <p className=' mt-2'>{manualChargeData?.chargeDesc}</p>
                  </div>
                  {isEditable ? (
                    <div className='col-md-2'>
                      <div className='charges-heading'>Live Posting</div>
                      <Switch
                        onChange={(checked) =>
                          handleChange('isChargeUpfront', checked)
                        }
                        checked={manualChargeData?.isChargeUpfront}
                        offColor='#ADADAD'
                        onColor='#1DE9B6'
                        onHandleColor='#fff'
                        offHandleColor='#fff'
                        uncheckedIcon={false}
                        checkedIcon={false}
                        className='mt-4'
                      />
                    </div>
                  ) : null}
                  {isEditable ? (
                    <div className='col-md-2'>
                      <div className='charges-heading'>GST Applicable</div>
                      <Switch
                        onChange={(checked) =>
                          handleChange('isGstApplicable', checked)
                        }
                        checked={manualChargeData?.isGstApplicable}
                        offColor='#ADADAD'
                        onColor='#1DE9B6'
                        onHandleColor='#fff'
                        offHandleColor='#fff'
                        uncheckedIcon={false}
                        checkedIcon={false}
                        className='mt-4'
                      />
                    </div>
                  ) : null}
                  {isEditable ? null : (
                    <div className='col-md-2'>
                      <div className='charges-heading'>Created Date</div>
                      <p className='mt-2'>{todayDate()}</p>
                    </div>
                  )}
                </div>
                <div className='d-flex justify-content-center mt-5'>
                  {isEditable ? (
                    <Button onClick={() => setIsEditable(false)}>
                      Confirm
                    </Button>
                  ) : (
                    <>
                      <Button
                        variant='outlined'
                        className='px-5'
                        onClick={() => setIsEditable(true)}
                      >
                        Edit
                      </Button>
                      <Button className='ml-5 px-5' onClick={submitDetails}>
                        Post
                      </Button>
                    </>
                  )}
                </div>
              </>
            ) : null}
          </div>
        </div>
      </>
    );
  };
  return (
    <div className={'manage-charges__modal'}>
      <AppCard heading='User Details'>
        <div className='row'>
          <div className='col-md-1 '>
            <div className='user-name-tag'>
              {props?.promoterData?.entityName?.[0] || 'A'}
            </div>
          </div>
          <div className='col-md-2'>
            <div className='label'>User Name</div>
            <div className='value'>{props?.promoterData?.entityName}</div>
          </div>
          <div className='col-md-2'>
            <div className='label'>User ID</div>
            <div className='value'>{props?.match?.params?.userId}</div>
          </div>
          <div className='col-md-3'>
            <div className='label'>Limit Account Number</div>
            <div className='value'>
              {props?.limitAccountNumber?.data || 'NA'}
            </div>
          </div>
        </div>
      </AppCard>
      <AppCard heading='Charges'>
        {isAddCharges ? (
          addCharges()
        ) : (
          <Button variant='contained' onClick={() => setIsAddCharges(true)}>
            Add Manual Charges
          </Button>
        )}
      </AppCard>
      <AppCard heading='Charge History'>
        <ReactTable
          data={chargeHistory?.charges || []}
          columns={chargeHistoryColumns}
          defaultPageSize={5}
          showPagination={false}
        />
        <ul className={'datatable-pagination'}>
          <li>
            <button
              disabled={chargeHistory?.currentPage < 1}
              className={'prev-btn'}
              type={'button'}
              onClick={() => fetchCharges('prev', chargeHistory)}
            >
              Previous
            </button>
          </li>
          <li>
            <span>
              Page No. {chargeHistory?.currentPage} of
              {chargeHistory?.totalPages}
            </span>
          </li>
          <li>
            <button
              className={'next-btn'}
              type={'button'}
              disabled={
                !(chargeHistory?.currentPage < chargeHistory?.totalPages)
              }
              onClick={() => fetchCharges('next', chargeHistory)}
            >
              Next
            </button>
          </li>
        </ul>
      </AppCard>
    </div>
  );
}
const mapStateToProps = ({
  listData,
  offerReducer,
  interestAndChargesReducer,
}) => ({
  getManualChargesList: listData?.getManualChargesList,
  activeLoans: offerReducer?.activeLoans,
  limitAccountNumber: interestAndChargesReducer?.limitAccountNumber,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ getList, getLimitAccountNumber }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ManageCharges);
