import React, { Component } from 'react';
import {
  API_ADD_SUB_PROGRAM,
  API_LENDER_LIST,
} from '../../../../utils/APIUrls';
import { GET, POST } from '../../../../utils/webAPI.service';

export default class AddSubProgram extends Component {
  state = {
    lenderList: [],
  };

  validateRadioGroups = () => {
    const radioGroups = {
      enableStaleInvoice: 'Enable Stale Invoice Config',
      penalChargeEnabled: 'Enable Penal Charge',
      accruedPostingEnabled: 'Enable Accrued Posting For Repayment',
      subventionApplicable: 'Subvention Applicable',
      interestBearer: 'Interest Borne By',
      interestCollection: 'Interest Collected',
      marginApplicable: 'Margin Applicable',
      isGraceApplicable: 'Grace Period Applicable',
      isGraceRoiOverridden: 'Override Interest',
      interestPostingStrategy: 'Interest Posting Strategy',
      settlementSubOrder: 'Settlement Suborder',
      npaRepaymentStrategy: 'NPA Suborder',
    };

    const requiredRadioGroups = [
      'enableStaleInvoice',
      'penalChargeEnabled',
      'accruedPostingEnabled',
      'subventionApplicable',
      'interestBearer',
      'interestCollection',
      'marginApplicable',
      'isGraceApplicable',
      'isGraceRoiOverridden',
      'interestPostingStrategy',
      'settlementSubOrder',
      'npaRepaymentStrategy',
    ];

    for (const group of requiredRadioGroups) {
      // Skip alert for interestBearer and interestCollection if subventionApplicable is false
      if (
        (group === 'interestBearer' || group === 'interestCollection') &&
        this.state.subventionApplicable === false
      ) {
        continue;
      }
      // Skip alert for isGraceRoiOverridden if isGraceApplicable is false
      if (
        group === 'isGraceRoiOverridden' &&
        this.state.isGraceApplicable === false
      ) {
        continue;
      }

      if (this.state[group] === undefined) {
        alert(`Please select an option for ${radioGroups[group]}`);
        return false;
      }
    }

    return true;
  };
  onSubmit = (e) => {
    e.preventDefault();
    let { anchorData } = this.props;
    let { supplierId, programId } = anchorData;
    let {
      interestBearer,
      interestCollection,
      marginApplicable,
      maxExtension,
      maxExtensionIntRate,
      maxGracePeriod,
      maxIntRate,
      maxLimitAmt,
      maxLimitValidity,
      maxMarginPer,
      maxOverdueIntRate,
      maxSetUpFees,
      maxSubIntRate,
      maxSubventionPeriod,
      maxTenure,
      minExtension,
      minExtensionIntRate,
      minGracePeriod,
      minIntRate,
      minLimitAmt,
      minLimitValidity,
      minMarginPer,
      minOverdueIntRate,
      minSetUpFees,
      minSubIntRate,
      minSubventionPeriod,
      minTenure,
      minInvAmt,
      maxInvAmt,
      minIntRateInGracePeriod,
      maxIntRateInGracePeriod,
      minIntRateInNPA,
      maxIntRateInNPA,
      lenderId,
      npaRepaymentStrategy,
      settlementSubOrder,
      interestPostingStrategy,
      subventionApplicable,
      isGraceApplicable,
      isGraceRoiOverridden,
      accruedPostingEnabled,
      penalChargeEnabled,
      enableStaleInvoice,
    } = this.state;
    let sendData = {
      anchorId: supplierId,
      interestBearer,
      interestCollection,
      marginApplicable,
      minInvAmt,
      maxInvAmt,
      maxExtension,
      maxExtensionIntRate,
      maxGracePeriod,
      maxIntRate,
      maxLimitAmt,
      maxLimitValidity,
      maxMarginPer,
      maxOverdueIntRate,
      maxSetUpFees,
      maxSubIntRate,
      maxSubventionPeriod,
      maxTenure,
      minExtension,
      minExtensionIntRate,
      minGracePeriod,
      minIntRate,
      minLimitAmt,
      minLimitValidity,
      minMarginPer,
      minOverdueIntRate,
      minSetUpFees,
      minSubIntRate,
      minSubventionPeriod,
      minTenure,
      programId,
      settlementSubOrder,
      subventionApplicable,
      minIntRateInGracePeriod: isGraceApplicable
        ? minIntRateInGracePeriod
        : null,
      maxIntRateInGracePeriod: isGraceApplicable
        ? maxIntRateInGracePeriod
        : null,
      minIntRateInNPA,
      maxIntRateInNPA,
      lenderId,
      npaRepaymentStrategy,
      settlementSubOrder,
      interestPostingStrategy,
      isGraceApplicable,
      isGraceRoiOverridden: isGraceApplicable ? isGraceRoiOverridden : null,
      accruedPostingEnabled,
      penalChargeEnabled,
      enableStaleInvoice,
    };
    if (
      Number(minInvAmt) > Number(maxLimitAmt) ||
      Number(maxInvAmt) > Number(maxLimitAmt)
    ) {
      alert(
        'Min Invoice Amount and Max Invoice Amount should be less than Max Limit Amount'
      );
      return;
    }

    if (!lenderId) {
      alert('Please select Lender');
      return;
    }

    if (!this.validateRadioGroups()) {
      return;
    }
    if (this.validateSendData()) {
      POST(API_ADD_SUB_PROGRAM, sendData)
        .then(({ data }) => {
          if (data?.code === 200) {
            alert('Sub-Programs added successfully');
            this.props.fetchDetails();
            this.props.closeAction();
          } else {
            alert(data?.message);
          }
        })
        .catch((err) => {
          alert('Something went wrong');
          throw err;
        });
    }
  };

  handleChange = (event) => {
    const { value, name } = event.target;
    this.setState({
      [name]: value?.trim(),
    });
  };

  componentDidMount = () => {
    GET(API_LENDER_LIST)
      .then(({ data }) => {
        if (data?.code === 200) {
          this.setState({
            lenderList: data?.data,
          });
        } else {
          alert(data?.message);
        }
      })
      .catch((err) => {
        alert('Something went wrong');
        throw err;
      });
  };

  validateSendData = () => {
    let {
      maxExtension,
      maxExtensionIntRate,
      maxGracePeriod,
      maxIntRate,
      maxLimitAmt,
      maxLimitValidity,
      maxMarginPer,
      maxOverdueIntRate,
      maxSetUpFees,
      maxSubIntRate,
      maxSubventionPeriod,
      maxTenure,
      minExtension,
      minExtensionIntRate,
      minGracePeriod,
      minIntRate,
      minLimitAmt,
      minLimitValidity,
      minMarginPer,
      minOverdueIntRate,
      minSetUpFees,
      minSubIntRate,
      minSubventionPeriod,
      minTenure,
      minInvAmt,
      maxInvAmt,
      minIntRateInGracePeriod,
      maxIntRateInGracePeriod,
      minIntRateInNPA,
      maxIntRateInNPA,
    } = this.state;

    const validations = [
      {
        min: minInvAmt,
        max: maxInvAmt,
        minHead: 'Min Invoice Amount',
        maxHead: 'Max Invoice Amount',
      },
      {
        min: minExtension,
        max: maxExtension,
        minHead: 'Min Extension (Days)',
        maxHead: 'Max Extension (Days)',
      },
      {
        min: minExtensionIntRate,
        max: maxExtensionIntRate,
        minHead: 'Min Extension Interest Rate (APR%)',
        maxHead: 'Max Extension Interest Rate (APR%)',
      },
      {
        min: minGracePeriod,
        max: maxGracePeriod,
        minHead: 'Min  Grace Period (Days)',
        maxHead: 'Max Grace Period (Days)',
      },
      {
        min: minIntRate,
        max: maxIntRate,
        minHead: 'Min Interest Rate (APR%)',
        maxHead: 'Max Interest Rate (APR%)',
      },
      {
        min: minLimitAmt,
        max: maxLimitAmt,
        minHead: 'Min Limit Amount',
        maxHead: 'Max Limit Amount',
      },
      {
        min: minLimitValidity,
        max: maxLimitValidity,
        minHead: 'Min Limit Validity (Days)',
        maxHead: 'Max Limit Validity (Days)',
      },
      {
        min: minMarginPer,
        max: maxMarginPer,
        minHead: 'Min Margin (%)',
        maxHead: 'Max Margin (%)',
      },
      {
        min: minOverdueIntRate,
        max: maxOverdueIntRate,
        minHead: 'Min Overdue Interest Rate (APR%)',
        maxHead: 'Max Overdue Interest Rate (APR%)',
      },
      {
        min: minSetUpFees,
        max: maxSetUpFees,
        minHead: 'Min Setup fees (Rupees)',
        maxHead: 'Max Setup fees (Rupees)',
      },
      {
        min: minSubIntRate,
        max: maxSubIntRate,
        minHead: 'Min Subvention Interest Rate (APR%)',
        maxHead: 'Max Subvention Interest Rate (APR%)',
      },
      {
        min: minSubventionPeriod,
        max: maxSubventionPeriod,
        minHead: 'Min Subvention Period (Days)',
        maxHead: 'Max Subvention Period (Days)',
      },
      {
        min: minTenure,
        max: maxTenure,
        minHead: 'Min  Total Tenure (Days)',
        maxHead: 'Max Total Tenure (Days)',
      },
      {
        min: minGracePeriod,
        max: maxGracePeriod,
        minHead: 'Min  Grace Period (Days)',
        maxHead: 'Max Grace Period (Days)',
      },
      {
        min: minIntRateInGracePeriod,
        max: maxIntRateInGracePeriod,
        minHead: 'Min Grace Period Interest Rate (APR%)',
        maxHead: 'Max Grace Period Interest Rate (APR%)',
      },
      {
        min: minIntRateInNPA,
        max: maxIntRateInNPA,
        minHead: 'Min NPA Interest Rate (APR%)',
        maxHead: 'Max NPA Interest Rate (APR%)',
      },
    ];

    let allValid = true;
    for (let i = 0; i < validations.length; i++) {
      const { min, max, minHead, maxHead } = validations[i];
      if (Number(max) <= Number(min) || Number(max) === 0) {
        alert(
          `${maxHead} should be greater than ${minHead} and ${maxHead} should not be zero`
        );
        allValid = false;
        break;
      }
    }
    return allValid;
  };

  render() {
    let { lenderList = [] } = this.state;
    return (
      <div className='modal-wrapper'>
        <h3 className='card_heading'>Sub Program</h3>
        <form onSubmit={(e) => this.onSubmit(e)}>
          <div className='col-md-4 ml-3'>
            <div className='input_heading'>
              Please Select Lender
              <select
                className={'select-box'}
                name={'lenderId'}
                onChange={this.handleChange}
                required
              >
                <option disabled selected>
                  Please Select Lender
                </option>
                {lenderList.map((el, index) => {
                  return (
                    <option value={el?.lenderId} key={`lenderList-${index}`}>
                      {' '}
                      {el.lenderName}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
          <h2 className='card_heading_sub ml-4 pl-4 mt-4'>
            Limit Configuration
          </h2>
          <div className='kyc_modal_wrapper'>
            <div className='row ckyc-wrapper'>
              <div className='card__wrapper col-12'>
                <div className='card-heading text-dark '>
                  <div className='col-4'></div>
                  <div className='col-4'>
                    <h2 className='card_heading_sub text-dark font-weight-normal  mt-1'>
                      Min
                    </h2>
                  </div>
                  <div className='col-4'>
                    <h2 className='card_heading_sub text-dark font-weight-normal  mt-1'>
                      Max
                    </h2>
                  </div>
                </div>
                <div className='row m-10 mt-4 application-wrapper ckyc-wrapper'>
                  <div className='col-4 '>
                    <h2 className='card_heading_sub text-dark font-weight-normal  '>
                      Limit Amount
                    </h2>
                  </div>
                  <div className=' col-4'>
                    {' '}
                    <input
                      min={0}
                      className='select col-8 input p-3'
                      required={true}
                      name='minLimitAmt'
                      onChange={this.handleChange}
                      type='number'
                    />
                  </div>
                  <div className=' col-4'>
                    {' '}
                    <input
                      className='select col-8 input p-3'
                      required={true}
                      name='maxLimitAmt'
                      onChange={this.handleChange}
                      type='number'
                      min={0}
                    />
                  </div>
                  <div className='col-4 mt-3'>
                    <h2 className='card_heading_sub text-dark font-weight-normal  '>
                      Limit Validity (Days)
                    </h2>
                  </div>

                  <div className=' col-4 mt-4'>
                    {' '}
                    <input
                      className='select col-8 input p-3'
                      required={true}
                      name='minLimitValidity'
                      onChange={this.handleChange}
                      type='number'
                      min={0}
                    />
                  </div>
                  <div className=' col-4 mt-1 mt-4'>
                    {' '}
                    <input
                      className='select col-8 input p-3'
                      required={true}
                      name='maxLimitValidity'
                      onChange={this.handleChange}
                      type='number'
                      min={0}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* *** */}

          {/* standardCard */}
          <h2 className='card_heading_sub ml-4 pl-4 mt-1'>
            Standard Interest & Terms
          </h2>
          <div className='kyc_modal_wrapper'>
            <div className='row ckyc-wrapper'>
              <div className='card__wrapper col-12'>
                <div className='card-heading text-dark '>
                  <div className='col-4'></div>
                  <div className='col-4'>
                    <h2 className='card_heading_sub text-dark font-weight-normal  mt-1'>
                      Min
                    </h2>
                  </div>
                  <div className='col-4'>
                    <h2 className='card_heading_sub text-dark font-weight-normal  mt-1'>
                      Max
                    </h2>
                  </div>
                </div>

                <div className='row m-10 mt-4 application-wrapper ckyc-wrapper'>
                  <div className='col-4 '>
                    <h2 className='card_heading_sub  text-dark font-weight-normal  '>
                      Interest Rate (APR%)
                    </h2>
                  </div>
                  <div className=' col-4'>
                    <input
                      className='select col-8 input p-3'
                      required={true}
                      name='minIntRate'
                      onChange={this.handleChange}
                      type='number'
                      min={0}
                      max={100}
                    />
                  </div>
                  <div className=' col-4'>
                    <input
                      className='select col-8 input p-3'
                      required={true}
                      name='maxIntRate'
                      onChange={this.handleChange}
                      type='number'
                      min={0}
                      max={100}
                    />
                  </div>
                  <div className='col-4 pt-3'>
                    <h2 className='card_heading_sub  text-dark font-weight-normal  '>
                      Extension Interest Rate (APR%)
                    </h2>
                  </div>
                  <div className=' col-4 mt-4'>
                    {' '}
                    <input
                      className='select col-8 input p-3'
                      required={true}
                      name='minExtensionIntRate'
                      onChange={this.handleChange}
                      type='number'
                      min={0}
                      max={100}
                    />
                  </div>
                  <div className=' col-4 mt-1 mt-4'>
                    {' '}
                    <input
                      className='select col-8 input p-3'
                      required={true}
                      name='maxExtensionIntRate'
                      onChange={this.handleChange}
                      type='number'
                      min={0}
                      max={100}
                    />
                  </div>
                  <div className='col-4 pt-3'>
                    <h2 className='card_heading_sub  text-dark font-weight-normal  '>
                      Overdue Interest Rate (APR%)
                    </h2>
                  </div>
                  <div className=' col-4 mt-4'>
                    {' '}
                    <input
                      className='select col-8 input p-3'
                      required={true}
                      name='minOverdueIntRate'
                      onChange={this.handleChange}
                      type='number'
                      min={0}
                      max={100}
                    />
                  </div>
                  <div className=' col-4 mt-1 mt-4'>
                    {' '}
                    <input
                      className='select col-8  input p-3'
                      required={true}
                      name='maxOverdueIntRate'
                      onChange={this.handleChange}
                      type='number'
                      min={0}
                      max={100}
                    />
                  </div>
                  <div className='col-4 mt-3'>
                    <h2 className='card_heading_sub text-dark font-weight-normal  '>
                      NPA Interest Rate (APR %)
                    </h2>
                  </div>
                  <div className=' col-4 mt-4'>
                    {' '}
                    <input
                      className='select col-8 input p-3'
                      required={true}
                      name='minIntRateInNPA'
                      onChange={this.handleChange}
                      type='number'
                      min={0}
                      max={100}
                    />
                  </div>
                  <div className=' col-4 mt-1 mt-4'>
                    {' '}
                    <input
                      className='select col-8 input p-3'
                      required={true}
                      name='maxIntRateInNPA'
                      onChange={this.handleChange}
                      type='number'
                      min={0}
                      max={100}
                    />
                  </div>
                  <div className='col-4 mt-3'>
                    <h2 className='card_heading_sub text-dark font-weight-normal  '>
                      Total Tenure (Days)
                    </h2>
                  </div>
                  <div className=' col-4 mt-4'>
                    {' '}
                    <input
                      className='select col-8 input p-3'
                      required={true}
                      name='minTenure'
                      onChange={this.handleChange}
                      type='number'
                      min={0}
                    />
                  </div>
                  <div className=' col-4 mt-1 mt-4'>
                    {' '}
                    <input
                      className='select col-8 input p-3'
                      required={true}
                      name='maxTenure'
                      onChange={this.handleChange}
                      type='number'
                      min={0}
                    />
                  </div>
                  <div className='col-4 mt-3'>
                    <h2 className='card_heading_sub text-dark font-weight-normal  '>
                      Extension (Days)
                    </h2>
                  </div>
                  <div className=' col-4 mt-4'>
                    {' '}
                    <input
                      className='select col-8 input p-3'
                      required={true}
                      name='minExtension'
                      onChange={this.handleChange}
                      type='number'
                      min={0}
                    />
                  </div>
                  <div className=' col-4 mt-1 mt-4'>
                    {' '}
                    <input
                      className='select col-8 input p-3'
                      required={true}
                      name='maxExtension'
                      onChange={this.handleChange}
                      type='number'
                      min={0}
                    />
                  </div>

                  <div className='col-4 mt-3'>
                    <h2 className='card_heading_sub text-dark font-weight-normal  '>
                      Setup fees (Rupees)
                    </h2>
                  </div>
                  <div className=' col-4 mt-4'>
                    {' '}
                    <input
                      className='select col-8 input p-3'
                      required={true}
                      name='minSetUpFees'
                      onChange={this.handleChange}
                      type='number'
                      min={0}
                    />
                  </div>
                  <div className=' col-4 mt-1 mt-4'>
                    {' '}
                    <input
                      className='select col-8 input p-3'
                      required={true}
                      name='maxSetUpFees'
                      onChange={this.handleChange}
                      type='number'
                      min={0}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='kyc_modal_wrapper'>
            <div className='row ckyc-wrapper'>
              <div className='card__wrapper col-12 d-flex'>
                <div className='col-4'>
                  <div className='card__wrapper mt-4 mb-4 col-12'>
                    <div className='card-heading'>
                      <h2 className='card_heading_sub text-dark font-weight-normal  mt-1'>
                        Enable Stale Invoice Config
                      </h2>
                    </div>
                    <label className='radio_button-label mt-4'>
                      <input
                        type='radio'
                        className='radio_button'
                        onChange={() =>
                          this.setState({
                            enableStaleInvoice: true,
                          })
                        }
                        value={true}
                        checked={this.state?.enableStaleInvoice === true}
                        name='enableStaleInvoice'
                      />
                      <div className='h4 ml-3'>Yes</div>
                    </label>
                    <label className='radio_button-label mt-2'>
                      <input
                        value={false}
                        type='radio'
                        className='radio_button'
                        onChange={() =>
                          this.setState({
                            enableStaleInvoice: false,
                          })
                        }
                        checked={this.state?.enableStaleInvoice === false}
                        name='enableStaleInvoice'
                      />
                      <div className='h4 ml-3'>No</div>
                    </label>
                  </div>
                </div>

                <div className='col-3'>
                  <div className='card__wrapper mt-4 col-12'>
                    <div className='card-heading'>
                      <h2 className='card_heading_sub text-dark font-weight-normal  mt-1'>
                        Enable Penal Charge
                      </h2>
                    </div>
                    <label className='radio_button-label mt-4'>
                      <input
                        type='radio'
                        className='radio_button'
                        onChange={() =>
                          this.setState({
                            penalChargeEnabled: true,
                          })
                        }
                        value={true}
                        checked={this.state?.penalChargeEnabled === true}
                        name='penalChargeEnabled'
                      />
                      <div className='h4 ml-3'>Yes</div>
                    </label>
                    <label className='radio_button-label mt-2'>
                      <input
                        value={false}
                        type='radio'
                        className='radio_button'
                        onChange={() =>
                          this.setState({
                            penalChargeEnabled: false,
                          })
                        }
                        checked={this.state?.penalChargeEnabled === false}
                        name='penalChargeEnabled'
                      />
                      <div className='h4 ml-3'>No</div>
                    </label>
                  </div>
                </div>

                <div className='col-5'>
                  <div className='card__wrapper mt-4 col-12'>
                    <div className='card-heading'>
                      <h2 className='card_heading_sub text-dark font-weight-normal  mt-1'>
                        Enable Accrued Posting For Repayment
                      </h2>
                    </div>
                    <label className='radio_button-label mt-4'>
                      <input
                        type='radio'
                        className='radio_button'
                        onChange={() =>
                          this.setState({
                            accruedPostingEnabled: true,
                          })
                        }
                        value={true}
                        checked={this.state?.accruedPostingEnabled === true}
                        name='accruedPostingEnabled'
                      />
                      <div className='h4 ml-3'>Yes</div>
                    </label>
                    <label className='radio_button-label mt-2'>
                      <input
                        value={false}
                        type='radio'
                        className='radio_button'
                        onChange={() =>
                          this.setState({
                            accruedPostingEnabled: false,
                          })
                        }
                        checked={this.state?.accruedPostingEnabled === false}
                        name='accruedPostingEnabled'
                      />
                      <div className='h4 ml-3'>No</div>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Subvention */}
          <h2 className='card_heading_sub ml-4 pl-4 mt-1'>
            Subvention Interest & Terms
          </h2>
          <div className='kyc_modal_wrapper mt-1'>
            <div className='row ckyc-wrapper '>
              <div className=' row card__wrapper  col-12'>
                <div className='col-4 ml-3'>
                  <div className='card__wrapper mt-4 col-12'>
                    <div className='card-heading'>
                      <h2 className='card_heading_sub text-dark font-weight-normal  mt-1'>
                        Applicable
                      </h2>
                    </div>
                    <label className='radio_button-label mt-4'>
                      <input
                        type='radio'
                        className='radio_button'
                        onChange={() =>
                          this.setState({
                            subventionApplicable: true,
                          })
                        }
                        value={true}
                        checked={this.state?.subventionApplicable === true}
                        name='subventionApplicable'
                      />
                      <div className='h4 ml-3'>Yes</div>
                    </label>
                    <label className='radio_button-label mt-2'>
                      <input
                        value={false}
                        type='radio'
                        className='radio_button'
                        onChange={() =>
                          this.setState({
                            subventionApplicable: false,
                          })
                        }
                        checked={this.state?.subventionApplicable === false}
                        name='subventionApplicable'
                      />
                      <div className='h4 ml-3'>No</div>
                    </label>
                  </div>
                </div>
                {/* Interest Borne By */}
                <div className='row col-4 ml-5'>
                  <div className='card__wrapper mt-4 col-12'>
                    <div className='card-heading text-dark '>
                      <h2 className='card_heading_sub text-dark font-weight-normal  mt-1'>
                        Interest Borne By
                      </h2>
                    </div>
                    <label className='radio_button-label mt-4'>
                      <input
                        type='radio'
                        className='radio_button'
                        onChange={this.handleChange}
                        value='buyer'
                        name='interestBearer'
                        disabled={this.state?.subventionApplicable === false}
                      />
                      <div className='h4 ml-3'>Buyer</div>
                    </label>
                    <label className='radio_button-label mt-2'>
                      <input
                        type='radio'
                        className='radio_button'
                        onChange={this.handleChange}
                        value='seller'
                        name='interestBearer'
                        disabled={this.state?.subventionApplicable === false}
                      />
                      <div className='h4 ml-3'>Seller</div>
                    </label>
                    <label className='radio_button-label mt-2'>
                      <input
                        type='radio'
                        className='radio_button'
                        onChange={this.handleChange}
                        value='third_party'
                        name='interestBearer'
                        disabled={this.state?.subventionApplicable === false}
                      />
                      <div className='h4 ml-3'>Third Party</div>
                    </label>
                  </div>
                </div>
                {/* Interest Collected */}
                <div className='row col-4 ml-3 mt-n3'>
                  <div className='card__wrapper mt-4 col-12'>
                    <div className='card-heading text-dark '>
                      <h2 className='card_heading_sub text-dark font-weight-normal  mt-1'>
                        Interest Collected
                      </h2>
                    </div>
                    <label className='radio_button-label mt-4'>
                      <input
                        type='radio'
                        className='radio_button'
                        onChange={this.handleChange}
                        value='upfront'
                        name='interestCollection'
                        disabled={this.state?.subventionApplicable === false}
                      />
                      <div className='h4 ml-3'>Upfront</div>
                    </label>
                    <label className='radio_button-label mt-2'>
                      <input
                        type='radio'
                        className='radio_button'
                        onChange={this.handleChange}
                        value='rear_ended'
                        name='interestCollection'
                        disabled={this.state?.subventionApplicable === false}
                      />
                      <div className='h4 ml-3'>Rear-Ended</div>
                    </label>
                  </div>
                </div>
                {/* //card */}
                <div className='kyc_modal_wrapper '>
                  <div className='row ckyc-wrapper '>
                    <div className='card__wrapper col-12'>
                      <div className='card-heading text-dark '>
                        <div className='col-4'></div>
                        <div className='col-4'>
                          <h2 className='card_heading_sub text-dark font-weight-normal  mt-1'>
                            Min
                          </h2>
                        </div>
                        <div className='col-4'>
                          <h2 className='card_heading_sub text-dark font-weight-normal  mt-1'>
                            Max
                          </h2>
                        </div>
                      </div>
                      <div className='row m-10 application-wrapper ckyc-wrapper'>
                        <div className='col-4 '>
                          <h2 className='card_heading_sub  text-dark font-weight-normal'>
                            Interest Rate (APR%)
                          </h2>
                        </div>
                        <div className=' col-4'>
                          {' '}
                          <input
                            min={0}
                            max={100}
                            type='number'
                            className='select col-9 input p-3'
                            required={true}
                            name='minSubIntRate'
                            onChange={this.handleChange}
                            disabled={
                              this.state?.subventionApplicable === false
                            }
                          />
                        </div>
                        <div className=' col-4'>
                          {' '}
                          <input
                            min={0}
                            max={100}
                            type='number'
                            className='select col-9 input p-3'
                            required={true}
                            name='maxSubIntRate'
                            onChange={this.handleChange}
                            disabled={
                              this.state?.subventionApplicable === false
                            }
                          />
                        </div>
                        <div className='col-4 '>
                          <h2 className='card_heading_sub pt-3 text-dark font-weight-normal  '>
                            Period (Days)
                          </h2>
                        </div>

                        <div className=' col-4 mt-4'>
                          {' '}
                          <input
                            min={0}
                            type='number'
                            className='select col-9 input p-3'
                            required={true}
                            name='minSubventionPeriod'
                            onChange={this.handleChange}
                            disabled={
                              this.state?.subventionApplicable === false
                            }
                          />
                        </div>
                        <div className='col-4 mt-4'>
                          {' '}
                          <input
                            min={0}
                            type='number'
                            className='select col-9 input p-3'
                            required={true}
                            name='maxSubventionPeriod'
                            onChange={this.handleChange}
                            disabled={
                              this.state?.subventionApplicable === false
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Margin */}
          <h2 className='card_heading_sub ml-4 pl-4 mt-1'>Margin</h2>
          <div className='kyc_modal_wrapper'>
            <div className='row ckyc-wrapper '>
              <div className=' row card__wrapper  col-12'>
                <div className=' col-4 ml-3'>
                  <div className='card__wrapper mt-4 col-12'>
                    <div className='card-heading text-dark '>
                      <h2 className='card_heading_sub text-dark font-weight-normal  mt-1'>
                        Applicable
                      </h2>
                    </div>
                    <label className='radio_button-label mt-4'>
                      <input
                        type='radio'
                        className='radio_button'
                        onChange={() =>
                          this.setState({
                            marginApplicable: true,
                          })
                        }
                        value={true}
                        checked={this.state?.marginApplicable === true}
                        name='marginApplicable'
                      />
                      <div className='h4 ml-3'>Yes</div>
                    </label>
                    <label className='radio_button-label mt-2'>
                      <input
                        type='radio'
                        className='radio_button'
                        onChange={() =>
                          this.setState({
                            marginApplicable: false,
                          })
                        }
                        value={false}
                        checked={this.state?.marginApplicable === false}
                        name='marginApplicable'
                      />
                      <div className='h4 ml-3'>No</div>
                    </label>
                  </div>
                </div>
                {/* //card */}
                <div className='kyc_modal_wrapper'>
                  <div className='row ckyc-wrapper'>
                    <div className='card__wrapper col-12'>
                      <div className='card-heading text-dark '>
                        <div className='col-4'></div>
                        <div className='col-4'>
                          <h2 className='card_heading_sub text-dark font-weight-normal  mt-1'>
                            Min
                          </h2>
                        </div>
                        <div className='col-4'>
                          <h2 className='card_heading_sub text-dark font-weight-normal  mt-1'>
                            Max
                          </h2>
                        </div>
                      </div>
                      <div className='row m-10 mt-4 application-wrapper ckyc-wrapper'>
                        <div className='col-4 '>
                          <h2 className='card_heading_sub  text-dark font-weight-normal  '>
                            Margin (%)
                          </h2>
                        </div>
                        <div className=' col-4'>
                          {' '}
                          <input
                            min={0}
                            max={100}
                            type='number'
                            className='select col-9 input p-3'
                            required={true}
                            name='minMarginPer'
                            onChange={this.handleChange}
                            disabled={this.state?.marginApplicable === false}
                          />
                        </div>
                        <div className=' col-4'>
                          {' '}
                          <input
                            type='number'
                            min={0}
                            max={100}
                            className='select col-9 input p-3'
                            required={true}
                            name='maxMarginPer'
                            onChange={this.handleChange}
                            disabled={this.state?.marginApplicable === false}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* gracePeriod */}
          <h2 className='card_heading_sub ml-4 pl-4 mt-1'>
            Grace Period & Interest{' '}
          </h2>
          <div className='kyc_modal_wrapper'>
            <div className='row ckyc-wrapper '>
              <div className=' row card__wrapper  col-12'>
                <div className='col-12 d-flex justify-content-between'>
                  <div className=' col-4 '>
                    {' '}
                    <div className='card__wrapper mt-4 col-12'>
                      <div className='card-heading text-dark '>
                        <h2 className='card_heading_sub text-dark font-weight-normal  mt-1'>
                          Applicable
                        </h2>
                      </div>
                      <label className='radio_button-label mt-4'>
                        <input
                          type='radio'
                          className='radio_button'
                          onChange={() =>
                            this.setState({
                              isGraceApplicable: true,
                            })
                          }
                          value={true}
                          checked={this.state?.isGraceApplicable === true}
                          name='isGraceApplicable'
                        />
                        <div className='h4 ml-3'>Yes</div>
                      </label>
                      <label className='radio_button-label mt-2'>
                        <input
                          type='radio'
                          className='radio_button'
                          onChange={() =>
                            this.setState({
                              isGraceApplicable: false,
                            })
                          }
                          value={false}
                          checked={this.state?.isGraceApplicable === false}
                          name='isGraceApplicable'
                        />
                        <div className='h4 ml-3'>No</div>
                      </label>
                    </div>
                  </div>
                  <div className='col-4'>
                    {' '}
                    <div className='card__wrapper mt-4 col-12'>
                      <div className='card-heading text-dark '>
                        <h2 className='card_heading_sub text-dark font-weight-normal  mt-1'>
                          Override Interest
                        </h2>
                      </div>
                      <label className='radio_button-label mt-4'>
                        <input
                          type='radio'
                          className='radio_button'
                          onChange={() =>
                            this.setState({
                              isGraceRoiOverridden: true,
                            })
                          }
                          value={true}
                          checked={this.state?.isGraceRoiOverridden === true}
                          disabled={this.state?.isGraceApplicable === false}
                          name='isGraceRoiOverridden'
                        />
                        <div className='h4 ml-3'>Yes</div>
                      </label>
                      <label className='radio_button-label mt-2'>
                        <input
                          type='radio'
                          className='radio_button'
                          onChange={() =>
                            this.setState({
                              isGraceRoiOverridden: false,
                            })
                          }
                          value={false}
                          checked={this.state?.isGraceRoiOverridden === false}
                          disabled={this.state?.isGraceApplicable === false}
                          name='isGraceRoiOverridden'
                        />
                        <div className='h4 ml-3'>No</div>
                      </label>
                    </div>
                  </div>
                </div>
                {/* //card */}
                <div className='kyc_modal_wrapper'>
                  <div className='row ckyc-wrapper'>
                    <div className='card__wrapper col-12'>
                      <div className='card-heading text-dark '>
                        <div className='col-4'></div>
                        <div className='col-4'>
                          <h2 className='card_heading_sub text-dark font-weight-normal  mt-1'>
                            Min
                          </h2>
                        </div>
                        <div className='col-4'>
                          <h2 className='card_heading_sub text-dark font-weight-normal  mt-1'>
                            Max
                          </h2>
                        </div>
                      </div>
                      <div className='row m-10 mt-4 application-wrapper ckyc-wrapper'>
                        <div className='col-4 mt-3'>
                          <h2 className='card_heading_sub text-dark font-weight-normal  '>
                            Interest Rate (APR %)
                          </h2>
                        </div>
                        <div className=' col-4 mt-4'>
                          {' '}
                          <input
                            className='select col-8 input p-3'
                            required={true}
                            name='minIntRateInGracePeriod'
                            onChange={this.handleChange}
                            disabled={this.state?.isGraceApplicable === false}
                            type='number'
                            min={0}
                            max={100}
                          />
                        </div>
                        <div className=' col-4 mt-1 mt-4'>
                          {' '}
                          <input
                            className='select col-8 input p-3'
                            required={true}
                            name='maxIntRateInGracePeriod'
                            onChange={this.handleChange}
                            disabled={this.state?.isGraceApplicable === false}
                            type='number'
                            min={0}
                            max={100}
                          />
                        </div>
                        <div className='col-4 mt-3'>
                          <h2 className='card_heading_sub text-dark font-weight-normal  '>
                            Period (Days)
                          </h2>
                        </div>
                        <div className=' col-4 mt-4'>
                          {' '}
                          <input
                            className='select col-8 input p-3'
                            required={true}
                            name='minGracePeriod'
                            onChange={this.handleChange}
                            type='number'
                            min={0}
                          />
                        </div>
                        <div className=' col-4 mt-1 mt-4'>
                          {' '}
                          <input
                            className='select col-8 input p-3'
                            required={true}
                            name='maxGracePeriod'
                            onChange={this.handleChange}
                            type='number'
                            min={0}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <h2 className='card_heading_sub ml-4 pl-4 mt-1'>Interest Posting</h2>
          <div className='row ckyc-wrapper ml-2  card__wrapper  col-12'>
            <div className=' col-4'>
              <div className='card__wrapper mt-4 col-12'>
                <div className='card-heading text-dark '>
                  <h2 className='card_heading_sub text-dark font-weight-normal  mt-1'>
                    Interest Posting Strategy
                  </h2>
                </div>
                <label className='radio_button-label mt-4'>
                  <input
                    type='radio'
                    className='radio_button'
                    onChange={this.handleChange}
                    value='monthly'
                    name='interestPostingStrategy'
                  />
                  <div className='h4 ml-3'>Monthly</div>
                </label>
                <label className='radio_button-label mt-2'>
                  <input
                    type='radio'
                    className='radio_button'
                    onChange={this.handleChange}
                    value='on_due_date'
                    name='interestPostingStrategy'
                  />
                  <div className='h4 ml-3'>Due Date</div>
                </label>
              </div>
            </div>
          </div>

          <h2 className='card_heading_sub mt-4 ml-4 pl-4 mt-1'>
            Repayment Strategy
          </h2>
          <div className='row ckyc-wrapper ml-2  card__wrapper  col-12'>
            <div className=' col-6'>
              <div className='card__wrapper mt-4 col-12'>
                <div className='card-heading text-dark '>
                  <h2 className='card_heading_sub text-dark font-weight-normal  mt-1'>
                    Settlement Suborder
                  </h2>
                </div>
                <label className='radio_button-label mt-4'>
                  <input
                    type='radio'
                    className='radio_button'
                    onChange={this.handleChange}
                    value='vertical_ipc'
                    name='settlementSubOrder'
                  />
                  <div className='h4 ml-3'>Vertical IPC</div>
                </label>
                <label className='radio_button-label mt-2'>
                  <input
                    type='radio'
                    className='radio_button'
                    onChange={this.handleChange}
                    value='vertical_pic'
                    name='settlementSubOrder'
                  />
                  <div className='h4 ml-3'>Vertical PIC</div>
                </label>
                <label className='radio_button-label mt-2'>
                  <input
                    type='radio'
                    className='radio_button'
                    onChange={this.handleChange}
                    value='horizontal_ipc'
                    name='settlementSubOrder'
                  />
                  <div className='h4 ml-3'>Horizontal IPC</div>
                </label>
                <label className='radio_button-label mt-2'>
                  <input
                    type='radio'
                    className='radio_button'
                    onChange={this.handleChange}
                    value='horizontal_pic'
                    name='settlementSubOrder'
                  />
                  <div className='h4 ml-3'>Horizontal PIC</div>
                </label>
                <label className='radio_button-label mt-2'>
                  <input
                    type='radio'
                    className='radio_button'
                    onChange={this.handleChange}
                    value='due_date_distance'
                    name='settlementSubOrder'
                  />
                  <div className='h4 ml-3'>Due Date Distance</div>
                </label>
              </div>
            </div>

            <div className='col-6'>
              <div className='card__wrapper mt-4 col-12'>
                <div className='card-heading text-dark '>
                  <h2 className='card_heading_sub text-dark font-weight-normal  mt-1'>
                    NPA Suborder
                  </h2>
                </div>
                <label className='radio_button-label mt-4'>
                  <input
                    type='radio'
                    className='radio_button'
                    onChange={this.handleChange}
                    value='vertical_ipc'
                    name='npaRepaymentStrategy'
                  />
                  <div className='h4 ml-3'>Vertical IPC</div>
                </label>
                <label className='radio_button-label mt-2'>
                  <input
                    type='radio'
                    className='radio_button'
                    onChange={this.handleChange}
                    value='vertical_pic'
                    name='npaRepaymentStrategy'
                  />
                  <div className='h4 ml-3'>Vertical PIC</div>
                </label>
                <label className='radio_button-label mt-2'>
                  <input
                    type='radio'
                    className='radio_button'
                    onChange={this.handleChange}
                    value='horizontal_ipc'
                    name='npaRepaymentStrategy'
                  />
                  <div className='h4 ml-3'>Horizontal IPC</div>
                </label>
                <label className='radio_button-label mt-2'>
                  <input
                    type='radio'
                    className='radio_button'
                    onChange={this.handleChange}
                    value='horizontal_pic'
                    name='npaRepaymentStrategy'
                  />
                  <div className='h4 ml-3'>Horizontal PIC</div>
                </label>
                <label className='radio_button-label mt-2'>
                  <input
                    type='radio'
                    className='radio_button'
                    onChange={this.handleChange}
                    value='due_date_distance'
                    name='npaRepaymentStrategy'
                  />
                  <div className='h4 ml-3'>Due Date Distance</div>
                </label>
              </div>
            </div>
          </div>
          {/* *** */}
          <div className='row ckyc-wrapper ml-2  card__wrapper mt-n3 col-12'></div>
          {/* settlementSuborder */}

          <div className='save_button px-5 py-2 mt-5'>
            <button className='save_button  button-success' type='submit'>
              Submit
            </button>
          </div>
        </form>
      </div>
    );
  }
}
