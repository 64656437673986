import React, { Component } from 'react';
import Excel from '../../../assets/images/ExcelNewIcon.png';
import { API_POST_DOWNLOAD_PENDING_INVOICES } from '../../../utils/APIUrls';
import { POST } from '../../../utils/webAPI.service';

export default class InvoiceExcelModal extends Component {
  state = {
    format: null,
  };

  excelDownload = () => {
    const { stateData } = this.props,
      { invoicesTableData } = stateData;
    const { format } = this.state;
    let checkedInvoices = invoicesTableData
      ?.filter((item) => item?.isChecked === true)
      .map((e) => e?.invoiceId);
    if (checkedInvoices?.length === 0) {
      alert('Please select the invoice to download excel');
      return;
    }
    const dataToSend = {
      format,
      invoiceIds: checkedInvoices,
    };
    if (this.state.format === null) {
      alert('Please select file format');
      return;
    }
    POST(API_POST_DOWNLOAD_PENDING_INVOICES, dataToSend)
      .then(({ data }) => {
        if (data.code === 200) {
          const downloadLink = data?.data?.fileUrl;
          const link = document.createElement('a');
          link.href = `${downloadLink}`;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } else {
          alert(data?.message);
        }
      })
      .catch((err) => {
        throw err;
      });
  };
  render() {
    return (
      <div className=' invoices-wrapper'>
        <h3 className='card_heading m-0'>Invoice Excel Download</h3>
        <div className='row mt-5 mb-5 justify-content-center'>
          <div className='col-8'>
            <select
              value={this.state.format}
              onChange={(e) =>
                this.setState({
                  format: e.target.value,
                })
              }
            >
              <option value=''>Select File Format</option>
              <option value='SBI'>SBI BANK</option>
              <option value='HDFC'>HDFC BANK</option>
              <option value='ICICI'> ICICI BANK</option>
            </select>
          </div>
        </div>
        <div className='row mt-5 justify-content-center'>
          <h5
            className='col-7 button add_button button_invoices btn-green '
            onClick={this.excelDownload}
          >
            <img src={Excel} alt='icon' />
            <span>Download File</span>
            {/* <img className='icon-right' src={DownloadExcel} alt='icon' /> */}
          </h5>
        </div>
      </div>
    );
  }
}
