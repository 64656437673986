import React, { Component } from 'react';
import SingleInvite from './SingleInvite';
import BulkInvite from './BulkInvites';
export default class InviteParties extends Component {
  state = {
    activeDashboard: 'stagged',
  };

  invoiceHandler = async (type) => {
    await this.setState({
      activeDashboard: type,
    });
  };

  render() {
    let { activeDashboard } = this.state;
    return (
      <>
        <form onSubmit={(e) => this.addSupplierHandler(e)}>
          <div className='modal-wrapper'>
            <h3 className='card_heading'>Invite Parties</h3>
            <div className='row mt-3 ml-2'>
              <div className='dashboard-wrapper'>
                <div className='buttons-box'>
                  <div className='toggle-buttons'>
                    <div
                      onClick={() => this.invoiceHandler('stagged')}
                      className={
                        activeDashboard === 'stagged'
                          ? 'active-button button-left '
                          : 'button-left '
                      }
                    >
                      SingleInvite
                    </div>
                    <div
                      onClick={() => this.invoiceHandler('sent')}
                      className={
                        activeDashboard === 'sent'
                          ? 'active-button button-left button-right '
                          : 'button-left button-right '
                      }
                    >
                      BulkInvite
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
        {activeDashboard === 'stagged' ? (
          <SingleInvite {...this.props} />
        ) : (
          <BulkInvite {...this.props} />
        )}
      </>
    );
  }
}
