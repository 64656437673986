import React, { Component } from 'react';
import { Map, GoogleApiWrapper, Marker, InfoWindow } from 'google-maps-react';
import UnitLocation from '../../../../assets/images/UnitLocation.svg';
import ImageLocation from '../../../../assets/images/ImageLocation.svg';
import axios from 'axios';
import { GOOGLE_MAP_API_KEY } from '../../../../utils/config.properties';

class LocationModal extends Component {
  state = {
    activeMarker: {},
    selectedPlace: {},
    showingInfoWindow: false,
    markers: [],
    center: {
      lat: 20.0114083,
      lng: 64.4398422
    }
  };

  componentDidMount = () => {
    let { propsData } = this.props,
      { photos } = propsData,
      { businessDocuments } = photos,
      equipmentPhotos = businessDocuments?.filter(
        item => item.name === 'Equipment & Machinery Photos'
      );
    let markers = equipmentPhotos
        ? equipmentPhotos?.map((location, index) => {
            return (
              <Marker
                onClick={this.onMarkerClick}
                name={`Unit location `}
                position={{
                  lat: location?.latitude,
                  lng: location?.longtitude
                }}
                icon={{
                  url: 'http://maps.google.com/mapfiles/ms/icons/red-dot.png'
                }}
              />
            );
          })
        : [],
      center = {
        lat: markers[0]?.props?.position?.lat,
        lng: markers[0]?.props?.position?.lng
      };
    this.setState({ markers, center });
    this.reverseGeocodeAddress();
  };

  reverseGeocodeAddress = () => {
    const KEY = GOOGLE_MAP_API_KEY;
    let { propsData } = this.props;
    let { photos } = propsData,
      { profileDocument = {}, businessDocuments = [] } = photos,
      equipmentPhotos = businessDocuments?.filter(
        item => item.name === 'Equipment & Machinery Photos'
      ),
      imageLocationUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${profileDocument?.latitude},${profileDocument?.longtitude}&key=${KEY}`,
      unitLocationUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${equipmentPhotos[0]?.latitude},${equipmentPhotos[0]?.longtitude}&key=${KEY}`;

    profileDocument?.latitude || profileDocument?.longtitude
      ? axios
          .get(imageLocationUrl)
          .then(({ data }) => {
            if ((data.status = 'OK')) {
              const imageAddress = data?.results[0]?.formatted_address;
              this.setState({ imageAddress });
            }
          })
          .catch(err => {
            throw err;
          })
      : alert('User Location not present ');

    equipmentPhotos[0]?.latitude || equipmentPhotos[0]?.longtitude
      ? axios
          .get(unitLocationUrl)
          .then(({ data }) => {
            if ((data.status = 'OK')) {
              const unitAddress = data?.results[0]?.formatted_address;
              this.setState({ unitAddress });
            }
          })
          .catch(err => {
            throw err;
          })
      : alert('Unit location not present');
  };

  onMarkerClick = (props, marker) =>
    this.setState({
      activeMarker: marker,
      selectedPlace: props,
      showingInfoWindow: true
    });

  onInfoWindowClose = () =>
    this.setState({
      activeMarker: null,
      showingInfoWindow: false
    });

  onMapClicked = () => {
    if (this.state.showingInfoWindow)
      this.setState({
        activeMarker: null,
        showingInfoWindow: false
      });
  };

  location = val => {
    let { imageAddress, unitAddress } = this.state;
    let { propsData } = this.props,
      { photos } = propsData,
      { profileDocument = {}, businessDocuments = [] } = photos;
    if (val === 'imageLocation') {
      if (
        profileDocument?.latitude !== '200.00' &&
        profileDocument?.longtitude !== '200.00'
      ) {
        return imageAddress;
      }
    } else if (val === 'unitLocation') {
      let equipmentPhotos = businessDocuments?.filter(
        item => item.name === 'Equipment & Machinery Photos'
      );
      if (
        equipmentPhotos[0]?.latitude !== '200.00' &&
        equipmentPhotos[0]?.longtitude !== '200.00'
      ) {
        return unitAddress;
      }
    }
    return 'No Data Available';
  };

  render() {
    let { markers = [], center } = this.state;

    let { propsData } = this.props,
      { photos } = propsData,
      { profileDocument = {} } = photos;

    return (
      <>
        <div className='compare-details-wrapper'>
          <h3
            className='card_heading'
            //  onClick={() => this.map()
            //  }
          >
            Location
          </h3>
          <Map
            center={center}
            google={this.props.google}
            onClick={this.onMapClicked}
            style={mapStyles}
            zoom={13}
            className='location_map'
          >
            {markers ? markers : null}
            <Marker
              onClick={this.onMarkerClick}
              name={`imageLocation`}
              position={{
                lat: profileDocument?.latitude,
                lng: profileDocument?.longtitude
              }}
              icon={{
                url: 'http://maps.google.com/mapfiles/ms/icons/blue-dot.png'
              }}
            />

            <InfoWindow
              marker={this.state.activeMarker}
              onClose={this.onInfoWindowClose}
              visible={this.state.showingInfoWindow}
            >
              <div>
                <h4>{this.state.selectedPlace.name}</h4>
              </div>
            </InfoWindow>
            <div className='row mt-4 map-container'>
              <div className='col-md-6'>
                <div className='row'>
                  <div className='col-md-2'>
                    <img src={ImageLocation} alt='icon' />
                  </div>
                  <div className='col-md-6'>
                    <div className='map-heading'>Image Location</div>
                    <div>{this.location('imageLocation')}</div>
                  </div>
                </div>
              </div>
              <div className='col-md-6'>
                <div className='row'>
                  <div className='col-md-2'>
                    <img src={UnitLocation} alt='icon' />
                  </div>
                  <div className='col-md-6'>
                    <div className='map-heading'>Unit Location</div>
                    <div>{this.location('unitLocation')}</div>
                  </div>
                </div>
              </div>
            </div>
          </Map>
        </div>
      </>
    );
  }
}

const mapStyles = {
  width: '100%',
  height: '60%',
  position: 'relative'
};

export default GoogleApiWrapper({
  apiKey: GOOGLE_MAP_API_KEY
})(LocationModal);
