import React, { Component } from 'react';
export default class LenderPushInvoice extends Component {
  render() {
    const { paymentModalData, userId } = this.props || {};

    const { invoiceId } = paymentModalData || {};
    return (
      <>
        <div className='modal_card services-wrapper'>
          <h3 className='modal_card-heading'>Invoice Id: {invoiceId}</h3>
          <div className='paragraph'>
            Are you sure you want to send the invoice to our lending partner?
          </div>
          <div className='row buttons_wrapper'>
            <button
              className='col-md-2 button cancel_button mr-4'
              onClick={() => this.props.closeAction()}
            >
              Cancel
            </button>
            <button
              className='col-md-2 button add_button'
              onClick={() => this.props.sendInvoiceLender(invoiceId, userId)}
            >
              Confirm
            </button>
          </div>
        </div>
      </>
    );
  }
}
