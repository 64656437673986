import React, { Component } from 'react';
import { API_POST_ACCRUED_ENTRIES } from '../../../utils/APIUrls';
import { POST } from '../../../utils/webAPI.service';

class PostAccruedCharges extends Component {
  postAccruedEntries = () => {
    let {
      promoterData: { supplierId },
      toggleModal,
      match,
      actions,
    } = this.props || {};
    let { getAdminSoaSummary, getSmaDetails } = actions;
    let userId = match?.params?.userId;
    POST(API_POST_ACCRUED_ENTRIES(userId, supplierId))
      .then(({ data }) => {
        if (data?.code === 200) {
          alert('Accured entries posted succesfully');
          getAdminSoaSummary(userId);
          getSmaDetails(userId);
          toggleModal('');
        } else {
          alert(data?.message);
        }
      })
      .catch((err) => {
        alert('Something went wrong');
        throw err;
      });
  };
  render() {
    let { toggleModal } = this.props || {};
    return (
      <>
        <div className='modal_card services-wrapper'>
          <h3 className='modal_card-heading'>Post Accured Entries</h3>
          <div className='paragraph mt-4'>
            Are you sure you want to post accured entries ?
          </div>
          <div className='row buttons_wrapper mt-4'>
            <button
              className='col-md-2 button cancel_button mr-4'
              onClick={() => toggleModal('')}
            >
              Cancel
            </button>
            <button
              className='col-md-2 button add_button'
              onClick={this.postAccruedEntries}
            >
              Confirm
            </button>
          </div>
        </div>
      </>
    );
  }
}
export default PostAccruedCharges;
