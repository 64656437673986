import React, { Component } from 'react';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import DownArrow from '../../../assets/images/downArrow.png';
import { rupeeDecimalFormatter } from '../../../utils/utility';

export default class RepaymentsModal extends Component {
  state = {};

  render() {
    let { stateData } = this.props,
      { repaymentDetails = {}, rePaymentstatus } = stateData,
      {
        listOfRepaymentDetails,
        totalChargesSettled,
        totalIntersetSettled,
        totalPrincipalSettled,
      } = repaymentDetails;

    const repaymentsColumns = [
      {
        Header: 'Invoice ID',
        accessor: 'invoiceId',
        Cell: (props) => {
          return `${props?.value || '-'}  `;
        },
      },
      {
        Header: 'Settled % ',
        accessor: 'settledPercenteges',
        Cell: (props) => {
          return `${props?.value}%`;
        },
      },
      {
        Header: 'Interest settled',
        accessor: 'interestSettled',
        headerStyle: { textAlign: 'right' },
        Cell: (props) => {
          return (
            <div className='right-aligned '>
              {rupeeDecimalFormatter(props?.value, '₹')}
            </div>
          );
        },
      },
      {
        Header: 'Charges settled',
        accessor: 'chargeSettled',
        headerStyle: { textAlign: 'right' },
        Cell: (props) => {
          return (
            <div className='right-aligned '>
              {rupeeDecimalFormatter(props?.value, '₹')}
            </div>
          );
        },
      },
      {
        Header: 'Principal Settled',
        accessor: 'principalSettled',
        headerStyle: { textAlign: 'right' },
        Cell: (props) => {
          return (
            <div className='right-aligned '>
              {rupeeDecimalFormatter(props?.value, '₹')}
            </div>
          );
        },
      },
      {
        Header: 'Outstanding amount',
        accessor: 'outStandingAmount',
        headerStyle: { textAlign: 'right' },
        Cell: (props) => {
          return (
            <div className='right-aligned '>
              {rupeeDecimalFormatter(props?.value, '₹')}
            </div>
          );
        },
      },
    ];
    return (
      <div className='compare-details-wrapper'>
        <h3 className='card_heading'>
          Repayment Details
          <img src={DownArrow} alt='download' className='downArrow' />
        </h3>
        <div className='border-bottom'>
          <div className='row  '>
            <div className='col-md-12'>
              <div className='row'>
                <div className='col-md-2 text-grey'>
                  Repayment Status
                  <div className='text-bold'>{rePaymentstatus || '-'}</div>
                </div>
                <div className='col-md-3 text-grey'>
                  Interest Amount Settled
                  <div className='text-bold'>
                    {rupeeDecimalFormatter(totalIntersetSettled, '₹')}
                  </div>
                </div>
                <div className='col-md-2 text-grey'>
                  Charges Settled
                  <div className='text-bold'>
                    {rupeeDecimalFormatter(totalChargesSettled, '₹')}
                  </div>
                </div>
                <div className='col-md-2 text-grey'>
                  Principal Settled
                  <div className='text-bold'>
                    {rupeeDecimalFormatter(totalPrincipalSettled, '₹')}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <h3 className='card_heading mt-4'>Invoice Breakdown</h3>
        <ReactTable
          columns={repaymentsColumns}
          data={listOfRepaymentDetails}
          showPagination={false}
        />
      </div>
    );
  }
}
