import React, { Component } from 'react';
import { rupeeDecimalFormatter } from '../../utils/utility';
export default class paymentMarginModal extends Component {
  render() {
    let { paymentModalData } = this.props || {};
    let {
      fundedAmount,
      invoiceAmount,
      invoiceId,
      marginAmount,
      subventionAmount,
    } = paymentModalData || {};

    return (
      <>
        <div className='modal-wrapper manage_user-wrapper ml-3'>
          <h3 className='card_heading'>Invoice Id : {invoiceId} </h3>
          <div className='row'>
            <div className='col-md-4  text-grey'>Invoice Amount :</div>
            <span className='text-bold mt-3'>
              {rupeeDecimalFormatter(invoiceAmount, '₹')}
            </span>
          </div>
          <div className='row'>
            <div className='col-md-4  text-grey'>Subvention Amount :</div>
            <span className='text-bold mt-3'>
              {rupeeDecimalFormatter(subventionAmount, '₹')}
            </span>
          </div>
          <div className='row'>
            <div className='col-md-4  text-grey'>Margin Amount :</div>
            <span className='text-bold mt-3'>
              {rupeeDecimalFormatter(marginAmount, '₹')}
            </span>
          </div>
          <div className='row'>
            <div className='col-md-4  text-grey'>Payment Amount :</div>
            <span className='text-bold mt-3'>
              {rupeeDecimalFormatter(fundedAmount, '₹')}
            </span>
          </div>
        </div>
      </>
    );
  }
}
