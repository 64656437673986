import React, { Component } from 'react';
import Dashboard from '../src/views/LeadManagement/Dashboard/Dashboard';
import Login from '../src/views/Login/Login';
import { Route, Switch, Router } from 'react-router-dom';
import LeadManager from '../src/views/LeadManagement/index';
import { createBrowserHistory } from 'history';
import { loaderModal } from './store/actions';
import LoaderModal from './components/LoaderModal';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import EodManagement from './views/EodManagement/EodManagement';
import InvoiceManagement from './views/InvoiceManagement/index';
import AnchorManagement from './views/AnchorManagement/index';
import AnchorDashboard from './views/AnchorManagement/Dashboard/Dashboard';
import SupplierNavs from './views/AnchorManagement/SupplierNavs/SupplierNavs';
export const hist = createBrowserHistory();

class App extends Component {
  render() {
    let { loaderModalStatus } = this.props;
    return (
      <Router history={hist}>
        <LoaderModal status={loaderModalStatus} />
        <Switch>
          <Route path='/pay-app/dashboard' exact component={Dashboard} />
          <Route path='/' exact component={Login} />
          <Route path={'/pay-app/:loanId/:userId'} component={LeadManager} />
          <Route path={'/invoice-dashboard'} component={InvoiceManagement} />
          <Route path='/pay-app/eod' exact component={EodManagement} />
          <Route path='/anchor-dashboard' exact component={AnchorDashboard} />
          <Route path='/anchor/:anchorId/supplier' component={SupplierNavs} />
          <Route path='/anchor-dashboard/:appId' component={AnchorManagement} />
        </Switch>
      </Router>
    );
  }
}

const mapStateToProps = ({ data }) => ({
  loaderModalStatus: data.loaderModal,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      loaderModal,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
