import React, { Component } from 'react';
import AddBankDetails from './addBankDetail';
import CredoModal from '../../../../components/CredoModal';
import { getSupplierDetails } from './profile.action';
import { loaderModal } from '../../../../store/actions';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalOpen: false,
    };
  }
  toggleModal = (modalType) => {
    this.setState({
      isModalOpen: !this.state.isModalOpen,
      modalType,
    });
  };

  componentDidMount = () => {
    let { match, history, actions } = this.props,
      { anchorId } = match.params;
    const { getSupplierDetails } = actions;
    getSupplierDetails(anchorId);
  };

  render() {
    let { supplierDetailsData } = this.props || {};
    let {
      bankDetails,
      supplierName,
      supplierMobileNumber,
      supplierEmail,
      businessAddress,
    } = supplierDetailsData || {};
    let { isModalOpen, modalType } = this.state;
    return (
      <>
        <div className='container invoices-wrapper mt-5'>
          <div className='card'>
            <div className='invoice-header'>
              <h3 className='card_heading'>Anchor Profile</h3>
            </div>
            <div className='row mt-3'>
              <div className='col-md-12'>
                <div className='row'>
                  <div className='col-md-3 text-grey'>
                    Name
                    <div className='text-bold'>{supplierName}</div>
                  </div>
                  <div className='col-md-3 text-grey'>
                    Phone
                    <div className='text-bold'>{supplierMobileNumber}</div>
                  </div>
                  <div className='col-md-3 text-grey'>
                    Email ID
                    <div className='text-bold'>{supplierEmail}</div>
                  </div>
                  <div className='col-md-3 text-grey'>
                    Business Address
                    <div className='text-bold'>
                      <div>
                        {businessAddress?.addr1} {businessAddress?.addr2}
                      </div>
                      <div>
                        {businessAddress?.streetOrLandmark}
                        {businessAddress?.city}
                      </div>
                      <div>
                        {businessAddress?.state} {businessAddress?.pincode}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='card'>
            <div className='invoice-header d-flex '>
              <h3 className='card_heading'>Bank Details</h3>
              <div className='col-2'>
                <h5
                  className='col-10 mt-2 btn btn-text button add_button button_invoices'
                  onClick={() => {
                    this.toggleModal(AddBankDetails);
                  }}
                >
                  <span>Update Bank Details</span>
                </h5>
              </div>
            </div>

            <div className='row mt-3'>
              <div className='col-md-12'>
                <div className='row'>
                  <div className='col-md-3 text-grey'>
                    Account Number
                    <div className='text-bold'>
                      {bankDetails?.accountNumber}
                    </div>
                  </div>
                  <div className='col-md-3 text-grey'>
                    Account Holder Name
                    <div className='text-bold'>
                      {' '}
                      {bankDetails?.accountHolderName}
                    </div>
                  </div>
                  <div className='col-md-3 text-grey'>
                    IFSC Code
                    <div className='text-bold'>{bankDetails?.ifsc}</div>
                  </div>
                  <div className='col-md-3 text-grey'>
                    Bank Name
                    <div className='text-bold'>{bankDetails?.bankName}</div>
                  </div>
                  <div className='col-md-3 text-grey'>
                    Bank Branch
                    <div className='text-bold'>{bankDetails?.branchName}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <CredoModal
          isOpen={isModalOpen}
          {...this.props}
          styles={{
            content: {
              width: '50%',
              height: 'max-content',
            },
          }}
          closeAction={() => this.toggleModal('')}
          RenderingComponent={modalType}
          toggleModal={this.toggleModal}
        />
      </>
    );
  }
}
const mapStateToProps = ({ SupplierDetailsReducer }) => ({
  supplierDetailsData: SupplierDetailsReducer?.supplierDetailsData,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      getSupplierDetails,
      loaderModal,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
