import {
  API_GET_MARGIN_SUMMARY,
  API_POST_MARGIN_LIST,
} from '../../../utils/APIUrls';
import { GET, POST } from '../../../utils/webAPI.service';

import * as marginConstant from './marginInvoices.action.constant';

const getMarginList = (data) => {
  return (dispatch) => {
    return POST(API_POST_MARGIN_LIST, data)
      .then(({ data }) => {
        if (data.code === 200) {
          if (data.data) {
            dispatch({
              type: marginConstant.SET_MARGIN_LIST,
              data: data?.data,
            });
          }
        } else {
          alert(data?.message);
        }
        return data;
      })
      .catch((err) => {
        throw err;
      });
  };
};

const getMarginSummary = () => {
  return (dispatch) => {
    return GET(API_GET_MARGIN_SUMMARY)
      .then(({ data }) => {
        if (data.code === 200) {
          if (data.data) {
            dispatch({
              type: marginConstant.SET_MARGIN_SUMMARY,
              data: data?.data,
            });
          }
        } else {
          alert(data?.message);
        }
        return data;
      })
      .catch((err) => {
        throw err;
      });
  };
};

export { getMarginList, getMarginSummary };
