import React, { Component } from 'react';
import Button from '../../../../components/Button';
export default class PennyDropResponseModal extends Component {
  render() {
    let {
      propsData: {
        bankDetails: { pennyDropResponse },
      },
    } = this.props;
    return (
      <>
        <div className='modal-wrapper manage_user-wrapper ml-3'>
          <h3 className='card_heading'>Penny Drop Status</h3>
          <div className='row'>
            <div className='col-md-3  text-grey'>Name :</div>
            <span className='text-bold mt-2'>
              {pennyDropResponse?.beneficiary_name_with_bank}
            </span>
          </div>
          <div className='row'>
            <div className='col-md-3  text-grey'>ID :</div>
            <span className='text-bold mt-2'>
              {pennyDropResponse?.id || '-'}
            </span>
          </div>
          <div className='row'>
            <div className='col-md-3  text-grey'>Verified :</div>
            <span className='text-bold mt-2'>
              {pennyDropResponse?.verified?.toString()?.toUpperCase() || '-'}
            </span>
          </div>
          <div className='row'>
            <div className='col-md-3  text-grey'>Date :</div>
            <span className='text-bold mt-2'>
              {pennyDropResponse?.verified_at || '-'}
            </span>
          </div>
        </div>
        <div className='row d-flex justify-content-center mt-5'>
          <Button className='ml-5 px-5 mr-3 mt-5'>Retrigger pennyDrop</Button>
        </div>
      </>
    );
  }
}
