import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import { POST } from '../../../../../utils/webAPI.service';
import { API_POST_DOCUMENT_VERIFY } from '../../../../../utils/APIUrls';
import Icon from 'react-icons-kit';
import { spinner11 } from 'react-icons-kit/icomoon/spinner11';
export default class Pan extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rotation: 0,
    };
  }

  postDocumentVerify = (loanId, docId, status) => {
    let { closeAction, pan } = this.props;
    const data = {
      docId,
      loanId,
      remarks: '',
      status: status,
    };
    POST(API_POST_DOCUMENT_VERIFY, data).then((res) => {
      if (res.status === 200) {
        closeAction('');
        pan('');
      }
    });
  };

  rotate = () => {
    let newRotation = this.state.rotation + 90;
    if (newRotation >= 360) {
      newRotation = -360;
    }
    this.setState({
      rotation: newRotation,
    });
  };

  render() {
    let {
      photos,
      propsData: {
        match: {
          params: { loanId },
        },
      },
    } = this.props;
    let { panDocuments } = photos,
      { rotation } = this.state;

    return (
      <div className='compare-details-wrapper digilocker-document-wrapper'>
        {panDocuments ? (
          <>
            <div className='image-box'>
              <img
                src={panDocuments?.fileUrl}
                alt='user_profile'
                className='profile_img'
                style={{
                  transform: `rotate(${rotation}deg)`,
                }}
              />
              <span onClick={this.rotate}>
                <Icon icon={spinner11} size={18} className='rotate-icon m-2' />
              </span>
            </div>
            <div className='btn'>
              <Button
                className='btn_verify'
                onClick={() =>
                  this.postDocumentVerify(loanId, panDocuments?.type, 'success')
                }
              >
                Verify
              </Button>
              <Button
                className='btn_cancel'
                onClick={() =>
                  this.postDocumentVerify(loanId, panDocuments?.type, 'failure')
                }
              >
                Reject
              </Button>
            </div>
          </>
        ) : (
          'No Data Available'
        )}
      </div>
    );
  }
}
