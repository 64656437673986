import React, { Component } from 'react';
import Excel from '../../../assets/images/ExcelNewIcon.png';
import ReactTable from 'react-table';
import CheckBox from '../../../components/CheckBox/CheckBox';
import CredoModal from '../../../components/CredoModal';
import MarginExcelModal from './MarginExcelModal';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import paymentMarginModal from '../PreviewPaymentModal';
import MarginModal from './MarginInvoicesModal';
import {
  API_GET_MARGIN_DETAILS,
  API_GET_LOAN_INFO,
  API_POST_APPROVE_BANK_INVOICES,
} from '../../../utils/APIUrls';
import { getMarginList, getMarginSummary } from './marginInvoices.action';
import { GET, POST } from '../../../utils/webAPI.service';
import { rupeeDecimalFormatter } from '../../../utils/utility';
import { loaderModal } from '../../../store/actions';
import {
  STATUS_PARAMS,
  STATUS_PARAMS_VALUE_MAP,
} from '../ALLInvoices/AllInvoices.config';

class MarginInvoices extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalOpen: false,
      modalType: '',
      marginTableData: [],
      invoiceModalDetails: {},
      currentPage: 1,
      marginDetails: {},
    };
  }

  getPageData = (type, totalPages) => {
    let { currentPage } = this.state;
    if (type === 'next' && currentPage < totalPages) {
      this.setState({ currentPage: currentPage + 1 }, () => {
        this.getPageChangeInvoiceData();
      });
    } else if (type === 'prev' && currentPage > 1) {
      this.setState({ currentPage: currentPage - 1 }, () => {
        this.getPageChangeInvoiceData();
      });
    }
  };

  getPageChangeInvoiceData = async () => {
    let { actions } = this.props;
    let { currentPage } = this.state;
    let { getMarginList, getMarginSummary, loaderModal } = actions;
    let dataToSend = {
      numberOfRows: 10,
      pageNumber: currentPage,
    };
    loaderModal(true);
    await getMarginList(dataToSend);
    await getMarginSummary();
    loaderModal(false);

    this.setState({
      marginTableData: this.props?.marginList?.data,
    });
  };

  toggleModal = (modalType, invoiceId, userId) => {
    this.setState({
      isModalOpen: !this.state.isModalOpen,
      modalType,
    });

    if (invoiceId) {
      GET(API_GET_MARGIN_DETAILS(invoiceId))
        .then(({ data }) => {
          if (data.code === 200) {
            this.setState({
              marginDetails: data,
            });
          }
        })
        .catch((err) => {
          throw err;
        });
    }
  };

  componentDidMount = async () => {
    let { actions } = this.props;
    let { getMarginList, getMarginSummary, loaderModal } = actions || {};
    let dataToSend = {
      numberOfRows: 10,
      pageNumber: this.state.currentPage,
    };
    loaderModal(true);
    await getMarginList(dataToSend);
    await getMarginSummary();
    loaderModal(false);
    this.setState({
      marginTableData: this.props?.marginList?.data,
    });
  };

  handleChange = () => {
    const { marginTableData } = this.state;
    let isAllSelected = marginTableData?.some(
      (marginData) => marginData?.isChecked !== true
    );
    let checkedValue = false;
    if (isAllSelected) {
      checkedValue = true;
    }
    let marginTableDataCopy = [
      ...this.state.marginTableData.filter(
        (invoice) =>
          invoice.disbursalStatus !== STATUS_PARAMS.PROCESSED &&
          invoice.disbursalStatus !== STATUS_PARAMS.BANK_FAILED
      ),
    ];

    this.setState({
      marginTableData: marginTableDataCopy?.map((marginDataCopy) => ({
        ...marginDataCopy,
        isChecked: checkedValue,
      })),
    });
  };

  getLoanInfo = (userId) => {
    loaderModal(true);
    GET(API_GET_LOAN_INFO(userId))
      .then(({ data }) => {
        if (data?.code === 200) {
          if (data?.data) {
            setTimeout(function () {
              let newWindow = window.open(
                `/pay-app/${data?.data?.loanId}/${userId}/application/overview`
              );
              newWindow.focus();
            }, 350);
            loaderModal(false);
          }
        } else {
          alert(data?.message);
          loaderModal(false);
        }
      })
      .catch((err) => {
        alert('Something went wrong');
        throw err;
      });
    loaderModal(false);
  };

  reviewSingleInvoice = (invoiceId, userId) => {
    let { actions } = this.props;
    let { getMarginList } = actions || {};
    const dataToSend = {
      invoiceId,
      invoiceStatus: 'pending',
      userId,
    };
    let dataSend = {
      numberOfRows: 10,
      pageNumber: this.state.currentPage,
    };
    POST(API_POST_APPROVE_BANK_INVOICES, dataToSend)
      .then(async ({ data }) => {
        if (data.code === 200) {
          if (data.data) {
            alert('Successfully updated');
            await getMarginList(dataSend);
          }
        } else {
          alert(data?.message);
        }
      })
      .catch((err) => {
        throw err;
      });
  };

  render() {
    let { modalType, currentPage, marginTableData } = this.state;
    let { marginList, marginSummary } = this.props;
    let { numberOfPages } = marginList || {};
    let { summary } = marginSummary || {};
    let {
      bank_failed,
      fund_pending,
      to_be_processed,
      under_processing,
      all,
      processed,
    } = summary || {};
    const marginColumn = [
      {
        Header: () => {
          let isAllSelected = marginTableData?.some(
            (marginData) => marginData?.isChecked !== true
          );
          return (
            <CheckBox
              checkboxColor={'#ADADAD'}
              htmlFor={'filter1'}
              inputClass={''}
              labelClass={''}
              checked={!isAllSelected}
              onChange={this.handleChange}
            />
          );
        },
        accessor: 'isChecked',
        width: 60,
        Cell: ({ value, index, original }) => {
          let { disbursalStatus } = original;
          const onCheckChange = () => {
            let marginTableDataCopy = [...marginTableData];
            marginTableDataCopy[index] = {
              ...marginTableDataCopy[index],
              isChecked: !value,
            };
            this.setState({ marginTableData: marginTableDataCopy });
          };
          return (
            <div>
              {' '}
              {disbursalStatus !== STATUS_PARAMS.PROCESSED &&
              disbursalStatus !== STATUS_PARAMS.BANK_FAILED ? (
                <CheckBox
                  checkboxColor={'#ADADAD'}
                  htmlFor={'filter1'}
                  inputClass={''}
                  labelClass={''}
                  checked={value}
                  onChange={onCheckChange}
                />
              ) : null}
            </div>
          );
        },
      },
      {
        Header: 'User Id',
        accessor: 'userId',
        width: 110,
        Cell: (props) => {
          let { original } = props;
          let { userId } = original;
          return (
            <>
              {
                <div
                  className='invoice-pointer'
                  onClick={() => this.getLoanInfo(userId)}
                >
                  {userId || 'NA'}
                </div>
              }
            </>
          );
        },
      },
      {
        Header: () => (
          <>
            <div className='ml-4'>Invoice Id</div>
          </>
        ),
        accessor: 'invoiceId',
        width: 170,
        Cell: (props) => {
          let { original } = props || {};
          let { disbursalStatus } = original;
          return (
            <>
              <div className='right-aligned pr-5'>{original?.invoiceId}</div>
              {disbursalStatus === STATUS_PARAMS.IN_REVIEW ? (
                <span className='capsule ml-4 capsule_orange'>
                  {STATUS_PARAMS_VALUE_MAP.IN_REVIEW}
                </span>
              ) : disbursalStatus === STATUS_PARAMS.PROCESSING ? (
                <span className='capsule  ml-4  capsule_orange'>
                  {STATUS_PARAMS_VALUE_MAP.PROCESSING}
                </span>
              ) : disbursalStatus === STATUS_PARAMS.PROCESSED ? (
                <span className='capsule ml-4 capsule_new_green'>
                  {STATUS_PARAMS_VALUE_MAP.PROCESSED}
                </span>
              ) : disbursalStatus === STATUS_PARAMS.ON_HOLD ? (
                <span className='capsule ml-4 capsule_grey'>
                  {STATUS_PARAMS_VALUE_MAP.ON_HOLD}
                </span>
              ) : disbursalStatus === STATUS_PARAMS.FAILED ? (
                <span className='capsule ml-4 capsule_red'>
                  {STATUS_PARAMS_VALUE_MAP.FAILED}{' '}
                </span>
              ) : disbursalStatus === STATUS_PARAMS.REJECTED ? (
                <span className='capsule ml-4 capsule_red'>
                  {STATUS_PARAMS_VALUE_MAP.REJECTED}
                </span>
              ) : disbursalStatus === STATUS_PARAMS.BANK_FAILED ? (
                <span className='capsule ml-4 capsule_red'>
                  {STATUS_PARAMS_VALUE_MAP.BANK_FAILED}
                </span>
              ) : disbursalStatus === STATUS_PARAMS.DRAFT ? (
                <span className='capsule ml-4 capsule_blue'>
                  {STATUS_PARAMS_VALUE_MAP.DRAFT}
                </span>
              ) : (
                <span className='capsule ml-4 capsule_blue'>
                  {disbursalStatus}
                </span>
              )}
            </>
          );
        },
      },
      {
        Header: 'Invoice Number',
        accessor: 'invoiceNumber',
        width: 150,
      },
      {
        Header: () => (
          <>
            <div>Transaction</div>
            <div>Date</div>
          </>
        ),
        width: 110,
        accessor: 'transactionDate',
        Cell: (props) => {
          let { original } = props;
          let { transactionDate } = original;
          return (
            <>
              <div>{transactionDate || 'NA'}</div>
            </>
          );
        },
      },
      {
        Header: () => (
          <>
            <div>Invoice</div>
            <div>Amount</div>
          </>
        ),
        width: 160,
        accessor: 'invoiceAmount',
        Cell: (props) => {
          let { original } = props;
          let { invoiceAmount } = original;

          return (
            <>
              <div className=''>
                {rupeeDecimalFormatter(invoiceAmount, '₹')}
              </div>
            </>
          );
        },
      },
      {
        Header: 'Payment Amount',
        accessor: 'fundedAmount',
        width: 170,
        Cell: (props) => {
          let { original } = props;
          let { fundedAmount } = original;
          return (
            <div className=' ml-3 invoices-wrapper d-flex justify-content-between'>
              {rupeeDecimalFormatter(fundedAmount, '₹')}
            </div>
          );
        },
      },
      {
        Header: 'Details',
        accessor: 'details',
        width: 80,
        Cell: (props) => {
          let { original } = props;
          let { invoiceId, userId } = original;

          return (
            <button
              className='details_button '
              onClick={() => this.toggleModal(MarginModal, invoiceId, userId)}
            >
              Details
            </button>
          );
        },
      },
      {
        Header: 'Action',
        width: 100,
        Cell: ({ original }) => {
          const { userId, invoiceId, disbursalStatus } = original;
          return (
            <>
              {disbursalStatus === STATUS_PARAMS.BANK_FAILED && (
                <select
                  className='dropdown'
                  onChange={(e) => this.reviewSingleInvoice(invoiceId, userId)}
                >
                  <option disabled selected>
                    Select
                  </option>
                  <option value='pending'>To be processed</option>
                </select>
              )}
            </>
          );
        },
      },
    ];

    return (
      <>
        <div className='container invoices-wrapper'>
          <div className='card'>
            <div className='invoice-header'>
              <h3 className='card_heading'>MARGIN SUMMARY</h3>
            </div>
            <div className='row'>
              <div className='col-md-8'>
                <div className='row'>
                  <div className='col-md-3 text-grey'>
                    All Margin
                    <div className='text-bold'>{all?.count}</div>
                  </div>
                  <div className='col-md-3 text-grey'>
                    Processed Margin
                    <div className='text-bold'>{processed?.count}</div>
                  </div>
                  <div className='col-md-3 text-grey'>
                    Fund Pending Margin
                    <div className='text-bold'>{fund_pending?.count}</div>
                  </div>
                  <div className='col-md-3 text-grey'>
                    To be Processed Margin
                    <div className='text-bold'>{to_be_processed?.count}</div>
                  </div>

                  <div className='col-md-3 text-grey'>
                    Total Amount
                    <div className='text-bold'>
                      {rupeeDecimalFormatter(all?.amount, '₹')}
                    </div>
                  </div>
                  <div className='col-md-3 text-grey'>
                    Total Amount
                    <div className='text-bold'>
                      {rupeeDecimalFormatter(processed?.amount, '₹')}
                    </div>
                  </div>
                  <div className='col-md-3 text-grey'>
                    Total Amount
                    <div className='text-bold'>
                      {rupeeDecimalFormatter(fund_pending?.amount, '₹')}
                    </div>
                  </div>
                  <div className='col-md-3 text-grey'>
                    Total Amount
                    <div className='text-bold'>
                      {rupeeDecimalFormatter(to_be_processed?.amount, '₹')}
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-md-4'>
                <div className='row'>
                  <div className='col-6'>
                    <div className='row'>
                      <div className='col-md-12 text-grey'>
                        Under Processing Margin
                        <div className='text-bold'>
                          {under_processing?.count}
                        </div>
                      </div>
                      <div className='col-md-12  text-grey'>
                        Total Amount
                        <div className='text-bold'>
                          {rupeeDecimalFormatter(under_processing?.amount, '₹')}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-6'>
                    <div className='row'>
                      <div className='col-md-12 text-grey'>
                        Bank Failed Margin
                        <div className='text-bold'>{bank_failed?.count}</div>
                      </div>
                      <div className='col-md-12  text-grey'>
                        Total Amount
                        <div className='text-bold'>
                          {rupeeDecimalFormatter(bank_failed?.amount, '₹')}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='card row'>
            <div className='row'>
              <div className='col-5'>
                <div className='row mt-4'>
                  <h3 className='col-md-10  card_heading_secondary'>
                    Margin Invoices
                  </h3>
                </div>
              </div>
              <div className='col'>
                <div className='row justify-content-end'>
                  <div className='col-4'>
                    <h5
                      className='col-4 btn-text button add_button button_invoices btn-green '
                      onClick={() => this.toggleModal(MarginExcelModal)}
                    >
                      <img src={Excel} alt='icon' />
                      <span>Download Excel</span>
                    </h5>
                  </div>
                </div>
              </div>
            </div>

            <ReactTable
              data={marginTableData}
              columns={marginColumn}
              defaultPageSize={10}
              showPagination={false}
              sortable={false}
            />
            <ul className={'datatable-pagination'}>
              <li>
                <button
                  disabled={!(currentPage > 1)}
                  className={'prev-btn'}
                  type={'button'}
                  onClick={() => this.getPageData('prev', numberOfPages)}
                >
                  Previous
                </button>
              </li>
              <li>
                <span>
                  Page No. {numberOfPages === 0 ? 0 : currentPage} of{' '}
                  {numberOfPages}
                </span>
              </li>

              <li>
                <button
                  disabled={!(numberOfPages > currentPage)}
                  className={'next-btn'}
                  type={'button'}
                  onClick={() => this.getPageData('next', numberOfPages)}
                >
                  Next
                </button>
              </li>
            </ul>
          </div>

          <CredoModal
            isOpen={this.state.isModalOpen}
            styles={{
              content: {
                width:
                  modalType === MarginExcelModal
                    ? '24vw'
                    : modalType === paymentMarginModal
                    ? '60rem'
                    : '60vw',
                height:
                  modalType === MarginExcelModal
                    ? '20vw'
                    : modalType === paymentMarginModal
                    ? '40rem'
                    : 'auto',
              },
            }}
            closeAction={() => this.toggleModal('')}
            RenderingComponent={modalType}
            toggleModal={this.toggleModal}
            marginDetails={this.state.marginDetails}
            marginTableData={marginTableData}
          />
        </div>
      </>
    );
  }
}

const mapStateToProps = ({ MarginReducer }) => ({
  marginList: MarginReducer.marginList,
  marginSummary: MarginReducer.marginSummary,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      getMarginList,
      getMarginSummary,
      loaderModal,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(MarginInvoices);
