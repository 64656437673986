import React, { Component } from 'react';
import PaidInvoices from './Paid';
import Unpaid from './Unpaid';
import Failed from './Failed';
export default class InvoiceView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      from: '',
      to: '',
      activeFigure: 'Unpaid',
      pageNumber: 1,
      searchParam: '',
      searchValue: '',
    };
  }

  invoiceHandler = async (type) => {
    await this.setState({
      activeFigure: type,
    });
  };

  render() {
    let { activeFigure } = this.state;
    return (
      <>
        <div className='row generic_button_box mt-5'>
          <div
            onClick={() => this.invoiceHandler('Unpaid')}
            className={
              activeFigure === 'Unpaid'
                ? 'col-md-1 generic_button_active'
                : 'col-md-1 generic_button'
            }
          >
            Unpaid
          </div>
          <div
            onClick={() => this.invoiceHandler('paid')}
            className={
              activeFigure === 'paid'
                ? 'col-md-1 generic_button_active'
                : 'col-md-1 generic_button'
            }
          >
            Paid
          </div>
          <div
            onClick={() => this.invoiceHandler('failed')}
            className={
              activeFigure === 'failed'
                ? 'col-md-1 generic_button_active'
                : 'col-md-1 generic_button'
            }
          >
            Failed
          </div>
        </div>
        <div>
          {activeFigure === 'Unpaid' ? (
            <Unpaid {...this.props} />
          ) : activeFigure === 'paid' ? (
            <PaidInvoices {...this.props} />
          ) : (
            <Failed {...this.props} />
          )}
        </div>
      </>
    );
  }
}
