import React, { Component, Fragment } from 'react';
import { Route, Switch } from 'react-router-dom';
import { get } from 'lodash';
import { createBrowserHistory } from 'history';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { loaderModal } from '../../store/actions';
import AnchorNavs from './AnchorNavs/AnchorNavs';
import Downstream from './Anchor/Downstream/Downstream';
import Upstream from './Anchor/Upstream/Upstream';
export const hist = createBrowserHistory();

class AnchorManagementRoutes extends Component {
  render() {
    const arrPath = get(this, 'props.location.pathname', '').split('/');
    const activeTab = arrPath.length
      ? arrPath[arrPath.length - 1]
      : 'downstream';
    return (
      <>
        <Fragment>
          <AnchorNavs active={activeTab} {...this.props} />
          <Switch>
            <Route
              path='/anchor-dashboard/:appId/downstream'
              exact
              component={Downstream}
            />
            <Route
              path='/anchor-dashboard/:appId/upstream'
              exact
              component={Upstream}
            />
          </Switch>
        </Fragment>
      </>
    );
  }
}

const mapStateToProps = ({ data }) => ({
  loaderModalStatus: data.loaderModal,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      loaderModal,
    },
    dispatch
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AnchorManagementRoutes);
