import React, { Component } from 'react';
import { API_ADD_SUPPLIER } from '../../../../utils/APIUrls';
import { POST, GET } from '../../../../utils/webAPI.service';
import {
  API_BUYER_GST_SEARCH,
  API_BUYER_INVITATION_SENT,
} from '../../../../utils/APIUrls';
import Switch from 'react-switch';
export default class SingleInvite extends Component {
  state = {
    isButtonDisabled: false,
    accountNumber: '',
    isFocused: false,
    activeDashboard: 'stagged',
    isGstApiSuccess: false,
    isNoGst: false,
    singleInviteObject: {
      buyerGstin: '',
      buyerName: '',
      buyerMobileNumber: '',
      buyerEmail: '',
      invitationStatus: '',
      averageSales: '',
    },
  };

  invoiceHandler = async (type) => {
    await this.setState({
      activeDashboard: type,
    });
  };

  noGstHandler = () => {
    this.setState((prevState) => ({
      isNoGst: !prevState.isNoGst,
    }));
  };

  onInputChange = (event) => {
    const { value, name } = event.target;
    this.setState((prevState) => ({
      singleInviteObject: {
        ...prevState.singleInviteObject,
        [name]: value,
      },
    }));
  };

  onInvitationSend = () => {
    let { match, history, actions } = this.props,
      { anchorId } = match.params;
    let { singleInviteObject, isNoGst } = this.state;
    const {
      buyerName,
      buyerMobileNumber,
      buyerEmail,
      buyerGstin,
      averageSales,
    } = singleInviteObject;
    const sendData = {
      avgSales: averageSales ? Number(averageSales) : null,
      buyerEmailId: buyerEmail ? buyerEmail?.trim() : null,
      buyerGstin: !isNoGst ? buyerGstin?.trim() : '', //without gst toggle button handle this part
      buyerMobileNumber: buyerMobileNumber,
      buyerName: buyerName?.trim(),
      supplierId: anchorId,
    };
    POST(API_BUYER_INVITATION_SENT, sendData)
      .then(({ data }) => {
        if (data?.code === 200) {
          alert('Invite Succesfully Send');
          this.props.closeAction();
        } else {
          alert(data?.message);
        }
      })
      .catch((err) => {
        alert('Something went wrong');
        throw err;
      });
  };

  onSearchGst = () => {
    let { match, history, actions } = this.props,
      { anchorId } = match.params;
    let { singleInviteObject, isGstApiSuccess } = this.state;
    const {
      buyerName,
      buyerMobileNumber,
      buyerEmail,
      invitationStatus,
      buyerGstin,
      averageSales,
    } = singleInviteObject;
    GET(API_BUYER_GST_SEARCH(anchorId, buyerGstin?.trim()))
      .then(({ data }) => {
        if (data?.code === 200) {
          this.setState((prevState) => ({
            isGstApiSuccess: !prevState.isGstApiSuccess,
            singleInviteObject: data?.data,
          }));
        } else {
          alert(data?.message);
        }
      })
      .catch((err) => {
        alert('Something went wrong');
        throw err;
      });
  };

  render() {
    let { singleInviteObject, isNoGst, isGstApiSuccess } = this.state;
    const {
      buyerName,
      buyerMobileNumber,
      buyerEmail,
      buyerGstin,
      averageSales,
    } = singleInviteObject;

    return (
      <>
        <div className='modal-wrapper'>
          <div className='row'>
            <span className='pt-5 ml-3'> Invite Without GST</span>
            <Switch
              onChange={this.noGstHandler}
              checked={isNoGst}
              offColor='#ADADAD'
              onColor='#1DE9B6'
              onHandleColor='#fff'
              offHandleColor='#fff'
              uncheckedIcon={false}
              checkedIcon={false}
              className='mt-5 ml-5'
            />
          </div>

          <div className='row mt-4'>
            {!isNoGst && (
              <>
                <div className='col-md-12'>
                  <div className='input_heading'>
                    Enter GST*
                    <div className='row align-items-center'>
                      <div className='col-md-8'>
                        <input
                          placeholder='Enter GST*'
                          value={buyerGstin.toUpperCase()}
                          required
                          name='buyerGstin'
                          onChange={this.onInputChange}
                        />
                      </div>
                      <div className='col-md-2 mb-3'>
                        <div className='save_button'>
                          <button
                            className='save_button  button-success'
                            type='submit'
                            onClick={this.onSearchGst}
                          >
                            Search
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}

            {(isGstApiSuccess || isNoGst) && (
              <>
                <div className='col-md-8'>
                  <div className='input_heading'>
                    Company Name*
                    <input
                      placeholder='Company Name*'
                      name='buyerName'
                      value={buyerName}
                      required
                      onChange={this.onInputChange}
                    />
                  </div>
                </div>
                <div className='col-md-8'>
                  <div className='input_heading'>
                    Enter Mobile Number*
                    <input
                      placeholder=' Enter Mobile Number*'
                      value={buyerMobileNumber}
                      name='buyerMobileNumber'
                      maxLength='10'
                      type='number'
                      onChange={this.onInputChange}
                    />
                  </div>
                </div>
                <div className='col-md-8'>
                  <div className='input_heading'>
                    <div>Enter Email ID</div>
                    <input
                      placeholder='Enter Email ID'
                      value={buyerEmail}
                      name='buyerEmail'
                      onChange={this.onInputChange}
                    />
                  </div>
                </div>
                <div className='col-md-8'>
                  <div className='input_heading'>
                    <div> Avg monthly Sales*</div>
                    <input
                      placeholder='Avg monthly Sales*'
                      value={averageSales}
                      required
                      name='averageSales'
                      maxLength='10'
                      type='number'
                      onChange={this.onInputChange}
                    />
                  </div>
                </div>
              </>
            )}
          </div>
          <div className='save_button mt-5'>
            <button
              className='save_button col-12  button-success'
              type='submit'
              onClick={this.onInvitationSend}
            >
              Invite
            </button>
          </div>
        </div>
      </>
    );
  }
}
