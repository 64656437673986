import React, { Component } from 'react';
import CheckBox from '../../../../components/CheckBox/CheckBox';
import { Tabs, Tab } from 'react-bootstrap';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { API_GET_BUREAU_HIT } from '../../../../utils/APIUrls';
import { POST } from '../../../../utils/webAPI.service';
import {
  generateHeatMap,
  enquriesColumns,
  applicantsColumns,
  xCategories,
  getHeatmapData,
  getYCategories,
} from './creditBehaviour.config';
import {
  fetchAnalysis,
  getApplicantsSummary,
  setInitialResponse,
  getCreditRules,
} from './creditBehvaiour.actions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { get } from 'lodash';
import Icon from 'react-icons-kit';
import { Helmet } from 'react-helmet';
import { API_POST_UPDATE_BUREAU_TRADE_LINE } from '../../../../utils/APIUrls';
import { fileExcel } from 'react-icons-kit/icomoon/fileExcel';
import {
  ApplicantDetailsCard,
  CreditBehaviourPersonalRemarks,
} from './CreditBehaviourComponents/ApplicantDetailsCard';
import RulesCard from './CreditBehaviourComponents/RulesCard';
import LoanRepaymentSummaryCard from './CreditBehaviourComponents/LoanRepaymentSummaryCard';
import CreditInquiries from './CreditBehaviourComponents/CreditInquiries';
import PersonalInfoCard from './CreditBehaviourComponents/PersonalInfoCard';
import LiveLoanAndOutstandingCard from './CreditBehaviourComponents/LiveLoanAndOutstandingCard';
import { loaderModal } from '../../../../store/actions';
import AreaChart from './CreditBehaviourComponents/AreaChart';

class LeadCreditBehaviour extends Component {
  constructor(props) {
    super(props);
    this.state = {
      applicantsData: [],
      filters: {
        assetCreationLoan: false,
        businessFundingLoan: false,
        closedLoan: false,
        consumtionLoan: false,
        liveLoan: false,
        loanWithDPD: false,
        otherLoan: false,
      },
      activePAN: '',
    };
  }

  setFilters = (name) => {
    let { filters } = this.state;
    let prevValue = filters[name];
    filters[name] = !prevValue;
    this.setState({ filters }, () => this.setApplicantsData());
  };

  clearFilters = () => {
    let filters = {};
    for (let item in this.state.filters) {
      if (this.state.filters.hasOwnProperty(item)) {
        filters[item] = false;
      }
    }
    this.setState({ filters }, this.setApplicantsData);
  };

  setApplicantsData = async () => {
    const { filters, activePAN } = this.state,
      { actions, match } = this.props,
      loanId = match?.params?.loanId,
      { fetchAnalysis } = actions;
    let dataToSend = {
      pan: activePAN,
      ...filters,
    };
    await fetchAnalysis(loanId, dataToSend);
    let { analysisData } = this.props;
    const data = get(analysisData, 'loanDetails') || [];
    this.setState({ applicantsData: data }, () => {
      this.createHeatMap(this.state?.applicantsData, activePAN);
    });
  };

  toggleTab = async (pan) => {
    await this.setState({ activePAN: pan });
    const { filters, activePAN } = this.state,
      { actions, match } = this.props,
      loanId = match?.params?.loanId,
      { fetchAnalysis } = actions;
    Object.keys(filters).forEach(function (key) {
      filters[key] = false;
    });
    let dataToSend = {
      data: {
        pan: activePAN,
        ...filters,
      },
    };
    await fetchAnalysis(loanId, dataToSend);
    let { analysisData } = this.props;
    const data = get(analysisData, 'loanDetails') || [];
    this.setState({ applicantsData: data }, () => {
      this.createHeatMap(this.state.applicantsData, activePAN);
    });
  };

  bureauHit = () => {
    let { match } = this.props,
      loanId = match?.params?.loanId;
    POST(API_GET_BUREAU_HIT(loanId))
      .then(async ({ data }) => {
        if (data.code === 200) {
          await this.fetchDetails();
          alert('Bureau hit successfully');
        } else {
          alert(data?.message);
        }
      })
      .catch((err) => {
        throw err;
      });
  };

  fetchDetails = async () => {
    let { actions, history, match } = this.props,
      { params } = match,
      { userId, loanId } = params;
    let { getApplicantsSummary, fetchAnalysis, getCreditRules } = actions;
    try {
      actions.loaderModal(true);
      if (loanId) {
        await getApplicantsSummary(loanId);
        getCreditRules(loanId);
        const { applicantsSummary } = this.props;
        this.setState({ activePAN: applicantsSummary[0]?.pan });
        let dataToSend = {
          pan: applicantsSummary[0]?.pan,
          ...this.state.filters,
        };
        if (applicantsSummary[0]?.pan) {
          actions.loaderModal(true);
          await fetchAnalysis(loanId, dataToSend);
          const { analysisData } = this.props,
            data = get(analysisData, 'loanDetails') || [];
          document.getElementById(applicantsSummary[0].pan) &&
            this.createHeatMap(data, applicantsSummary[0].pan);
          this.setState({ applicantsData: data });
        }
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      actions.loaderModal(false);
    } finally {
      actions.loaderModal(false);
    }
  };

  createHeatMap = (data, renderTo) => {
    let deliquencyMonthsData = getHeatmapData(data);
    let yCategories = getYCategories(data.length);
    let config = {
      renderTo,
      xCategories,
      yCategories,
      series: {
        name: '',
        data: deliquencyMonthsData,
      },
    };
    generateHeatMap(config, data);
  };

  componentWillMount() {
    window.scroll(0, 0);
  }

  componentDidMount = async () => {
    this.fetchDetails();
  };
  remarksHandler = (e) => {
    this.setState({ remark: e.target.value });
  };
  componentWillUnmount() {
    let { setInitialResponse } = this.props.actions;
    setInitialResponse(false);
  }
  editHandler = (sno) => {
    this.setState({ [sno]: !this.state[sno] });
  };
  handleTabSelect = (key) => {
    this.setState({ key });
  };
  lenderNameHandler = async (data) => {
    let { fetchAnalysis } = this.props.actions,
      { loanId } = this.props.match.params;
    this.setState({ [data.sno]: !this.state[data.sno] });
    var dataToSend = {
      data: {
        comment: this.state.remark,
        pan: data.pancard,
        seqNo: data.sno,
        lenderName: data.lender,
        isTradeLine: data.isTradeLine,
        isEmiObligated: data.isEmiObligated,
        obligatedEMI: 1000,
      },
    };
    await POST(API_POST_UPDATE_BUREAU_TRADE_LINE(loanId), dataToSend)
      .then(({ data, status }) => {
        if (data.status === 'success') {
          let dataSend = {
            data: {
              pan: this.state.activePAN,
              ...this.state.filters,
            },
          };
          setTimeout(async () => {
            await fetchAnalysis(loanId, dataSend);
            const { analysisData } = this.props,
              data = get(analysisData, 'loanDetails') || [];
            this.setState({ applicantsData: data });
          }, 500);
        }
        return data;
      })
      .catch((err) => {
        throw err;
      });
  };

  render() {
    const cibilDownload = (original) => {};
    const _applicantsColumns = [
      ...applicantsColumns.slice(0, 10),

      {
        Header: 'Business Purpose',
        accessor: 'businessPerpose',
      },
      {
        Header: 'Obligated',
        accessor: 'obligatedEMI',
        width: 60,
      },
      {
        Header: 'Settlement Amount',
        accessor: 'settlementAmount',
        width: 60,
      },
      ...applicantsColumns.slice(10),
    ];

    const applicantColumns = [
      {
        Header: () => (
          <div>
            <div className='heading'>Asset Creation</div>
            <div className='subheading'>
              <span>Live</span>
              <span>Closed</span>
            </div>
          </div>
        ),
        Cell: ({ original }) => {
          return (
            <div className='subheading'>
              <span className='liveLoans'>{original?.loanCount[1]?.live}</span>
              <span>{original?.loanCount[1]?.closed}</span>
            </div>
          );
        },
      },
      {
        Header: () => (
          <div>
            <div className='heading'>Buisness Funding</div>
            <div className='subheading'>
              <span>Live</span>
              <span>Closed</span>
            </div>
          </div>
        ),
        Cell: ({ original }) => {
          return (
            <div className='subheading'>
              <span className='liveLoans'>{original?.loanCount[0]?.live}</span>
              <span>{original?.loanCount[0]?.closed}</span>
            </div>
          );
        },
      },
      {
        Header: () => (
          <div>
            <div className='heading'>Consumption</div>
            <div className='subheading'>
              <span>Live</span>
              <span>Closed</span>
            </div>
          </div>
        ),
        Cell: ({ original }) => {
          return (
            <div className='subheading'>
              <span className='liveLoans'>{original?.loanCount[3]?.live}</span>
              <span>{original?.loanCount[3]?.closed}</span>
            </div>
          );
        },
      },
    ];

    const applicantColumnsData = [
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Case Role',
        accessor: 'caseRole',
      },
      {
        Header: () => <div className='heading'>Age Role</div>,
        accessor: 'age',
      },
      {
        Header: () => <div className='heading'>Credit Score</div>,
        accessor: 'bureauScore',
        Cell: (original) => {
          let { bureauScore } = original.original;
          return (
            <div className='subheading'>
              <span className=''>
                {bureauScore !== null ? (
                  <div className=''>{bureauScore}</div>
                ) : (
                  'NA'
                )}
              </span>
            </div>
          );
        },
      },
      {
        Header: () => (
          <div>
            <div className='heading'>Total Loans</div>
            <div className='subheading'>
              <span>Live</span>
              <span>Closed</span>
            </div>
          </div>
        ),
        Cell: ({ original }) => {
          return (
            <div className='subheading'>
              <span className='liveLoans'>{original?.loanCount[4]?.live}</span>
              <span className='blue-text'>
                {original?.loanCount[4]?.closed}
              </span>
            </div>
          );
        },
      },
      {
        Header: () => (
          <div>
            <div className='heading'>Asset Creation</div>
            <div className='subheading'>
              <span>Live</span>
              <span>Closed</span>
            </div>
          </div>
        ),
        Cell: ({ original }) => {
          return (
            <div className='subheading'>
              <span className='liveLoans'>{original?.loanCount[1]?.live}</span>
              <span className='blue-text'>
                {original?.loanCount[1]?.closed}
              </span>
            </div>
          );
        },
      },
      {
        Header: () => (
          <div>
            <div className='heading'>Buisness Funding</div>
            <div className='subheading'>
              <span>Live</span>
              <span>Closed</span>
            </div>
          </div>
        ),
        Cell: ({ original }) => {
          return (
            <div className='subheading'>
              <span className='liveLoans'>{original?.loanCount[0]?.live}</span>
              <span className='blue-text'>
                {original?.loanCount[0]?.closed}
              </span>
            </div>
          );
        },
      },
      {
        Header: () => (
          <div>
            <div className='heading'>Consumption</div>
            <div className='subheading'>
              <span>Live</span>
              <span>Closed</span>
            </div>
          </div>
        ),
        Cell: ({ original }) => {
          return (
            <div className='subheading'>
              <span className='liveLoans'>{original?.loanCount[3]?.live}</span>
              <span className='blue-text'>
                {original?.loanCount[3]?.closed}
              </span>
            </div>
          );
        },
      },
      {
        Header: () => (
          <div>
            <div className='heading'>Other Loan</div>
            <div className='subheading'>
              <span>Live</span>
              <span>Closed</span>
            </div>
          </div>
        ),
        Cell: ({ original }) => {
          return (
            <div className='subheading'>
              <span className='liveLoans'>{original?.loanCount[2]?.live}</span>
              <span className='blue-text'>
                {original?.loanCount[2]?.closed}
              </span>
            </div>
          );
        },
      },
    ];

    const { applicantsData, filters } = this.state,
      {
        applicantsSummary,
        initialResponse,
        analysisData,
        creditRulesResponse,
      } = this.props,
      allEnquiries = get(analysisData, 'allEnquiries') || [],
      assetCreationEnquiries =
        get(analysisData, 'assetCreationEnquiries') || [],
      businessFundingEnquiries =
        get(analysisData, 'businessFundingEnquiries') || [],
      consumptionEnquiries = get(analysisData, 'consumptionEnquiries') || [];
    return (
      <>
        <Helmet>
          <meta charSet='utf-8' />
          <title>Credit Behaviour</title>
        </Helmet>
        <div className={'credit-behaviour-wrapper   container'}>
          <div className={'box-wrapper cam_wrapper'}>
            <div className={'row box-header'}>
              <div className={'col-12 '}>
                <div className='row'>
                  <h4 className=' col-8'>Credit Summary of applicants</h4>
                  <div className=' hover col-4  text-right'>
                    <button
                      className='  submit_button'
                      onClick={() => this.bureauHit()}
                    >
                      Retrigger Bureau
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-md-12'>
              <ReactTable
                data={applicantsSummary}
                columns={applicantColumnsData}
                resizable={false}
                showPagination={false}
                className={
                  !applicantsSummary.length
                    ? 'no-data no-pagination m-b-0'
                    : 'm-b-0'
                }
                minRows={0}
              />
            </div>
          </div>

          {initialResponse ? (
            <div>
              <Tabs
                defaultActiveKey={get(applicantsSummary, `[${0}].pan`, '')}
                onSelect={this.toggleTab}
                id='applicants-tabs'
                variant='tabs'
              >
                {applicantsSummary?.map((item, index) => {
                  return (
                    <Tab.Content
                      key={index}
                      eventKey={item.pan}
                      title={item.name}
                    >
                      <div className='mb-5'>
                        <div className={'box-wrapper pt-0'}>
                          <div className='row'>
                            <div className='col-md-8'>
                              <ApplicantDetailsCard summary={item} />
                            </div>
                            <div className='col-md-8'>
                              <ReactTable
                                data={applicantsSummary}
                                columns={applicantColumns}
                                resizable={false}
                                showPagination={false}
                                className={
                                  !applicantsSummary.length
                                    ? 'no-data no-pagination m-b-0'
                                    : 'm-b-0'
                                }
                                minRows={0}
                              />
                            </div>
                          </div>
                        </div>
                        <PersonalInfoCard summary={item} />
                        <LiveLoanAndOutstandingCard
                          data={item}
                          circularData={
                            item?.totalOutstandingPercentage
                              ? item?.totalOutstandingPercentage
                              : 0
                          }
                        />
                        <AreaChart data={item?.liveLoanMonthWise} />
                      </div>
                      <div className={'box-wrapper'}>
                        <div className={'box-header mb-5'}>
                          <h4 className={'mb-2'}>Loan Repayment History</h4>
                        </div>
                        <div className={'row filters-box'}>
                          <div className={'col-md-10'}>
                            <div className={'row'}>
                              <div className={'col-md-3'}>
                                <label>Select Filters</label>
                              </div>
                              <div className={'col-md-9'}>
                                <ul>
                                  <li>
                                    <CheckBox
                                      labelColor={'#a4a1fb'}
                                      checkboxColor={'#a4a1fb'}
                                      htmlFor={'filter1'}
                                      inputClass={''}
                                      labelClass={''}
                                      label={'Loans with Delinquency'}
                                      checked={filters.loanWithDPD}
                                      onChange={() =>
                                        this.setFilters('loanWithDPD')
                                      }
                                    />
                                  </li>
                                  <li>
                                    <CheckBox
                                      labelColor={'#67caf2'}
                                      checkboxColor={'#67caf2'}
                                      htmlFor={'filter3'}
                                      inputClass={''}
                                      labelClass={''}
                                      label={'Business Loans'}
                                      checked={filters.businessFundingLoan}
                                      onChange={() =>
                                        this.setFilters('businessFundingLoan')
                                      }
                                    />
                                  </li>
                                  <li>
                                    <CheckBox
                                      labelColor={'#36ddd1'}
                                      checkboxColor={'#36ddd1'}
                                      htmlFor={'filter2'}
                                      inputClass={''}
                                      labelClass={''}
                                      label={'Asset Creation Loans'}
                                      checked={filters.assetCreationLoan}
                                      onChange={() =>
                                        this.setFilters('assetCreationLoan')
                                      }
                                    />
                                  </li>
                                  <li>
                                    <CheckBox
                                      labelColor={'#3670cc'}
                                      checkboxColor={'#3670cc'}
                                      htmlFor={'filter4'}
                                      inputClass={''}
                                      labelClass={''}
                                      label={'Consumption'}
                                      checked={filters.consumtionLoan}
                                      onChange={() =>
                                        this.setFilters('consumtionLoan')
                                      }
                                    />
                                  </li>
                                  <li>
                                    <CheckBox
                                      labelColor={'#a4a1fb'}
                                      checkboxColor={'#a4a1fb'}
                                      htmlFor={'filter5'}
                                      inputClass={''}
                                      labelClass={''}
                                      label={'Live Loans'}
                                      checked={filters.liveLoan}
                                      onChange={() =>
                                        this.setFilters('liveLoan')
                                      }
                                    />
                                  </li>
                                  <li>
                                    <CheckBox
                                      labelColor={'#a4a1fb'}
                                      checkboxColor={'#a4a1fb'}
                                      htmlFor={'filter6'}
                                      inputClass={''}
                                      labelClass={''}
                                      label={'Closed Loans'}
                                      checked={filters.closedLoan}
                                      onChange={() =>
                                        this.setFilters('closedLoan')
                                      }
                                    />
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div className={'col-md-2 text-center'}>
                            <button onClick={this.clearFilters} type={'button'}>
                              Clear All Filers
                            </button>
                            <span
                              className='icon-download'
                              title='Excel Download'
                            >
                              <a href='#'>
                                <Icon icon={fileExcel} size={22} />
                              </a>
                            </span>
                          </div>
                        </div>
                        <ReactTable
                          className={
                            !applicantsData.length
                              ? 'no-data applicants-table'
                              : 'applicants-table'
                          }
                          data={applicantsData}
                          columns={_applicantsColumns}
                          resizable={false}
                          minRows={0}
                          pageSizeOptions={[10, 20, 25, 50, 100]}
                          defaultPageSize={10}
                          sortable={false}
                        />
                      </div>
                      <div className={'box-wrapper '}>
                        <div className={'box-header'}>
                          <h4>Delinquency History of {item.name}</h4>
                        </div>
                        <div className={'chart-box '} id={item.pan} />
                      </div>
                      <RulesCard data={creditRulesResponse} />
                      <LoanRepaymentSummaryCard data={analysisData} />
                      <div className={'row mt-5'}>
                        <div className={'col-md-12'}>
                          <div className={'box-wrapper m-0'}>
                            <div className={'box-header'}>
                              <h4>Credit Inquiries</h4>
                              <CreditInquiries data={analysisData} />
                              <Tabs
                                defaultActiveKey='all'
                                id='all-tabs'
                                activeKey={this.state.key}
                                onSelect={this.handleTabSelect}
                              >
                                <Tab.Content eventKey='all' title={'All'}>
                                  <ReactTable
                                    className={
                                      !allEnquiries.length
                                        ? 'no-data m-b-0'
                                        : 'm-b-0'
                                    }
                                    data={allEnquiries}
                                    columns={enquriesColumns}
                                    resizable={false}
                                    pageSizeOptions={[10, 20, 25, 50, 100]}
                                    defaultPageSize={10}
                                    minRows={0}
                                  />
                                </Tab.Content>
                                <Tab.Content
                                  title={'Asset Creation'}
                                  eventKey='assetCreation'
                                >
                                  <ReactTable
                                    className={
                                      !assetCreationEnquiries.length
                                        ? 'no-data m-b-0'
                                        : 'm-b-0'
                                    }
                                    data={assetCreationEnquiries}
                                    columns={enquriesColumns}
                                    resizable={false}
                                    pageSizeOptions={[10, 20, 25, 50, 100]}
                                    defaultPageSize={10}
                                    minRows={0}
                                  />
                                </Tab.Content>
                                <Tab.Content
                                  eventKey='businessFunding'
                                  title={'Business Funding'}
                                >
                                  <ReactTable
                                    className={
                                      !businessFundingEnquiries.length
                                        ? 'no-data m-b-0'
                                        : 'm-b-0'
                                    }
                                    data={businessFundingEnquiries}
                                    columns={enquriesColumns}
                                    resizable={false}
                                    pageSizeOptions={[10, 20, 25, 50, 100]}
                                    defaultPageSize={10}
                                    minRows={0}
                                  />
                                </Tab.Content>
                                <Tab.Content
                                  eventKey='consumption'
                                  title={'Consumption'}
                                >
                                  <ReactTable
                                    className={
                                      !consumptionEnquiries.length
                                        ? 'no-data m-b-0'
                                        : 'm-b-0'
                                    }
                                    data={consumptionEnquiries}
                                    columns={enquriesColumns}
                                    resizable={false}
                                    pageSizeOptions={[10, 20, 25, 50, 100]}
                                    defaultPageSize={10}
                                    minRows={0}
                                  />
                                </Tab.Content>
                              </Tabs>
                            </div>
                          </div>
                          <CreditBehaviourPersonalRemarks />
                        </div>
                      </div>
                    </Tab.Content>
                  );
                })}
              </Tabs>
            </div>
          ) : null}
        </div>
      </>
    );
  }
}

const mapStateToProps = ({ leadCreditBehaviour }) => ({
  initialResponse: leadCreditBehaviour.initialResponse,
  applicantsSummary: leadCreditBehaviour.applicantsSummary,
  analysisData: leadCreditBehaviour.analysisData,
  creditRulesResponse: leadCreditBehaviour.creditRulesResponse,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      fetchAnalysis,
      getApplicantsSummary,
      setInitialResponse,
      getCreditRules,
      loaderModal,
    },
    dispatch
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LeadCreditBehaviour);
