const SET_BUSINESS_DETAILS = Symbol('setBusinessDetails'),
  SET_ADDRESS_DETAILS = Symbol('setAddressDetails'),
  SET_BANK_DETAILS = Symbol('setBankDetails'),
  SET_DEVICE_DETAILS = Symbol('setDeviceDetails'),
  SET_COMPARE_DETAILS = Symbol('setComapreDetails'),
  SET_PHOTOS_DETAILS = Symbol('setPhotosDetails'),
  SET_CONDITION_NOTES = Symbol('setConditionNotes'),
  SET_LOAN_LIMIT = Symbol('setLoanLimit'),
  SET_COMMENT_NOTES = Symbol('setCommentNotes'),
  SET_CPO_DOCUMENT = Symbol('setCpoDocument'),
  SET_TRANSACTION_DETAILS = Symbol('setTransactionDetails'),
  SET_DEDUPE_DETAILS = Symbol('setDedupeDetails'),
  SET_PROGRAM_LIST = Symbol('setProgramList'),
  SET_SUB_PROGRAM_LIST = Symbol('setSubProgramList'),
  SET_DIGILOCKER_DETAILS = Symbol('setDigilockerDetail'),
  SET_VERIFICATION_STATUS = Symbol('setVerificationStatus'),
  SET_COMPARE_KYC_DETAILS = Symbol('setCompareKycDetails'),
  SET_SUPPLIER_DETAILS = Symbol('setSupplierDetails'),
  SET_LENDERS_LIST = Symbol('setLendersList');
export {
  SET_SUPPLIER_DETAILS,
  SET_COMPARE_KYC_DETAILS,
  SET_VERIFICATION_STATUS,
  SET_CPO_DOCUMENT,
  SET_DIGILOCKER_DETAILS,
  SET_BUSINESS_DETAILS,
  SET_ADDRESS_DETAILS,
  SET_BANK_DETAILS,
  SET_DEVICE_DETAILS,
  SET_COMPARE_DETAILS,
  SET_PHOTOS_DETAILS,
  SET_CONDITION_NOTES,
  SET_COMMENT_NOTES,
  SET_LOAN_LIMIT,
  SET_TRANSACTION_DETAILS,
  SET_DEDUPE_DETAILS,
  SET_PROGRAM_LIST,
  SET_SUB_PROGRAM_LIST,
  SET_LENDERS_LIST,
};
