import React, { Component, Fragment } from 'react';
import { Route, Switch } from 'react-router-dom';
import LoaderModal from '../../components/LoaderModal';
import { get } from 'lodash';
import { createBrowserHistory } from 'history';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { loaderModal } from '../../store/actions';
import ALLInvoices from './ALLInvoices/ALLInvoices';
import MarginInvoices from './MarginInvoices/MarginInvoices';
import InvoiceManagementNavs from './InvoiceManagementNavs/InvoiceManagementNavs';
import OnHoldInvoices from './OnHoldInvoices/OnHoldInvoices';
import UnderReviewInvoices from './UnderReviewInvoices/UnderReviewInvoices';
import FundPendingInvoices from './FundPendingInvoices/FundPendingInvoices';
import FailedInvoices from './FailedInvoices/FailedInvoices';
import DisbursedInvoices from './DisbursedInvoices/DisbursedInvoices';
import DraftedInvoices from './DraftedInvoices/DraftedInvoices';

export const hist = createBrowserHistory();

class InvoiceManagementRoutes extends Component {
  render() {
    const arrPath = get(this, 'props.location.pathname', '').split('/');
    const activeTab = arrPath.length
      ? arrPath[arrPath.length - 1]
      : 'all-invoices';
    let { loaderModalStatus } = this.props;
    return (
      <>
        <LoaderModal status={loaderModalStatus} />
        <Fragment>
          <InvoiceManagementNavs active={activeTab} {...this.props} />
          <Switch>
            <Route
              path='/invoice-dashboard/all-invoices'
              component={ALLInvoices}
            />
            <Route
              path='/invoice-dashboard/disbursed-invoices'
              exact
              component={DisbursedInvoices}
            />
            <Route
              path='/invoice-dashboard/fund-pending-invoices'
              exact
              component={FundPendingInvoices}
            />
            <Route
              path='/invoice-dashboard/under-review-invoices'
              exact
              component={UnderReviewInvoices}
            />
            <Route
              path='/invoice-dashboard/on-hold-invoices'
              exact
              component={OnHoldInvoices}
            />
            <Route
              path='/invoice-dashboard/failed-invoices'
              exact
              component={FailedInvoices}
            />
            <Route
              path='/invoice-dashboard/margin-invoices'
              exact
              component={MarginInvoices}
            />
            <Route
              path='/invoice-dashboard/drafted-invoices'
              exact
              component={DraftedInvoices}
            />
          </Switch>
        </Fragment>
      </>
    );
  }
}

const mapStateToProps = ({ data }) => ({
  loaderModalStatus: data.loaderModal,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      loaderModal,
    },
    dispatch
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InvoiceManagementRoutes);
