const Kyc = [
  {
    Header: 'Source',
    accessor: 'counterparty',
  },
  {
    Header: 'GstIn',
    accessor: 'counterparty',
  },
  {
    Header: 'System',
    accessor: 'counterparty',
  },
];
const B2B = [
  {
    Header: '',
    accessor: 'counterparty',
  },
  {
    Header: 'Average',
    accessor: 'counterparty',
  },
  {
    Header: 'Median',
    accessor: 'counterparty',
  },
  {
    Header: 'jan 2022',
    accessor: 'counterparty',
  },
  {
    Header: 'Feb 2022',
    accessor: 'counterparty',
  },
  {
    Header: 'March 2022',
    accessor: 'counterparty',
  },
  {
    Header: 'April 2022',
    accessor: 'counterparty',
  },
  {
    Header: 'May 2022',
    accessor: 'counterparty',
  },
  {
    Header: 'june 2022',
    accessor: 'counterparty',
  },
  {
    Header: 'july 2022',
    accessor: 'counterparty',
  },
  {
    Header: 'August 2022',
    accessor: 'counterparty',
  },
  {
    Header: 'Septembr 2022',
    accessor: 'counterparty',
  },
  {
    Header: 'octobe 2022',
    accessor: 'counterparty',
  },
  {
    Header: 'November 2022',
    accessor: 'counterparty',
  },
  {
    Header: 'December 2022',
    accessor: 'counterparty',
  },
];
export { Kyc, B2B };
