import * as invitationConstants from './invite.action.constant';

const initialState = {
  buyerInvitation: {},
};
const invitationReducer = (state = initialState, action) => {
  let { type, data } = action;
  switch (type) {
    case invitationConstants.SET_BUYER_INVITATION:
      return { ...state, buyerInvitation: data };
    default:
      return state;
  }
};

export default invitationReducer;
