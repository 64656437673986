import React, { Component } from 'react';
import { POST, GET } from '../../../../utils/webAPI.service';
import {
  API_UPDATE_BANK_DETAILS,
  API_IFSC_VERIFY,
} from '../../../../utils/APIUrls';
export default class AddBankDetails extends Component {
  state = {
    isButtonDisabled: true,
    accountNumber: '',
    isFocused: false,
    singleBankObject: {
      accountHolderName: null,
      accountNumber: null,
      accountType: null,
      ifsc: null,
      mobileNumber: null,
      partyUniqueId: null,
      reEnterAccountNumber: null,
      supplierId: null,
      userId: null,
    },
  };

  trimObjectValues(obj) {
    for (let key in obj) {
      if (obj[key] !== null) {
        obj[key] = obj?.[key]?.trim();
      }
    }
    return obj;
  }

  validate = () => {
    let { singleBankObject } = this.state;
    let {
      accountType,
      accountHolderName,
      accountNumber,
      reEnterAccountNumber,
    } = singleBankObject;
    if (accountType === null) {
      alert('Please Select Account Type');
      return false;
    } else if (
      Number(accountHolderName?.length) < 3 ||
      Number(accountHolderName?.length) > 50
    ) {
      alert(
        'Account Holder Name cannot be less than 3 and more than 50 characters.'
      );
      return false;
    } else if (accountNumber !== reEnterAccountNumber) {
      alert('Account number does not match, please re-enter.');
      return false;
    }
    return true;
  };

  updateBankDetails = (e) => {
    e.preventDefault();
    let { singleBankObject } = this.state;
    console.log('In');
    if (this.validate()) {
      POST(API_UPDATE_BANK_DETAILS, this.trimObjectValues(singleBankObject))
        .then(({ data }) => {
          if (data.code === 200) {
            alert('Bank Details Updated Successfully');
            this.props.closeAction();
          } else if (data.code === 406) {
            alert(data?.message);
          } else {
            alert(data?.message);
          }
        })
        .catch((err) => {
          alert('Something went wrong');
          throw err;
        });
    }
  };

  onIfscVerify = (e) => {
    e.preventDefault();
    let { singleBankObject } = this.state;
    let { propsData: { deviceDetails, match: { params } } = {} } =
      this.props || {};
    this.setState((prevState) => ({
      singleBankObject: {
        ...prevState.singleBankObject,
        mobileNumber: deviceDetails?.mobile,
        userId: params?.userId,
      },
    }));
    let { ifsc } = singleBankObject;
    GET(API_IFSC_VERIFY(ifsc))
      .then(({ data }) => {
        if (data?.data) {
          this.setState({
            isButtonDisabled: false,
          });
          alert('IFSC code verified successfully');
        } else {
          alert(data?.message);
        }
      })
      .catch((err) => {
        alert('Something went wrong');
        throw err;
      });
  };

  onInputChange = (event) => {
    const { value, name } = event.target;
    let newValue = value;
    if (name === 'reEnterAccountNumber' || name === 'accountNumber') {
      newValue = value.replace(/\D/g, ''); // Remove non-digit characters
    }
    this.setState((prevState) => ({
      singleBankObject: {
        ...prevState.singleBankObject,
        [name]: newValue,
      },
    }));
  };

  render() {
    let { isFocused, singleBankObject, isButtonDisabled } = this.state;
    let { accountHolderName, accountNumber, ifsc, reEnterAccountNumber } =
      singleBankObject;
    let { propsData: { deviceDetails, match: { params } } = {} } =
      this.props || {};
    return (
      <div className='modal-wrapper'>
        <h3 className='card_heading'>Add/Edit Bank Details</h3>
        <form onSubmit={(e) => this.updateBankDetails(e)}>
          <div className='row'>
            <div className='col-md-6'>
              <div className='input_heading'>
                Account Holder Namess
                <input
                  placeholder='Enter Account Holder Name'
                  value={accountHolderName}
                  name='accountHolderName'
                  required
                  onChange={this.onInputChange}
                  autoComplete='off'
                />
              </div>
            </div>
            <div className='col-md-6'>
              <div className='input_heading'>
                Account Number
                <input
                  placeholder='Account Number'
                  value={accountNumber}
                  name='accountNumber'
                  required
                  type={
                    isFocused === false && accountNumber?.length > 0
                      ? 'password'
                      : 'text'
                  }
                  onFocus={() =>
                    this.setState((prevState) => ({
                      ...prevState,
                      isFocused: !prevState.isFocused,
                    }))
                  }
                  onBlur={() =>
                    this.setState((prevState) => ({
                      ...prevState,
                      isFocused: !prevState.isFocused,
                    }))
                  }
                  onPaste={(e) => {
                    e.preventDefault();
                    return false;
                  }}
                  onCopy={(e) => {
                    e.preventDefault();
                    return false;
                  }}
                  onChange={this.onInputChange}
                  autoComplete='off'
                />
              </div>
            </div>
            <div className='col-md-6'>
              <div className='input_heading'>
                Re-Enter Account Number
                <input
                  placeholder='Re Enter Account Number'
                  value={reEnterAccountNumber}
                  required
                  name='reEnterAccountNumber'
                  onChange={this.onInputChange}
                />
              </div>
            </div>
            <div className='col-md-6'>
              <div className='input_heading'>
                <div> Select Account Type</div>
                <select
                  className={'select-box'}
                  name={'accountType'}
                  onChange={this.onInputChange}
                >
                  <option disabled selected>
                    Select Account Type
                  </option>
                  <option value={'current'}>Current</option>
                </select>
              </div>
            </div>
            <div className='col-md-6'>
              <div className='input_heading'>
                <div> IFSC</div>
                <input
                  placeholder=' Enter the IFSC'
                  value={ifsc}
                  required
                  name='ifsc'
                  type='tel'
                  onChange={this.onInputChange}
                />
              </div>
            </div>
            <div className='save_button mt-5 col-md-3'>
              <button
                className='save_button  button-success px-5'
                onClick={this.onIfscVerify}
              >
                Verify
              </button>
            </div>
          </div>
          <div className='save_button mt-5'>
            <button
              className='save_button  button-success'
              disabled={isButtonDisabled}
              type='submit'
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    );
  }
}
