import * as fundPendingInvoicesConstant from './fundPendingInvoices.action.constant';
const initialState = {
  fundPendingInvoicesList: {},
  fundPendingInvoicesSummary: {}
};

const FundPendingInvoicesReducer = (state = initialState, action) => {
  const { type, data } = action;
  switch (type) {
    case fundPendingInvoicesConstant.SET_FUND_PENDING_INVOICES_LIST:
      return { ...state, fundPendingInvoicesList: data };
    case fundPendingInvoicesConstant.SET_FUND_PENDING_INVOICES_SUMMARY:
      return { ...state, fundPendingInvoicesSummary: data };
    default:
      return state;
  }
};
export default FundPendingInvoicesReducer;
