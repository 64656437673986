import React, { useEffect } from 'react';
import Cross from '../../../../../assets/images/crossnew.png';
import Green from '../../../../../assets/images/Greentik.png';
import Question from '.././../../../../assets/images/QuestionIcon.svg';
import { bindActionCreators } from 'redux';
import { getDocumentVerifiationStatus } from '../application.actions';
import { connect } from 'react-redux';

export const EntityIcon = (props) => {
  let {
    actions: { getDocumentVerifiationStatus },
    docId,
    loanId,
    docType,
  } = props;
  let docIdCopy = docId !== 0 ? docId : docType;
  useEffect(() => {
    getDocumentVerifiationStatus(loanId, docIdCopy);
  }, [docIdCopy, loanId]);

  const getStatusIcon = () => {
    if (props.verificationStatus[docIdCopy] === 'success') {
      return Green;
    } else if (props.verificationStatus[docIdCopy] === 'failure') {
      return Cross;
    } else {
      return Question;
    }
  };
  return <img className=' cross' alt='' src={getStatusIcon()}></img>;
};

const mapStateToProps = ({ ApplicationReducer }) => ({
  verificationStatus: ApplicationReducer.verificationStatus,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      getDocumentVerifiationStatus,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(EntityIcon);
