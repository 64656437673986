import React, { Component } from 'react';
import { API_ADD_SUPPLIER } from '../../../../utils/APIUrls';
import { POST } from '../../../../utils/webAPI.service';
export default class BulkInvite extends Component {
  render() {
    return (
      <>
        <div className='row align-items-center mt-5 border-bottom pb-2'>
          <div className='d-flex align-items-center col-5'>
            <input className='w-25' type='radio' />
            <div>Pemant Template</div>
          </div>
          <div className='d-flex align-items-center col-3'>
            <input className='w-25' type='radio' />
            <div>Tally</div>
          </div>
          <div className='d-flex align-items-center col-4'>
            <input className='w-25' type='radio' />
            <div>Invite from GST</div>
          </div>
        </div>
        <div className='row mt-5 text-primary d-flex align-items-center justify-content-center'>
          <a>Click here to download pemant Template for bulk Upload</a>
        </div>
        <div className='modal-wrapper'>
          <div className='save_button modal-wrapper mt-5'>
            <button
              className='save_button col-12  button-success'
              type='submit'
            >
              Upload document here
            </button>
          </div>
        </div>
      </>
    );
  }
}
