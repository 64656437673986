import React, { Component } from 'react';
import 'react-table/react-table.css';
import { rupeeCommaFormatter } from '../../../../utils/utility';

export default class BusinessSummaryModal extends Component {
  bgColor = (color) => {
    if (color === 'GREEN') {
      return '#ECF9F1';
    } else if (color === 'RED') {
      return '#FFEFEE';
    } else {
      return;
    }
  };
  render() {
    let { gstinAnalysis } = this.props,
      { annualAnalysis = {} } = gstinAnalysis || {},
      { b2bSalesSummary } = annualAnalysis || {};
    let { invoices = [] } = b2bSalesSummary || {};

    return (
      <>
        <div className='sales_form_container gst__container'>
          <div className=' box-wrapper'>
            <div className='box-header'>
              <h4>B2B Sales Summary</h4>
            </div>
            {b2bSalesSummary ? (
              <>
                <div>
                  <div className={'table-box'}>
                    <table>
                      <thead>
                        <tr>
                          <th></th>
                          <th>Average</th>
                          <th>Median</th>
                          {invoices?.map((el, i) => {
                            return <th>{el?.month.replace('-', '  ')}</th>;
                          })}
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className=' sub_column'>B2B Buyers </td>
                          <td className=' sub_column'>
                            {b2bSalesSummary?.avgBuyers}
                          </td>
                          <td className=' sub_column'>
                            {b2bSalesSummary?.medianBuyers}
                          </td>
                          {invoices?.map((el, i) => (
                            <td
                              key={i}
                              className='sub_column'
                              style={{
                                backgroundColor: this.bgColor(
                                  el?.buyersCategory
                                ),
                              }}
                            >
                              {el?.noOfBuyers}
                            </td>
                          ))}
                        </tr>
                        <tr>
                          <td className=' sub_column'>No of B2B Invoices </td>
                          <td className=' sub_column'>
                            {b2bSalesSummary?.avgInvoices}
                          </td>
                          <td className=' sub_column'>
                            {b2bSalesSummary?.medianInvoices}
                          </td>
                          {invoices?.map((el, i) => (
                            <td
                              key={i}
                              className='sub_column'
                              style={{
                                backgroundColor: this.bgColor(
                                  el?.invoicesCategory
                                ),
                              }}
                            >
                              {el?.noOfInvoices}
                            </td>
                          ))}
                        </tr>
                        <tr>
                          <td className=' sub_column'>B2b Sales Amount </td>
                          <td className=' sub_column'>
                            ₹ {b2bSalesSummary?.avgSalesAmount}
                          </td>
                          <td className=' sub_column'>
                            ₹ {b2bSalesSummary?.medianSalesAmount}
                          </td>
                          {invoices?.map((el, i) => (
                            <td
                              key={i}
                              className='sub_column'
                              style={{
                                backgroundColor: this.bgColor(
                                  el?.salesAmountCategory
                                ),
                              }}
                            >
                              ₹ {rupeeCommaFormatter(el?.salesAmount) || '-'}
                            </td>
                          ))}
                        </tr>
                        <tr>
                          <td className=' sub_column'>
                            Average Invoice Amount{' '}
                          </td>
                          <td className=' sub_column'>
                            ₹ {b2bSalesSummary?.meanAvgInvoiceAmount}
                          </td>
                          <td className=' sub_column'>
                            ₹ {b2bSalesSummary?.medianAvgInvoiceAmount}
                          </td>
                          {invoices?.map((el, i) => (
                            <td
                              key={i}
                              className='sub_column'
                              style={{
                                backgroundColor: this.bgColor(
                                  el?.averageInvoiceAmountCategory
                                ),
                              }}
                            >
                              ₹{' '}
                              {rupeeCommaFormatter(el?.averageInvoiceAmount) ||
                                '-'}
                            </td>
                          ))}
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className='row ml-2'>
                    <div className='color-information'>
                      <div class='color-box lesser-median '></div>
                      <div className='ml-2'>
                        Month - Invoices is Less than 15% from Median / Mean,
                        Average Invoice Amount is Less than 15% from Median /
                        Mean
                      </div>
                    </div>
                    <div className='color-information'>
                      <div class='color-box greater-median'></div>
                      <div className='ml-2'>
                        Month - Invoices is Greater than 15% from Median / Mean,
                        Average Invoice Amount is Greater than 15% from Median /
                        Mean
                      </div>
                    </div>
                  </div>
                </div>

                <div className={'box-footer'}>
                  <ul className={'list-unstyled'}>
                    <li>
                      <label>
                        Count of Month where sales is less than 15% of Median
                      </label>
                      <span>
                        {
                          b2bSalesSummary?.cntOfMonthsWhereSalesLessThan85PerOfMedian
                        }
                        %
                      </span>
                    </li>
                    <li>
                      <label>
                        Count of Month where sales is less than 15% of Mean
                      </label>
                      <span>
                        {
                          b2bSalesSummary?.cntOfMonthsWhereSalesLessThan85PerOfMean
                        }
                        %
                      </span>
                    </li>
                  </ul>
                </div>
              </>
            ) : (
              'No Data Available'
            )}
          </div>
        </div>
      </>
    );
  }
}
