import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { rupeeDecimalFormatter } from '../../../../utils/utility';
import { getBuyerSummary } from './activeParties.action';
import Arrow from '../../../../assets/images/LeftArrow.svg';
import Invoice from './Invoice';
class BuyerDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount = () => {
    const { actions, match } = this.props;
    const { getBuyerSummary } = actions;
    getBuyerSummary(match?.params?.anchorId, match?.params?.buyerId);
  };

  render() {
    const { buyerSummaryData, match } = this.props;
    const BUYER_LIMIT_STATUS = {
      ACTIVE: 'Active',
      ON_HOLD: 'On Hold',
      INACTIVE: 'Inactive',
      BLOCKED: 'Blocked',
    };
    return (
      <>
        <div className='container invoices-wrapper'>
          <div className='heading'>
            <img
              src={Arrow}
              alt='buyerName'
              onClick={this.props.history.goBack}
            />
            {buyerSummaryData?.buyerName}
          </div>
          <div className='card'>
            <div className='row mt-5'>
              <div className='col-md-3  text_heading mt-2'>
                {buyerSummaryData?.buyerName}
                <div className='text-bold'>
                  {buyerSummaryData?.buyerMobileNumber}
                </div>
              </div>
              <div className='col-md-3 text-grey'>
                GST Number
                <div className='text-bold'>
                  {buyerSummaryData?.buyerGstin || '-'}
                </div>
              </div>
            </div>
            <div className='entity_container container-box entity_height '>
              <div className='row m-10 mt-4 '>
                <div className='col-md-3 text-grey'>
                  Total Invoices
                  <div className='text-dark'>
                    {buyerSummaryData?.totalInvoice ?? '-'}
                  </div>
                </div>
                <div className='col-md-3 text-grey'>
                  Paid Invoice
                  <div className='text-dark'>
                    {buyerSummaryData?.paidInvoice ?? '-'}
                  </div>
                </div>
                <div className='col-md-3 text-grey'>
                  Unpaid Invoice
                  <div className='text-dark'>
                    {buyerSummaryData?.unpaidInvoice ?? '-'}
                  </div>
                </div>
                <div className='col-md-3 text-grey'>
                  Overdue Invoices
                  <div className='text-dark'>
                    {buyerSummaryData?.overdueInvoice ?? '-'}{' '}
                  </div>
                </div>
                <div className='col-md-3 text-grey '>
                  Total Amount
                  <div className='text-dark '>
                    {rupeeDecimalFormatter(buyerSummaryData?.totalAmount, '₹')}
                  </div>
                </div>
                <div className='col-md-3 text-grey '>
                  Paid Amount
                  <div className='text-dark'>
                    {rupeeDecimalFormatter(buyerSummaryData?.paidAmount, '₹')}
                  </div>
                </div>
                <div className='col-md-3 text-grey '>
                  Unpaid Amount
                  <div className='text-dark'>
                    {rupeeDecimalFormatter(buyerSummaryData?.unpaidAmount, '₹')}
                  </div>
                </div>
                <div className='col-md-3 text-grey '>
                  Overdue Amount
                  <div className='text-dark'>
                    {rupeeDecimalFormatter(
                      buyerSummaryData?.overdueAmount,
                      '₹'
                    )}
                  </div>
                </div>
                <div className='col-md-3 text-grey '>
                  Total Limit
                  <div className='text-dark'>
                    {rupeeDecimalFormatter(buyerSummaryData?.totalLimit, '₹')}
                  </div>
                </div>
                <div className='col-md-3 text-grey '>
                  Limit Left
                  <div className='text-dark'>
                    {rupeeDecimalFormatter(buyerSummaryData?.leftLimit, '₹')}
                  </div>
                </div>
                <div className='col-md-3 text-grey '>
                  Total Outstanding
                  <div className='text-dark'>
                    {rupeeDecimalFormatter(
                      buyerSummaryData?.totalOutStandingAmount,
                      '₹'
                    )}
                  </div>
                </div>
                <div className='col-md-3 text-grey '>
                  Status
                  <div className='text-dark'>
                    {buyerSummaryData?.limitStatus ===
                    BUYER_LIMIT_STATUS.ACTIVE ? (
                      <span
                        className='capsule  capsule_new_green'
                        style={{ fontSize: '15px' }}
                      >
                        Active
                      </span>
                    ) : buyerSummaryData?.limitStatus ===
                      BUYER_LIMIT_STATUS.BLOCKED ? (
                      <span
                        className='capsule capsule_red'
                        style={{ fontSize: '15px' }}
                      >
                        Blocked
                      </span>
                    ) : buyerSummaryData?.limitStatus ===
                      BUYER_LIMIT_STATUS.ON_HOLD ? (
                      <span
                        className='capsule capsule_grey'
                        style={{ fontSize: '15px' }}
                      >
                        On-Hold
                      </span>
                    ) : buyerSummaryData?.limitStatus ===
                      BUYER_LIMIT_STATUS.INACTIVE ? (
                      <span
                        className='capsule  capsule_orange'
                        style={{ fontSize: '15px' }}
                      >
                        In-Active
                      </span>
                    ) : (
                      null || '-'
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className=''>
          <Invoice {...this.props} userId={match?.params?.buyerId} />
        </div>
      </>
    );
  }
}

const mapStateToProps = ({ activePartiesReducer }) => ({
  buyerSummaryData: activePartiesReducer?.buyerSummaryData,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      getBuyerSummary,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(BuyerDetails);
