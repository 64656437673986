import * as dashboardConstants from './dashboard.action.constant';
import { API_ANCHOR_LIST } from '../../../utils/APIUrls';
import { POST } from '../../../utils/webAPI.service';

const getAnchorList = (
  anchorStatus,
  pageNumber,
  searchType,
  searchValue,
  { sortParameter, sortingOn }
) => {
  let dataToSend = {
    anchorStatus,
    pageNumber,
    searchType,
    searchValue,
    sortingRequest: {
      sortParameter,
      sortingOn,
    },
  };
  return (dispatch) => {
    return POST(API_ANCHOR_LIST, dataToSend)
      .then(({ data }) => {
        if (data.code === 200) {
          if (data?.data) {
            dispatch({
              type: dashboardConstants.SET_ANCHOR_LIST,
              data: data.data,
            });
          }
        } else {
          dispatch({
            type: dashboardConstants.SET_ANCHOR_LIST,
            data: data,
          });
          alert(data?.message);
        }
        return data;
      })
      .catch((err) => {
        alert('Something went wrong');
        throw err;
      });
  };
};

export { getAnchorList };
