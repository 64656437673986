import React, { Component } from 'react';
import Download from '../../../../assets/images/download-cloud.png';
import Preview from '../../../../assets/images/eye.png';
import Greentik from '../../../../assets/images/Greentik.png';
import Cross from '../../../../assets/images/crossnew.png';
export default class KycNewModal extends Component {
  render() {
    return (
      <div className='kyc_modal_wrapper'>
        <div className='row ckyc-wrapper'>
          <div className='card__wrapper col-12'>
            <div className='card-heading '>Aadhaar data</div>
            <div className='row m-10 mt-4 application-wrapper ckyc-wrapper'>
              <div className='col-md-4 mt-2 entity_container_heading '>
                Full Name
                <div className='text-dark '>NA</div>
              </div>
              <div className='col-md-4 mt-2  entity_container_heading '>
                Father Name
                <div className='text-dark '>NA</div>
              </div>
              <div className='col-md-4 mt-2  entity_container_heading '>
                Mother Name
                <div className='text-dark '>NA</div>
              </div>
              <div className='col-md-4 mt-2  entity_container_heading '>
                Gender
                <div className='text-dark '>NA</div>
              </div>
              <div className='col-md-4 mt-2  entity_container_heading '>
                DOB
                <div className='text-dark '>NA</div>
              </div>
              <div className='col-md-4 mt-2  entity_container_heading '>
                CKYC Number
                <div className='text-dark  '>NA</div>
              </div>
              <div className='col-md-4 mt-2  entity_container_heading'>
                Permanent address
                <img className='icon' src={Greentik} />
                <div className='text-dark '>NA</div>
              </div>
              <div className='col-md-4 mt-2  entity_container_heading  '>
                Correspondence address
                <img className='icon' src={Greentik} />
                <div className='text-dark  '>NA</div>
              </div>
            </div>
          </div>
        </div>

        <div className='row ckyc-wrapper center'>
          <div className='card__wrapper col-5'>
            <div className='card-heading '>
              <div>
                Aadhaar data
                <img className='card-heading__icon' src={Cross} />
              </div>

              <div className=''>
                <img className='img' src={Preview}></img>
                <img src={Download}></img>
              </div>
            </div>
            <div className='card-body'>NA</div>
          </div>
          <div className='card__wrapper col-5'>
            <div className='card-heading '>
              <div>
                DL data
                <img className='card-heading__icon' src={Greentik} />
              </div>

              <div className=''>
                <img className='img' src={Preview}></img>
                <img src={Download}></img>
              </div>
            </div>
            <div className='card-body'>NA</div>
          </div>
          <div className='card__wrapper col-5'>
            <div className='card-heading '>
              <div>
                Pan data
                <img className='card-heading__icon' src={Greentik} />
              </div>

              <div className=''>
                <img className='img' src={Preview}></img>
                <img src={Download}></img>
              </div>
            </div>
            <div className='card-body'>NA</div>
          </div>
          <div className='card__wrapper col-5'>
            <div className='card-heading '>
              <div>
                Voter data
                <img className='card-heading__icon' src={Greentik} />
              </div>

              <div className=''>
                <img className='img' src={Preview}></img>
                <img src={Download}></img>
              </div>
            </div>
            <div className='card-body'>NA</div>
          </div>
          <div className='card__wrapper col-5'>
            <div className='card-heading '>
              <div>
                Passport data
                <img className='card-heading__icon' src={Greentik} />
              </div>

              <div className=''>
                <img className='img' src={Preview}></img>
                <img src={Download}></img>
              </div>
            </div>
            <div className='card-body'>NA</div>
          </div>
        </div>
      </div>
    );
  }
}
