import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import { POST } from '../../../../../utils/webAPI.service';
import { API_POST_DOCUMENT_VERIFY } from '../../../../../utils/APIUrls';

export default class DigilockerPanDocument extends Component {
  postDocumentVerify = (loanId, docId, status) => {
    let { closeAction, pan } = this.props;
    const data = {
      docId,
      loanId,
      remarks: '',
      status: status,
    };

    POST(API_POST_DOCUMENT_VERIFY, data).then((res) => {
      if (res.status === 200) {
        closeAction('');
        pan('');
      }
    });
  };

  render() {
    let {
      photos,

      propsData: {
        propsData: {
          match: {
            params: { loanId },
          },
        },
      },
    } = this.props;
    let { kycPanDocument } = photos;

    return (
      <div className='compare-details-wrapper digilocker-document-wrapper'>
        {kycPanDocument ? (
          <>
            <div>
              <iframe
                title='pandoc'
                className='profile_img preview_img'
                src={kycPanDocument?.fileUrl}
              ></iframe>
            </div>

            <div className='btn'>
              <Button
                className='btn_verify'
                onClick={() =>
                  this.postDocumentVerify(
                    loanId,
                    kycPanDocument?.type,
                    'success'
                  )
                }
              >
                Verify
              </Button>
              <Button
                className='btn_cancel'
                onClick={() =>
                  this.postDocumentVerify(
                    loanId,
                    kycPanDocument?.type,
                    'failure'
                  )
                }
              >
                Reject
              </Button>
            </div>
          </>
        ) : (
          'No Data Available'
        )}
      </div>
    );
  }
}
