import React, { Component } from 'react';
import ReactTable from 'react-table';
import { getInvitationList } from './invite.action';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Processing from '../../../../assets/images/Processing.svg';
import Greentick from '../../../../assets/images/green tick.svg';
import Cross from '../../../../assets/images/crossnew.png';
import Pen from '../../../../assets/images/pen.png';
import { CommentModal } from './CommentModal';
import CredoModal from '../../../../components/CredoModal';
class Stagged extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeFigure: 'all',
      pageNumber: 1,
      searchBy: 'buyerStatus',
      searchValue: 'all',
      isModalOpen: false,
      modalType: null,
    };
  }

  getPageData = async (type, totalPages) => {
    let { match, history, actions } = this.props,
      { anchorId } = match.params;
    let { pageNumber, searchBy, searchValue } = this.state;
    const { getInvitationList } = actions;
    let updatedPageNumber = pageNumber;
    if (type === 'next' && pageNumber < totalPages) {
      updatedPageNumber = pageNumber + 1; // Update page number for next page
    } else if (type === 'prev' && pageNumber > 1) {
      updatedPageNumber = pageNumber - 1; // Update page number for previous page
    }
    await this.setState({ pageNumber: updatedPageNumber });
    getInvitationList(updatedPageNumber, searchBy, searchValue, anchorId);
  };

  invitationTabHandler = async (type, value) => {
    await this.setState({
      activeFigure: type,
      searchValue: value,
      pageNumber: 1,
    }); //set page number 1
    let { match, history, actions } = this.props,
      { anchorId } = match.params;
    let { searchBy, searchValue } = this.state;
    const { getInvitationList } = actions;
    getInvitationList(1, searchBy, searchValue, anchorId);
  };

  refreshPage = () => {
    let { match, history, actions } = this.props,
      { anchorId } = match.params;
    let { pageNumber, searchBy, searchValue } = this.state;
    const { getInvitationList } = actions;
    getInvitationList(pageNumber, searchBy, searchValue, anchorId);
  };

  componentDidMount = () => {
    let { match, history, actions } = this.props,
      { anchorId } = match.params;
    let { pageNumber, searchBy, searchValue } = this.state;
    const { getInvitationList } = actions;
    getInvitationList(pageNumber, searchBy, searchValue, anchorId);
  };

  toggleModal = (modalType, loanId, comment) => {
    this.setState({
      isModalOpen: !this.state.isModalOpen,
      modalType,
      loanId,
      comment,
    });
  };

  render() {
    let { match, history, actions } = this.props,
      { anchorId } = match.params;
    let { buyerInvitation } = this.props || {};
    let { buyersList = [], totalPages } = buyerInvitation || {};
    let { activeFigure, pageNumber, isModalOpen, modalType, loanId, comment } =
      this.state;
    const BUYER_STATUS_PARAMS = {
      PENDING: 'onboarding_pending',
      COMPLETED: 'onboarding_completed',
      REJECTED: 'Rejected',
    };
    const buyerInvitationColumn = [
      {
        Header: 'PARTY NAME',
        accessor: 'buyerName',
        width: 290,
        Cell: (props) => {
          let { original } = props;
          let { buyerName } = original;
          return <>{<div>{buyerName || '-'}</div>}</>;
        },
      },
      {
        Header: 'Mobile No',
        accessor: 'mobileNumber',
      },

      {
        Header: 'Gstin',
        accessor: 'buyerGstIn',
        Cell: (props) => {
          let { original } = props;
          let { buyerGstIn } = original;
          return <>{buyerGstIn || '-'}</>;
        },
      },
      {
        Header: 'Status',
        accessor: 'invitationStatus',
        width: 200,
        Cell: (props) => {
          let { original } = props;
          let { invitationDate, buyerStatus } = original;
          return (
            <>
              <div>
                {buyerStatus === BUYER_STATUS_PARAMS?.PENDING ? (
                  <div className='d-flex'>
                    <img className='img-icon' src={Processing} />
                    <div className='ml-3'>Onboarding Pending</div>
                  </div>
                ) : buyerStatus === BUYER_STATUS_PARAMS?.COMPLETED ? (
                  <div className='d-flex'>
                    <img className='img-icon' src={Greentick} />
                    <div className='ml-3'>Onboarded</div>
                  </div>
                ) : buyerStatus === BUYER_STATUS_PARAMS?.REJECTED ? (
                  <div className='d-flex'>
                    <img className='img-icon' src={Cross} />
                    <div className='ml-3'>Rejected</div>
                  </div>
                ) : null}
              </div>
              <div className='mt-2'>{invitationDate || '-'}</div>
            </>
          );
        },
      },
    ];

    let updatedColumns = [
      ...buyerInvitationColumn,
      ...(activeFigure !== 'Onboarding'
        ? [
            {
              Header: 'Comments',
              accessor: 'buyerGstIn',
              Cell: (props) => {
                let { original } = props;
                let { comment, buyerStatus } = original;
                // If buyerStatus is 'onboarding_pending'
                if (buyerStatus === BUYER_STATUS_PARAMS?.PENDING) {
                  return null; // Don't display comments for 'onboarding_pending'
                }

                return (
                  <div className='d-flex ml-3'>
                    <div>{comment}</div>
                  </div>
                );
              },
            },
            {
              Header: '',
              width: 80,
              Cell: (props) => {
                let { original } = props;
                let { loanId, buyerStatus, comment } = original;
                // Only display the 'Pen Icon' if the status is 'onboarding_completed'
                if (buyerStatus !== BUYER_STATUS_PARAMS?.COMPLETED) {
                  return null; // Don't display the icon for statuses other than 'onboarding_completed'
                }
                return (
                  <div className='application-wrapper d-flex justify-content-center'>
                    <img
                      src={Pen}
                      alt='pen Icon'
                      className='pen_img'
                      onClick={() =>
                        this.toggleModal(CommentModal, loanId, comment)
                      }
                    />
                  </div>
                );
              },
            },
          ]
        : []),
    ];

    return (
      <>
        <div className='container invoices-wrapper mt-5'>
          <div className='row generic_button_box my-4'>
            <div
              onClick={() => this.invitationTabHandler('all', 'all')}
              className={
                activeFigure === 'all'
                  ? 'col-md-1 generic_button_active'
                  : 'col-md-1 generic_button'
              }
            >
              All
            </div>
            <div
              onClick={() =>
                this.invitationTabHandler('Onboarded', 'onboarding_completed')
              }
              className={
                activeFigure === 'Onboarded'
                  ? 'col-md-1 generic_button_active'
                  : 'col-md-1 generic_button'
              }
            >
              Onboarded
            </div>
            <div
              onClick={() =>
                this.invitationTabHandler('Onboarding', 'onboarding_pending')
              }
              className={
                activeFigure === 'Onboarding'
                  ? 'col-md-2 generic_button_active'
                  : 'col-md-2 generic_button'
              }
            >
              Onboarding Pending
            </div>
            <div
              onClick={() => this.invitationTabHandler('Rejected', 'Rejected')}
              className={
                activeFigure === 'Rejected'
                  ? 'col-md-2 generic_button_active'
                  : 'col-md-2 generic_button'
              }
            >
              Rejected
            </div>
          </div>
          <div className='mt-1'>
            <ReactTable
              data={buyersList || []}
              columns={updatedColumns}
              defaultPageSize={10}
              showPagination={false}
              sortable={false}
            />
            <ul className={'datatable-pagination'}>
              <li>
                <button
                  disabled={!(pageNumber > 1)}
                  className={'prev-btn'}
                  type={'button'}
                  onClick={() => this.getPageData('prev', totalPages)}
                >
                  Previous
                </button>
              </li>
              <li>
                <span>
                  Page No. {totalPages === 0 ? 0 : pageNumber} of {totalPages}
                </span>
              </li>

              <li>
                <button
                  disabled={!(totalPages > pageNumber)}
                  className={'next-btn'}
                  type={'button'}
                  onClick={() => this.getPageData('next', totalPages)}
                >
                  Next
                </button>
              </li>
            </ul>
          </div>
          <CredoModal
            isOpen={isModalOpen}
            styles={{
              content: {
                width: '40%',
                height: '40%',
              },
            }}
            closeAction={() => this.toggleModal('')}
            RenderingComponent={modalType}
            loanId={loanId}
            comment={comment}
            refreshPage={this.refreshPage}
          />
        </div>
      </>
    );
  }
}

const mapStateToProps = ({ invitationReducer }) => ({
  buyerInvitation: invitationReducer?.buyerInvitation,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      getInvitationList,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(Stagged);
