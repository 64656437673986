const SET_ACTIVE_PARTIES = Symbol('setActiveParties');
const SET_BUYER_SUMMARY = Symbol('setBuyerSummary');
const SET_PAYMENT_INVOICES = Symbol('setPaymentInvoices');
const SET_BUYER_INVOICES = Symbol('setBuyerInvoices');
export {
  SET_ACTIVE_PARTIES,
  SET_BUYER_SUMMARY,
  SET_PAYMENT_INVOICES,
  SET_BUYER_INVOICES,
};
