import React, { Component } from 'react';
import InvoiceButton from '../../../assets/images/invoice-button.png';
import SearchIcon from '../../../assets/images/SearchIcon.svg';
import FailedRuledIcon from '../../../assets/images/failedRuledIcon.svg';
import ReactTable from 'react-table';
import CredoModal from '../../../components/CredoModal';
import UnderReviewModal from './UnderReviewModal';
import Button from '../../../components/Button/index';
import FailedTransactionRulesModal from './FailedTransactionRulesModal';
import CheckBox from '../../../components/CheckBox/CheckBox';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  getInvoiceList,
  getInvoiceSummary,
} from './underReviewInvoices.action';
import {
  API_POST_INVOICE_DETAILS,
  API_GET_RULES_FAILED,
  API_POST_REVIEW_INVOICES,
  API_GET_LOAN_INFO,
} from '../../../utils/APIUrls';
import { GET, POST } from '../../../utils/webAPI.service';
import { loaderModal } from '../../../store/actions';
import { rupeeDecimalFormatter } from '../../../utils/utility';
import {
  STATUS_PARAMS,
  STATUS_PARAMS_VALUE_MAP,
} from '../ALLInvoices/AllInvoices.config';

class UnderReviewInvoices extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showFilter: false,
      showDatePicker: false,
      isModalOpen: false,
      modalType: '',
      invoicesTableData: [],
      selectedInvoices: [],
      invoiceModalDetails: {},
      currentPage: 1,
      endDate: null,
      startDate: null,
      duration: null,
      FailedTransactionRules: {},
      paymentModalData: {},
    };
  }
  getPageData = (type, totalPages) => {
    let { currentPage } = this.state;
    if (type === 'next' && currentPage < totalPages) {
      this.setState({ currentPage: currentPage + 1 }, () => {
        this.getPageChangeInvoiceData();
      });
    } else if (type === 'prev' && currentPage > 1) {
      this.setState({ currentPage: currentPage - 1 }, () => {
        this.getPageChangeInvoiceData();
      });
    }
  };

  handleInputChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value?.trim(),
    });
  };
  searchHandler = async (e) => {
    e.preventDefault();
    let { searchBy, searchValue } = this.state;
    await this.setState({
      currentPage: 1,
    });
    let { actions } = this.props;
    let { getInvoiceList, getInvoiceSummary, loaderModal } = actions;
    let dataToSend = {
      endDate: null,
      numberOfRows: 10,
      pageNumber: 1,
      searchParam: searchValue ? searchBy : null,
      searchValue: searchBy ? searchValue : null,
      startDate: null,
      type: 'under_review',
    };
    if (searchBy === undefined) {
      alert('Please select search type');
      return;
    }
    loaderModal(true);
    await getInvoiceList(dataToSend);
    loaderModal(false);
    this.setState({
      invoicesTableData: this.props?.underReviewInvoicesList?.invoiceDetails,
    });
  };

  toggleModal = (modalType, invoiceId, userId, FailedTransactionModal) => {
    this.setState({
      isModalOpen: !this.state.isModalOpen,
      modalType,
      paymentModalData: {
        invoiceId,
      },
    });
    let dataToSend = {
      invoiceId,
      userId,
    };
    if (FailedTransactionModal === 'transactionRules') {
      GET(API_GET_RULES_FAILED(invoiceId))
        .then(({ data }) => {
          if (data.code === 200) {
            this.setState({
              FailedTransactionRules: data,
            });
          }
        })
        .catch((err) => {
          throw err;
        });
    } else {
      if (invoiceId) {
        POST(API_POST_INVOICE_DETAILS, dataToSend)
          .then(({ data }) => {
            if (data.code === 200) {
              this.setState({
                invoiceModalDetails: data,
              });
            }
          })
          .catch((err) => {
            throw err;
          });
      }
    }
  };

  getPageChangeInvoiceData = async () => {
    let { actions } = this.props;
    let { duration, currentPage, searchBy, searchValue, endDate, startDate } =
      this.state;
    let { getInvoiceList, loaderModal } = actions;
    let dataToSend = {
      duration,
      endDate: endDate?.split('-')?.reverse()?.join('-'),
      numberOfRows: 10,
      pageNumber: currentPage,
      searchParam: searchValue ? searchBy : null,
      searchValue: searchBy ? searchValue : null,
      startDate: startDate?.split('-')?.reverse()?.join('-'),
      type: 'under_review',
    };
    loaderModal(true);
    await getInvoiceList(dataToSend);
    loaderModal(false);
    this.setState({
      invoicesTableData: this.props?.underReviewInvoicesList?.invoiceDetails,
    });
  };

  getFilterData = async (duration) => {
    await this.setState((prevState) => ({
      ...prevState,
      showFilter: !prevState.showFilter,
      duration,
      currentPage: 1,
    }));
    this.invoiceData();
  };

  getDateFilterData = async () => {
    let { actions } = this.props;
    let { endDate, startDate, currentPage } = this.state;
    let { getInvoiceList, getInvoiceSummary } = actions;
    let dataToSend = {
      endDate: endDate?.split('-')?.reverse()?.join('-'),
      numberOfRows: 10,
      pageNumber: currentPage,
      searchParam: null,
      searchValue: null,
      startDate: startDate?.split('-')?.reverse()?.join('-'),
      type: 'under_review',
    };
    loaderModal(true);
    await getInvoiceList(dataToSend);
    await getInvoiceSummary(dataToSend);
    loaderModal(false);
    this.setState((prevState) => ({
      ...prevState,
      showDatePicker: !prevState.showDatePicker,
      showFilter: !prevState.showFilter,
      currentPage: 1,
    }));
  };

  invoiceData = async () => {
    let { actions } = this.props;
    let { duration, currentPage, searchValue, searchBy } = this.state;
    let { getInvoiceList, getInvoiceSummary, loaderModal } = actions;
    let dataToSend = {
      duration: duration || 'till_now',
      endDate: null,
      numberOfRows: 10,
      pageNumber: currentPage,
      searchParam: searchValue ? searchBy : null,
      searchValue: searchBy ? searchValue : null,
      startDate: null,
      type: 'under_review',
    };
    loaderModal(true);
    await getInvoiceList(dataToSend);
    await getInvoiceSummary(dataToSend);
    loaderModal(false);
    this.setState({
      invoicesTableData: this.props?.underReviewInvoicesList?.invoiceDetails,
    });
  };

  componentDidMount = async () => {
    await this.invoiceData();
  };

  reviewInvoicesHandler = (dataToSend) => {
    POST(API_POST_REVIEW_INVOICES, dataToSend)
      .then(({ data }) => {
        if (data.code === 200) {
          this.invoiceData();
          if (data.data) {
            alert('Successfully Reviewed');
          }
        } else {
          alert(data?.message);
        }
        return data;
      })
      .catch((err) => {
        throw err;
      });
  };

  reviewInvoices = async (reviewAction) => {
    const { invoicesTableData } = this.state;
    let checkedInvoices = invoicesTableData
      ?.filter((item) => item?.isChecked === true)
      .map((e) => e?.invoiceId);
    const dataToSend = {
      invoiceIds: checkedInvoices,
      reviewAction,
    };
    await this.reviewInvoicesHandler(dataToSend);
  };

  reviewSingleInvoice = async (e, invoiceId) => {
    const reviewAction = e.target.value;
    const dataToSend = {
      invoiceIds: [invoiceId],
      reviewAction,
    };
    await this.reviewInvoicesHandler(dataToSend);
  };

  getLoanInfo = (userId) => {
    loaderModal(true);
    GET(API_GET_LOAN_INFO(userId))
      .then(({ data }) => {
        if (data?.code === 200) {
          if (data?.data) {
            setTimeout(function () {
              let newWindow = window.open(
                `/pay-app/${data?.data?.loanId}/${userId}/application/overview`
              );
              newWindow.focus();
            }, 350);
            loaderModal(false);
          }
        } else {
          alert(data?.message);
          loaderModal(false);
        }
      })
      .catch((err) => {
        alert('Something went wrong');
        throw err;
      });
    loaderModal(false);
  };

  handleChange = () => {
    const { invoicesTableData } = this.state;
    let isAllSelected = invoicesTableData?.some(
      (invoicesData) => invoicesData?.isChecked !== true
    );
    let checkedValue = false;
    if (isAllSelected) {
      checkedValue = true;
    }
    let invoicesTableDataCopy = [...this.state.invoicesTableData];
    this.setState({
      invoicesTableData: invoicesTableDataCopy?.map((invoicesDataCopy) => ({
        ...invoicesDataCopy,
        isChecked: checkedValue,
      })),
    });
  };

  render() {
    let { underReviewInvoicesList, underReviewInvoicesSummary } = this.props;

    let { summary } = underReviewInvoicesSummary || {};
    let { five_rules_failed, one_rule_failed, two_to_four_rules_failed } =
      summary || {};

    let {
      showFilter,
      showDatePicker,
      invoicesTableData,
      modalType,
      invoiceModalDetails,
      currentPage,
      FailedTransactionRules,
      paymentModalData,
    } = this.state;
    let { numberOfPages } = underReviewInvoicesList || {};
    const isAnySelected = invoicesTableData.some(
      (invoicesData) => invoicesData.isChecked
    );

    const underReviewInvoicesColumn = [
      {
        Header: () => {
          let isAllSelected = invoicesTableData?.some(
            (invoicesData) => invoicesData?.isChecked !== true
          );
          return (
            <CheckBox
              checkboxColor={'#ADADAD'}
              htmlFor={'filter1'}
              inputClass={''}
              labelClass={''}
              checked={!isAllSelected}
              onChange={this.handleChange}
            />
          );
        },

        accessor: 'isChecked',
        width: 60,
        Cell: ({ value, index }) => {
          const onCheckChange = () => {
            let invoicesTableDataCopy = [...invoicesTableData];
            invoicesTableDataCopy[index] = {
              ...invoicesTableDataCopy[index],
              isChecked: !value,
            };
            this.setState({ invoicesTableData: invoicesTableDataCopy });
          };

          return (
            <div>
              {' '}
              <CheckBox
                checkboxColor={'#ADADAD'}
                htmlFor={'filter1'}
                inputClass={''}
                labelClass={''}
                checked={value}
                onChange={onCheckChange}
              />
            </div>
          );
        },
      },
      {
        Header: 'User Id',
        accessor: 'userId',
        width: 100,
        Cell: (props) => {
          let { original } = props;
          let { userId } = original;
          return (
            <>
              {
                <div
                  className='invoice-pointer'
                  onClick={() => this.getLoanInfo(userId)}
                >
                  {userId || 'NA'}
                </div>
              }
            </>
          );
        },
      },
      {
        Header: () => (
          <>
            <div className='ml-5'>Invoice Id </div>
          </>
        ),
        accessor: 'invoiceid',
        width: 170,
        Cell: (props) => {
          let { original } = props || {};
          let { disbursalStatus } = original;
          return (
            <>
              <div className='right-aligned pr-5'>{original?.invoiceId}</div>
              {disbursalStatus === STATUS_PARAMS.IN_REVIEW ? (
                <span className='capsule ml-4 capsule_orange'>
                  {STATUS_PARAMS_VALUE_MAP.IN_REVIEW}
                </span>
              ) : (
                <span className='capsule ml-4 capsule_blue'>
                  {disbursalStatus}
                </span>
              )}
            </>
          );
        },
      },
      {
        Header: () => (
          <>
            <div>Invoice </div>
            <div>Number</div>
          </>
        ),
        accessor: 'invoiceNumber',
      },
      {
        Header: () => (
          <>
            <div>Transaction</div>
            <div>Date</div>
          </>
        ),
        accessor: 'transactionDate',
        Cell: (props) => {
          let { original } = props;
          let { transactionDate } = original;
          return (
            <>
              <div>{transactionDate || 'NA'}</div>
            </>
          );
        },
      },
      {
        Header: () => (
          <>
            <div>Invoice </div>
            <div>Amount</div>
          </>
        ),
        width: 170,
        accessor: 'invoiceAmount',
        Cell: (props) => {
          let { original } = props;
          let { invoiceAmount } = original;
          return (
            <>
              <div className=''>
                {rupeeDecimalFormatter(invoiceAmount, '₹')}
              </div>
            </>
          );
        },
      },
      {
        Header: () => (
          <>
            <div>No of </div>
            <div>Rules Failed</div>
          </>
        ),
        accessor: 'numberOfTransactionRulesFailed',
        Cell: (props) => {
          let { original } = props || {};
          let { numberOfTransactionRulesFailed, invoiceId, userId } =
            original || {};
          return (
            <>
              <div className='pr-5'>
                {numberOfTransactionRulesFailed ? (
                  <>
                    {numberOfTransactionRulesFailed}

                    <span>
                      <img
                        style={{
                          height: '3rem',
                          marginLeft: '2rem',
                        }}
                        onClick={() =>
                          this.toggleModal(
                            FailedTransactionRulesModal,
                            invoiceId,
                            userId,
                            'transactionRules'
                          )
                        }
                        src={FailedRuledIcon}
                        alt='icon'
                      />
                    </span>
                  </>
                ) : (
                  ''
                )}
              </div>
            </>
          );
        },
      },
      {
        Header: 'Details',
        accessor: 'details',
        Cell: (props) => {
          let { original } = props;
          let { invoiceId, userId } = original;

          return (
            <button
              className='details_button'
              onClick={() =>
                this.toggleModal(UnderReviewModal, invoiceId, userId)
              }
            >
              Details
            </button>
          );
        },
      },
      {
        Header: 'Action',
        Cell: ({ original }) => {
          return (
            <select
              className='dropdown'
              name='searchBy'
              onChange={(e) => this.reviewSingleInvoice(e, original?.invoiceId)}
            >
              <option disabled selected>
                Select
              </option>
              <option value='rejection'>Reject</option>
              <option value='approval'>Approve</option>
              <option value='on_hold'>On-Hold</option>
            </select>
          );
        },
      },
    ];

    return (
      <>
        <div className='container invoices-wrapper'>
          <div className='card'>
            <div className='invoice-header'>
              <h3 className='card_heading'>INVOICE SUMMARY</h3>
              <div>
                <img
                  onClick={() =>
                    this.setState((prevState) => ({
                      ...prevState,
                      showFilter: !prevState.showFilter,
                    }))
                  }
                  alt='filter'
                  className='invoice-btn mt-5'
                  src={InvoiceButton}
                ></img>
              </div>
            </div>

            <div className='row'>
              <div className='col-md-8'>
                <div className='row'>
                  <div className='col-md-4 text-grey'>
                    Failed Rules(1)
                    <div className='text-bold'>{one_rule_failed?.count}</div>
                  </div>
                  <div className='col-md-4 text-grey'>
                    Failed Rules(2-4)
                    <div className='text-bold'>
                      {two_to_four_rules_failed?.count}
                    </div>
                  </div>
                  <div className='col-md-4 text-grey'>
                    Failed Rules(5)
                    <div className='text-bold'>{five_rules_failed?.count}</div>
                  </div>
                  <div className='col-md-4 text-grey'>
                    Total Amount
                    <div className='text-bold'>
                      {rupeeDecimalFormatter(one_rule_failed?.amount, '₹')}
                    </div>
                  </div>
                  <div className='col-md-4 text-grey'>
                    Total Amount
                    <div className='text-bold'>
                      {rupeeDecimalFormatter(
                        two_to_four_rules_failed?.amount,
                        '₹'
                      )}
                    </div>
                  </div>
                  <div className='col-md-4 text-grey'>
                    Total Amount
                    <div className='text-bold'>
                      {rupeeDecimalFormatter(five_rules_failed?.amount, '₹')}
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-md-4'>
                {showFilter ? (
                  <div className='card card-filter'>
                    <ul className='list-group list-group-flush'>
                      <li
                        className='list-group-item hover'
                        onClick={() => this.getFilterData('today')}
                      >
                        24 hours
                      </li>
                      <li
                        className='list-group-item hover'
                        onClick={() => this.getFilterData('one_week')}
                      >
                        Past one week
                      </li>
                      <li
                        className='list-group-item hover'
                        onClick={() => this.getFilterData('one_month')}
                      >
                        Past one month
                      </li>
                      <li
                        className='list-group-item hover'
                        onClick={() =>
                          this.setState((prevState) => ({
                            ...prevState,
                            showDatePicker: !prevState.showDatePicker,
                          }))
                        }
                      >
                        Choose date
                      </li>
                    </ul>
                  </div>
                ) : null}
                {showDatePicker && showFilter ? (
                  <div className='card date-picker'>
                    <div className='row'>
                      <div className='col mt-5'>
                        Start Date
                        <input
                          className='date'
                          type='date'
                          onChange={(e) =>
                            this.setState({
                              startDate: e.target.value,
                            })
                          }
                        ></input>
                      </div>
                      <div className='col mt-5'>
                        End Date
                        <input
                          className='date'
                          type='date'
                          onChange={(e) =>
                            this.setState({
                              endDate: e.target.value,
                            })
                          }
                        ></input>
                      </div>
                    </div>
                    <div className='row mt-3 ml-2'>
                      <Button onClick={() => this.getDateFilterData()}>
                        OK
                      </Button>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>

          <div className='card'>
            <div className='row'>
              <div className='col-5 mt-5'>
                <form onSubmit={(e) => this.searchHandler(e)}>
                  <div className='search-form'>
                    <select
                      className='select '
                      onChange={this.handleInputChange}
                      name='searchBy'
                      required
                    >
                      <option disabled selected>
                        Search By
                      </option>
                      <option value={'userId'}>User Id</option>
                      <option value={'mobileNumber'}>
                        Registered Mobile Number
                      </option>
                      <option value={'invoiceNumber'}>Invoice No</option>
                      <option value={'uniqueInvoiceId'}>Invoice Id</option>
                    </select>
                    <input
                      className='select input'
                      required={true}
                      placeholder='Search'
                      name='searchValue'
                      onChange={this.handleInputChange}
                    />
                    <button type='submit' className='submit'>
                      <img src={SearchIcon} alt='icon' />
                    </button>
                  </div>
                </form>
              </div>
              <div className='col mt-5'>
                {isAnySelected && (
                  <div className='row justify-content-end'>
                    <h5
                      className='col-3 btn btn-text button add_button button_invoices '
                      onClick={() => this.reviewInvoices('approval')}
                    >
                      <span>Approve </span>
                    </h5>
                    <h5
                      className='col-3 btn-text button add_button button_invoices btn-red'
                      onClick={() => this.reviewInvoices('rejection')}
                    >
                      <span>Reject </span>
                    </h5>
                    <h5
                      className='col-3 btn-text button add_button button_invoices btn-grey'
                      onClick={() => this.reviewInvoices('on_hold')}
                    >
                      <span>Put on hold </span>
                    </h5>
                  </div>
                )}
              </div>
            </div>

            <div className='row'>
              <h3 className='col-md-10 card_heading_secondary'>Under Review</h3>
            </div>
            <ReactTable
              data={invoicesTableData}
              columns={underReviewInvoicesColumn}
              defaultPageSize={10}
              showPagination={false}
              sortable={false}
            />
            <ul className={'datatable-pagination'}>
              <li>
                <button
                  disabled={!(currentPage > 1)}
                  className={'prev-btn'}
                  type={'button'}
                  onClick={() => this.getPageData('prev', numberOfPages)}
                >
                  Previous
                </button>
              </li>
              <li>
                <span>
                  Page No. {numberOfPages === 0 ? 0 : currentPage} of{' '}
                  {numberOfPages}
                </span>
              </li>

              <li>
                <button
                  disabled={!(numberOfPages > currentPage)}
                  className={'next-btn'}
                  type={'button'}
                  onClick={() => this.getPageData('next', numberOfPages)}
                >
                  Next
                </button>
              </li>
            </ul>
          </div>
          <CredoModal
            isOpen={this.state.isModalOpen}
            styles={{
              content: {
                width:
                  modalType === FailedTransactionRulesModal ? '40vw' : '60vw',
                height:
                  modalType === FailedTransactionRulesModal ? '15vw' : 'auto',
              },
            }}
            closeAction={() => this.toggleModal('')}
            invoiceModalDetails={invoiceModalDetails}
            FailedTransactionRules={FailedTransactionRules}
            RenderingComponent={modalType}
            paymentModalData={paymentModalData}
            toggleModal={this.toggleModal}
          />
        </div>
      </>
    );
  }
}

const mapStateToProps = ({ UnderReviewInvoicesReducer }) => ({
  underReviewInvoicesList: UnderReviewInvoicesReducer.underReviewInvoicesList,
  underReviewInvoicesSummary:
    UnderReviewInvoicesReducer.underReviewInvoicesSummary,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      getInvoiceList,
      getInvoiceSummary,
      loaderModal,
    },
    dispatch
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UnderReviewInvoices);
