import * as creditScoreConstants from './CreditScoreCard.actions.constants';
import {
  API_GET_CREDIT_INPUT_DATA,
  API_GET_CREDIT_RULES_DATA,
  API_GET_CREDIT_BENCHMARK_DATA
} from '../../../../utils/APIUrls';
import { GET } from '../../../../utils/webAPI.service';

const getCreditInputData = userId => {
  return dispatch => {
    return GET(API_GET_CREDIT_INPUT_DATA(userId))
      .then(({ data }) => {
        if (data.code === 200) {
          if (data.data) {
            dispatch({
              type: creditScoreConstants.SET_INPUT_DATA,
              data: data.data
            });
          }
        } else {
          // alert(data?.message);
        }
      })
      .catch(err => {
        alert('Something Went Wrong');
        throw err;
      });
  };
};

const getCreditBenchMarkData = () => {
  return dispatch => {
    return GET(API_GET_CREDIT_BENCHMARK_DATA)
      .then(({ data }) => {
        if (data.code === 200) {
          if (data.data) {
            dispatch({
              type: creditScoreConstants.SET_BENCHMARK_DATA,
              data: data.data
            });
          }
        } else {
          // alert(data?.message);
        }
      })
      .catch(err => {
        alert('Something Went Wrong');
        throw err;
      });
  };
};

const getCreditRulesData = userId => {
  return dispatch => {
    return GET(API_GET_CREDIT_RULES_DATA(userId))
      .then(({ data }) => {
        if (data.code === 200) {
          if (data.data) {
            dispatch({
              type: creditScoreConstants.SET_RULES_DATA,
              data: data.data
            });
          }
        } else {
          // alert(data?.message);
        }
      })
      .catch(err => {
        alert('Something Went Wrong');
        throw err;
      });
  };
};

export { getCreditInputData, getCreditBenchMarkData, getCreditRulesData };
