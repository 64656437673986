import React, { Component } from 'react';
import { API_POST_ADD_PAYMENTS } from '../../../utils/APIUrls';
import { POST } from '../../../utils/webAPI.service';
import DatePicker from '../../../components/DatePicker';
import { getDMY } from '../../../utils/utility';
import UploadFile from '../../../assets/images/uploadFile.png';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getRepayments } from './payments.actions';

class PostPaymentsModal extends Component {
  state = {
    chequeNumber: null,
    fileContent: null,
    isShow: false,
    uploadFileName: '',
    isButtonDisabled: false,
  };

  getFiles = async (e) => {
    const file = e.target.files[0],
      fileName = file.name,
      base64 = await this.convertBase64(file),
      base64Img = base64.split(',')[1];
    this.setState({ fileContent: base64Img, uploadFileName: fileName });
  };

  convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  dateHandler = (e) => {
    if (e !== null) {
      let paymentDate = getDMY(e);
      this.setState({ paymentDate });
    }
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
      isShow: true,
      referenceNumber: null,
      chequeNumber: null,
      fileContent: null,
      uploadFileName: '',
    });
  };

  addPaymentHandler = (e, mode) => {
    e.preventDefault();
    this.setState({
      isButtonDisabled: true,
    });

    let {
        amount,
        comments,
        modeOfPayment,
        paymentDate,
        referenceNumber,
        chequeNumber,
        fileContent,
      } = this.state,
      { propsData } = this.props,
      fileContentCopy = mode === 'cheque' ? fileContent : null,
      referenceNumberCopy = mode === 'cheque' ? null : referenceNumber,
      chequeNumberCopy = mode === 'cheque' ? chequeNumber : null;

    let dataToSend = {
      userId: propsData.match.params.userId,
      amount,
      comments,
      modeOfPayment,
      paymentDate,
      referenceNumber: referenceNumberCopy,
      chequeNumber: chequeNumberCopy,
      fileContent: fileContentCopy,
      status: null,
    };

    POST(API_POST_ADD_PAYMENTS, dataToSend)
      .then(({ data }) => {
        this.setState({
          isButtonDisabled: false,
        });
        if (data.code === 200) {
          let { toggleModal, actions, propsData } = this.props;
          alert('Payment Done successfully');
          actions.getRepayments(propsData.match.params.userId, 1);
          toggleModal(PostPaymentsModal);
        } else {
          alert(data?.message);
        }
      })
      .catch((err) => {
        alert('something went wrong');
        throw err;
      });
  };

  render() {
    let paymentModeList = [
        { mode: 'cheque' },
        { mode: 'neft' },
        { mode: 'nach' },
        { mode: 'cash' },
        { mode: 'other' },
        { mode: 'rtgs' },
      ],
      { paymentDate, modeOfPayment, isShow, uploadFileName } = this.state,
      { toggleModal } = this.props;

    return (
      <div className='compare-details-wrapper modal-wrapper'>
        <h3 className='card_heading'>Post Payment</h3>
        <form onSubmit={(e) => this.addPaymentHandler(e, modeOfPayment)}>
          <div className='row'>
            <div className='col-md-6'>
              <div className='input_heading'>
                Re-Payment Amount
                <input
                  placeholder=' Enter the  Payment Amount'
                  value={this.state.amount}
                  required
                  type='number'
                  onChange={(e) => this.setState({ amount: e.target.value })}
                />
              </div>
            </div>
            <div className='col-md-6'>
              <div className='input_heading'>
                Re-Payment Date
                <DatePicker
                  placeholderText={'Enter the Payment Date'}
                  required='true'
                  defaultValue={null}
                  name={'paymentDate'}
                  maxDate={new Date()}
                  selected={
                    paymentDate
                      ? new Date(paymentDate.split('/').reverse())
                      : null
                  }
                  onChange={(e) => this.dateHandler(e)}
                />
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-md-6'>
              <div className='input_heading'>
                Payment Mode
                <select
                  onChange={this.handleChange}
                  name='modeOfPayment'
                  required
                  value={this.state.modeOfPayment}
                >
                  <option value=''>Choose Payment Mode</option>
                  {paymentModeList?.map((e, index) => {
                    return (
                      <option key={`mode-${index}`} value={e.mode}>
                        {' '}
                        {e.mode}{' '}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
          </div>

          <div className='row'>
            <div className='col-md-6'>
              {modeOfPayment === 'rtgs' ||
              modeOfPayment === 'other' ||
              modeOfPayment === 'neft' ? (
                <div className='input_heading'>
                  Reference Number
                  <input
                    placeholder=' Enter Reference Number'
                    value={this.state.referenceNumber}
                    required
                    onChange={(e) =>
                      this.setState({ referenceNumber: e.target.value })
                    }
                  />
                </div>
              ) : null}
              {modeOfPayment === 'nach' ? (
                <div className='input_heading'>
                  URN
                  <input
                    placeholder=' Enter URN'
                    value={this.state.referenceNumber}
                    required
                    onChange={(e) =>
                      this.setState({ referenceNumber: e.target.value })
                    }
                  />
                </div>
              ) : null}
              {modeOfPayment === 'cash' ? (
                <div className='input_heading'>
                  Deposit / Transfer Reference
                  <input
                    placeholder=' Enter Deposit / Transfer Reference'
                    value={this.state.referenceNumber}
                    required
                    onChange={(e) =>
                      this.setState({ referenceNumber: e.target.value })
                    }
                  />
                </div>
              ) : null}
              {modeOfPayment === 'cheque' ? (
                <div className='input_heading'>
                  Cheque Number
                  <input
                    placeholder=' Enter Cheque Number'
                    value={this.state.chequeNumber}
                    required
                    onChange={(e) =>
                      this.setState({ chequeNumber: e.target.value })
                    }
                  />
                </div>
              ) : null}
            </div>
            <div className='col-md-6'>
              {modeOfPayment === 'cheque' ? (
                <div className='input_heading'>
                  Image Of Cheque
                  <label className='file_input'>
                    {uploadFileName === '' ? (
                      <>
                        <img
                          src={UploadFile}
                          alt='Block Icon'
                          className='icon_img'
                        />
                        <span>Click here to upload Image </span>
                      </>
                    ) : (
                      <div className='upload_file'>
                        <img
                          src={UploadFile}
                          alt='Block Icon'
                          className='icon_img'
                        />
                        <span>{uploadFileName}</span>
                      </div>
                    )}
                    <input type='file' onChange={(e) => this.getFiles(e)} />
                  </label>
                </div>
              ) : null}
            </div>
          </div>
          {isShow === true ? (
            <div className='row'>
              <div className='col-md-12'>
                <div className='input_heading'>
                  Comment
                  <div
                    className='comments_text-area'
                    data-placeholder='Enter text here'
                    cols='25'
                    rows='4'
                    id='comment'
                    tabIndex='0'
                    name='comment'
                    contentEditable
                    placeholder='Enter your comment'
                    onInput={(e) =>
                      this.setState({
                        comments: e.currentTarget.textContent,
                      })
                    }
                  />
                </div>
              </div>
            </div>
          ) : null}

          <div className='save_button'>
            <button
              className={'button-failure basic-button'}
              onClick={() => toggleModal(PostPaymentsModal)}
            >
              Cancel
            </button>
            <button
              className={'button-success basic-button'}
              type='submit'
              disabled={this.state.isButtonDisabled}
            >
              Save
            </button>
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = ({}) => ({});
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      getRepayments,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(PostPaymentsModal);
