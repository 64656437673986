import React, { Component } from 'react';
import { getCompareKycDetails } from './application.actions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
export class CompareNewModal extends Component {
  componentDidMount = async () => {
    let { actions, propsData } = this.props || {},
      { getCompareKycDetails } = actions || {},
      { match } = propsData || {},
      { params } = match || {};
    await getCompareKycDetails(params?.loanId);
  };
  render() {
    let { compareKycDetails = [] } = this.props || [];
    return (
      <div className='entity-kyc-wrapper'>
        <h3 className='card_heading'> Compare Details</h3>
        <table className='m-5 width'>
          <thead>
            <th width='150'></th>
            <th width='200'>Name</th>
            <th width='200'>Addresses</th>
            <th width='100'>DOB</th>
            <th width='200'>Mobile Number</th>
          </thead>
          <tbody>
            {compareKycDetails?.map((ele, index) => {
              return (
                <tr key={index}>
                  <td> {ele?.docType}</td>
                  <td>{ele?.fullName}</td>
                  <td>{ele?.address}</td>
                  <td>{ele?.dob}</td>
                  <td>{ele?.mobileNumber}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }
}

const mapStateToProps = ({ ApplicationReducer }) => ({
  compareKycDetails: ApplicationReducer.compareKycDetails,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      getCompareKycDetails,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(CompareNewModal);
