import React, { Component } from 'react';
import Excel from '../../../assets/images/ExcelNewIcon.png';
import { API_POST_UPLOAD_BANK_PDF } from '../../../utils/APIUrls';
import { POST } from '../../../utils/webAPI.service';

export default class InvoiceBankFeedbackModal extends Component {
  state = {
    format: null,
  };

  uploadFiles = async (e) => {
    const files = e.target.files;
    const token = localStorage.getItem('token');
    const headers = {
      'X-CLIENT-NAME': 'PDU',
      'X-USER-ID': localStorage.getItem('userId'),
      Authorization: `Bearer ${token}`,
      'Content-Type': 'multipart/form-data',
    };
    const formData = new FormData();
    for (let file in files) {
      if (files.hasOwnProperty(file)) {
        formData.append('file', files[file]);
      } else {
        break;
      }
    }
    if (this.state.format === null) {
      alert('Please select file format');
      return;
    }
    await POST(
      `${API_POST_UPLOAD_BANK_PDF}?format=${this.state.format}`,
      formData,
      {
        headers,
      }
    )
      .then(({ data }) => {
        if (data.code === 200) {
          alert('SuccessFully Uploaded');
          this.props?.closeAction();
        } else {
          alert(data?.message);
        }
      })
      .catch((err) => {
        throw err;
      });
  };

  render() {
    return (
      <div className=' invoices-wrapper'>
        <h3 className='card_heading m-0'>Upload Bank Feedback</h3>
        <div className='row mt-5 mb-5 justify-content-center'>
          <div className='col-8'>
            <select
              value={this.state.format}
              onChange={(e) =>
                this.setState({
                  format: e.target.value,
                })
              }
            >
              <option value=''>Select File Format</option>
              <option value='SBI'>SBI BANK</option>
              <option value='HDFC'>HDFC BANK</option>
              <option value='ICICI'> ICICI BANK</option>
            </select>
          </div>
        </div>
        <div className='row mt-5 justify-content-center'>
          <h5 className='col-7 button add_button button_invoices btn-green '>
            <img src={Excel} alt='icon' />
            <span>Upload File</span>
            <input
              type={'file'}
              className='upload-file'
              multiple
              onChange={(e) => this.uploadFiles(e)}
              accept='.xlsx ,.xlsm,.xls,.xlt,.xltm,.xml'
            />
          </h5>
        </div>
      </div>
    );
  }
}
