import React, { Component } from 'react';
import ReactTable from 'react-table';
import { API_GET_SUPPLIER_PAYMENT_DETAILS } from '../../../utils/APIUrls';
import { GET } from '../../../utils/webAPI.service';
import preview from '../../../assets/images/eye.png';
import CredoModal from '../../../components/CredoModal';
import BankModal from './BankModal';
import UpiModal from './UpiModal';
export default class SupplierDetailsModal extends Component {
  state = {
    activeFigure: 'upi',
    bankList: [],
    upiList: [],
    isModalOpen: false,
    modalType: null
  };
  componentDidMount = async () => {
    let { stateData } = this.props,
      { rowData } = stateData;
    GET(API_GET_SUPPLIER_PAYMENT_DETAILS(rowData?.uniqueId))
      .then(({ data }) => {
        if (data.code === 200) {
          this.setState({
            bankList: data?.data?.bankList,
            upiList: data?.data?.upiList
          });
        } else {
          alert(data?.message);
        }
      })
      .catch(err => {
        throw err;
      });
  };
  paymentHandler = type => {
    this.setState({ activeFigure: type });
  };
  toggleModal = (name, original) => {
    this.setState(prevState => ({
      isModalOpen: !prevState.isModalOpen,
      modalType: name,
      original
    }));
  };
  render() {
    const bankColumns = [
      {
        Header: 'Date',
        accessor: 'date'
      },
      {
        Header: 'Account No.',
        accessor: 'accountNumber'
      },
      {
        Header: 'IFSC Code',
        accessor: 'ifscCode'
      },

      {
        Header: 'Bank Name',
        accessor: 'bankName'
      },
      {
        Header: 'Branch Name',
        accessor: 'branchName'
      },
      {
        Header: 'Verified',
        accessor: 'verified',
        Cell: ({ original }) => {
          let { verified } = original;
          return (
            <>
              {verified === 'Yes' ? (
                <span className='capsule capsule-green'>Yes</span>
              ) : (
                <span className='capsule capsule-red'>No</span>
              )}
            </>
          );
        }
      },
      {
        Header: () => (
          <>
            <div className='pr-5'>
              <div>Penny Drop</div>
              <div>Status</div>
            </div>
          </>
        ),
        accessor: 'pennyValidation',
        width: 150,
        Cell: ({ original }) => {
          let { pennyValidation } = original;
          return (
            <>
              {pennyValidation === true ? (
                <span className='capsule capsule-green'>Verified</span>
              ) : pennyValidation === false ? (
                <span className='capsule capsule-red'>Failed</span>
              ) : (
                <span className='capsule capsule-blue'>Pending</span>
              )}
            </>
          );
        }
      },
      {
        Header: '',
        Cell: ({ original }) => {
          return (
            <>
              <img
                alt='preview'
                src={preview}
                onClick={() => {
                  this.toggleModal(BankModal, original);
                }}
              />
            </>
          );
        }
      }
    ];

    const upiColumns = [
      {
        Header: 'Date',
        accessor: 'date'
      },
      {
        Header: 'UPI ID',
        accessor: 'upiId'
      },
      {
        Header: 'Verified',
        accessor: 'verified',
        Cell: ({ original }) => {
          let { verified } = original;
          return (
            <>
              {verified === 'Yes' ? (
                <span className='capsule capsule-green'>Yes</span>
              ) : (
                <span className='capsule capsule-red'>No</span>
              )}
            </>
          );
        }
      },
      {
        Header: 'Status',
        width: 150,
        accessor: 'upiValidation',
        Cell: ({ original }) => {
          let { upiValidation } = original;
          return (
            <>
              {upiValidation === true ? (
                <span className='capsule capsule-green'>Verified</span>
              ) : upiValidation === false ? (
                <span className='capsule capsule-red'>Failed</span>
              ) : (
                <span className='capsule capsule-blue'>Pending</span>
              )}
            </>
          );
        }
      },
      {
        Header: '',
        Cell: ({ original }) => {
          return (
            <>
              <img
                alt='preview'
                src={preview}
                onClick={() => {
                  this.toggleModal(UpiModal, original);
                }}
              />
            </>
          );
        }
      }
    ];
    let {
        activeFigure,
        bankList,
        upiList,
        isModalOpen,
        modalType
      } = this.state,
      { stateData } = this.props,
      { rowData } = stateData;

    return (
      <div className='modal-wrapper manage_user-wrapper'>
        <h3 className='card_heading'>Payment Details </h3>
        <div className='row'>
          <div className='col-md-3 text-grey'>
            Supplier Name{' '}
            <div className='text-bold'>{rowData?.name || '-'}</div>
          </div>
          <div className='col-md-3 text-grey'>
            Supplier ID{' '}
            <div className='text-bold'>{rowData?.uniqueId || '-'}</div>
          </div>
        </div>
        <div className='row  generic_button_box'>
          <div
            onClick={() => this.paymentHandler('upi')}
            className={
              activeFigure === 'upi'
                ? 'col-md-1 generic_button_active'
                : 'col-md-1 generic_button'
            }
          >
            UPI
          </div>
          <div
            onClick={() => this.paymentHandler('bank')}
            className={
              activeFigure === 'bank'
                ? 'col-md-1 generic_button_active'
                : 'col-md-1 generic_button'
            }
          >
            Bank
          </div>
        </div>
        <h3 className='card_heading mt-4'>Payments</h3>
        {activeFigure === 'upi' ? (
          <ReactTable
            columns={upiColumns}
            data={upiList || []}
            defaultPageSize={10}
            showPagination={false}
          />
        ) : (
          <ReactTable
            columns={bankColumns}
            data={bankList || []}
            defaultPageSize={10}
            showPagination={false}
          />
        )}
        <CredoModal
          isOpen={isModalOpen}
          styles={{
            content: {
              width: '50rem',
              height: '35rem'
            }
          }}
          closeAction={() => this.toggleModal('')}
          RenderingComponent={modalType}
          stateData={this.state}
          propsData={this.props}
        />
        ;
      </div>
    );
  }
}
