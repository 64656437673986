import * as offerConstants from './offer.actions.constants';
import {
  API_GET_ACTIVE_LOAN,
  API_POST_SUB_PROGRAM,
  API_GET_ALL_OFFERS,
  API_GET_OFFER_HISTORY,
  API_GET_OFFER_REGERATE_CHECK,
} from '../../../utils/APIUrls';
import { GET, POST } from '../../../utils/webAPI.service';

const getActiveLoan = (loanId) => {
  return (dispatch) => {
    return GET(API_GET_ACTIVE_LOAN(loanId))
      .then(({ data }) => {
        if (data.code === 200) {
          if (data.data) {
            dispatch({
              type: offerConstants.SET_ACTIVE_LOAN,
              data: data.data,
            });
          }
        }
        GET(API_GET_OFFER_HISTORY(loanId))
          .then(({ data }) => {
            if (data.code === 200) {
              if (data.data) {
                dispatch({
                  type: offerConstants.SET_OFFER_HISTORY,
                  data: data.data?.offers,
                });
              }
            }
          })
          .catch((err) => {
            throw err;
          });

        GET(API_GET_ALL_OFFERS(loanId))
          .then(({ data }) => {
            if (data.code === 200) {
              if (data.data) {
                dispatch({
                  type: offerConstants.SET_ALL_OFFERS,
                  data: data.data?.offers,
                });
              }
            }
          })
          .catch((err) => {
            throw err;
          });

        return data;
      })
      .then((data) => {
        if (data.code === 200) {
          let { programName, subProgramName, supplierId, loanId, status } =
              data?.data,
            dataToSend = {
              programName,
              subProgramName,
              supplierId,
            },
            applicationStatus = status?.toLowerCase();
          if (applicationStatus === 'pending') {
            POST(API_POST_SUB_PROGRAM, dataToSend)
              .then(({ data }) => {
                if (data.code === 200) {
                  if (data.data) {
                    dispatch({
                      type: offerConstants.SET_LOAN_LIMITS,
                      data: data.data,
                    });
                  }
                } else if (data.code === 401) {
                  alert(data?.message);
                }
              })
              .catch((err) => {
                throw err;
              });
          }
          if (applicationStatus === 'accepted') {
            GET(API_GET_OFFER_REGERATE_CHECK(loanId))
              .then(({ data }) => {
                if (data.code === 200) {
                  if (data.data) {
                    dispatch({
                      type: offerConstants.SET_REGENERATE_CHECK,
                      data: data.data,
                    });
                  }
                } else if (data.code === 401) {
                  alert(data?.message);
                }
              })
              .catch((err) => {
                throw err;
              });
          }
        }
      })
      .catch((err) => {
        throw err;
      });
  };
};

const getActiveLoanOffer = (loanId) => {
  return (dispatch) => {
    return GET(API_GET_ACTIVE_LOAN(loanId))
      .then(({ data }) => {
        if (data.code === 200) {
          if (data.data) {
            dispatch({
              type: offerConstants.SET_ACTIVE_LOAN,
              data: data.data,
            });
          }
        } else if (data.code === 999) {
          dispatch({
            type: offerConstants.SET_ACTIVE_LOAN,
            data: {},
          });
        }
        return data;
      })
      .catch((err) => {
        throw err;
      });
  };
};
export { getActiveLoan, getActiveLoanOffer };
