import { GET, POST } from '../../../utils/webAPI.service';
import {
  API_GET_INVOICE_LIST,
  API_GET_INVOICE_SUMMARY_LIST,
  API_GET_EINVOICE_FILE,
} from '../../../utils/APIUrls';
import * as allInvoicesConstant from './allInvoices.action.constant';

const getInvoiceList = (data) => {
  return (dispatch) => {
    return POST(API_GET_INVOICE_LIST, data)
      .then(({ data }) => {
        if (data.code === 200) {
          if (data.data) {
            dispatch({
              type: allInvoicesConstant.SET_INVOICES_LIST,
              data: data?.data,
            });
          }
        } else {
          alert(data?.message);
        }
        return data;
      })
      .catch((err) => {
        throw err;
      });
  };
};

const getInvoiceSummary = (data) => {
  return (dispatch) => {
    return POST(API_GET_INVOICE_SUMMARY_LIST, data)
      .then(({ data }) => {
        if (data.code === 200) {
          if (data.data) {
            dispatch({
              type: allInvoicesConstant.SET_INVOICES_SUMMARY,
              data: data?.data,
            });
          }
        } else {
          alert(data?.message);
        }
        return data;
      })
      .catch((err) => {
        throw err;
      });
  };
};

const getEInvoiceData = (data) => {
  return (dispatch) => {
    return GET(API_GET_EINVOICE_FILE(data))
      .then(({ data }) => {
        if (data.code === 200) {
          if (data.data) {
            dispatch({
              type: allInvoicesConstant.SET_EINVOICE_FILE,
              data: data?.data,
            });
          }
        } else {
          dispatch({
            type: allInvoicesConstant.SET_EINVOICE_FILE,
            data: null,
          });
        }
      })
      .catch((err) => {
        throw err;
      });
  };
};

export { getInvoiceList, getInvoiceSummary, getEInvoiceData };
