import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { API_UPDATE_SANCTIONED_LIMIT } from '../../../../utils/APIUrls';
import { POST } from '../../../../utils/webAPI.service';
import { getBankDetails, getBankingAnalysis } from './banking.action';
import { loaderModal } from '../../../../store/actions';
class LimitUtilizationEditForm extends Component {
  state = {
    sanctionLimit: [],
  };
  limitChangeHandler = (event, index) => {
    let value = event.target.value;
    this.setState((prevState) => ({
      ...prevState,
      sanctionLimit: prevState.sanctionLimit.map((el, mapIndex) =>
        mapIndex >= index
          ? { ...prevState.sanctionLimit[index], limit: parseInt(value || 0) }
          : el
      ),
    }));
  };
  submitLimitUtilization = async () => {
    let { bankingCardDetail, actions, applicationNumber } = this.props,
      { accountNumber } = bankingCardDetail,
      { getBankDetails, getBankingAnalysis } = actions,
      { sanctionLimit } = this.state;
    if (accountNumber && applicationNumber) {
      let data = {
        accountNumber,
        applicationNumber,
        sanctionedLimits: sanctionLimit,
      };
      await POST(API_UPDATE_SANCTIONED_LIMIT, data)
        .then(({ data }) => {
          let { status, message } = data;
          if (status === 'success') {
            this.props.closeAction();
            getBankDetails(applicationNumber);
            getBankingAnalysis(applicationNumber);
          } else {
            this.setState({ error: message });
          }
        })
        .catch((err) => {
          this.setState({
            error: 'Something went wrong please try again later.',
          });
          throw err;
        });
    }
  };

  render() {
    let { sanctionLimit } = this.state,
      { isEditable } = this.props;
    return (
      <div className='limit-utilization__modal'>
        <table className='limit-utilization__modal-table'>
          <tbody>
            <tr className='limit-utilization__modal-table-row'>
              <td className='table-header'>
                {isEditable ? 'Enter Sanction Limit' : 'EMI Amount'}
              </td>
              {sanctionLimit?.map(({ limit }, index) => (
                <td
                  className='limit-utilization__modal-table-row-data'
                  key={`limit-utilization-month-row${index}`}
                >
                  {isEditable ? (
                    <input
                      type='text'
                      value={limit}
                      onChange={(e) => this.limitChangeHandler(e, index)}
                    />
                  ) : (
                    limit
                  )}
                </td>
              ))}
            </tr>
            <tr className='limit-utilization__modal-table-row'>
              <td className='table-header'>
                {isEditable ? 'Months Available' : 'Date of Debit'}
              </td>
              {sanctionLimit?.map(({ month }, index) => (
                <td
                  className='limit-utilization__modal-table-row-data'
                  key={`limit-utilization-month-row${index}`}
                >
                  {month}
                </td>
              ))}
            </tr>
          </tbody>
        </table>
        {isEditable ? (
          <div className={'btns-box'}>
            <button
              onClick={this.submitLimitUtilization}
              type={'button'}
              className={'save-btn'}
            >
              Submit
            </button>
          </div>
        ) : null}
      </div>
    );
  }
  componentDidMount() {
    this.setState({
      sanctionLimit: this.props?.bankingCardDetail?.sanctionedLimits,
    });
  }
}

const mapStateToProps = ({ bankingReducer }) => ({
  bankDetails: bankingReducer.bankDetails,
});
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      getBankDetails,
      loaderModal,
      getBankingAnalysis,
    },
    dispatch
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LimitUtilizationEditForm);
