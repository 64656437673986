import React from 'react';
import AppCard from '../../../../../components/AppCard';
import { rupeeDecimalFormatter } from '../../../../../utils/utility';

const RulesCard = (props) => {
  const { data } = props;

  const headingRules = (element) => {
    if (element === 'liveLoans') {
      return 'No of Live Loans(other than GL,CD,TW)';
    } else if (element === 'currentBucketAgeing') {
      return 'Current Bucket Agening';
    } else if (element === 'writeOff') {
      return ' Write off';
    } else if (element === 'bureauScore') {
      return 'Bureau Score';
    } else if (element === 'thirtyPlusDPDWithin6Months') {
      return 'DPD in last 6 Months > 30';
    } else if (element === 'sixtyPlusDPDWithin12Months') {
      return 'DPD in last 12 Months > 60';
    } else if (element === 'ninetyPlusDPDWithin18Months') {
      return 'DPD in last 18 Months > 90';
    } else if (element === 'smaOrDoubtFulOrSubstandard') {
      return 'Sma Or DoubtFul Or Substandard';
    } else if (element === 'writeOfOrRestructuringOrSettled') {
      return 'WriteOf or Restructuring or Settled';
    } else if (element === 'creditAmountOverdue') {
      return 'Credit amount overdue < 5000';
    } else if (element === 'unsecuredLoans') {
      return 'Last Unsecured loan taken < 12 month';
    } else if (element === 'settlementAmount') {
      return 'Settlement Amount';
    } else if (element === 'overDueAmount') {
      return 'Overdue Amount';
    } else if (element === 'instancesOfAgeingNinetyPlusDPD') {
      return 'DPD in last 18 Months > 90';
    } else if (element === 'instancesOfAgeingSixtyPlusDPD') {
      return 'DPD in last 12 Months > 60';
    } else if (element === 'instancesOfThirtyPlusDPD') {
      return 'DPD in last 6 Months > 30';
    }
  };

  return (
    <>
      <AppCard className='rules-wrapper' heading='Rules'>
        <div className='rules-wrapper row'>
          {data?.map((ele, i) => {
            return (
              <>
                <div
                  key={i}
                  className={`rules-wrapper-heading ${
                    ele?.result === 'true' ? '' : 'red'
                  }  col-4`}
                >
                  {headingRules(ele?.name)}
                  <div className={`${ele.result === 'true' ? '' : 'sub-red'}`}>
                    {ele?.name === 'settlementAmount' ||
                    ele?.name === 'overDueAmount'
                      ? rupeeDecimalFormatter(ele?.value)
                      : ele?.value}
                  </div>
                </div>
              </>
            );
          })}
        </div>
      </AppCard>
    </>
  );
};
export default RulesCard;
