import * as ACTION_TYPE from './gst.action.constant';
import { GET, POST } from '../../../../utils/webAPI.service';
import {
  API_FETCH_GST_ANNUAL_ANALYSIS,
  API_GET_GST_ANALYSIS,
  API_GST_GET_DETAILS,
  API_GET_GST_MANUAL_TURNOVER,
} from '../../../../utils/APIUrls';
const getGstAnalysis = (appNo) => (dispatch) => {
  return POST(API_GET_GST_ANALYSIS(appNo))
    .then(({ data }) => {
      if (data.data) {
        dispatch({ type: ACTION_TYPE.GET_GST_ANALYSIS, data: data.data });
      }
      return data;
    })
    .catch((err) => {
      throw err;
    });
};

const getGstFetch = (gstNo) => (dispatch) => {
  return GET(API_GST_GET_DETAILS(gstNo))
    .then(({ data }) => {
      if (data.data) {
        dispatch({ type: ACTION_TYPE.FETCH_GST_DETAILS, data: data.data });
      }
      return data;
    })
    .catch((err) => {
      throw err;
    });
};

const getGstManualTurnover = (gstNo) => (dispatch) => {
  return GET(API_GET_GST_MANUAL_TURNOVER(gstNo))
    .then(({ data }) => {
      if (data.data) {
        dispatch({
          type: ACTION_TYPE.FETCH_GST_MANUAL_TURNOVER,
          data: data.data,
        });
      }
      return data;
    })
    .catch((err) => {
      throw err;
    });
};

const getGSTINAnnualAnalysis = (appNo, gstin) => (dispatch) => {
  return GET(API_FETCH_GST_ANNUAL_ANALYSIS(appNo, gstin))
    .then(({ data }) => {
      if (data.data) {
        dispatch({
          type: ACTION_TYPE.FETCH_GST_ANNUAL_ANALYSIS,
          data: data.data,
        });
      }
      return data;
    })
    .catch((err) => {
      throw err;
    });
};
export {
  getGSTINAnnualAnalysis,
  getGstAnalysis,
  getGstFetch,
  getGstManualTurnover,
};
