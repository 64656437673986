import React, { Component } from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import { get } from 'lodash';
import OverView from './Overview/OverView';
import CreditBehaviour from './CreditBehaviour/CreditBehaviour';
import CreditScoreCard from './CreditScoreCard/CreditScoreCard';
import Gst from './Gst/Gst';
import Banking from './Banking/Banking';
import Cam from './Cam/Cam';
import LenderService from './LenderService/LenderService';
import { Route, Switch } from 'react-router-dom';
import LedgerAnalysis from './LedgerAnalysis/LedgerAnalysis';

class Application extends Component {
  clickHandle = (e, name) => {
    let { match, history } = this.props,
      { loanId, userId } = match.params;

    if (e.ctrlKey) {
      if ((e.ctrlKey || e.metaKey) && name === 'overview') {
        window.open(`/pay-app/${loanId}/${userId}/application/overview`);
      } else if ((e.ctrlKey || e.metaKey) && name === 'credit-behaviour') {
        window.open(
          `/pay-app/${loanId}/${userId}/application/credit-behaviour`
        );
      } else if ((e.ctrlKey || e.metaKey) && name === 'cam') {
        window.open(`/pay-app/${loanId}/${userId}/application/cam`);
      } else if ((e.ctrlKey || e.metaKey) && name === 'credit-score-card') {
        window.open(
          `/pay-app/${loanId}/${userId}/application/credit-score-card`
        );
      } else if ((e.ctrlKey || e.metaKey) && name === 'lender-services') {
        window.open(`/pay-app/${loanId}/${userId}/application/lender-services`);
      } else if ((e.ctrlKey || e.metaKey) && name === 'banking') {
        window.open(`/pay-app/${loanId}/${userId}/application/banking`);
      } else if ((e.ctrlKey || e.metaKey) && name === 'gst') {
        window.open(`/pay-app/${loanId}/${userId}/application/gst`);
      } else if ((e.ctrlKey || e.metaKey) && name === 'ledger-analysis') {
        window.open(`/pay-app/${loanId}/${userId}/application/ledger-analysis`);
      }
    } else if (e.button === 0) {
      if (name === 'overview') {
        history.push(`/pay-app/${loanId}/${userId}/application/overview`);
      } else if (name === 'credit-behaviour') {
        history.push(
          `/pay-app/${loanId}/${userId}/application/credit-behaviour`
        );
      } else if (name === 'cam') {
        history.push(`/pay-app/${loanId}/${userId}/application/cam`);
      } else if (name === 'credit-score-card') {
        history.push(
          `/pay-app/${loanId}/${userId}/application/credit-score-card`
        );
      } else if (name === 'lender-services') {
        history.push(
          `/pay-app/${loanId}/${userId}/application/lender-services`
        );
      } else if (name === 'banking') {
        history.push(`/pay-app/${loanId}/${userId}/application/banking`);
      } else if (name === 'gst') {
        history.push(`/pay-app/${loanId}/${userId}/application/gst`);
      } else if (name === 'ledger-analysis') {
        history.push(
          `/pay-app/${loanId}/${userId}/application/ledger-analysis`
        );
      }
    }
  };

  render() {
    const arrPath = get(this, 'props.location.pathname', '').split('/');
    const activeTab = arrPath.length ? arrPath[arrPath.length - 1] : 'overview';
    return (
      <>
        <section className={'leadmanager-navs-wrapper mt-5 container'}>
          <Navbar>
            <Nav className='credit-navs container' activeKey={activeTab}>
              <Nav.Link
                eventKey='overview'
                onClick={(e) => this.clickHandle(e, 'overview')}
              >
                OVERVIEW
              </Nav.Link>
              <Nav.Link
                eventKey='gst'
                onClick={(e) => this.clickHandle(e, 'gst')}
              >
                GST
              </Nav.Link>
              <Nav.Link
                eventKey='banking'
                onClick={(e) => this.clickHandle(e, 'banking')}
              >
                BANKING
              </Nav.Link>
              <Nav.Link
                eventKey='credit-behaviour'
                onClick={(e) => this.clickHandle(e, 'credit-behaviour')}
              >
                CREDIT BEHAVIOUR
              </Nav.Link>
              <Nav.Link
                eventKey='ledger-analysis'
                onClick={(e) => this.clickHandle(e, 'ledger-analysis')}
              >
                LEDGER ANALYSIS
              </Nav.Link>
              <Nav.Link
                eventKey='cam'
                onClick={(e) => this.clickHandle(e, 'cam')}
              >
                CAM
              </Nav.Link>
              <Nav.Link
                eventKey='credit-score-card'
                onClick={(e) => this.clickHandle(e, 'credit-score-card')}
              >
                CREDIT RULES
              </Nav.Link>
              <Nav.Link
                eventKey='lender-services'
                onClick={(e) => this.clickHandle(e, 'lender-services')}
              >
                LENDER SERVICES
              </Nav.Link>
            </Nav>
          </Navbar>
        </section>
        <Switch>
          <Route
            path='/pay-app/:loanId/:userId/application/overview'
            exact
            component={OverView}
          />
          <Route
            path='/pay-app/:loanId/:userId/application/gst'
            exact
            component={Gst}
          />
          <Route
            path='/pay-app/:loanId/:userId/application/banking'
            exact
            component={Banking}
          />
          <Route
            path='/pay-app/:loanId/:userId/application/credit-behaviour'
            exact
            component={CreditBehaviour}
          />
          <Route
            path='/pay-app/:loanId/:userId/application/cam'
            exact
            component={Cam}
          />
          <Route
            path='/pay-app/:loanId/:userId/application/credit-score-card'
            exact
            component={CreditScoreCard}
          />
          <Route
            path='/pay-app/:loanId/:userId/application/lender-services'
            exact
            component={LenderService}
          />
          <Route
            path='/pay-app/:loanId/:userId/application/ledger-analysis'
            exact
            component={LedgerAnalysis}
          />
        </Switch>
      </>
    );
  }
}

export default Application;
