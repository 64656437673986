import React, { Component } from 'react';
import ReactTable from 'react-table';
import Button from '../../../../../components/Button';
import ExcelIcon from '../../../../../assets/images/excel.svg';
import InvoiceButton from '../../../../../assets/images/invoice-button.png';
import SearchIcon from '../../../../../assets/images/SearchIcon.svg';
import { bindActionCreators } from 'redux';
import { getInvoicesList } from '../invoice.action';
import { connect } from 'react-redux';
import { rupeeDecimalFormatter } from '../../../../../utils/utility';
import PaidInvoiceModal from './InvoicesModal/PaidModal';
import { loaderModal } from '../../../../../store/actions';
import CredoModal from '../../../../../components/CredoModal';
import Greentick from '../../../../../assets/images/green tick.svg';
import Processing from '../../../../../assets/images/Processing.svg';
import Warning from '../../../../../assets/images/red alert.svg';
import WarningOrange from '../../../../../assets/images/orange alert.svg';
import File from '../../../../../assets/images/form.svg';
import { excelDownload } from '../../../../../utils/utility';
import { API_INVOICE_PAID_EXCEL } from '../../../../../utils/APIUrls';
class PaidInvoices extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showDatePicker: false,
      isModalOpen: false,
      invoiceData: {},
      endDate: '',
      pageNumber: 1,
      searchParam: '',
      startDate: '',
      types: 'paid',
      searchValue: '',
    };
  }

  componentDidMount = () => {
    const { actions, match } = this.props;
    let { anchorId } = match.params;
    let { endDate, pageNumber, searchParam, startDate, types, searchValue } =
      this.state;
    const { getInvoicesList, loaderModal } = actions;
    loaderModal(true);
    getInvoicesList(
      endDate,
      pageNumber,
      searchParam,
      startDate,
      types,
      searchValue,
      anchorId
    );
    loaderModal(false);
  };

  onResetFilter = () => {
    let { types } = this.state;
    let { match, actions } = this.props,
      { anchorId } = match.params;
    const { getInvoicesList } = actions;
    this.setState(
      {
        endDate: '',
        pageNumber: 1,
        searchParam: '',
        startDate: '',
        searchValue: '',
      },
      () => getInvoicesList('', 1, '', '', types, '', anchorId)
    );
  };

  getPageData = async (type, totalPages) => {
    const { actions, match } = this.props;
    let { anchorId } = match.params;
    let { endDate, pageNumber, searchParam, startDate, types, searchValue } =
      this.state;
    const { getInvoicesList } = actions;
    let updatedPageNumber = pageNumber;
    if (type === 'next' && pageNumber < totalPages) {
      updatedPageNumber = pageNumber + 1;
    } else if (type === 'prev' && pageNumber > 1) {
      updatedPageNumber = pageNumber - 1;
    }
    await this.setState({ pageNumber: updatedPageNumber });
    await getInvoicesList(
      endDate,
      updatedPageNumber,
      searchParam,
      startDate,
      types,
      searchValue,
      anchorId
    );
  };

  toggleModal = (modalType, original) => {
    this.setState({
      isModalOpen: !this.state.isModalOpen,
      modalType,
      invoiceData: original,
    });
  };

  onSearch = async (e) => {
    e.preventDefault();
    const { actions, match } = this.props;
    let { anchorId } = match.params;
    await this.setState({
      pageNumber: 1,
    });
    const { getInvoicesList } = actions;
    let { endDate, pageNumber, searchParam, startDate, types, searchValue } =
      this.state;
    if (searchParam === '') {
      alert('Please select search type');
      return;
    }
    getInvoicesList(
      endDate,
      pageNumber,
      searchParam,
      startDate,
      types,
      searchValue,
      anchorId
    );
  };
  paidExcelDownload = () => {
    let { match } = this.props,
      { anchorId } = match.params;
    let { startDate, endDate, types } = this.state;
    let sendData = {
      endDate,
      startDate,
      types,
    };
    excelDownload(API_INVOICE_PAID_EXCEL, anchorId, sendData, 'paid_excel');
  };

  onDateFilter = async (e) => {
    e.preventDefault();
    const { actions, match } = this.props;
    let { anchorId } = match.params;
    let { endDate, startDate } = this.state;
    const formattedEndDate = endDate
      ? endDate.split('-').reverse().join('/')
      : '';
    const formattedStartDate = startDate
      ? startDate.split('-').reverse().join('/')
      : '';
    await this.setState((prevState) => ({
      ...prevState,
      showDatePicker: !prevState.showDatePicker,
      startDate: formattedStartDate,
      endDate: formattedEndDate,
      pageNumber: 1,
    }));
    let { pageNumber, searchParam, types, searchValue } = this.state;
    const { getInvoicesList } = actions;
    getInvoicesList(
      formattedEndDate,
      pageNumber,
      searchParam,
      formattedStartDate,
      types,
      searchValue,
      anchorId
    );
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value?.trim(),
    });
  };

  render() {
    let { showDatePicker, isModalOpen, modalType, pageNumber, invoiceData } =
      this.state;
    let { invoicesList } = this.props || {};
    let { invoiceDetails, numberOfPages } = invoicesList;
    const PAYMENT_STATUS = {
      PROCESSED: 'processed',
      PROCESSING: 'disbursal_pending_with_lendar',
      PAYOUT_FAILED: 'payout_failed',
      PENDING: 'pending',
    };
    const paidInvoicesColumn = [
      {
        Header: () => (
          <>
            <div className='ml-4'>Party Name</div>
          </>
        ),
        accessor: 'partyName',
        Cell: (props) => {
          let { original } = props;
          let { partyName } = original;
          return <>{partyName || '-'}</>;
        },
      },
      {
        Header: 'Invoice Number',
        accessor: 'invoiceNumber',
        Cell: ({ original }) => {
          return (
            <>
              <img className='mr-2 img-icon' src={File} />
              <span
                className='invoice_number'
                onClick={() => {
                  this.toggleModal(PaidInvoiceModal, original);
                }}
              >
                {original?.invoiceNumber}
              </span>
            </>
          );
        },
      },
      {
        Header: 'Uploaded By',
        accessor: 'invoiceUploadDate',
        width: 110,
      },
      {
        Header: 'Payment Amount',
        accessor: 'invoiceAmount',
        Cell: (props) => {
          let { original } = props;
          let { paymentAmount } = original;

          return (
            <>
              <div>{rupeeDecimalFormatter(paymentAmount, '₹')}</div>
            </>
          );
        },
      },
      {
        Header: 'Payment Date',
        accessor: 'paymentDate',
        width: 120,
      },
      {
        Header: 'UTR',
        accessor: 'utrNumber',
        width: 190,
        Cell: (props) => {
          let { original } = props;
          let { utrNumber, paymentStatus } = original;
          return (
            <>
              <div>{utrNumber || '-'}</div>
              <div className='mt-2'>
                {paymentStatus === PAYMENT_STATUS.PROCESSED ? (
                  <div className='d-flex'>
                    <img className='img-icon' src={Greentick} />
                    <div className='ml-3 mt-1'>Processed</div>
                  </div>
                ) : paymentStatus === PAYMENT_STATUS.PROCESSING ||
                  paymentStatus === PAYMENT_STATUS.PAYOUT_FAILED ||
                  paymentStatus === PAYMENT_STATUS.PENDING ? (
                  <div className='d-flex'>
                    <img className='img-icon' src={Processing} />
                    <div className='ml-3 mt-1'>Processing</div>
                  </div>
                ) : null}
              </div>
            </>
          );
        },
      },
      {
        Header: 'Repayment Status',
        accessor: 'repaymentStatus',
        Cell: (props) => {
          let { original } = props;
          let { repaymentStatus } = original;
          return (
            <div className='d-flex ml-4'>
              <span>
                {original?.settled ? (
                  <img className='img-icon' src={Greentick} />
                ) : original?.overdue ? (
                  <img className='img-icon' src={Warning} />
                ) : (
                  <img src={WarningOrange} />
                )}
              </span>
              <div className='ml-3'>{repaymentStatus}</div>
            </div>
          );
        },
      },
    ];
    return (
      <>
        <div className='row invoices-wrapper interest_charges_wrapper flex'>
          <div className='col-10'>
            <div className='row'>
              <div className='col-6'>
                <form onSubmit={(e) => this.onSearch(e)}>
                  <div className='search-form'>
                    <select
                      className='select '
                      onChange={this.handleChange}
                      name='searchParam'
                      value={this.state.searchParam || ''}
                      required
                    >
                      <option value='' disabled selected>
                        Search By
                      </option>

                      <option value={'invoiceNumber'}>Invoice No</option>
                    </select>
                    <input
                      className='select input'
                      required={true}
                      placeholder='Search'
                      name='searchValue'
                      value={this.state.searchValue}
                      onChange={this.handleChange}
                    />
                    <button type='submit' className='submit'>
                      <img src={SearchIcon} alt='icon' />
                    </button>
                  </div>
                </form>
              </div>
              <div className='col-4'>
                <Button className='mt-1' onClick={this.onResetFilter}>
                  Reset
                </Button>
              </div>
            </div>
          </div>
          <div className='col-2 card_heading_secondary_right'>
            <a onClick={this.paidExcelDownload} download>
              <img
                src={ExcelIcon}
                alt='icon'
                className='icon_image h-75 mr-4'
              />
            </a>
            <img
              src={InvoiceButton}
              alt='icon'
              className='icon_image '
              onClick={() =>
                this.setState((prevState) => ({
                  ...prevState,
                  showDatePicker: !prevState.showDatePicker,
                }))
              }
            />
          </div>
        </div>
        {showDatePicker ? (
          <div className='row d-flex justify-content-end'>
            <div className='card date-picker col-4'>
              <div className='row  '>
                <div className='col text-grey mt-4'>
                  Start Date
                  <input
                    className='date'
                    type='date'
                    value={this.state.startDate.split('/').reverse().join('-')}
                    onChange={(e) =>
                      this.setState({
                        startDate: e.target.value,
                      })
                    }
                  ></input>
                </div>
                <div className='col text-grey mt-4'>
                  End Date
                  <input
                    className='date'
                    type='date'
                    value={this.state.endDate.split('/').reverse().join('-')}
                    onChange={(e) =>
                      this.setState({
                        endDate: e.target.value,
                      })
                    }
                  ></input>
                </div>
              </div>
              <div className='row mt-3 ml-2'>
                <Button onClick={(e) => this.onDateFilter(e)}>OK</Button>
              </div>
            </div>
          </div>
        ) : null}
        <div className='mt-4 interest_charges_wrapper'>
          <ReactTable
            data={invoiceDetails}
            columns={paidInvoicesColumn}
            defaultPageSize={10}
            showPagination={false}
            sortable={false}
          />
          <ul className={'datatable-pagination'}>
            <li>
              <button
                disabled={!(pageNumber > 1)}
                className={'prev-btn'}
                type={'button'}
                onClick={() => this.getPageData('prev', numberOfPages)}
              >
                Previous
              </button>
            </li>
            <li>
              <span>
                Page No. {numberOfPages === 0 ? 0 : pageNumber} of{' '}
                {numberOfPages}
              </span>
            </li>

            <li>
              <button
                disabled={!(numberOfPages > pageNumber)}
                className={'next-btn'}
                type={'button'}
                onClick={() => this.getPageData('next', numberOfPages)}
              >
                Next
              </button>
            </li>
          </ul>
        </div>
        <CredoModal
          isOpen={isModalOpen}
          styles={{
            content: {
              width: '80%',
              height: '80%',
            },
          }}
          closeAction={() => this.toggleModal('')}
          RenderingComponent={modalType}
          invoiceData={invoiceData}
          toggleModal={this.toggleModal}
        />
      </>
    );
  }
}
const mapStateToProps = ({ supplierInvoicesReducer }) => ({
  invoicesList: supplierInvoicesReducer?.invoicesList,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      getInvoicesList,
      loaderModal,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(PaidInvoices);
