import {
  API_POST_CUSTOMER_AND_SUPPLIER_LIST,
  API_GET_INVOICE_SUMMARY,
  API_GET_FUNDING_SUMMARY,
  API_VENDOR_OFFER_DETAILS,
} from '../../../utils/APIUrls';
import { POST, GET } from '../../../utils/webAPI.service';
import * as manageUserConstants from './manageUser.actions.constants';

const getInvoiceSummary = (userId) => {
  return (dispatch) => {
    return GET(API_GET_INVOICE_SUMMARY(userId))
      .then(({ data }) => {
        if (data.code === 200) {
          if (data.data) {
            dispatch({
              type: manageUserConstants.SET_INVOICE_SUMMARY,
              data: data.data,
            });
          }
        } else if (data.code === 401) {
          alert(data?.message);
        }
      })
      .catch((err) => {
        alert('Something went wrong');
        throw err;
      });
  };
};

const getVendorLimit = (userId, anchorId) => {
  return (dispatch) => {
    return GET(API_VENDOR_OFFER_DETAILS(userId, anchorId))
      .then(({ data }) => {
        if (data.code === 200) {
          if (data.data) {
            dispatch({
              type: manageUserConstants.SET_VENDOR_LIMIT_DETAILS,
              data: data.data,
            });
          }
        } else {
          alert(data?.message);
        }
      })
      .catch((err) => {
        alert('Something went wrong');
        throw err;
      });
  };
};

const getCustomerAndSupplierList = (
  userId,
  contactType,
  pageNumber,
  sortParameter = 0,
  sortingOn = ''
) => {
  let dataToSend = {
    contactType,
    pageNumber,
    userId,
    sortingRequest: {
      sortParameter,
      sortingOn,
    },
  };
  return (dispatch) => {
    return POST(API_POST_CUSTOMER_AND_SUPPLIER_LIST, dataToSend)
      .then(({ data }) => {
        if (data.code === 200) {
          if (data.data && contactType === 'customer') {
            dispatch({
              type: manageUserConstants.SET_CUSTOMER_LIST,
              data: data.data,
            });
          } else if (data.data && contactType === 'supplier') {
            dispatch({
              type: manageUserConstants.SET_SUPPLIER_LIST,
              data: data.data,
            });
          }
        } else if (data.code === 401) {
          alert(data?.message);
        }
      })
      .catch((err) => {
        alert('Something went wrong ');
        throw err;
      });
  };
};

const getFundingSummary = (userId) => {
  return (dispatch) => {
    return GET(API_GET_FUNDING_SUMMARY(userId))
      .then(({ data }) => {
        if (data.code === 200) {
          if (data.data) {
            dispatch({
              type: manageUserConstants.SET_FUNDING_SUMMARY,
              data: data.data,
            });
          }
        } else if (data.code === 401) {
          alert(data?.message);
        }
      })
      .catch((err) => {
        alert('Something went wrong');
        throw err;
      });
  };
};

export {
  getInvoiceSummary,
  getCustomerAndSupplierList,
  getFundingSummary,
  getVendorLimit,
};
