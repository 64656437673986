import React, { Component } from 'react';
import ReactTable from 'react-table';
import DownArrow from '../../../assets/images/downArrow.png';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { loaderModal } from '../../../store/actions';
import { getEInvoiceData } from '../../InvoiceManagement/ALLInvoices/allInvoices.action';

export class OutstandingInvoiceModal extends Component {
  async componentDidMount() {
    try {
      const { actions, invoiceId } = this.props || {};
      const { getEInvoiceData } = actions;
      await getEInvoiceData(invoiceId);
    } catch (error) {
      console.error('error', error);
    }
  }
  render() {
    let { stateData, eInvoices } = this.props,
      {
        fundingStatus,
        paymentDate,
        paymentMode,
        paymentReference,
        invoiceStatus,
        partyName,
        paymentDetails = {},
        invoiceFileUrl = '',
      } = stateData?.outstandingInvoiceDetails;
    const paymentDetailsArray = paymentDetails ? [paymentDetails] : [];
    const outstandingInvoiceColumns = [
      {
        Header: 'Account Holder',
        accessor: 'accountHolder',
      },
      {
        Header: 'Account Number',
        accessor: 'accountNumber',
      },
      {
        Header: 'IFSC Code',
        accessor: 'ifscCode',
      },
      {
        Header: 'Bank Name',
        accessor: 'bankName',
      },
      {
        Header: 'Branch',
        accessor: 'branchName',
      },
      {
        Header: 'UPI ID',
        accessor: 'upiId',
      },
      {
        Header: 'QR Ref',
        accessor: 'qrReference',
      },
    ];
    return (
      <div className='compare-details-wrapper'>
        <h3 className='card_heading'>
          Invoice Details
          <img src={DownArrow} alt='download' className='downArrow' />
        </h3>

        <div className='border-bottom'>
          <div className='row'>
            <div className='col-md-4 text-grey'>
              Invoice Status
              <div className='text-bold'>{invoiceStatus || '-'}</div>
            </div>
            <div className='col-md-4 text-grey'>
              Funding Status
              <div className='text-bold'>{fundingStatus || '-'}</div>
            </div>
            <div className='col-md-4 text-grey'>
              Party
              <div className='text-bold'>{partyName || '-'}</div>
            </div>
          </div>
          <div className='row'>
            <div className='col-md-4 text-grey'>
              Payment Date
              <div className='text-bold'>{paymentDate || '-'}</div>
            </div>
            <div className='col-md-4 text-grey'>
              Payment Mode
              <div className='text-bold'>{paymentMode || '-'}</div>
            </div>
            <div className='col-md-4 text-grey'>
              Payment Reference
              <div className='text-bold'>{paymentReference || '-'}</div>
            </div>
          </div>
        </div>
        <h3 className='card_heading mt-4 mb-4'>Bank Account Details</h3>
        <ReactTable
          columns={outstandingInvoiceColumns}
          data={paymentDetailsArray}
          defaultPageSize={1}
          showPagination={false}
        />

        <h3 className='card_heading mt-4 mb-4'>
          Uploaded Invoice
          <a href={invoiceFileUrl} download>
            <img src={DownArrow} alt='download' className='downArrow' />
          </a>
        </h3>
        {invoiceFileUrl?.includes('pdf') ? (
          <iframe
            title='Invoice'
            src={invoiceFileUrl}
            className='profile_img  preview_img'
          ></iframe>
        ) : (
          <img
            src={invoiceFileUrl}
            alt='invoice_image'
            className='invoice_img'
          />
        )}

        {/* E-invoice */}
        {eInvoices ? (
          <>
            <h3 className='card_heading mt-4 mb-4'>
              <div className='d-flex justify-content-between'>
                <div>e-Invoice Id :{eInvoices?.docId ?? ''}</div>
              </div>
            </h3>
            <img
              alt='e-invoice'
              src={eInvoices?.fileUrl ?? ''}
              className='invoice_img'
            />
          </>
        ) : (
          ''
        )}
      </div>
    );
  }
}
const mapStateToProps = ({ AllInvoicesReducer }) => ({
  eInvoices: AllInvoicesReducer?.eInvoices,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      loaderModal,
      getEInvoiceData,
    },
    dispatch
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OutstandingInvoiceModal);
