import React, { Component } from 'react';
import ReactTable from 'react-table';
import DownArrow from '../../../assets/images/downArrow.png';
import FileDownload from 'js-file-download';
import axios from 'axios';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { loaderModal } from '../../../store/actions';
import { getEInvoiceData } from './allInvoices.action';

export class AllInvoiceModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isClicked: false,
    };
  }
  invoiceDownload = async () => {
    const { invoiceModalDetails } = this.props;
    const { data } = invoiceModalDetails || {};
    try {
      const response = await fetch(data?.invoiceFileUrl);
      const blob = await response.blob();
      FileDownload(blob, `invoice.jpeg`);
    } catch (error) {
      console.log(error);
    }
  };
  async componentDidMount() {
    try {
      const { actions, paymentModalData } = this.props || {};
      const { invoiceId } = paymentModalData;
      const { getEInvoiceData } = actions;
      await getEInvoiceData(invoiceId);
    } catch (error) {
      console.error('error', error);
    }
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  handleClick = () => {
    this.setState((prevState) => ({
      isClicked: !prevState.isClicked,
    }));
    if (this.state.docId && this.state?.docId?.trim()?.length > 0) {
      //docId?.trim()?.length > 0 is used for validation
      setTimeout(() => {
        this.inputFile.click(); // Trigger the click event of the hidden input element
        this.setState((prevState) => ({
          isClicked: !prevState.isClicked,
        }));
      }, 350);
    } else {
      alert('Please Enter e-Invoice Id');
      this.setState((prevState) => ({
        isClicked: !prevState.isClicked,
      }));
    }
  };

  handleFileChange(event) {
    const selectedFile = event.target.files[0];
    let { paymentModalData } = this.props || {};
    let { invoiceId } = paymentModalData;
    let token = localStorage.getItem('token');
    let User = localStorage.getItem('userId');
    const headers = {
      Accept: 'application/json',
      Authorization: 'Bearer ' + token,
      'Content-Type': 'multipart/formData',
      'X-CLIENT-NAME': 'PDU',
      'X-USER-ID': User,
    };

    const formData = new FormData();
    formData.append('file', selectedFile);
    axios({
      method: 'POST',
      url: `${
        process.env.REACT_APP_BASE_URL_PEMANT_C1
      }/invoice/v1/invoice/verify/e-invoice?docId=${this.state.docId.trim()}&invoiceId=${invoiceId}`,
      data: formData,
      headers: headers,
    })
      .then(async (response) => {
        const { actions, paymentModalData } = this.props || {};
        const { invoiceId } = paymentModalData;
        const { getEInvoiceData, loaderModal } = actions;
        if (response?.data?.code === 200) {
          loaderModal(true);
          await getEInvoiceData(invoiceId);
          this.setState({
            docId: null,
          });
          loaderModal(false);
        }
      })
      .catch((error) => {
        console.error('Upload error:', error);
        loaderModal(false);
      });
    loaderModal(false);
  }
  render() {
    let { isClicked } = this.state;
    let { invoiceModalDetails, eInvoices } = this.props || {};
    let { data } = invoiceModalDetails || {};
    let { paymentDetails, invoiceFileUrl } = data || [];
    const paymentDetailsArray = paymentDetails ? [paymentDetails] : [];
    const outstandingInvoiceColumns = [
      {
        Header: 'Account Holder',
        accessor: 'accountHolder',
      },
      {
        Header: 'Account Number',
        accessor: 'accountNumber',
      },
      {
        Header: 'IFSC Code',
        accessor: 'ifscCode',
      },
      {
        Header: 'Bank Name',
        accessor: 'bankName',
      },
      {
        Header: 'Branch',
        accessor: 'branchName',
      },
      {
        Header: 'UPI ID',
        accessor: 'upiId',
      },
      {
        Header: 'QR Ref',
        accessor: 'qrReference',
      },
    ];
    return (
      <div className='compare-details-wrapper'>
        <h3 className='card_heading'>
          Invoice Details
          <img src={DownArrow} alt='download' className='downArrow' />
        </h3>

        <div className='border-bottom'>
          <div className='row'>
            <div className='col-md-3 text-grey'>
              Invoice Status
              <div className='text-bold'>{data?.invoiceStatus || '-'}</div>
            </div>
            <div className='col-md-3 text-grey'>
              Funding Status
              <div className='text-bold'>{data?.fundingStatus || '-'}</div>
            </div>
            <div className='col-md-3 text-grey'>
              Invoice Date
              <div className='text-bold'>{data?.invoiceDate || '-'}</div>
            </div>
            <div className='col-md-3 text-grey'>
              Party
              <div className='text-bold'>{data?.partyName || '-'}</div>
            </div>
          </div>
          <div className='row'>
            <div className='col-md-3 text-grey'>
              Payment Date
              <div className='text-bold'>{data?.paymentDate || '-'}</div>
            </div>
            <div className='col-md-3 text-grey'>
              Payment Mode
              <div className='text-bold'>{data?.paymentMode || '-'}</div>
            </div>
            <div className='col-md-3 text-grey'>
              Payment Reference
              <div className='text-bold'>{data?.paymentReference || '-'}</div>
            </div>
          </div>
        </div>
        <h3 className='card_heading mt-4 mb-4'>Bank Account Details</h3>
        <ReactTable
          columns={outstandingInvoiceColumns}
          data={paymentDetailsArray}
          defaultPageSize={1}
          showPagination={false}
        />

        <h3 className='card_heading mt-4 mb-4'>
          <div className='d-flex justify-content-between'>
            <div>
              Uploaded Invoice
              <a onClick={this.invoiceDownload}>
                <img src={DownArrow} alt='download' className='downArrow' />
              </a>
            </div>
            <div className='d-flex align-items-center'>
              <div className='mr-4 mb-2 invoice_input'>
                <input
                  placeholder='Enter e-Invoice Id'
                  value={this.state.docId || ''}
                  name='docId'
                  onChange={this.handleChange}
                ></input>
              </div>
              <div>
                <button
                  className={`button ${isClicked ? 'clicked' : ''}`}
                  onClick={this.handleClick}
                >
                  <span>
                    Verify e - Invoice
                    <img
                      src={DownArrow}
                      alt='download'
                      className='downArrow rotate-90'
                    />
                  </span>
                </button>
                <input
                  type='file'
                  ref={(input) => (this.inputFile = input)}
                  onChange={(e) => this.handleFileChange(e)}
                  accept='image/*'
                />
              </div>
            </div>
          </div>
        </h3>
        {invoiceFileUrl && invoiceFileUrl.toLowerCase().includes('pdf') ? (
          <iframe
            title='Invoice'
            src={invoiceFileUrl}
            className='profile_img  preview_img'
          ></iframe>
        ) : (
          <img
            src={invoiceFileUrl}
            alt='invoice_image'
            className='invoice_img'
          />
        )}

        {/* E-invoice */}
        {eInvoices ? (
          <>
            <h3 className='card_heading mt-4 mb-4'>
              <div className='d-flex justify-content-between'>
                <div>e-Invoice Id :{eInvoices?.docId ?? ''}</div>
              </div>
            </h3>
            <img
              alt='e-invoice'
              src={eInvoices?.fileUrl ?? ''}
              className='invoice_img'
            />
          </>
        ) : (
          ''
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ AllInvoicesReducer }) => ({
  eInvoices: AllInvoicesReducer?.eInvoices,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      loaderModal,
      getEInvoiceData,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(AllInvoiceModal);
