import * as homeConstants from './home.action.constant';
import {
  API_GET_SUPPLIER_SUMMARY,
  API_PAYMENT_VIEW,
} from '../../../../utils/APIUrls';
import { POST, GET } from '../../../../utils/webAPI.service';

const getLatestPayments = (
  from,
  pageNumber,
  searchBy,
  searchValue,
  to,
  supplierId
) => {
  let dataToSend = {
    from,
    pageNumber,
    searchBy,
    searchValue,
    to,
    supplierId,
  };
  return (dispatch) => {
    return POST(API_PAYMENT_VIEW, dataToSend)
      .then(({ data }) => {
        if (data.code === 200) {
          if (data?.data) {
            dispatch({
              type: homeConstants.SET_LATEST_PAYMENTS,
              data: data.data,
            });
          }
        } else if (data.code === 401) {
          alert(data?.message);
        }
        return data;
      })
      .catch((err) => {
        alert('Something went wrong');
        throw err;
      });
  };
};

const getBuyerSummary = (supplierId) => {
  return (dispatch) => {
    return GET(API_GET_SUPPLIER_SUMMARY(supplierId))
      .then(({ data }) => {
        if (data.code === 200) {
          if (data?.data) {
            dispatch({
              type: homeConstants.SET_SUPPLIER_SUMMARY,
              data: data.data,
            });
          }
        } else if (data.code === 401) {
          alert(data?.message);
        }
        return data;
      })
      .catch((err) => {
        alert('Something went wrong');
        throw err;
      });
  };
};

export { getLatestPayments, getBuyerSummary };
