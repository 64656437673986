import { find, get } from 'lodash';
import React, { Component } from 'react';
import { API_FETCH_TRANSACTION_RULES } from '../../../utils/APIUrls';
import { GET } from '../../../utils/webAPI.service';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { loaderModal } from '../../../store/actions';

class PreviewLimit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      transactionRulesData: [],
    };
  }

  componentDidMount() {
    const { limitAccountNumber, propsData, actions } = this.props;
    actions.loaderModal(true);
    GET(
      `${API_FETCH_TRANSACTION_RULES}/${propsData.match.params.userId}/${limitAccountNumber}`
    )
      .then(({ data }) => {
        actions.loaderModal(false);

        if (data.code === 200) {
          this.setState({ transactionRulesData: data.data });
        } else {
          alert('Something went wrong.');
        }
      })
      .catch((err) => {
        actions.loaderModal(false);
        console.error(err);
        alert('Something went wrong.');
      });
  }

  getSingleValueFromObject = (strategyName) => {
    const { singleValueTransactionRules } = this.state.transactionRulesData;
    return get(
      find(singleValueTransactionRules, {
        strategyName,
      }),
      'maxAllowedValue',
      0
    );
  };
  render() {
    const { multiValueCriteriaWiseTransactionRules } =
      this.state.transactionRulesData;
    const monthAmountArray = get(
      multiValueCriteriaWiseTransactionRules,
      '[0].criteriaWiseAllowedValueList',
      []
    );
    const { transactionRulesData } = this.state;
    const maxNumberOfInvoice =
      this.getSingleValueFromObject('max_inv_per_month');
    const maxValPerInvoice = this.getSingleValueFromObject(
      'max_value_per_invoice'
    );
    const invoiceDate = this.getSingleValueFromObject(
      'max_days_less_than_today'
    );
    const maxNumberOfInvInSevenDays = this.getSingleValueFromObject(
      'max_invoices_in_seven_days'
    );

    return (
      <>
        {transactionRulesData?.length !== 0 &&
        (transactionRulesData.multiValueCriteriaWiseTransactionRules !== null ||
          transactionRulesData.singleValueTransactionRules !== null) ? (
          <div className='configure-transaction-wrapper'>
            <div className='heading'>Transaction Rules</div>
            <div className='body'>
              <div className='row'>
                <div className='left-section align-self-start'>
                  <div className='text '>
                    Total Invoice Amount in low business month
                  </div>
                </div>
                <div className='right-section d-block '>
                  {monthAmountArray.map((monthAmount, index) => {
                    return (
                      <div
                        className='d-flex align-items-center justify-content-between'
                        key={`month-amount-${index}`}
                      >
                        <div className='d-flex justify-content-between w-100 mb-4'>
                          <div className='w-100 align-self-start mr-5 '>
                            <label className='d-flex helper-text font-weight-bold'>
                              Month
                            </label>
                            <div className='read-only-box'>
                              {monthAmount.criteriaKey}
                            </div>
                          </div>
                          <div className='w-100'>
                            <label className='d-flex helper-text font-weight-bold'>
                              Sales Amount
                            </label>
                            <div className='read-only-box'>
                              {monthAmount.maxAllowedValue}
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className='row'>
                <div className='left-section'>
                  <div className='text'>
                    Maximum number of invoices in a month ( #)
                  </div>
                </div>
                <div className='right-section'>
                  <div className='read-only-box'>{maxNumberOfInvoice}</div>
                  <div className='helper-text'>(in number)</div>
                </div>
              </div>
              <div className='row'>
                <div className='left-section'>
                  <div className='text'>Maximum value per invoice</div>
                </div>
                <div className='right-section'>
                  <div className='read-only-box'>{maxValPerInvoice}</div>
                  <div className='helper-text'>(amount in rupees)</div>
                </div>
              </div>
              <div className='row'>
                <div className='left-section'>
                  <div className='text'>Invoice Date not older than</div>
                </div>
                <div className='right-section'>
                  <div className='read-only-box'>{invoiceDate}</div>
                  <div className='helper-text'>(in days)</div>
                </div>
              </div>
              <div className='row'>
                <div className='left-section'>
                  <div className='text'>
                    Maximum number of invoices in 7 days
                  </div>
                </div>
                <div className='right-section'>
                  <div className='read-only-box'>
                    {maxNumberOfInvInSevenDays}
                  </div>
                  <div className='helper-text'>(in number)</div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <>
            <div className='configure-transaction-wrapper'>
              <h4>No Rules has been setup for this user !</h4>
            </div>
          </>
        )}
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      loaderModal,
    },
    dispatch
  ),
});

export default connect(null, mapDispatchToProps)(PreviewLimit);
