import React, { PureComponent } from 'react';
import Icon from 'react-icons-kit';
import { ic_check_box } from 'react-icons-kit/md/ic_check_box';
import { ic_check_box_outline_blank } from 'react-icons-kit/md/ic_check_box_outline_blank';

class CheckBox extends PureComponent {
  render() {
    let {
      htmlFor,
      name,
      label,
      checkboxColor,
      labelColor,
      checked,
      onChange,
      inputClass,
      labelClass,
      required
    } = this.props;
    return (
      <div className={'checkbox-box'}>
        <input
          name={name}
          ref={ref => (this.checkboxRef = ref)}
          className={inputClass}
          id={htmlFor}
          type={'checkbox'}
          checked={checked}
          onChange={onChange}
          required={required}
        />
        <div className={'checkbox'}>
          <span
            onClick={() => this.checkboxRef.click()}
            style={{ color: checkboxColor }}
            className={'checkbox-checked'}
          >
            <Icon icon={ic_check_box} size={22} />
          </span>
          <span
            onClick={() => this.checkboxRef.click()}
            style={{ color: checkboxColor }}
            className={'checkbox-unchecked'}
          >
            <Icon icon={ic_check_box_outline_blank} size={22} />
          </span>
        </div>
        <label
          className={labelClass}
          style={{ color: labelColor }}
          htmlFor={htmlFor}
        >
          {label}
        </label>
      </div>
    );
  }
}

CheckBox.defaultProps = {
  htmlFor: '',
  label: '',
  checkboxColor: '',
  labelColor: '',
  checked: false,
  onChange: '',
  inputClass: '',
  labelClass: '',
  required: false,
  name: ''
};

export default CheckBox;
