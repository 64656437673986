import React, { Component } from 'react';
import ReactTable from 'react-table';
import { API_POST_CHARGE_WAIVE_OFF } from '../../../utils/APIUrls';
import { POST } from '../../../utils/webAPI.service';
import { connect } from 'react-redux';
import {
  getSoa,
  getLimitAccountNumber,
  getSoaSummary,
} from './interestAndCharges.actions';
import { bindActionCreators } from 'redux';

class WaiveOffModal extends Component {
  state = {
    isEditable: true,
    isButtonDisabled: false,
    isPartial: null,
    amount: '',
  };

  componentDidMount = () => {
    let { actions, match } = this.props,
      { userId } = match.params,
      { getLimitAccountNumber } = actions;
    getLimitAccountNumber(userId);
  };

  adminWaiveOffCheck = (data) => {
    let { rowData } = this.props;
    if (Number(data) > 0 && Number(data) < Number(rowData?.debitAmount)) {
      return data?.trim();
    } else if (Number(data) < 0) {
      alert('Partial waiver amount should not be negative');
      return null;
    } else {
      if (data) {
        alert(
          'Partial waiver amount should be greater than zero and smaller than debit amount'
        );
        return null;
      }
    }
  };

  submitHandler = () => {
    let { rowData, match } = this.props,
      { userId } = match.params,
      { comment, serviceRequestBy, isPartial, amount } = this.state;
    // Check for invalid conditions before making the API call
    const adminWaiveOffCheckResult = this.adminWaiveOffCheck(amount);

    if (adminWaiveOffCheckResult === null) {
      // Invalid condition, show appropriate alert and do not proceed with the API call
      return;
    }
    const dataToSend = {
      transactionInfoId: rowData?.transId,
      userId,
      comment,
      serviceRequestBy,
      isPartial,
      amount: adminWaiveOffCheckResult || '',
    };
    this.setState({
      isButtonDisabled: true,
    });
    if (isPartial !== null) {
      POST(API_POST_CHARGE_WAIVE_OFF, dataToSend)
        .then(({ data }) => {
          this.setState({
            isButtonDisabled: false,
          });
          if (data.code === 200) {
            let { toggleModal, match, actions } = this.props,
              { userId } = match.params;
            alert('Charge waived of successfully');
            actions.getSoa(userId);
            actions.getSoaSummary(userId);
            toggleModal('');
          } else if (data.code === 999 || data.code === 401) {
            alert(data.message);
          } else {
            alert('Something went wrong');
          }
        })
        .catch((err) => {
          alert('Something went wrong');
          throw err;
        });
    } else {
      alert('Please Select Partial Waiver');
    }
  };
  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };
  render() {
    let { rowData, toggleModal, limitAccountNumber } = this.props,
      { isEditable, amount } = this.state;
    const outstandingInvoiceColumns = [
      {
        Header: 'Effective Date',
        accessor: 'effectiveDate',
      },
      {
        Header: 'Transaction Date',
        accessor: 'transDate',
      },
      {
        Header: 'Transaction Description',
        accessor: 'description',
      },
      {
        Header: 'Invoice No.',
        accessor: 'invoiceId',
      },
      {
        Header: 'Debit',
        accessor: 'debitAmount',
      },
    ];

    return (
      <div className='modal-wrapper'>
        <h3 className='card_heading'>Waiver</h3>
        <ReactTable
          columns={outstandingInvoiceColumns}
          data={[rowData]}
          defaultPageSize={1}
          showPagination={false}
          getTrProps={this.rowClicked}
        />
        <div className='row py-5 px-2'>
          <div className='col-md-4'>
            Service Request from
            <label className='radio_button-label'>
              <input
                type='radio'
                className='radio_button'
                onChange={this.handleChange}
                value='user'
                name='serviceRequestBy'
              />
              <div className=' h4'> User</div>
            </label>
            <label className='radio_button-label'>
              <input
                type='radio'
                className='radio_button'
                onChange={this.handleChange}
                value='ops_user'
                name='serviceRequestBy'
              />
              <div className=' h4'> Operation Team</div>
            </label>
          </div>
          <div className='col-md-8'>
            Comment
            <div
              className='comments'
              contentEditable={isEditable}
              onInput={(e) =>
                this.setState({
                  comment: e.currentTarget.textContent,
                })
              }
            />
          </div>
        </div>

        <div className='row py-5 px-2'>
          <div className='col-md-2'>
            Partial Waiver
            <label className='radio_button-label'>
              <input
                type='radio'
                className='radio_button'
                onChange={(e) =>
                  this.setState({
                    isPartial: true,
                  })
                }
                checked={this.state.isPartial === true}
                value={true}
                name='isPartial'
              />
              <div className='ml-3 h4'>Yes</div>
            </label>
            <label className='radio_button-label'>
              <input
                type='radio'
                className='radio_button mr-2'
                onChange={(e) =>
                  this.setState({
                    isPartial: false,
                    amount: null,
                  })
                }
                checked={this.state.isPartial === false}
                value={false}
                name='isPartial'
              />
              <div className='ml-3 h4'>No</div>
            </label>
          </div>
          <div className='col-md-3 mt-4'>
            {this.state?.isPartial && (
              <input
                placeholder='Enter Partial Waiver Amount'
                onChange={this.handleChange}
                required={true}
                name='amount'
              />
            )}
          </div>
        </div>

        <div className='text-centre'>
          You are about to Wave off ₹ {rowData?.debitAmount} for Loan account
          Number: {limitAccountNumber?.data || '-'}
        </div>
        <div className='save_button'>
          <button
            className={'button-failure basic-button'}
            onClick={() => toggleModal('')}
          >
            Cancel
          </button>
          <button
            className={'button-success basic-button'}
            type='submit'
            onClick={this.submitHandler}
            // disabled={this.state.isButtonDisabled}
          >
            Save
          </button>
        </div>
      </div>
    );
  }
}
const mapStateToProps = ({ interestAndChargesReducer }) => ({
  limitAccountNumber: interestAndChargesReducer.limitAccountNumber,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    { getSoa, getLimitAccountNumber, getSoaSummary },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(WaiveOffModal);
