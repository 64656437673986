import React, { Component } from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import HeaderTop from '../../../components/HeaderTop';
import { activeDashboard } from '../../../store/actions';
import { Helmet } from 'react-helmet';
import { Route, Switch } from 'react-router-dom';
import Home from '../SupplierDashboard/Home/Home';
import { get } from 'lodash';
import Profile from '../SupplierDashboard/Profile/Profile';
import Invitation from '../SupplierDashboard/Invitation/Invitation';
import ActiveParties from '../SupplierDashboard/ActiveParties/ActiveParties';
import Invoice from '../SupplierDashboard/Invoices/Invoice';
import BuyerDetails from '../SupplierDashboard/ActiveParties/BuyerDetails';
class SupplierNavs extends Component {
  clickHandle = (e, name) => {
    let { match, history } = this.props,
      { anchorId } = match.params;
    if (e.ctrlKey) {
      if ((e.ctrlKey || e.metaKey) && name === 'home') {
        window.open(`/anchor/${anchorId}/supplier/home`);
      } else if ((e.ctrlKey || e.metaKey) && name === 'active-parties') {
        window.open(`/anchor/${anchorId}/supplier/active-parties`);
      } else if ((e.ctrlKey || e.metaKey) && name === 'invoices') {
        window.open(`/anchor/${anchorId}/supplier/invoices`);
      } else if ((e.ctrlKey || e.metaKey) && name === 'profile') {
        window.open(`/anchor/${anchorId}/supplier/profile`);
      } else if ((e.ctrlKey || e.metaKey) && name === 'invitations') {
        window.open(`/anchor/${anchorId}/supplier/invitations`);
      }
    } else if (e.button === 0) {
      if (name === 'home') {
        history.push(`/anchor/${anchorId}/supplier/home`);
      } else if (name === 'active-parties') {
        history.push(`/anchor/${anchorId}/supplier/active-parties`);
      } else if (name === 'profile') {
        history.push(`/anchor/${anchorId}/supplier/profile`);
      } else if (name === 'invitations') {
        history.push(`/anchor/${anchorId}/supplier/invitations`);
      } else if (name === 'invoices') {
        history.push(`/anchor/${anchorId}/supplier/invoices`);
      }
    }
  };

  render() {
    const { location } = this.props;
    const arrPath = get(location, 'pathname', '').split('/');
    const activeTab = arrPath.includes('active-parties')
      ? 'active-parties'
      : arrPath.length
      ? arrPath[arrPath.length - 1]
      : 'home';
    return (
      <div className='dashboard-wrapper'>
        <Helmet>
          <meta charSet='utf-8' />
          <title>Supplier Dashboard</title>
        </Helmet>
        <HeaderTop {...this.props} />
        <section className={'leadmanager-navs-wrapper'}>
          <Navbar>
            <Nav className='credit-navs  container' activeKey={activeTab}>
              <Nav.Link
                eventKey='home'
                onClick={(e) => this.clickHandle(e, 'home')}
              >
                HOME
              </Nav.Link>
              <Nav.Link
                eventKey='active-parties'
                onClick={(e) => this.clickHandle(e, 'active-parties')}
              >
                ACTIVE PARTIES
              </Nav.Link>
              <Nav.Link
                eventKey='invoices'
                onClick={(e) => this.clickHandle(e, 'invoices')}
              >
                INVOICES
              </Nav.Link>
              <Nav.Link
                eventKey='profile'
                onClick={(e) => this.clickHandle(e, 'profile')}
              >
                PROFILE
              </Nav.Link>
              <Nav.Link
                eventKey='invitations'
                onClick={(e) => this.clickHandle(e, 'invitations')}
              >
                INVITATIONS
              </Nav.Link>
            </Nav>
          </Navbar>
        </section>
        <Switch>
          <Route
            path='/anchor/:anchorId/supplier/home'
            exact
            component={Home}
          />
          <Route
            path='/anchor/:anchorId/supplier/profile'
            exact
            component={Profile}
          />
          <Route
            path='/anchor/:anchorId/supplier/invitations'
            exact
            component={Invitation}
          />

          <Route
            path='/anchor/:anchorId/supplier/invoices'
            exact
            component={Invoice}
          />
          <Route
            path='/anchor/:anchorId/supplier/active-parties'
            exact
            component={ActiveParties}
          ></Route>
          <Route
            path='/anchor/:anchorId/supplier/active-parties/:buyerId'
            exact
            component={BuyerDetails}
          />
        </Switch>
      </div>
    );
  }
}

SupplierNavs.defaultProps = {
  active: '',
};

const mapStateToProps = ({ data }) => ({
  activeDashboard: data.activeDashboard,
});
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      activeDashboard,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(SupplierNavs);
