import React, { Component } from 'react';
import DownArrow from '../../../assets/images/downArrow.png';
import { rupeeDecimalFormatter } from '../../../utils/utility';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { loaderModal } from '../../../store/actions';
import { getEInvoiceData } from '../../InvoiceManagement/ALLInvoices/allInvoices.action';

export class SettledInvoiceModal extends Component {
  async componentDidMount() {
    try {
      const { actions, invoiceId } = this.props || {};
      const { getEInvoiceData } = actions;
      await getEInvoiceData(invoiceId);
    } catch (error) {
      console.error('error', error);
    }
  }
  render() {
    let { InvoiceData } = this.props || {};
    let { stateData, eInvoices } = this.props,
      { settledInvoiceDetails } = stateData,
      {
        invoiceStatus,
        daysOutstanding,
        repaymentMode,
        repaymentRefernce,
        principleAmount,
        interest,
        invoiceFileUrl,
      } = settledInvoiceDetails;
    return (
      <div className='compare-details-wrapper'>
        <h3 className='card_heading'>
          Settled Invoice Details
          <img src={DownArrow} alt='download' className='downArrow' />
        </h3>
        <div className='row'>
          <div className='col-md-4 text-grey'>
            Invoice Status
            <div className='text-bold'>{invoiceStatus || '-'}</div>
          </div>
          <div className='col-md-4 text-grey'>
            Days Outstanding
            <div className='text-bold'>{daysOutstanding || '-'} Days</div>
          </div>
          <div className='col-md-4 text-grey'>
            Principal Amount
            <div className='text-bold'>
              {rupeeDecimalFormatter(principleAmount, '₹')}
            </div>
          </div>
          <div className='col-md-4 text-grey'>
            Settled Amount
            <div className='text-bold'>
              {rupeeDecimalFormatter(InvoiceData?.settledAmount, '₹')}
            </div>
          </div>
          <div className='col-md-4 text-grey'>
            Interest
            <div className='text-bold'>
              {rupeeDecimalFormatter(interest, '₹')}
            </div>
          </div>

          <div className='col-md-4 text-grey'>
            Repayment Mode
            <div className='text-bold'>{repaymentMode || '-'}</div>
          </div>
          <div className='col-md-4 text-grey'>
            Repayment Reference
            <div className='text-bold'>{repaymentRefernce || '-'}</div>
          </div>
        </div>
        <h3 className='card_heading'>
          Uploaded Invoice
          <a href={invoiceFileUrl} download>
            <img src={DownArrow} alt='download' className='downArrow' />
          </a>
        </h3>
        {invoiceFileUrl?.includes('pdf') ? (
          <iframe
            title='Invoice'
            src={invoiceFileUrl}
            className='profile_img  preview_img'
          ></iframe>
        ) : (
          <img
            src={invoiceFileUrl}
            alt='invoice_image'
            className='invoice_img'
          />
        )}

        {/* E-invoice */}
        {eInvoices ? (
          <>
            <h3 className='card_heading mt-4 mb-4'>
              <div className='d-flex justify-content-between'>
                <div>e-Invoice Id :{eInvoices?.docId ?? ''}</div>
              </div>
            </h3>
            <img
              alt='e-invoice'
              src={eInvoices?.fileUrl ?? ''}
              className='invoice_img'
            />
          </>
        ) : (
          ''
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ AllInvoicesReducer }) => ({
  eInvoices: AllInvoicesReducer?.eInvoices,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      loaderModal,
      getEInvoiceData,
    },
    dispatch
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SettledInvoiceModal);
