export function loaderModal(val) {
  return {
    type: 'SET_LOADER_MODAL_STATUS',
    val
  };
}

export function activeDashboard(val) {
  return {
    type: 'SET_ACTIVE_DASHBOARD',
    val
  };
}
