import * as supplierDetailsConstant from './profile.action.constant';
const initialState = {
  supplierDetailsData: {},
};

const SupplierDetailsReducer = (state = initialState, action) => {
  const { type, data } = action;
  switch (type) {
    case supplierDetailsConstant.SET_SUPPLIERS_DETAILS:
      return { ...state, supplierDetailsData: data };
    default:
      return state;
  }
};
export default SupplierDetailsReducer;
