import React, { Component } from 'react';
import Sent from './Sent';
import Stagged from './Stagged';
import InviteParties from './InviteParties';
import CredoModal from '../../../../components/CredoModal';

export default class Invitation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 'stagged',
      isModalOpen: false,
    };
  }

  invoiceHandler = async (type) => {
    await this.setState({
      activeTab: type,
    });
  };

  toggleModal = (modalType) => {
    this.setState({
      isModalOpen: !this.state.isModalOpen,
      modalType,
    });
  };

  render() {
    let { activeTab, isModalOpen, modalType } = this.state;
    return (
      <>
        <div className='container invoices-wrapper  mt-5'>
          <div className='card'>
            <div className='row invoice-header'>
              <div className='col-10'>
                <h3 className='card_heading'>Invitations</h3>
              </div>
              <div className='col-2'>
                <h5
                  className='col-10 btn btn-text button add_button button_invoices'
                  onClick={() => {
                    this.toggleModal(InviteParties);
                  }}
                >
                  <span>Invite Parties</span>
                </h5>
              </div>
            </div>
            <div className='dashboard-wrapper'>
              <div className='buttons-box'>
                <div className='toggle-buttons'>
                  <div
                    onClick={() => this.invoiceHandler('stagged')}
                    className={
                      activeTab === 'stagged'
                        ? 'active-button button-left '
                        : 'button-left '
                    }
                  >
                    Stagged
                  </div>
                  <div
                    onClick={() => this.invoiceHandler('sent')}
                    className={
                      activeTab === 'sent'
                        ? 'active-button button-left button-right '
                        : 'button-left button-right '
                    }
                  >
                    Sent
                  </div>
                </div>
              </div>
            </div>
            {activeTab === 'stagged' ? (
              <Stagged {...this.props} />
            ) : (
              <Sent {...this.props} />
            )}
          </div>
        </div>
        <CredoModal
          isOpen={isModalOpen}
          styles={{
            content: {
              width: '40%',
              height: 'max-content',
            },
          }}
          {...this.props}
          closeAction={() => this.toggleModal('')}
          RenderingComponent={modalType}
          toggleModal={this.toggleModal}
        />
      </>
    );
  }
}
