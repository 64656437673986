import * as creditScoreConstants from './CreditScoreCard.actions.constants';

const initialState = {
  creditInputData: {},
  creditBenchMarkData: {},
  creditRulesData: []
};

const creditScoreReducer = (state = initialState, action) => {
  let { type, data } = action;
  switch (type) {
    case creditScoreConstants.SET_INPUT_DATA:
      return { ...state, creditInputData: data };
    case creditScoreConstants.SET_BENCHMARK_DATA:
      return { ...state, creditBenchMarkData: data };
    case creditScoreConstants.SET_RULES_DATA:
      return { ...state, creditRulesData: data };
    default:
      return state;
  }
};

export default creditScoreReducer;
