import React, { Component } from 'react';
import { API_ESIGN } from '../../../../../utils/APIUrls';
import { POST } from '../../../../../utils/webAPI.service';
export class ESign extends Component {
  esign = () => {
    let { loanId, refresh } = this.props;
    POST(API_ESIGN(loanId))
      .then(({ data }) => {
        if (data?.code === 200) {
          alert('Esign link shared successfully');
          this.props.closeAction();
          refresh(loanId);
        } else {
          alert(data?.message);
        }
      })
      .catch((err) => {
        alert('Something went wrong');
        throw err;
      });
  };
  render() {
    return (
      <>
        <div className='modal_card services-wrapper'>
          <h3 className='modal_card-heading'>E Sign</h3>
          <div className='paragraph'>
            Are you sure you want to trigger an e-sign ?
          </div>
          <div className='row buttons_wrapper'>
            <button
              className='col-md-2 button cancel_button mr-4'
              onClick={() => this.props.closeAction()}
            >
              Cancel
            </button>
            <button className='col-md-2 button add_button' onClick={this.esign}>
              Confirm
            </button>
          </div>
        </div>
      </>
    );
  }
}
