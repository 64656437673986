import React, { Component } from 'react';
import { GET, POST } from '../../../../../utils/webAPI.service';
import { API_LOAN_STATUS } from '../../../../../utils/APIUrls';

export class LoanstatusandRoi extends Component {
  loanStatusandRoi = () => {
    let { loanId, refresh } = this.props;
    POST(API_LOAN_STATUS(loanId))
      .then(({ data }) => {
        if (data?.code === 200) {
          alert('Loan status fetched successfully');
          this.props.closeAction();
          refresh(loanId);
        } else {
          alert(data?.message);
        }
      })
      .catch((err) => {
        alert('Something went wrong');
        throw err;
      });
  };

  render() {
    return (
      <div className='modal_card services-wrapper'>
        <h3 className='modal_card-heading'>Loan Status & ROI Details</h3>
        <div className='paragraph'>
          Are you sure you want to fetch Loan Status & ROI Details?
        </div>
        <div className='row buttons_wrapper'>
          <button
            className='col-md-2 button cancel_button mr-4'
            onClick={this.props.closeAction}
          >
            Cancel
          </button>
          <button
            className='col-md-2 button add_button'
            onClick={this.loanStatusandRoi}
          >
            Confirm
          </button>
        </div>
      </div>
    );
  }
}
