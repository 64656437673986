import React, { Component, Fragment } from 'react';
import { androidArrowDropdownCircle } from 'react-icons-kit/ionicons/androidArrowDropdownCircle';
import Icon from 'react-icons-kit';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import * as config from './banking.config';
export default class CashFlowFlags extends Component {
  state = {
    showTransactionMismatch: false
  };
  render() {
    let { showTransactionMismatch } = this.state;
    let { dataTosendFlags } = this.props;
    let { flagsData } = dataTosendFlags || [];
    let clickHandler = e => {
      if (e === 'showTransactionMismatch') {
        this.setState({ showTransactionMismatch: !showTransactionMismatch });
      }
    };
    let TransactionMismatchdata = flagsData.transactionDetails;
    let UploadedFilesdata = flagsData.fileDetails;
    return (
      <Fragment>
        <div className='scrollbar__wrapper'>
          <div className='heading'>Bank Statement Red flags</div>
          {UploadedFilesdata?.length !== 0 ? (
            <div className='information__wrapper border-bottom'>
              <div className='sub-heading'>Uploaded files</div>
              <div className='uploaded-files-table'>
                <ReactTable
                  data={UploadedFilesdata}
                  style={{ border: 'none' }}
                  columns={config.UploadTableHeaders}
                  showPagination={false}
                  resizable={false}
                  minRows={0}
                />
              </div>
            </div>
          ) : (
            <div />
          )}
          {flagsData.nameAsPerBankStatement ? (
            <div className='information__wrapper border-bottom'>
              <div className='sub-heading'>Name Match</div>
              <div className='data'>
                <div className='info-columns'>
                  <div className='labels'>Name as per bank statement</div>
                  <div className='value'>
                    {flagsData.nameAsPerBankStatement}
                  </div>
                </div>
                <div className='info-columns'>
                  <div className='labels'>Entity name as per application</div>
                  <div className='value'>{flagsData.nameAsPerApplicant}</div>
                </div>
                <div className='info-columns'>
                  <div className='labels'>Match %</div>
                  <div className='value match'>-</div>
                </div>
              </div>
            </div>
          ) : null}
          {flagsData.accountAsPerSystem ? (
            <div className='information__wrapper border-bottom'>
              <div className='sub-heading'>Account Type</div>
              <div className='data'>
                <div className='info-columns'>
                  <div className='labels'>As per system</div>
                  <div className='value'>{flagsData.accountAsPerSystem}</div>
                </div>
                <div className='info-columns'>
                  <div className='labels'>As per applicant</div>
                  <div className='value'>{flagsData.accountAsPerApplicant}</div>
                </div>
              </div>
            </div>
          ) : (
            <div />
          )}
          {TransactionMismatchdata?.length !== 0 ? (
            <div
              id='transactionMismatch'
              className='information__wrapper border-bottom'
            >
              <Icon
                className={
                  showTransactionMismatch
                    ? 'green set-cursor-pointer rotate'
                    : 'green set-cursor-pointer'
                }
                icon={androidArrowDropdownCircle}
                size={40}
                onClick={() => clickHandler('showTransactionMismatch')}
              />
              <div className='sub-heading'>Suspicious Transactions</div>
              <div
                className={
                  showTransactionMismatch
                    ? 'uploaded-files-table'
                    : 'no-display'
                }
              >
                <div className='data'>
                  <div className='info-columns'>
                    <div className='labels'>Account Number</div>
                    <div className='value'>{dataTosendFlags.accountNumber}</div>
                  </div>
                </div>
                <ReactTable
                  data={TransactionMismatchdata}
                  style={{ border: 'none' }}
                  columns={config.TransactionTableHeaders}
                  showPagination={false}
                  resizable={false}
                  minRows={0}
                />
              </div>
            </div>
          ) : null}
        </div>
      </Fragment>
    );
  }
}
