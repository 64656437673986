import React, { Component } from 'react';
import { API_POST_MARGIN_RELEASE } from '../../../utils/APIUrls';
import { POST } from '../../../utils/webAPI.service';

export class ConfirmationModal extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <div className='modal_card services-wrapper'>
          <h3 className='modal_card-heading'>Margin Release</h3>
          <div className='paragraph'>
            Are you sure want to release margin of these invoices ?
          </div>
          <div className='row buttons_wrapper'>
            <button
              className='col-md-2 button cancel_button mr-4'
              onClick={() => this.props.closeAction()}
            >
              Cancel
            </button>
            <button
              className='col-md-2 button add_button'
              onClick={() => this.props.onReleaseMargin()}
            >
              Confirm
            </button>
          </div>
        </div>
      </>
    );
  }
}
