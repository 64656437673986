import React from 'react';
import { rupeeDecimalFormatter } from '../../../utils/utility';

const repaymentColumns = [
  {
    Header: 'Transaction Description',
    accessor: 'transactionType',
    width: 200,
  },
  {
    Header: 'Invoice No.',
    accessor: 'invoiceNumber',
    width: 190,
    Cell: ({ original }) => {
      let { invoiceNumber } = original || {};
      return (
        <>
          <div className='invoice_capsule'>{invoiceNumber}</div>
        </>
      );
    },
  },
  {},
  {
    Header: 'Credit',
    accessor: 'creditAmount',
    headerStyle: { textAlign: '' },
    width: 250,
    Cell: (props) => {
      return <div>{rupeeDecimalFormatter(props?.value, '₹')}</div>;
    },
  },

  { width: 60 },
  {
    Header: ' ',
    accessor: '',
    expander: true,
    Expander: ({ isExpanded, ...row }) => {
      return '';
    },
  },
];

export { repaymentColumns };
