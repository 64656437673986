import React from 'react';
import AppCard from '../../../../../components/AppCard';
import AppInput from '../../../../../components/AppInput';
import 'react-circular-progressbar/dist/styles.css';
import 'react-table/react-table.css';

const ApplicantDetailsCard = (props) => {
  let { summary } = props,
    {
      name,
      caseRole,
      age,
      bureauScore,
      ccScore,
      totalLoans,
      totalLiveLoans,
      bureauTag,
      totalClosedLoans,
    } = summary;
  return (
    <>
      <div className=' offer_wrapper live-loan-and-outstanding'>
        <div className='row main-row  '>
          <div className='col-md-2 main-row_inner'>
            Name
            <div className='text-bold'>{name ?? '-'}</div>
          </div>
          <div className='col-md-2 main-row_inner'>
            Case role
            <div className='text-bold'>{caseRole ?? '-'}</div>
          </div>
          <div className='col-md-2 main-row_inner'>
            Age
            <div className='text-bold'>{age ?? '-'}</div>
          </div>
          <div className='col-md-2 main-row_inner'>
            Bureau Score
            <div className='text-bold'>{bureauScore ?? '-'}</div>
          </div>
          <div className='col-md-2 main-row_inner'>
            CC Score
            <div className='text-bold'>{ccScore ?? '-'}</div>
          </div>
          <div className='col-md-2 main-row_inner'>
            Total Loans
            <div className='text-bold'>{totalLoans ?? '-'}</div>
          </div>
          <div className='col-md-2 main-row_inner'>
            Live Loans
            <div className='text-bold'>{totalLiveLoans ?? '-'}</div>
          </div>
          <div className='col-md-2 main-row_inner'>
            Closed Loans
            <div className='text-bold'>{totalClosedLoans ?? '-'}</div>
          </div>
          <div className='col-md-2 main-row_inner'>
            Bureau tag
            <div className='text-bold'>{bureauTag ?? '-'}</div>
          </div>
        </div>
      </div>
    </>
  );
};

// Remarks Card
const CreditBehaviourPersonalRemarks = () => {
  return (
    <>
      <AppCard className='remarks-wrapper' heading='Remarks'>
        <div className='remarks-wrapper-row row'>
          <div className='col'>
            <AppInput labelText=''></AppInput>
          </div>
        </div>
        {/* <Button className='remarks-btn' children='Edit'></Button>
        <Button className='remarks-btn' children='submit'></Button> */}
      </AppCard>
    </>
  );
};

export { ApplicantDetailsCard, CreditBehaviourPersonalRemarks };
