import React, { Component } from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import HeaderTop from '../../../components/HeaderTop';
import { activeDashboard } from '../../../store/actions';
import { Helmet } from 'react-helmet';

class AnchorNavs extends Component {
  clickHandle = (e, name) => {
    let { match, history } = this.props,
      { appId } = match.params;
    if (e.ctrlKey) {
      if ((e.ctrlKey || e.metaKey) && name === 'downstream') {
        window.open(`/anchor-dashboard/${appId}/downstream`);
      } else if ((e.ctrlKey || e.metaKey) && name === 'upstream') {
        window.open(`/anchor-dashboard/${appId}/upstream`);
      }
    } else if (e.button === 0) {
      if (name === 'downstream') {
        history.push(`/anchor-dashboard/${appId}/downstream`);
      } else if (name === 'upstream') {
        history.push(`/anchor-dashboard/${appId}/upstream`);
      }
    }
  };

  render() {
    let { active } = this.props;
    return (
      <div className='dashboard-wrapper'>
        <Helmet>
          <meta charSet='utf-8' />
          <title>Anchor Dashboard</title>
        </Helmet>
        <HeaderTop {...this.props} />
        <section className={'leadmanager-navs-wrapper'}>
          <Navbar>
            <Nav className='credit-navs  container' activeKey={active}>
              <Nav.Link
                eventKey='downstream'
                onClick={(e) => this.clickHandle(e, 'downstream')}
              >
                DOWNSTREAM
              </Nav.Link>
              <Nav.Link
                eventKey='upstream'
                onClick={(e) => this.clickHandle(e, 'upstream')}
              >
                UPSTREAM
              </Nav.Link>
            </Nav>
          </Navbar>
        </section>
      </div>
    );
  }
}

AnchorNavs.defaultProps = {
  active: '',
};

const mapStateToProps = ({ data }) => ({
  activeDashboard: data.activeDashboard,
});
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      activeDashboard,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(AnchorNavs);
