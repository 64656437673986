import * as interestAndChargesConstant from './interestAndCharges.actions.constants';

const initialState = {
  soaData: [],
  soaSummary: {},
  soaAdminSummary: {},
  limitAccountNumber: {},
  smaDetails: {},
};

const interestAndChargesReducer = (state = initialState, action) => {
  let { type, data } = action;
  switch (type) {
    case interestAndChargesConstant.SET_SOA:
      return { ...state, soaData: data };
    case interestAndChargesConstant.SET_SOA_SUMMARY:
      return { ...state, soaSummary: data };
    case interestAndChargesConstant.SET_LIMIT_ACCOUNT_NUMBER:
      return { ...state, limitAccountNumber: data };
    case interestAndChargesConstant.SET_SMA_DETAILS:
      return { ...state, smaDetails: data };
    case interestAndChargesConstant.SET_ADMIN_SOA_SUMMARY:
      return { ...state, soaAdminSummary: data };
    default:
      return state;
  }
};

export default interestAndChargesReducer;
