import { rupeeDecimalFormatter } from '../../../../utils/utility';

const invoiceSummaryColumns = [
  {
    Header: 'Month - Year',
    accessor: 'month',
    headerStyle: { padding: '1.5rem 1rem' },
    width: 110,
  },
  {
    Header: '# Invoices',
    accessor: 'count',
    headerStyle: {
      background: ' #4687F41A',
      padding: '1.5rem 1rem',
      borderRadius: '1rem 0rem 0rem 1rem',
    },
    Cell: (props) => {
      return <div>{props?.value}</div>;
    },
  },
  {
    Header: ' Invoicing Amount',
    accessor: 'amount',
    headerStyle: { background: ' #4687F41A', padding: '1.5rem 1rem' },
    Cell: (props) => {
      return <div>{rupeeDecimalFormatter(props?.value, '₹')}</div>;
    },
  },
  {
    Header: 'Average Invoice Value',
    accessor: 'avgAmount',
    headerStyle: {
      background: ' #4687F41A',
      padding: '1.5rem 1rem',
      borderRadius: '0rem 1rem 1rem 0rem',
    },
    Cell: (props) => {
      return <div>{rupeeDecimalFormatter(props?.value, '₹')}</div>;
    },
  },
];

const paymentSummaryColumns = [
  {
    Header: 'Month - Year',
    accessor: 'month',
    show: false,
    headerStyle: { padding: '1.5rem 1rem' },
  },
  {
    Header: '# Payments',
    accessor: 'count',
    headerStyle: {
      background: ' #4687F41A',
      padding: '1.5rem 1rem',
      borderRadius: '1rem 0rem 0rem 1rem',
    },
    Cell: (props) => {
      return <div>{props?.value}</div>;
    },
  },
  {
    Header: '  Payment Amount',
    accessor: 'amount',
    headerStyle: { background: ' #4687F41A', padding: '1.5rem 1rem' },
    Cell: (props) => {
      return <div>{rupeeDecimalFormatter(props?.value, '₹')}</div>;
    },
  },
  {
    Header: 'Average Payment Value',
    accessor: 'avgAmount',
    headerStyle: {
      background: ' #4687F41A',
      padding: '1.5rem 1rem',
      borderRadius: '0rem 1rem 1rem 0rem',
    },
    Cell: (props) => {
      return <div>{rupeeDecimalFormatter(props?.value, '₹')}</div>;
    },
  },
];

const returnSummaryColumns = [
  {
    Header: 'Month - Year',
    accessor: 'month',
    headerStyle: { padding: '1.5rem 1rem' },
    width: 110,
  },
  {
    Header: '# Payments',
    accessor: 'count',
    headerStyle: {
      background: '#FF7D1A1A',
      padding: '1.5rem 1rem',
      borderRadius: '1rem 0rem 0rem 1rem',
    },

    Cell: (props) => {
      return <div>{props?.value}</div>;
    },
  },
  {
    Header: '  Payment Amount',
    accessor: 'amount',
    headerStyle: { background: ' #FF7D1A1A', padding: '1.5rem 1rem' },
    Cell: (props) => {
      return <div>{rupeeDecimalFormatter(props?.value, '₹')}</div>;
    },
  },
  {
    Header: 'Average Payment Value',
    accessor: 'avgAmount',
    headerStyle: {
      background: ' #FF7D1A1A',
      padding: '1.5rem 1rem',
      borderRadius: '0rem 1rem 1rem 0rem',
    },
    Cell: (props) => {
      return <div>{rupeeDecimalFormatter(props?.value, '₹')}</div>;
    },
  },
];

const paymentCycleColumns = [
  {
    Header: 'Period(In Days) ',
    accessor: 'period',
  },
  {
    Header: '# Invoices %',
    accessor: 'paidInvPer',
    Cell: (props) => {
      return `${props.value} %`;
    },
  },
  {
    Header: 'Amount in %',
    accessor: 'paidAmountPer',
    Cell: (props) => {
      return `${props.value} %`;
    },
  },
];
const meanInvoicesColumns = [
  {
    Header: ' ',
    accessor: 'name',
    width: 110,
    Cell: (props) => {
      return <div style={{ color: '#4687F4' }}>{props.value} </div>;
    },
  },
  {
    Header: ' ',
    accessor: 'avgCount',
  },
  {
    Header: '',
    accessor: 'amount',
    Cell: (props) => {
      return <div>{rupeeDecimalFormatter(props?.value, '₹')}</div>;
    },
  },
  {
    Header: '',
    accessor: 'avgAmount',
    Cell: (props) => {
      return <div>{rupeeDecimalFormatter(props?.value, '₹')}</div>;
    },
  },
];

const meanPaymentsColumns = [
  {
    Header: ' ',
    accessor: 'name',
    show: false,
  },
  {
    Header: ' ',
    accessor: 'avgCount',
  },
  {
    Header: '',
    accessor: 'amount',
    Cell: (props) => {
      return <div>{rupeeDecimalFormatter(props?.value, '₹')}</div>;
    },
  },
  {
    Header: '',
    accessor: 'avgAmount',
    Cell: (props) => {
      return <div>{rupeeDecimalFormatter(props?.value, '₹')}</div>;
    },
  },
];

export {
  returnSummaryColumns,
  paymentCycleColumns,
  paymentSummaryColumns,
  invoiceSummaryColumns,
  meanPaymentsColumns,
  meanInvoicesColumns,
};
