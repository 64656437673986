import React, { Component } from 'react';
import AppCard from '../../../../../src/components/AppCard';
import Button from '../../../../components/Button/index';
import { Tabs, Tab } from 'react-bootstrap';
import editIcon from '../../../../../src/assets/images/editIcon.png';
import refresh from '../../../../../src/assets/images/refreshIcon.png';
import CheckBox from '../../../../components/CheckBox/CheckBox';
import { getGstFetch, getGstManualTurnover } from './gst.action';
import { getApplicationId } from '../../LeadManagementNavs/LeadManagementNavs.actions';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { API_POST_GST_MANUAL } from '../../../../utils/APIUrls';
import { POST } from '../../../../utils/webAPI.service';
import moment from 'moment';
export class GstManualUpdateModal extends Component {
  state = {
    filterSale: {
      salesMonthly: false,
      salesQuarterly: false,
    },
    filterPurchase: {
      purchaseMonthly: false,
      purchaseQuarterly: false,
    },
    salesDataMonthly: [],
    salesDataQuarterly: [],
    purchaseDataMonthly: [],
    purchaseDataQuarterly: [],
    disableData: false,
  };

  componentDidMount() {
    const financialYearStart = moment()
      .startOf('year')
      .month(new Date().getMonth());
    // Assuming the financial year starts from April (month 3)
    const previousMonths = [];

    for (let i = 1; i < 13; i++) {
      const month = financialYearStart.clone().subtract(i, 'months');
      previousMonths.push(month.format('MMMM YYYY')?.split(' ')?.join('-'));
    }
    const monthsArrayWithAmount = [];
    previousMonths?.reverse().forEach((monthVal) => {
      monthsArrayWithAmount.push({
        amount: null,
        monthCode: moment().month(monthVal).format('M'),
        month: monthVal,
      });
    });

    this.setState({
      salesDataMonthly: monthsArrayWithAmount,
      purchaseDataMonthly: monthsArrayWithAmount,
    });
    this.generatePreviousQuarters();
  }

  quarterMonthName = (monthName) => {
    const months = {
      January: 'Jan',
      February: 'Feb',
      March: 'Mar',
      April: 'April',
      May: 'May',
      June: 'June',
      July: 'July',
      August: 'Aug',
      September: 'Sept',
      October: 'Oct',
      November: 'Nov',
      December: 'Dec',
    };
    return months[monthName];
  };

  onSaleCheckboxChange = async (name) => {
    let { filterSale } = this.state;
    let prevValue = filterSale[name];
    filterSale[name] = !prevValue;
    for (const key in filterSale) {
      if (key !== name) {
        filterSale[key] = false;
      }
    }
    await this.setState({
      filterSale,
    });
  };

  onPurchaseCheckboxChange = async (name) => {
    let { filterPurchase } = this.state;
    let prevValue = filterPurchase[name];
    filterPurchase[name] = !prevValue;
    for (const key in filterPurchase) {
      if (key !== name) {
        filterPurchase[key] = false;
      }
    }
    await this.setState({
      filterPurchase,
    });
  };

  handleTabSelect = (key) => {
    this.setState({ key });
  };

  onGstChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value.toUpperCase()?.trim(),
    });
  };
  generatePreviousQuarters = async () => {
    const today = moment();
    const currentQuarter = Math.ceil((today.month() + 1) / 3);
    let previousQuarters = [];
    for (let i = 1; i < 5; i++) {
      const quarterStartMonth = (currentQuarter - i - 1) * 3;
      const quarterStartDate = moment()
        .month(quarterStartMonth)
        .startOf('month');
      const quarterEndDate = quarterStartDate.clone().endOf('quarter');
      const quarter = {
        amount: null,
        year: Number(quarterEndDate.format('YYYY')),
        monthCode: Number(quarterEndDate.format('M')),
        month: `${quarterStartDate.format(
          'MMMM YYYY'
        )} - ${quarterEndDate.format('MMMM YYYY')}`,
      };
      previousQuarters.push(quarter);
    }
    previousQuarters = previousQuarters.reverse();
    this.setState({
      salesDataQuarterly: previousQuarters,
      purchaseDataQuarterly: previousQuarters,
    });
  };

  validateGST = () => {
    const gstRegex = /^[0-9]{2}[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}[A-Za-z0-9]{3}$/;
    const { gstNumber } = this.state;
    const isValid = gstRegex.test(gstNumber);
    if (!isValid) {
      alert('Invalid GST number.');
      return false;
    }
  };

  gstSearchFilter = async () => {
    let {
      gstNumber,
      salesDataMonthly,
      purchaseDataMonthly,
      salesDataQuarterly,
      purchaseDataQuarterly,
    } = this.state;
    let salesDataMonthlyCopy = [...salesDataMonthly];
    let purchaseDataMonthlyCopy = [...purchaseDataMonthly];
    let salesDataQuarterlyCopy = [...salesDataQuarterly];
    let purchaseDataQuarterlyCopy = [...purchaseDataQuarterly];

    let { actions } = this.props;
    let { getGstFetch, getGstManualTurnover } = actions;
    if (gstNumber) {
      getGstFetch(gstNumber);
      await getGstManualTurnover(gstNumber);
    } else {
      alert('Please enter gst number');
      return;
    }

    this.validateGST(gstNumber);

    if (
      this.props.gstManualTunover.salesData.length > 0 ||
      this.props.gstManualTunover.purchaseData.length > 0
    ) {
      this.setState({
        disableData: true,
      });
    }
    if (this.props.gstManualTunover.type === 'Quarterly') {
      this.setState({
        filterPurchase: {
          purchaseQuarterly: true,
        },
        filterSale: {
          salesQuarterly: true,
        },
      });
    } else {
      this.setState({
        filterPurchase: {
          purchaseMonthly: true,
        },
        filterSale: {
          salesMonthly: true,
        },
      });
    }
    this.props.gstManualTunover?.salesData?.forEach((saleData) => {
      if (this.props.gstManualTunover?.salesData.length > 4) {
        if (saleData.amount) {
          salesDataMonthlyCopy = salesDataMonthlyCopy.map((stateSaleData) => {
            if (
              Number(stateSaleData.monthCode) === Number(saleData.monthCode)
            ) {
              return {
                ...stateSaleData,
                amount: saleData.amount,
              };
            } else {
              return stateSaleData;
            }
          });
        }
      } else {
        if (saleData.amount) {
          salesDataQuarterlyCopy = salesDataQuarterlyCopy.map(
            (stateSaleData) => {
              if (
                Number(stateSaleData.monthCode) === Number(saleData.monthCode)
              ) {
                return {
                  ...stateSaleData,
                  amount: saleData.amount,
                };
              } else {
                return stateSaleData;
              }
            }
          );
        }
      }
    });

    this.props.gstManualTunover?.purchaseData?.forEach((purchaseData) => {
      if (this.props.gstManualTunover?.purchaseData.length > 4) {
        if (purchaseData.amount) {
          purchaseDataMonthlyCopy = purchaseDataMonthlyCopy.map(
            (statePurchaseData) => {
              if (
                Number(statePurchaseData.monthCode) ===
                Number(purchaseData.monthCode)
              ) {
                return {
                  ...statePurchaseData,
                  amount: purchaseData.amount,
                };
              } else {
                return statePurchaseData;
              }
            }
          );
        }
      } else {
        if (purchaseData.amount) {
          purchaseDataQuarterlyCopy = purchaseDataQuarterlyCopy.map(
            (statePurchaseData) => {
              if (
                Number(statePurchaseData.monthCode) ===
                Number(purchaseData.monthCode)
              ) {
                return {
                  ...statePurchaseData,
                  amount: purchaseData.amount,
                };
              } else {
                return statePurchaseData;
              }
            }
          );
        }
      }
    });

    this.setState({
      salesDataMonthly: salesDataMonthlyCopy,
      purchaseDataMonthly: purchaseDataMonthlyCopy,
      salesDataQuarterly: salesDataQuarterlyCopy,
      purchaseDataQuarterly: purchaseDataQuarterlyCopy,
    });
  };

  onInputChange = (event, index, stateVar) => {
    const { value } = event.target;
    const numberedVal = Number(value);
    const parsedValue = parseFloat(value);
    if (isNaN(parsedValue) || parsedValue < 0) {
      this.setState((prevState) => ({
        [stateVar]: prevState[stateVar].map((item, i) =>
          i === index ? { ...item, amount: '' } : item
        ),
      }));
      return;
    }

    if (!isNaN(numberedVal)) {
      const stateVarCopy = [...this.state[stateVar]];
      stateVarCopy[index] = {
        ...stateVarCopy[index],
        amount: Number(value),
      };
      this.setState({ [stateVar]: stateVarCopy });
    }
  };

  onSubmit = (type) => {
    let { gstNumber, filterSale, filterPurchase } = this.state;
    let year = new Date().getFullYear();
    let { applicationId } = this.props;
    let sendData = {
      applicationNumber: applicationId,
      gstNumber,
      purchaseData: filterPurchase?.purchaseMonthly
        ? this.state.purchaseDataMonthly.map((el) => ({
            amount: Number(el.amount) || 0,
            monthCode: Number(el.monthCode),
            year: Number(el.month.split('-')[1]),
          }))
        : this.state.purchaseDataQuarterly.map((el) => ({
            amount: Number(el.amount) || 0,
            monthCode: Number(el.monthCode),
            year: el.year,
          })),
      salesData: filterSale?.salesMonthly
        ? this.state.salesDataMonthly.map((el) => ({
            amount: Number(el.amount) || 0,
            monthCode: Number(el.monthCode),
            year: Number(el.month.split('-')[1]),
          }))
        : this.state.salesDataQuarterly.map((el) => ({
            amount: Number(el.amount) || 0,
            monthCode: Number(el.monthCode),
            year: el.year,
          })),
      type,
      year,
    };
    POST(API_POST_GST_MANUAL, sendData).then((res) => {
      if (res?.data?.status === 'success') {
        if (gstNumber) {
          this.props.actions.getGstManualTurnover(gstNumber);
        } else {
          alert('Please enter GST number');
          return;
        }
        alert('Data saved succesfully');
      } else {
        alert(res?.data?.message);
      }
    });
  };

  render() {
    let {
      filters,
      salesDataMonthly,
      salesDataQuarterly,
      purchaseDataMonthly,
      purchaseDataQuarterly,
      disableData,
      filterSale,
      filterPurchase,
    } = this.state;
    let { gstSearchDetails, gstManualTunover } = this.props || {};
    let { pradr } = gstSearchDetails || {};
    let { addr } = pradr || {};
    let { totalPurchaseTurnover, totalSalesTurnOver, percentageVariation } =
      gstManualTunover || {};
    return (
      <div className='gst_manual_update_wrapper '>
        <div className='credit-behaviour-wrapper'>
          <div className=' box-wrapper'>
            <div className='box-header'>
              <h3>Manual Gst Update</h3>
            </div>
            <div>
              <AppCard
                heading='Entity details as per GstIn'
                className={'border-bottom'}
              >
                <div className='row'>
                  <div className='col-8'>
                    <div className='row '>
                      <div className='col-3 pt-4 ml-4'>
                        <div className='mt-2 gst-details'>
                          Enter Gst Details
                        </div>
                      </div>
                      <div className='col-4'>
                        <input
                          className='input mt-3'
                          placeholder='Enter Gst Number'
                          value={this.state.gstNumber}
                          name='gstNumber'
                          onChange={this.onGstChange}
                        />
                      </div>
                      <div className='col-4 pt-4'>
                        <Button onClick={this.gstSearchFilter}>Search</Button>
                      </div>
                    </div>
                  </div>
                </div>
                {Object.keys(gstSearchDetails).length !== 0 && (
                  <div className='row mt-5 ml-5'>
                    <div className='col-md-4 mt-5'>
                      <div className='heading'>Legal Name Of Buisness</div>
                      <div className=' value mt-3'>
                        {gstSearchDetails?.lgnm || '-'}
                      </div>
                    </div>
                    <div className='col-md-4 mt-5'>
                      <div className='heading'>Trade Name</div>
                      <div className=' value mt-3'>
                        {gstSearchDetails?.tradeNam || '-'}
                      </div>
                    </div>
                    <div className='col-md-4 mt-5'>
                      <div className='heading'>
                        Effective Date of Registration
                      </div>
                      <div className='value mt-3'>
                        {gstSearchDetails?.rgdt || '-'}
                      </div>
                    </div>
                    <div className='col-md-4 mt-5'>
                      <div className='heading'>Constitution of Business</div>
                      <div className=' value mt-3'>
                        {gstSearchDetails?.ctb || '-'}
                      </div>
                    </div>
                    <div className='col-md-4 mt-5'>
                      <div className='heading'>GSTIN/UIN Status</div>
                      <div className=' value mt-3'>
                        {gstSearchDetails?.sts || '-'}
                      </div>
                    </div>
                    <div className='col-md-4 mt-5'>
                      <div className='heading'>Taxpayer Type</div>
                      <div className=' value mt-3'>
                        {gstSearchDetails?.dty || '-'}
                      </div>
                    </div>
                    <div className='col-md-4 mt-5'>
                      <div className='heading'>Registered Address</div>
                      <div className='value mt-3'>
                        <span> {addr?.bnm || '-'}</span>
                        <span> {addr?.bno || '-'}</span>
                        <span> {addr?.st || '-'}</span>
                        <div>{addr?.city || '-'}</div>
                        <span> {addr?.loc || '-'}</span>
                        <span> {addr?.stcd || '-'}</span>
                        <span> {addr?.pncd || '-'}</span>
                      </div>
                    </div>
                  </div>
                )}
              </AppCard>
            </div>
          </div>
          {Object.keys(gstSearchDetails).length !== 0 && (
            <div className='card card-shadow'>
              <div className='box-header ml-5 mt-4'>
                <h4>GST Sales and Purchase Data as per GST Returns</h4>
              </div>
              <Tabs
                defaultActiveKey='Sales Data'
                id='all-tabs'
                onSelect={this.handleTabSelect}
              >
                <Tab.Content eventKey='Sales Data' title={'Sales Data'}>
                  <div className='d-flex'>
                    <CheckBox
                      labelColor={'#474747'}
                      checkboxColor={'#474747'}
                      htmlFor={'sales-monthly'}
                      labelClass={'mr-5'}
                      label={'Monthly'}
                      checked={filterSale.salesMonthly}
                      onChange={() => this.onSaleCheckboxChange('salesMonthly')}
                    />
                    <CheckBox
                      labelColor={'#474747'}
                      checkboxColor={'#474747'}
                      htmlFor={'sales-quarterly'}
                      label={'Quarterly'}
                      checked={filterSale.salesQuarterly}
                      onChange={() =>
                        this.onSaleCheckboxChange('salesQuarterly')
                      }
                    />
                  </div>
                  {filterSale.salesMonthly === true ? (
                    <div className='col-5 summary-card '>
                      <div className='row'>
                        <div className='col-5'>
                          <div className='heading ml-5'>Month</div>
                        </div>
                        <div className='col-4'>
                          <div className='heading ml-5'>Value</div>
                        </div>
                        <div className='col-3'>
                          <div className='d-flex justify-content-between'>
                            <img src={refresh} alt='refresh' className='' />
                            <img
                              src={editIcon}
                              alt='Edit Icon'
                              onClick={() =>
                                this.setState((prevState) => ({
                                  ...prevState,
                                  disableData: !prevState.disableData,
                                }))
                              }
                            />
                          </div>
                        </div>
                      </div>
                      <div class='card border-top '>
                        <div class='card-body month'>
                          {salesDataMonthly?.map(({ amount, month }, index) => {
                            return (
                              <div
                                className='row '
                                key={`salesMonthly_${index}`}
                              >
                                <div className='col-6 d-flex justify-content-between mt-3'>
                                  {month}
                                </div>
                                <div className='col-6 '>
                                  <input
                                    className='input'
                                    type='number'
                                    onChange={(e) =>
                                      this.onInputChange(
                                        e,
                                        index,
                                        'salesDataMonthly'
                                      )
                                    }
                                    value={amount}
                                    disabled={disableData ? true : false}
                                    min={0}
                                  />
                                </div>
                              </div>
                            );
                          })}
                          <div className='row d-flex justify-content-center mt-5'>
                            <Button
                              onClick={() => this.onSubmit('Monthly')}
                              disabled={disableData ? true : false}
                            >
                              Submit
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : filterSale.salesQuarterly ? (
                    <div className='col-7 summary-card '>
                      <div className='row'>
                        <div className='col-5'>
                          <div className='heading ml-5'>Month</div>
                        </div>
                        <div className='col-4'>
                          <div className='heading ml-5'>Value</div>
                        </div>
                        <div className='col-3'>
                          <div className='d-flex justify-content-between'>
                            <div className=''>
                              <img src={refresh} alt='refresh'></img>
                            </div>
                            <div>
                              <img
                                src={editIcon}
                                alt='Edit icon'
                                onClick={() =>
                                  this.setState((prevState) => ({
                                    ...prevState,
                                    disableData: !prevState.disableData,
                                  }))
                                }
                              ></img>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class='card   border-top '>
                        <div class='card-body month'>
                          {salesDataQuarterly?.map(
                            ({ amount, month }, index) => {
                              return (
                                <div
                                  className='mt-2'
                                  key={`salesQuarterly_${index}`}
                                >
                                  <div className='row '>
                                    <div className='col-6 d-flex justify-content-between mt-3'>
                                      {month}
                                    </div>
                                    <div className='col-6'>
                                      <input
                                        className='input'
                                        type='number'
                                        onChange={(e) =>
                                          this.onInputChange(
                                            e,
                                            index,
                                            'salesDataQuarterly'
                                          )
                                        }
                                        value={amount}
                                        disabled={disableData ? true : false}
                                      />
                                    </div>
                                  </div>
                                </div>
                              );
                            }
                          )}

                          <div className='row d-flex justify-content-center mt-5'>
                            <Button
                              onClick={() => this.onSubmit('Quarterly')}
                              disabled={disableData ? true : false}
                            >
                              Submit
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                </Tab.Content>
                <Tab.Content title={'Purchase Data'} eventKey='Purchase Data'>
                  <div className={'col-md-9'}>
                    <ul>
                      <li className='mr-5'>
                        <CheckBox
                          labelColor={'#474747'}
                          checkboxColor={'#474747'}
                          htmlFor={'filter6'}
                          inputClass={''}
                          labelClass={''}
                          label={'Monthly'}
                          checked={filterPurchase.purchaseMonthly}
                          onChange={() =>
                            this.onPurchaseCheckboxChange('purchaseMonthly')
                          }
                        />
                      </li>
                      <li>
                        <CheckBox
                          labelColor={'#474747'}
                          checkboxColor={'#474747'}
                          htmlFor={'filter6'}
                          inputClass={''}
                          labelClass={''}
                          label={'Quarterly'}
                          checked={filterPurchase.purchaseQuarterly}
                          onChange={() =>
                            this.onPurchaseCheckboxChange('purchaseQuarterly')
                          }
                        />
                      </li>
                    </ul>
                  </div>

                  {filterPurchase?.purchaseMonthly ? (
                    <div className='col-5 summary-card '>
                      <div className='row'>
                        <div className='col-5'>
                          <div className='heading ml-5'>Month</div>
                        </div>
                        <div className='col-4'>
                          <div className='heading ml-5'>Value</div>
                        </div>
                        <div className='col-3'>
                          <div className='d-flex justify-content-between'>
                            <div>
                              <img src={refresh} alt='Refresh Icon' />
                            </div>
                            <div>
                              <img
                                src={editIcon}
                                alt='Edit Icon'
                                onClick={() =>
                                  this.setState((prevState) => ({
                                    ...prevState,
                                    disableData: !prevState.disableData,
                                  }))
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class='card  border-top '>
                        <div class='card-body  month'>
                          {purchaseDataMonthly?.map(
                            ({ month, amount }, index) => {
                              return (
                                <div
                                  className='row '
                                  key={`purchaseMonthly_${index}`}
                                >
                                  <div className='col-6 d-flex justify-content-between mt-3'>
                                    {month}
                                  </div>
                                  <div className='col-6'>
                                    <input
                                      className='input'
                                      type='number'
                                      onChange={(e) =>
                                        this.onInputChange(
                                          e,
                                          index,
                                          'purchaseDataMonthly'
                                        )
                                      }
                                      value={amount}
                                      disabled={disableData ? true : false}
                                    />
                                  </div>
                                </div>
                              );
                            }
                          )}
                          <div className='row d-flex justify-content-center mt-5'>
                            <Button
                              onClick={() => this.onSubmit('Monthly')}
                              disabled={disableData ? true : false}
                            >
                              Submit
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : filterPurchase.purchaseQuarterly ? (
                    <div className='col-7 summary-card '>
                      <div className='row'>
                        <div className='col-5'>
                          <div className='heading ml-5'>Month</div>
                        </div>
                        <div className='col-4'>
                          <div className='heading ml-5'>Value</div>
                        </div>
                        <div className='col-3'>
                          <div className='d-flex justify-content-between'>
                            <img src={refresh} alt='Reresh' />
                            <img
                              src={editIcon}
                              alt='Edit Icon'
                              onClick={() =>
                                this.setState((prevState) => ({
                                  ...prevState,
                                  disableData: !prevState.disableData,
                                }))
                              }
                            />
                          </div>
                        </div>
                      </div>

                      <div class='card  border-top '>
                        <div class='card-body month'>
                          {purchaseDataQuarterly?.map(
                            ({ month, amount }, index) => {
                              return (
                                <div
                                  className='row '
                                  key={`purchaseQuarterly_${index}`}
                                >
                                  <div className='col-6 d-flex justify-content-between'>
                                    {month}
                                  </div>
                                  <div className='col-6'>
                                    <input
                                      className='input'
                                      type='number'
                                      onChange={(e) =>
                                        this.onInputChange(
                                          e,
                                          index,
                                          'purchaseDataQuarterly'
                                        )
                                      }
                                      value={amount}
                                      disabled={disableData ? true : false}
                                    />
                                  </div>
                                </div>
                              );
                            }
                          )}

                          <div className='row d-flex justify-content-center mt-5'>
                            <Button
                              onClick={() => this.onSubmit('Quarterly')}
                              disabled={disableData ? true : false}
                            >
                              Submit
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                </Tab.Content>
              </Tabs>
            </div>
          )}

          {/* 3rd card */}
          {Object.keys(gstSearchDetails).length !== 0 && (
            <div className=' box-wrapper mt-5'>
              <AppCard heading='Summary' className={'border-bottom'}>
                <div className='d-flex justify-content-between'>
                  <div className='col-5 summary-card margin-left ml-5'>
                    <h4 className='ml-5'>Monthly / Quarterly Sales Anaylsis</h4>
                    <div class='card'>
                      <div class='card-body'>
                        <div className='column'>
                          <div className=' d-flex flex-row mt-3'>
                            <div className='col-6 mt-3 label'>
                              Total Sales Return
                            </div>
                            <div className='col-6'>
                              <div className='input-container'>
                                <div className='rupee-icon'>&#x20B9;</div>
                                <input
                                  className='input'
                                  value={totalSalesTurnOver}
                                  disabled
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='column'>
                          <div className=' d-flex flex-row mt-3'>
                            <div className='col-6 mt-3 '>
                              Monthly/Quarterly Average Sales
                            </div>
                            <div className='col-6'>
                              <div className='input-container'>
                                <div className='rupee-icon'>&#x20B9;</div>
                                <input
                                  className='input'
                                  value={gstManualTunover?.averageMonthlySales}
                                  disabled
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='column'>
                          <div className=' d-flex flex-row mt-3'>
                            <div className='col-6   mt-3 label'>
                              Percentage Variation
                            </div>
                            <div className='col-6'>
                              <input
                                className='input'
                                value={percentageVariation}
                                disabled
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-5 summary-card margin-right mr-5'>
                    <h4 className='ml-2'>
                      Monthly / Quarterly Purchase Analysis
                    </h4>
                    <div class='card'>
                      <div class='card-body'>
                        <div className='column'>
                          <div className=' d-flex flex-row mt-3'>
                            <div className='col-6 mt-3 label'>
                              Total Purchase Return
                            </div>
                            <div className='col-6'>
                              <div className='input-container'>
                                <div className='rupee-icon'>&#x20B9;</div>
                                <input
                                  className='input'
                                  value={totalPurchaseTurnover}
                                  disabled
                                ></input>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='column'>
                          <div className=' d-flex flex-row mt-3'>
                            <div className='col-6 mt-3 '>
                              Monthly/Quarterly Average Purchase
                            </div>
                            <div className='col-6'>
                              <div className='input-container'>
                                <div className='rupee-icon'>&#x20B9;</div>
                                <input
                                  className='input'
                                  disabled
                                  value={
                                    gstManualTunover?.averageMonthlyPurchases
                                  }
                                ></input>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='column'>
                          <div className=' d-flex flex-row mt-3'>
                            <div className='col-6  mt-3 label'>
                              Percentage Variation
                            </div>
                            <div className='col-6'>
                              <input
                                className='input'
                                disabled
                                value={percentageVariation}
                              ></input>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </AppCard>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ gstReducer, leadManagementNavsReducer }) => ({
  gstSearchDetails: gstReducer?.gstSearchDetails,
  applicationId: leadManagementNavsReducer?.applicationId,
  gstManualTunover: gstReducer?.gstManualTunover,
});
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      getGstManualTurnover,
      getGstFetch,
      getApplicationId,
    },
    dispatch
  ),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GstManualUpdateModal);
