import React from 'react';
import AppCard from '../../../../../components/AppCard';
const CreditInquiries = (props) => {
  let { data } = props,
    {
      allEnquiriesCount,
      assetCreationEnquiriesCount,
      businessFundingEnquiriesCount,
      consumptionEnquiriesCount,
    } = data || {};
  return (
    <>
      <AppCard
        className='personal_wrapper-row'
        heading='Credit Enquiries in Last 12 Months'
        bgcolor='#ECF9F1'
      >
        <div className={'box-content'}>
          <ul className={'list-unstyled'}>
            <li className='col-3'>
              <label className='text-secondary'>ALL Loans</label>
              <span>{allEnquiriesCount}</span>
            </li>
            <li className='col-3'>
              <label className='text-secondary'>Asset Creation</label>
              <span>{assetCreationEnquiriesCount}</span>
            </li>
            <li className='col-3'>
              <label className='text-secondary'>Business Funding</label>
              <span>{businessFundingEnquiriesCount}</span>
            </li>
            <li className='col-3'>
              <label className='text-secondary'>Consumption</label>
              <span>{consumptionEnquiriesCount}</span>
            </li>
          </ul>
        </div>
      </AppCard>
    </>
  );
};

export default CreditInquiries;
