import React, { Component } from 'react';
import { API_POST_ACTIVE_LIMIT } from '../../../utils/APIUrls';
import { POST } from '../../../utils/webAPI.service';

export default class ConfigureConfirmation extends Component {
  onSkip = async () => {
    const { actions, match } = this.props.propsData;
    actions.loaderModal(true);
    let { loanId, userId } = match?.params;
    const dataToSend = {
      loanId,
      userId,
      multiValueCriteriaWiseTransactionRules: null,
      singleValueTransactionRules: null,
      limitAccountNumber: null,
    };
    POST(API_POST_ACTIVE_LIMIT, dataToSend)
      .then(async ({ data }) => {
        if (data?.code === 200) {
          this.props.closeAction();
          await this.props.getLimitAccountNumber(userId);
          await this.props.getLimitSummary(userId);
        } else if (data.code === 999) {
          alert(data?.message);
          this.props.closeAction();
        } else {
          alert(data?.message);
          this.props.closeAction();
        }
      })
      .catch((err) => {
        console.error(err);
        alert('Something went wrong');
        this.props.closeAction();
      })
      .finally(() => {
        actions.loaderModal(false);
        this.props.closeAction();
      });
    this.props.closeAction();
  };

  onConfigure = () => {
    this.props.onConfigure();
  };
  render() {
    return (
      <div className='configure-transaction-wrapper'>
        <div className='heading'>Configure Transaction Rule</div>
        <div className='body'>
          <div className='confirmation-text'>
            Do you want to Configure/Skip ?
          </div>
          <div className='buttons_wrapper'>
            <button className='add_button' onClick={this.onConfigure}>
              Configure
            </button>
            <button className='cancel_button ml-4' onClick={this.onSkip}>
              Skip
            </button>
          </div>
        </div>
      </div>
    );
  }
}
