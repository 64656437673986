import React from 'react';
import AppCard from '../../../../../components/AppCard';
import { rupeeCommaFormatter } from '../../../../../utils/utility';

const LoanRepaymentSummaryCard = (props) => {
  let { data } = props,
    {
      assetCreationLoanHistory,
      businessFundingLoanHistory,
      consumtionLoanHistory,
    } = data || {};
  return (
    <>
      <AppCard
        className=' loan-repayment-summary  container'
        heading='Category Wise Loan Repayment Summary'
      >
        <div className='personal_wrapper-row'>
          <AppCard
            className='personal_wrapper-row '
            heading='Loan Repayment summary-Asset Creation'
            bgcolor='#ECF9F1'
          >
            <div className={'box-content'}>
              <ul className={'list-unstyled'}>
                <li>
                  <label className='text-secondary'>Asset Creation Loan</label>
                  <span>{assetCreationLoanHistory?.totalLoans}</span>
                </li>
                <li>
                  <label className='text-secondary'>&gt; 30 DPD Observed</label>
                  <span>{assetCreationLoanHistory?.dpdObserved}</span>
                </li>
                <li>
                  <label className='text-secondary'>Highest Loan</label>
                  <span>
                    ₹{' '}
                    {rupeeCommaFormatter(assetCreationLoanHistory?.highestLoan)}
                  </span>
                </li>
                <li>
                  <label className='text-secondary'>
                    Total Current Outstanding
                  </label>
                  <span>
                    ₹{' '}
                    {rupeeCommaFormatter(
                      assetCreationLoanHistory?.currentOustanding
                    )}
                  </span>
                </li>
                <li>
                  <label className='text-secondary'>
                    Total Loan Taken in last 6 months
                  </label>
                  <span>
                    {assetCreationLoanHistory?.loanAmountInLast6Months}
                  </span>
                </li>
                <li>
                  <label className='text-secondary'>First Loan Taken On</label>
                  <span>{assetCreationLoanHistory?.firstLoanDate}</span>
                </li>
                <li>
                  <label className='text-secondary'>
                    Most recent Loan Taken On
                  </label>
                  <span>{assetCreationLoanHistory?.lastLoanDate}</span>
                </li>
              </ul>
            </div>
          </AppCard>
          <AppCard
            className='personal_wrapper-row '
            heading='Loan Repayment summary-Buisness Funding'
            bgcolor='#ECF9F1'
          >
            <div className={'box-content'}>
              <ul className={'list-unstyled'}>
                <li>
                  <label className='text-secondary'>Business Funding</label>
                  <span>{businessFundingLoanHistory?.totalLoans}</span>
                </li>
                <li>
                  <label className='text-secondary'>&gt; 30 DPD Observed</label>
                  <span>{businessFundingLoanHistory?.dpdObserved}</span>
                </li>
                <li>
                  <label className='text-secondary'>Highest Loan</label>
                  <span>
                    ₹{' '}
                    {rupeeCommaFormatter(
                      businessFundingLoanHistory?.highestLoan
                    )}
                  </span>
                </li>
                <li>
                  <label className='text-secondary'>
                    Total Current Outstanding
                  </label>
                  <span>
                    ₹{' '}
                    {rupeeCommaFormatter(
                      businessFundingLoanHistory?.currentOustanding
                    )}
                  </span>
                </li>
                <li>
                  <label className='text-secondary'>
                    Total Loan Taken in last 6 months
                  </label>
                  <span>
                    {businessFundingLoanHistory?.loanAmountInLast6Months}
                  </span>
                </li>
                <li>
                  <label className='text-secondary'>First Loan Taken On</label>
                  <span>{businessFundingLoanHistory?.firstLoanDate}</span>
                </li>
                <li>
                  <label className='text-secondary'>
                    Most recent Loan Taken On
                  </label>
                  <span>{businessFundingLoanHistory?.lastLoanDate}</span>
                </li>
              </ul>
            </div>
          </AppCard>
          <AppCard
            className='personal_wrapper-row'
            heading='Loan Repayment summary-Consumption'
            bgcolor='#ECF9F1'
          >
            <div className={'box-content'}>
              <ul className={'list-unstyled'}>
                <li>
                  <label className='text-secondary'>Consumption</label>
                  <span>{consumtionLoanHistory?.totalLoans}</span>
                </li>
                <li>
                  <label className='text-secondary'>&gt; 30 DPD Observed</label>
                  <span>{consumtionLoanHistory?.dpdObserved}</span>
                </li>
                <li>
                  <label className='text-secondary'>Highest Loan</label>
                  <span>
                    ₹ {rupeeCommaFormatter(consumtionLoanHistory?.highestLoan)}
                  </span>
                </li>
                <li>
                  <label className='text-secondary'>
                    Total Current Outstanding
                  </label>
                  <span>
                    ₹{' '}
                    {rupeeCommaFormatter(
                      consumtionLoanHistory?.currentOustanding
                    )}
                  </span>
                </li>
                <li>
                  <label className='text-secondary'>
                    Total Loan Taken in last 6 months
                  </label>
                  <span>{consumtionLoanHistory?.loanAmountInLast6Months}</span>
                </li>
                <li>
                  <label className='text-secondary'>First Loan Taken On</label>
                  <span>{consumtionLoanHistory?.firstLoanDate}</span>
                </li>
                <li>
                  <label className='text-secondary'>
                    Most recent Loan Taken On
                  </label>
                  <span>{consumtionLoanHistory?.lastLoanDate}</span>
                </li>
              </ul>
            </div>
          </AppCard>
        </div>
      </AppCard>
    </>
  );
};
export default LoanRepaymentSummaryCard;
