import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Button from '../../../../components/Button';
import { API_LENDER_SHARED } from '../../../../utils/APIUrls';
import { POST } from '../../../../utils/webAPI.service';
import { formatString } from '../../../../utils/utility';

export default class DocumentBox extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      searchType: '',
    };
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  triggerFile = () => {
    const { docType, loanId } = this.props;
    const { searchType } = this.state;

    if (!searchType) {
      alert('Please select a file type before confirming.');
      return;
    }

    POST(API_LENDER_SHARED(loanId, docType, searchType))
      .then(({ data }) => {
        if (data?.code === 200) {
          alert('File shared successfully');
        } else {
          alert(data?.message || 'Failed to share the file.');
        }
      })
      .catch((err) => {
        alert('Something went wrong. Please try again.');
        console.error(err);
      });
  };

  render() {
    const { labelHead, type = [], uniqueId } = this.props;
    return (
      <div className='document-box' id={uniqueId}>
        <div className='label1'>
          <p>{formatString(labelHead) || '-'}</p>
        </div>

        <div className='w-100 m-3'>
          <select
            className='select-box'
            name='searchType'
            onChange={this.handleChange}
            value={this.state?.searchType}
            required
          >
            <option value='' disabled>
              Please Select a file .
            </option>
            {type.map((el, index) => (
              <option value={el} key={index}>
                {formatString(el)}
              </option>
            ))}
          </select>
        </div>

        <Button className='m-3 p-3 px-5' onClick={this.triggerFile}>
          Confirm
        </Button>
      </div>
    );
  }
}
