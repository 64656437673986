import * as allInvoicesConstant from './allInvoices.action.constant';
const initialState = {
  invoicesListData: {},
  invoicesSummary: {},
  eInvoices: {},
};

const AllInvoicesReducer = (state = initialState, action) => {
  const { type, data } = action;
  switch (type) {
    case allInvoicesConstant.SET_INVOICES_LIST:
      return { ...state, invoicesListData: data };
    case allInvoicesConstant.SET_INVOICES_SUMMARY:
      return { ...state, invoicesSummary: data };
    case allInvoicesConstant.SET_EINVOICE_FILE:
      return { ...state, eInvoices: data };
    default:
      return state;
  }
};
export default AllInvoicesReducer;
