import React, { Component } from 'react';
import { API_DOC_LIST } from '../../../../../utils/APIUrls';
import { POST } from '../../../../../utils/webAPI.service';
import Documents from '../Documents';
export class ShareDocuments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      documentList: [],
    };
  }

  componentDidMount = () => {
    let { loanId } = this.props;
    POST(API_DOC_LIST(loanId))
      .then(({ data }) => {
        if (data?.code === 200) {
          this.setState({
            documentList: data?.data,
          });
        } else {
          alert(data?.message);
        }
      })
      .catch((err) => {
        alert('Something went wrong');
        throw err;
      });
  };

  render() {
    let { loanId } = this.props;

    return (
      <>
        <div className='modal_card services-wrapper'>
          <h3 className='modal_card-heading'>Share Document with Lenders</h3>
          <Documents documentList={this.state.documentList} loanId={loanId} />
        </div>
      </>
    );
  }
}
