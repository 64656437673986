import React, { Component } from 'react';
import ReactTable from 'react-table';
import SearchIcon from '../../../../assets/images/SearchIcon.svg';
import { getActivePartiesList } from './activeParties.action';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  excelDownload,
  rupeeDecimalFormatter,
} from '../../../../utils/utility';
import ExcelIcon from '../../../../assets/images/excel.svg';
import Button from '../../../../components/Button';
import {
  API_ACTIVE_PARTIES_EXCEL,
  API_INVOICE_FAILED_EXCEL,
} from '../../../../utils/APIUrls';
class ActiveParties extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 1,
      pageNumber: 1,
      searchBy: '',
      searchValue: '',
    };
  }

  componentDidMount = () => {
    let { match, actions } = this.props,
      { anchorId } = match.params;
    let { pageNumber, searchBy, searchValue } = this.state;
    const { getActivePartiesList } = actions;
    getActivePartiesList(pageNumber, searchBy, searchValue, anchorId);
  };

  onResetFilter = () => {
    let { match, actions } = this.props,
      { anchorId } = match.params;
    const { getActivePartiesList } = actions;
    this.setState(
      {
        currentPage: 1,
        pageNumber: 1,
        searchBy: '',
        searchValue: '',
      },
      () => getActivePartiesList(1, '', '', anchorId) //SearchBy and searchValue should be '' and pageNumber is 1 .
    );
  };

  getPageData = async (type, totalPages) => {
    let { match, actions } = this.props,
      { anchorId } = match.params;
    let { pageNumber, searchBy, searchValue } = this.state;
    const { getActivePartiesList } = actions;
    let updatedPageNumber = pageNumber;
    if (type === 'next' && pageNumber < totalPages) {
      updatedPageNumber = pageNumber + 1;
    } else if (type === 'prev' && pageNumber > 1) {
      updatedPageNumber = pageNumber - 1;
    }
    await this.setState({ pageNumber: updatedPageNumber });
    getActivePartiesList(updatedPageNumber, searchBy, searchValue, anchorId);
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value?.trim(),
    });
  };

  onSearch = async (e) => {
    e.preventDefault();
    let { match, actions } = this.props,
      { anchorId } = match.params;
    let { pageNumber, searchBy, searchValue } = this.state;
    const { getActivePartiesList } = actions;
    await this.setState({
      pageNumber: 1,
    });
    if (searchBy === '') {
      alert('Please select search type');
      return;
    }
    getActivePartiesList(pageNumber, searchBy, searchValue, anchorId);
  };

  rowClicked = (state, rowInfo, column) => {
    let { match, history } = this.props,
      { anchorId } = match.params;
    return {
      onClick: () => {
        history.push(
          `/anchor/${anchorId}/supplier/active-parties/${rowInfo?.original?.userId}`
        );
      },
    };
  };

  activeExcelDownload = async () => {
    let { match } = this.props,
      { anchorId } = match.params;
    let sendData = {};
    excelDownload(
      API_ACTIVE_PARTIES_EXCEL,
      anchorId,
      sendData,
      'active_parties_excel'
    );
  };

  render() {
    let { activePartiesData } = this.props || {};
    let { pageNumber } = this.state;
    let { totalPages } = activePartiesData || {};
    const BUYER_LIMIT_STATUS = {
      ACTIVE: 'Active',
      ON_HOLD: 'On Hold',
      INACTIVE: 'Inactive',
      BLOCKED: 'Blocked',
    };
    const activePartiesColumn = [
      {
        Header: 'Party Name',
        accessor: 'partyName',
        width: 280,
        Cell: (props) => {
          let { original } = props;
          let { partyName } = original;
          return <>{partyName || '-'}</>;
        },
      },

      {
        Header: 'Mobile Number',
        accessor: 'mobileNumber',
        Cell: (props) => {
          let { original } = props,
            { mobileNumber } = original;
          return <>{mobileNumber || '-'}</>;
        },
      },
      {
        Header: 'Limit Approved',
        accessor: 'limitApproved',
        Cell: (props) => {
          let { original } = props,
            { limitApproved } = original;
          return <>{rupeeDecimalFormatter(limitApproved, '₹')}</>;
        },
      },
      {
        Header: 'Balance Limit',
        accessor: 'balanceLimit',
        Cell: (props) => {
          let { original } = props,
            { balanceLimit } = original;
          return <>{rupeeDecimalFormatter(balanceLimit, '₹')}</>;
        },
      },
      {
        Header: 'Used Limit',
        accessor: 'usedLimit',
        Cell: (props) => {
          let { original } = props,
            { usedLimit } = original;
          return <>{rupeeDecimalFormatter(usedLimit, '₹')}</>;
        },
      },
      {
        Header: () => (
          <>
            <div className='ml-4'>Limit Status</div>
          </>
        ),
        accessor: 'limitStatus',
        Cell: (props) => {
          let { original } = props,
            { limitStatus } = original;
          return (
            <>
              {limitStatus === BUYER_LIMIT_STATUS.ACTIVE ? (
                <span
                  className='capsule ml-4 capsule_new_green'
                  style={{ fontSize: '16px' }}
                >
                  Active
                </span>
              ) : limitStatus === BUYER_LIMIT_STATUS.BLOCKED ? (
                <span
                  className='capsule ml-4 capsule_red'
                  style={{ fontSize: '16px' }}
                >
                  Blocked
                </span>
              ) : limitStatus === BUYER_LIMIT_STATUS.ON_HOLD ? (
                <span
                  className='capsule ml-4 capsule_grey'
                  style={{ fontSize: '16px' }}
                >
                  On-Hold
                </span>
              ) : limitStatus === BUYER_LIMIT_STATUS.INACTIVE ? (
                <span
                  className='capsule ml-4 capsule_orange'
                  style={{ fontSize: '16px' }}
                >
                  In-Active
                </span>
              ) : (
                null || '-'
              )}
            </>
          );
        },
      },
    ];

    return (
      <>
        <div className='container invoices-wrapper mt-5'>
          <div className='card'>
            <div className='row'>
              <h3 className='col-md-10 card_heading'>
                Active Parties ({activePartiesData?.list?.length ?? 0})
              </h3>
            </div>
            <div className='row interest_charges_wrapper my-4'>
              <div className='col-10'>
                <div className='row'>
                  <div className='col-6'>
                    <form onSubmit={(e) => this.onSearch(e)}>
                      <div className='search-form'>
                        <select
                          className='select'
                          onChange={this.handleChange}
                          name='searchBy'
                          value={this.state.searchBy || ''}
                          required
                        >
                          <option value='' disabled selected>
                            Search By
                          </option>
                          <option value={'gst'}>GST Number</option>
                          <option value={'mobileNumber'}>Mobile Number</option>
                        </select>
                        <input
                          className='select input'
                          required={true}
                          placeholder='Search'
                          name='searchValue'
                          value={this.state.searchValue}
                          onChange={this.handleChange}
                        />
                        <button type='submit' className='submit'>
                          <img src={SearchIcon} alt='icon' />
                        </button>
                      </div>
                    </form>
                  </div>
                  <div className='col-4'>
                    <Button className='mt-1' onClick={this.onResetFilter}>
                      Reset
                    </Button>
                  </div>
                </div>
              </div>
              <div className='col-2 card_heading_secondary_right'>
                <a onClick={this.activeExcelDownload} download>
                  <img
                    src={ExcelIcon}
                    alt='icon'
                    className='icon_image h-75 mr-4'
                  />
                </a>
              </div>
            </div>
            <div className='mt-4'>
              <ReactTable
                data={activePartiesData?.list || []}
                columns={activePartiesColumn}
                defaultPageSize={10}
                showPagination={false}
                sortable={false}
                getTdProps={this.rowClicked}
              />
              <ul className={'datatable-pagination'}>
                <li>
                  <button
                    disabled={!(pageNumber > 1)}
                    className={'prev-btn'}
                    type={'button'}
                    onClick={() => this.getPageData('prev', totalPages)}
                  >
                    Previous
                  </button>
                </li>
                <li>
                  <span>
                    Page No. {totalPages === 0 ? 0 : pageNumber} of {totalPages}
                  </span>
                </li>
                <li>
                  <button
                    disabled={!(totalPages > pageNumber)}
                    className={'next-btn'}
                    type={'button'}
                    onClick={() => this.getPageData('next', totalPages)}
                  >
                    Next
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = ({ activePartiesReducer }) => ({
  activePartiesData: activePartiesReducer?.activePartiesData,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      getActivePartiesList,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(ActiveParties);
