import * as draftedConstant from './draftedInvoices.action.constant';
const initialState = {
  draftedList: {},
  draftedSummary: {},
};

const DraftedReducer = (state = initialState, action) => {
  const { type, data } = action;
  switch (type) {
    case draftedConstant.SET_DRAFTED_LIST:
      return { ...state, draftedList: data };
    case draftedConstant.SET_DRAFTED_SUMMARY:
      return { ...state, draftedSummary: data };
    default:
      return state;
  }
};
export default DraftedReducer;
