import React, { Component } from 'react';
import Download from '../../../../assets/images/download-cloud.png';
import Preview from '../../../../assets/images/eye.png';
import Greentick from '../../../../assets/images/Greentik.png';
import CredoModal from '../../../../components/CredoModal';
import DigilockerPanDocument from './DigilockerPreviewModal.js/DigilockerPanDocument';
import CompareModal from './CompareModal';
import AadharCardVerification from './DigilockerPreviewModal.js/AadharDocumentModal';
import Cross from '../../../../assets/images/crossnew.png';
import { API_GET_DOCUMENT_VERIFICATION_STATUS } from '../../../../utils/APIUrls';
import Question from '../../../../assets/images/QuestionIcon.svg';
import { GET } from '../../../../utils/webAPI.service';
import FileDownload from 'js-file-download';
export default class Digilocker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
      modalType: CompareModal,
      pan: null,
      aadhar: null,
    };
  }
  toggleModal = (name) => {
    this.setState((prevState) => ({
      modalOpen: !prevState.modalOpen,
      modalType: name,
    }));
  };

  getAadharVerifyStatus = () => {
    let { propsData } = this.props;
    let { photos, match } = propsData;
    let { kycAadharDocument } = photos;

    GET(
      API_GET_DOCUMENT_VERIFICATION_STATUS(
        match?.params?.loanId,
        kycAadharDocument?.type
      )
    ).then((data) => {
      this.setState({
        aadhar: data?.data?.data?.status,
      });
    });
  };

  getPanVerifyStatus = () => {
    let { propsData } = this.props;
    let { photos, match } = propsData;
    let { kycPanDocument } = photos;

    GET(
      API_GET_DOCUMENT_VERIFICATION_STATUS(
        match?.params?.loanId,
        kycPanDocument?.type
      )
    ).then((data) => {
      this.setState({
        pan: data?.data?.data?.status,
      });
    });
  };

  componentDidMount() {
    this.getAadharVerifyStatus();
    this.getPanVerifyStatus();
  }

  download = async (url, name) => {
    try {
      const response = await fetch(url);
      const blob = await response.blob();
      FileDownload(blob, `${name}.pdf`);
    } catch (error) {
      throw error;
    }
  };

  render(props) {
    let { aadhar, pan } = this.state;
    let { digiLocker = {}, propsData } = this.props;
    let { kycAaadhar = {}, kycPan = {} } = digiLocker;
    let { photos, match } = propsData;
    let { modalType, modalOpen } = this.state;
    let { kycAadharDocument = {}, kycPanDocument = {} } = photos;

    return (
      <div className=' kyc_modal_wrapper'>
        <div className='row ckyc-wrapper'>
          <div className='card__wrapper digilocker_card_outer col-12'>
            <div className='card-heading '>
              <div>
                Pan Card Verification
                <img
                  className='card-heading__icon'
                  alt='panCard'
                  src={
                    pan === 'success'
                      ? Greentick
                      : pan === 'failure'
                      ? Cross
                      : Question
                  }
                />
              </div>
              <div>
                <img
                  alt='digilockerPanModal'
                  className='img cursor'
                  onClick={() =>
                    this.toggleModal(
                      DigilockerPanDocument,
                      match,
                      photos,
                      this.getPanVerifyStatus()
                    )
                  }
                  src={Preview}
                ></img>

                <img
                  className='cursor'
                  alt='icon'
                  onClick={() =>
                    this.download(kycPanDocument?.fileUrl, 'kycPan')
                  }
                  src={Download}
                />
              </div>
            </div>
            <div className='row ml-3 application-wrapper '>
              <div className='col-md-3 entity_container_heading'>
                FullName
                <div className=' text-dark'>
                  {kycPan?.fullName}
                  {/* <img className='blue-tik'></img> */}
                </div>
              </div>
              <div className='col-md-3 entity_container_heading'>
                Pan number
                <div className=' text-dark'>
                  {kycPan?.panNumber}
                  {/* <img alt="icon" className='blue-tik'></img> */}
                </div>
              </div>
              <div className='col-md-3 entity_container_heading'>
                D.O.B
                <div className='text-dark '>
                  {kycPan?.dob}
                  {/* <img className='blue-tik'></img> */}
                </div>
              </div>
              <div className='col-md-3 entity_container_heading'>
                Gender
                <div className='text-dark'>
                  {kycPan?.gender === 'M'
                    ? 'Male'
                    : kycPan?.gender === 'F'
                    ? 'Female'
                    : 'NA'}
                  {/* <img className='blue-tik'></img> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='row ckyc-wrapper'>
          <div className='card__wrapper digilocker_card_outer col-12'>
            <div className='card-heading '>
              <div>
                Aadhaar Card Verification
                <img
                  className='card-heading__icon'
                  alt='aadharCard'
                  src={
                    aadhar === 'success'
                      ? Greentick
                      : aadhar === 'failure'
                      ? Cross
                      : Question
                  }
                />
              </div>
              <div>
                <img
                  className='img cursor'
                  alt='aadharCard'
                  onClick={() =>
                    this.toggleModal(AadharCardVerification, match, photos)
                  }
                  src={Preview}
                ></img>
                <img
                  alt='download'
                  className='cursor'
                  src={Download}
                  onClick={() =>
                    this.download(kycAadharDocument?.fileUrl, 'kycAadhar')
                  }
                ></img>
              </div>
            </div>
            <div className='row ml-3 application-wrapper '>
              <div className='col-md-3 entity_container_heading'>
                FullName
                <div className=' text-dark'>
                  {kycAaadhar?.fullName?.toUpperCase()}
                  <img className='blue-tik'></img>
                </div>
              </div>
              <div className='col-md-3 entity_container_heading'>
                Aadhaar number
                <div className=' text-dark'>
                  {kycAaadhar?.aadharNumber}
                  <img className='blue-tik'></img>
                </div>
              </div>
              <div className='col-md-3 entity_container_heading'>
                D.O.B
                <div className=' text-dark'>
                  {kycAaadhar?.dob}
                  <img className='blue-tik'></img>
                </div>
              </div>
              <div className='col-md-3 entity_container_heading'>
                Gender
                <div className=' text-dark'>
                  {kycAaadhar?.gender === 'M'
                    ? 'Male'
                    : kycAaadhar?.gender === 'F'
                    ? 'Female'
                    : 'NA'}
                  <img className='blue-tik'></img>
                </div>
              </div>
              <div className='col-md-5 mt-5 entity_container_heading'>
                Permanent address
                <div className=' text-dark'>
                  {kycAaadhar?.corrAddress?.line1 || '-'}
                  {kycAaadhar?.corrAddress?.line2 || '-'}{' '}
                  {kycAaadhar?.corrAddress?.district || '-'}{' '}
                  {kycAaadhar?.corrAddress?.state || '-'}{' '}
                  {kycAaadhar?.corrAddress?.pincode || '-'}
                  <img className='blue-tik'></img>
                </div>
              </div>
            </div>
          </div>
        </div>
        <CredoModal
          styles={{
            content: {
              minWidth: '60vw',
            },
          }}
          isOpen={modalOpen}
          closeAction={() => this.toggleModal('')}
          RenderingComponent={modalType}
          photos={photos}
          propsData={this.props}
          aadhar={() => this.getAadharVerifyStatus()}
          pan={() => this.getPanVerifyStatus()}
        />
      </div>
    );
  }
}
