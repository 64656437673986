import React, { Component } from 'react';
import {
  API_POST_NOTES,
  API_POST_REJECT_COMMENT,
} from '../../../../utils/APIUrls';
import { POST } from '../../../../utils/webAPI.service';
export class RejectCases extends Component {
  handleChange = (event) => {
    const { value, name } = event.target;
    this.setState({
      [name]: value?.trim(),
    });
  };

  saveCommentsHandler = () => {
    const { loanId } = this.props;
    const userId = localStorage.getItem('userId');
    let data = {
      comment: this.state.comment?.trim(),
      serviceRequestBy: userId,
      uniqueId: loanId,
      type: 'loan_rejected',
    };
    return POST(API_POST_REJECT_COMMENT, data)
      .then(({ data }) => {
        if (data.code === 200) {
        } else {
          alert(data?.message);
        }
      })
      .catch((err) => {
        throw err;
      });
  };

  handleConfirm = () => {
    this.saveCommentsHandler()
      .then(() => {
        this.props.rejectHandler();
      })
      .catch((error) => {
        console.error('Error while rejecting:', error);
      });
  };
  render() {
    return (
      <>
        <div className='modal_card services-wrapper'>
          <h3 className='modal_card-heading'>Reject Loan Application</h3>
          <div className='row m-3 col-12'>
            <textarea
              className='w-100 rounded'
              placeholder='Add Your comments here....'
              name={'comment'}
              onChange={this.handleChange}
            ></textarea>
          </div>
          <div className='paragraph'>
            Are you sure you want to reject this loan application?
          </div>
          <div className='row buttons_wrapper'>
            <button
              className='col-md-2 button cancel_button mr-4'
              onClick={() => this.props.closeAction()}
            >
              Cancel
            </button>
            <button
              className='col-md-2 button add_button'
              onClick={this.handleConfirm}
            >
              Confirm
            </button>
          </div>
        </div>
      </>
    );
  }
}
