import * as invoicesConstants from './invoice.action.constant';
import { API_INVOICES_VIEW, API_PAYMENT_VIEW } from '../../../../utils/APIUrls';
import { POST } from '../../../../utils/webAPI.service';

const getPaymentInvoicesList = (
  from,
  pageNumber,
  searchBy,
  searchValue,
  to,
  supplierId
) => {
  let dataToSend = {
    from,
    pageNumber,
    searchBy,
    searchValue,
    to,
    supplierId,
  };
  return (dispatch) => {
    return POST(API_PAYMENT_VIEW, dataToSend)
      .then(({ data }) => {
        if (data.code === 200) {
          if (data?.data) {
            dispatch({
              type: invoicesConstants.SET_PAYMENT_INVOICES,
              data: data.data,
            });
          }
        } else if (data.code === 401) {
          alert(data?.message);
        }
        return data;
      })
      .catch((err) => {
        alert('Something went wrong');
        throw err;
      });
  };
};

const getInvoicesList = (
  endDate,
  pageNumber,
  searchParam,
  startDate,
  type,
  searchValue,
  anchorId
) => {
  let dataToSend = {
    endDate,
    pageNumber,
    searchParam,
    startDate,
    type,
    searchValue,
  };
  return (dispatch) => {
    return POST(API_INVOICES_VIEW(anchorId), dataToSend)
      .then(({ data }) => {
        if (data.code === 200) {
          if (data?.data) {
            dispatch({
              type: invoicesConstants.SET_INVOICES,
              data: data.data,
            });
          }
        } else if (data.code === 401) {
          alert(data?.message);
        }
        return data;
      })
      .catch((err) => {
        alert('Something went wrong');
        throw err;
      });
  };
};
export { getPaymentInvoicesList, getInvoicesList };
