import React, { Component } from 'react';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { Helmet } from 'react-helmet';
import SupplierPaymentModal from './supplierPaymentModal';
import SelfTransferModal from './selfTransferModal';
import RepaymentsModal from './repaymentsModal';
import CredoModal from '../../../components/CredoModal';
import addUserIcon from '../../../assets/images/user-plus (5) 1.png';
import PostPaymentsModal from './postPaymentsModal';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getPaymentHistory, getRepayments } from './payments.actions';
import { rupeeDecimalFormatter } from '../../../utils/utility';
import { POST, GET } from '../../../utils/webAPI.service';
import {
  API_POST_PAYMENTS_DETAILS,
  API_GET_REPAYMENT_DETAILS,
} from '../../../utils/APIUrls';
import DownArrow from '../../../assets/images/DownArrow.svg';
import UpArrow from '../../../assets/images/UpArrow.svg';
import { loaderModal } from '../../../store/actions';

class Payments extends Component {
  state = {
    activeFigure: 'supplier',
    isModalOpen: false,
    supplierDetails: {},
    selfTransferDetails: {},
    sortDate: -1,
    sortAmount: -1,
    currentPageNum: 1,
    sortingOn: '',
  };

  componentDidMount = async () => {
    let { actions, match } = this.props,
      { userId } = match.params,
      { getPaymentHistory, loaderModal } = actions,
      { activeFigure } = this.state,
      token = localStorage.getItem('token');
    loaderModal(true);
    if (token) {
      await getPaymentHistory(userId, activeFigure, 1);
      loaderModal(false);
    } else {
      let { history } = this.props;
      alert('Unauthorized access');
      actions.loaderModal(false);
      history.push('/');
    }
  };

  getPageData = async (type, list) => {
    let { currentPage, totalPages } = list,
      { actions, match } = this.props,
      { userId } = match.params,
      { activeFigure, sortingOn, sortAmount, sortDate } = this.state;
    actions.loaderModal(true);
    if (activeFigure === 'repayments') {
      if (type === 'prev' && currentPage > 1) {
        await this.setState({ currentPageNum: currentPage - 1 });
      } else if (type === 'next' && currentPage < totalPages) {
        await this.setState({ currentPageNum: currentPage + 1 });
      }
      await actions.getRepayments(
        userId,
        this.state.currentPageNum,
        sortingOn === 'amount' ? sortAmount : sortDate,
        sortingOn
      );
    } else {
      if (type === 'prev' && currentPage > 1) {
        await this.setState({ currentPageNum: currentPage - 1 });
      } else if (type === 'next' && currentPage < totalPages) {
        await this.setState({ currentPageNum: currentPage + 1 });
      }
      await actions.getPaymentHistory(
        userId,
        activeFigure,
        this.state.currentPageNum,
        sortingOn === 'paymentDate' ? sortDate : sortAmount,
        sortingOn
      );
    }
    actions.loaderModal(false);
  };

  getTheadThProps = (state, rowInfo, column, instance) => ({
    onClick: () => {
      let { sortDate, sortAmount, currentPageNum } = this.state,
        { actions, match } = this.props,
        { userId } = match.params;

      if (column?.id === 'rePaymentDate') {
        this.setState(
          {
            sortDate: sortDate === -1 ? 1 : -1,
            sortAmount: -1,
            sortingOn: 'createdDate',
          },
          () => {
            let { sortDate, sortingOn } = this.state;
            actions.getRepayments(userId, currentPageNum, sortDate, sortingOn);
          }
        );
      } else if (column?.id === 'rePaymentAmount') {
        this.setState(
          {
            sortAmount: sortAmount === -1 ? 1 : -1,
            sortDate: -1,
            sortingOn: 'amount',
          },
          () => {
            let { sortAmount, sortingOn } = this.state;
            actions.getRepayments(
              userId,
              currentPageNum,
              sortAmount,
              sortingOn
            );
          }
        );
      } else if (column?.id === 'paymentDate') {
        this.setState(
          {
            sortDate: sortDate === -1 ? 1 : -1,
            sortAmount: -1,
            sortingOn: 'paymentDate',
          },
          () => {
            let { sortDate, sortingOn, activeFigure } = this.state;
            actions.getPaymentHistory(
              userId,
              activeFigure,
              currentPageNum,
              sortDate,
              sortingOn
            );
          }
        );
      } else if (column?.id === 'paymentAmount') {
        this.setState(
          {
            sortAmount: sortAmount === -1 ? 1 : -1,
            sortDate: -1,
            sortingOn: 'disbursalAmount',
          },
          () => {
            let { sortAmount, sortingOn, activeFigure } = this.state;
            actions.getPaymentHistory(
              userId,
              activeFigure,
              currentPageNum,
              sortAmount,
              sortingOn
            );
          }
        );
      }
    },
  });

  paymentHandler = async (type) => {
    let { actions, match } = this.props,
      { userId } = match.params,
      { getPaymentHistory, getRepayments } = actions;
    actions.loaderModal(true);
    if (type === 'supplier') {
      await getPaymentHistory(userId, 'supplier', 1);
    } else if (type === 'customer') {
      await getPaymentHistory(userId, 'customer', 1);
    } else if (type === 'repayments') {
      await getRepayments(userId, 1);
    }

    this.setState({
      activeFigure: type,
      sortDate: -1,
      sortAmount: -1,
      currentPageNum: 1,
      sortingOn: '',
    });
    actions.loaderModal(false);
  };

  toggleModal = (name, invoiceId, type) => {
    this.setState({
      isModalOpen: !this.state.isModalOpen,
      modalType: name,
    });
    let { match } = this.props,
      { userId } = match.params,
      dataToSend = {
        invoiceId,
        userId,
      };

    if (invoiceId) {
      POST(API_POST_PAYMENTS_DETAILS, dataToSend)
        .then(({ data }) => {
          if (data.code === 200) {
            if (data.data && type === 'supplierDetails') {
              this.setState({
                supplierDetails: data.data,
              });
            } else if (data.data && type === 'selfTransferDetails') {
              this.setState({
                selfTransferDetails: data.data,
              });
            }
          } else {
            alert(data?.message);
          }
        })
        .catch((err) => {
          throw err;
        });
    }
  };

  togglePostPaymentModal = (name) => {
    this.setState({
      isModalOpen: !this.state.isModalOpen,
      modalType: name,
      activeButton: 'PostPaymentsModal',
    });
  };

  toggleRePaymentModal = (name, transactionId, rePaymentstatus) => {
    this.setState({
      isModalOpen: !this.state.isModalOpen,
      modalType: name,
      rePaymentstatus,
    });
    GET(API_GET_REPAYMENT_DETAILS(transactionId))
      .then(({ data }) => {
        if (data.code === 200) {
          this.setState({ repaymentDetails: data.data });
        } else {
          alert(data.message);
        }
      })
      .catch((err) => {
        throw err;
      });
  };

  render() {
    let {
        activeFigure,
        isModalOpen,
        modalType,
        activeButton,
        sortDate,
        sortAmount,
      } = this.state,
      { supplierPaymentHistory, selfPaymentHistory, repayments } = this.props;

    const supplierColumns = [
      {
        Header: () => (
          <span>
            Payment Date
            <img
              src={sortDate === -1 ? UpArrow : DownArrow}
              alt='arrow'
              className='sort_arrow'
            />
          </span>
        ),
        accessor: 'paymentDate',
      },
      {
        Header: 'Invoice Date',
        accessor: 'invoiceDate',
      },
      {
        Header: 'Invoice ID',
        accessor: 'invoiceId',

        Cell: ({ original }) => {
          let { invoiceId, status } = original;
          return (
            <>
              <div>{invoiceId}</div>
              {status === 'processed' ? (
                <span className='capsule capsule_green'> Processed</span>
              ) : status === 'processing' ? (
                <span className='capsule capsule_orange'>Processing</span>
              ) : status === 'reversed' ? (
                <span className='capsule capsule_red'> Reversed </span>
              ) : status === 'payout_failed' ? (
                <span className='capsule capsule_red'> Payout Failed </span>
              ) : null}
            </>
          );
        },
      },
      {
        Header: 'Supplier Name',
        accessor: 'contactName',
      },
      {
        Header: ' Mob. Number',
        accessor: 'mobileNumber',
      },
      {
        Header: 'Transaction ID',
        accessor: 'transactionId',
      },
      {
        Header: () => (
          <span className='pr-5'>
            Payment Amt.
            <img
              src={sortAmount === -1 ? UpArrow : DownArrow}
              alt='arrow'
              className='sort_arrow'
            />
          </span>
        ),
        headerStyle: { textAlign: 'right' },
        accessor: 'paymentAmount',
        Cell: (props) => {
          return (
            <div className='right-aligned pr-5'>
              {rupeeDecimalFormatter(props?.value, '₹')}
            </div>
          );
        },
      },

      {
        Header: 'Details',
        width: 80,
        accessor: '',
        Cell: ({ original }) => {
          let { invoiceId } = original;
          return (
            <button
              className='details_button'
              onClick={() =>
                this.toggleModal(
                  SupplierPaymentModal,
                  invoiceId,
                  'supplierDetails'
                )
              }
            >
              Details
            </button>
          );
        },
      },
    ];

    const selfTransferColumns = [
      {
        Header: () => (
          <span>
            Payment Date
            <img
              src={sortDate === -1 ? UpArrow : DownArrow}
              alt='arrow'
              className='sort_arrow'
            />
          </span>
        ),
        accessor: 'paymentDate',
      },
      {
        Header: 'Invoice Date',
        accessor: 'invoiceDate',
      },
      {
        Header: 'Invoice ID',
        accessor: 'invoiceId',
        Cell: ({ original }) => {
          let { invoiceId, status } = original;
          return (
            <>
              <div>{invoiceId}</div>
              {status === 'processed' ? (
                <span className='capsule capsule_green'> Processed</span>
              ) : status === 'processing' ? (
                <span className='capsule capsule_orange'>Processing</span>
              ) : status === 'reversed' ? (
                <span className='capsule capsule_red'> Reversed </span>
              ) : status === 'payout_failed' ? (
                <span className='capsule capsule_red'> Payout Failed </span>
              ) : null}
            </>
          );
        },
      },
      {
        Header: 'Customer Name',
        accessor: 'contactName',
      },
      {
        Header: ' Mob. Number',
        accessor: 'mobileNumber',
      },
      {
        Header: 'Transaction ID',
        accessor: 'transactionId',
      },
      {
        Header: () => (
          <span className='pr-5'>
            Payment Amt.
            <img
              src={sortAmount === -1 ? UpArrow : DownArrow}
              alt='arrow'
              className='sort_arrow'
            />
          </span>
        ),
        accessor: 'paymentAmount',
        headerStyle: { textAlign: 'right' },
        Cell: (props) => {
          return (
            <div className='right-aligned pr-5'>
              {rupeeDecimalFormatter(props?.value, '₹')}
            </div>
          );
        },
      },

      {
        Header: 'Details',
        width: 80,
        accessor: '',
        Cell: ({ original }) => {
          let { invoiceId } = original;
          return (
            <button
              className='details_button'
              onClick={() =>
                this.toggleModal(
                  SelfTransferModal,
                  invoiceId,
                  'selfTransferDetails'
                )
              }
            >
              Details
            </button>
          );
        },
      },
    ];

    const repaymentsColumns = [
      {
        Header: () => (
          <span>
            Repayment Date
            <img
              src={sortDate === -1 ? UpArrow : DownArrow}
              alt='arrow'
              className='sort_arrow'
            />
          </span>
        ),
        accessor: 'rePaymentDate',
      },
      {
        Header: 'Repayment Mode',
        accessor: 'rePaymentMode',
      },

      {
        Header: 'Transaction ID',
        accessor: 'transactionId',
      },
      {
        Header: 'Status',
        accessor: 'status',
        width: 80,
      },
      {
        Header: () => (
          <span className='pr-5'>
            'Repayment Amt.
            <img
              src={sortAmount === -1 ? UpArrow : DownArrow}
              alt='arrow'
              className='sort_arrow'
            />
          </span>
        ),
        headerStyle: { textAlign: 'right' },
        accessor: 'rePaymentAmount',
        Cell: (props) => {
          return (
            <div className='right-aligned pr-5'>
              {rupeeDecimalFormatter(props?.value, '₹')}
            </div>
          );
        },
      },
      {
        Header: 'Details',
        width: 80,
        accessor: '',
        Cell: ({ original }) => {
          let { transactionId, status } = original;
          return (
            <button
              className='details_button'
              onClick={() =>
                this.toggleRePaymentModal(
                  RepaymentsModal,
                  transactionId,
                  status
                )
              }
            >
              Details
            </button>
          );
        },
      },
    ];
    return (
      <>
        <div className='payment_wrapper container'>
          <Helmet>
            <meta charSet='utf-8' />
            <title> Payments</title>
          </Helmet>
          <div className='card'>
            <div className='row '>
              <h3 className='col-md-10 card_heading_secondary '>
                Payments Summary
              </h3>
              {activeFigure === 'repayments' ? (
                <h5
                  className='col-md-2  add_button'
                  onClick={() => this.togglePostPaymentModal(PostPaymentsModal)}
                >
                  <img src={addUserIcon} alt='icon' />
                  Post Payments
                </h5>
              ) : null}
            </div>

            <div className='row generic_button_box'>
              <div
                onClick={() => this.paymentHandler('supplier')}
                className={
                  activeFigure === 'supplier'
                    ? 'col-md-1 generic_button_active'
                    : 'col-md-1 generic_button'
                }
              >
                To Supplier
              </div>
              <div
                onClick={() => this.paymentHandler('customer')}
                className={
                  activeFigure === 'customer'
                    ? 'col-md-1 generic_button_active'
                    : 'col-md-1 generic_button'
                }
              >
                Self Transfer
              </div>
              <div
                onClick={() => this.paymentHandler('repayments')}
                className={
                  activeFigure === 'repayments'
                    ? 'col-md-1 generic_button_active'
                    : 'col-md-1 generic_button'
                }
              >
                Repayments
              </div>
            </div>
            {activeFigure === 'supplier' ? (
              <div className='row'>
                <div className='col-md-12'>
                  <div className='row'>
                    <div className='col-md-2 text-grey'>
                      Total Payments
                      <div className='text-bold'>
                        {supplierPaymentHistory?.numberOfSuccessfulPayments}
                      </div>
                    </div>
                    <div className='col-md-2 text-grey'>
                      Total amount
                      <div className='text-bold'>
                        {rupeeDecimalFormatter(
                          supplierPaymentHistory?.amountOfSuccessfulPayments,
                          '₹'
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
            {activeFigure === 'customer' ? (
              <div className='row'>
                <div className='col-md-12'>
                  <div className='row'>
                    <div className='col-md-2 text-grey'>
                      Total Payments
                      <div className='text-bold'>
                        {selfPaymentHistory?.numberOfSuccessfulPayments}
                      </div>
                    </div>
                    <div className='col-md-2 text-grey'>
                      Total amount
                      <div className='text-bold'>
                        {rupeeDecimalFormatter(
                          selfPaymentHistory?.amountOfSuccessfulPayments,
                          '₹'
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
            {activeFigure === 'repayments' ? (
              <div className='row'>
                <div className='col-md-12'>
                  <div className='row'>
                    <div className='col-md-2 text-grey'>
                      Total Repayments
                      <div className='text-bold'>
                        {repayments?.totalRepayments}
                      </div>
                    </div>
                    <div className='col-md-2 text-grey'>
                      Total amount
                      <div className='text-bold'>
                        {rupeeDecimalFormatter(repayments?.totalAmount, '₹')}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
          </div>

          <div className='card'>
            {activeFigure === 'supplier' ? (
              <>
                <div className='row'>
                  <h3 className='col-md-10 card_heading_secondary'>
                    Supplier Payments
                  </h3>
                  <h5 className='col-md-2  card_heading_secondary_right'>
                    All amounts in ₹
                  </h5>
                </div>

                <ReactTable
                  data={supplierPaymentHistory?.payments || []}
                  columns={supplierColumns}
                  defaultPageSize={10}
                  showPagination={false}
                  sortable={false}
                  getTheadThProps={this.getTheadThProps}
                />
                <ul className={'datatable-pagination'}>
                  <li>
                    <button
                      disabled={supplierPaymentHistory?.currentPage <= 1}
                      className={'prev-btn'}
                      type={'button'}
                      onClick={() =>
                        this.getPageData('prev', supplierPaymentHistory)
                      }
                    >
                      Previous
                    </button>
                  </li>
                  <li>
                    <span>
                      {`Page No. ${supplierPaymentHistory?.currentPage} of
                        ${supplierPaymentHistory?.totalPages}`}
                    </span>
                  </li>

                  <li>
                    <button
                      className={'next-btn'}
                      type={'button'}
                      disabled={
                        !(
                          supplierPaymentHistory?.currentPage <
                          supplierPaymentHistory?.totalPages
                        )
                      }
                      onClick={() =>
                        this.getPageData('next', supplierPaymentHistory)
                      }
                    >
                      Next
                    </button>
                  </li>
                </ul>
              </>
            ) : null}
            {activeFigure === 'customer' ? (
              <>
                <div className='row'>
                  <h3 className='col-md-10 card_heading_secondary'>
                    Self Transfer Payments
                  </h3>
                  <h5 className='col-md-2  card_heading_secondary_right'>
                    All amounts in ₹
                  </h5>
                </div>

                <ReactTable
                  data={selfPaymentHistory?.payments || []}
                  columns={selfTransferColumns}
                  defaultPageSize={10}
                  showPagination={false}
                  sortable={false}
                  getTheadThProps={this.getTheadThProps}
                />
                <ul className={'datatable-pagination'}>
                  <li>
                    <button
                      disabled={selfPaymentHistory?.currentPage <= 1}
                      className={'prev-btn'}
                      type={'button'}
                      onClick={() =>
                        this.getPageData('prev', selfPaymentHistory)
                      }
                    >
                      Previous
                    </button>
                  </li>
                  <li>
                    <span>
                      {`Page No. ${selfPaymentHistory?.currentPage} of
                      ${selfPaymentHistory?.totalPages}`}
                    </span>
                  </li>

                  <li>
                    <button
                      className={'next-btn'}
                      type={'button'}
                      disabled={
                        !(
                          selfPaymentHistory?.currentPage <
                          selfPaymentHistory?.totalPages
                        )
                      }
                      onClick={() =>
                        this.getPageData('next', selfPaymentHistory)
                      }
                    >
                      Next
                    </button>
                  </li>
                </ul>
              </>
            ) : null}
            {activeFigure === 'repayments' ? (
              <>
                <div className='row'>
                  <h3 className='col-md-10 card_heading_secondary'>
                    Repayments
                  </h3>
                  <h5 className='col-md-2  card_heading_secondary_right'>
                    All amounts in ₹
                  </h5>
                </div>

                <ReactTable
                  data={repayments?.listOfRepayments || []}
                  columns={repaymentsColumns}
                  defaultPageSize={10}
                  showPagination={false}
                  sortable={false}
                  getTheadThProps={this.getTheadThProps}
                />
                <ul className={'datatable-pagination'}>
                  <li>
                    <button
                      className={'prev-btn'}
                      disabled={repayments?.currentPage <= 1}
                      type={'button'}
                      onClick={() => this.getPageData('prev', repayments)}
                    >
                      Previous
                    </button>
                  </li>
                  <li>
                    <span>
                      {` Page No. ${repayments?.currentPage} of
                      ${repayments?.totalPages}`}
                    </span>
                  </li>

                  <li>
                    <button
                      className={'next-btn'}
                      type={'button'}
                      disabled={
                        !(repayments?.currentPage < repayments?.totalPages)
                      }
                      onClick={() => this.getPageData('next', repayments)}
                    >
                      Next
                    </button>
                  </li>
                </ul>
              </>
            ) : null}
          </div>
          <CredoModal
            isOpen={isModalOpen}
            styles={
              activeButton === 'PostPaymentsModal'
                ? {
                    content: {
                      minWidth: '60vw',
                      display: 'table',
                      height: 'auto',
                    },
                  }
                : {
                    content: {
                      minWidth: '70vw',
                    },
                  }
            }
            closeAction={() => this.toggleModal('')}
            RenderingComponent={modalType}
            stateData={this.state}
            propsData={this.props}
            toggleModal={this.toggleModal}
          />
        </div>
      </>
    );
  }
}

const mapStateToProps = ({ paymentsReducer }) => ({
  supplierPaymentHistory: paymentsReducer.supplierPaymentHistory,
  selfPaymentHistory: paymentsReducer.selfPaymentHistory,
  repayments: paymentsReducer.repayments,
});
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      getPaymentHistory,
      getRepayments,
      loaderModal,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(Payments);
