import React, { Component } from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  getPromoterDetails,
  getApplicationId
} from './LeadManagementNavs.actions';
import { getActiveLoanOffer } from '../Offer/offer.actions';
import HeaderTop from '../../../components/HeaderTop';

class LeadManagementNavs extends Component {
  componentDidMount = async () => {
    let { match, actions } = this.props,
      { loanId, userId } = match.params,
      { getPromoterDetails, getActiveLoanOffer, getApplicationId } = actions;

    await getApplicationId(userId);
    getPromoterDetails(loanId);
    getActiveLoanOffer(loanId);
  };

  clickHandle = (e, name) => {
    let { match, history } = this.props,
      { loanId, userId } = match.params;
    if (e.ctrlKey) {
      if ((e.ctrlKey || e.metaKey) && name === 'overview') {
        window.open(`/pay-app/${loanId}/${userId}/application/overview`);
      } else if ((e.ctrlKey || e.metaKey) && name === 'offer') {
        window.open(`/pay-app/${loanId}/${userId}/offer`);
      } else if ((e.ctrlKey || e.metaKey) && name === 'invoices') {
        window.open(`/pay-app/${loanId}/${userId}/invoices`);
      } else if ((e.ctrlKey || e.metaKey) && name === 'payments') {
        window.open(`/pay-app/${loanId}/${userId}/payments`);
      } else if ((e.ctrlKey || e.metaKey) && name === 'interestAndCharges') {
        window.open(`/pay-app/${loanId}/${userId}/interestAndCharges`);
      } else if ((e.ctrlKey || e.metaKey) && name === 'manageUser') {
        window.open(`/pay-app/${loanId}/${userId}/manageUser`);
      } else if ((e.ctrlKey || e.metaKey) && name === 'services') {
        window.open(`/pay-app/${loanId}/${userId}/services`);
      }
    } else if (e.button === 0) {
      if (name === 'overview') {
        history.push(`/pay-app/${loanId}/${userId}/application/overview`);
      } else if (name === 'offer') {
        history.push(`/pay-app/${loanId}/${userId}/offer`);
      } else if (name === 'invoices') {
        history.push(`/pay-app/${loanId}/${userId}/invoices`);
      } else if (name === 'payments') {
        history.push(`/pay-app/${loanId}/${userId}/payments`);
      } else if (name === 'interestAndCharges') {
        history.push(`/pay-app/${loanId}/${userId}/interestAndCharges`);
      } else if (name === 'manageUser') {
        history.push(`/pay-app/${loanId}/${userId}/manageUser`);
      } else if (name === 'services') {
        history.push(`/pay-app/${loanId}/${userId}/services`);
      }
    }
  };

  render() {
    let {
      active,
      match,
      promoterData,
      activeLoans,
      applicationId
    } = this.props;

    return (
      <>
        <HeaderTop {...this.props} />
        <section className={'leadmanager-navs-wrapper'}>
          <Navbar>
            <Nav className='container'>
              <Nav.Link className='nav-top'>
                Active applications <span className='logo'>{'>'}</span>
                <span className='value'>{promoterData?.entityName}</span> (
                {match?.params?.userId})
                {applicationId ? (
                  <div>(Application Id: {applicationId})</div>
                ) : null}
              </Nav.Link>
            </Nav>
          </Navbar>
          <Navbar>
            <Nav className='credit-navs container' activeKey={active}>
              <Nav.Link
                eventKey='overview'
                onClick={e => this.clickHandle(e, 'overview')}
              >
                APPLICATION
              </Nav.Link>
              <Nav.Link
                eventKey='offer'
                onClick={e => this.clickHandle(e, 'offer')}
              >
                OFFER
              </Nav.Link>
              {activeLoans?.offerId ? (
                <Nav.Link
                  eventKey='invoices'
                  onClick={e => this.clickHandle(e, 'invoices')}
                >
                  INVOICES
                </Nav.Link>
              ) : null}
              {activeLoans?.offerId && (
                <Nav.Link
                  eventKey='payments'
                  onClick={e => this.clickHandle(e, 'payments')}
                >
                  PAYMENTS
                </Nav.Link>
              )}
              {activeLoans?.offerId && (
                <Nav.Link
                  eventKey='interestAndCharges'
                  onClick={e => this.clickHandle(e, 'interestAndCharges')}
                  className='w-200'
                >
                  INTEREST AND CHARGES
                </Nav.Link>
              )}
              {activeLoans?.offerId && (
                <Nav.Link
                  eventKey='manageUser'
                  onClick={e => this.clickHandle(e, 'manageUser')}
                >
                  MANAGE USER
                </Nav.Link>
              )}
              {activeLoans?.offerId && (
                <Nav.Link
                  eventKey='services'
                  onClick={e => this.clickHandle(e, 'services')}
                >
                  SERVICES
                </Nav.Link>
              )}
            </Nav>
          </Navbar>
        </section>
      </>
    );
  }
}

LeadManagementNavs.defaultProps = {
  active: ''
};

const mapStateToProps = ({ leadManagementNavsReducer, offerReducer }) => ({
  promoterData: leadManagementNavsReducer?.promoterData,
  activeLoans: offerReducer?.activeLoans,
  applicationId: leadManagementNavsReducer?.applicationId
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      getPromoterDetails,
      getActiveLoanOffer,
      getApplicationId
    },
    dispatch
  )
});

export default connect(mapStateToProps, mapDispatchToProps)(LeadManagementNavs);
