import { API_SUPPLIER_DETAILS } from '../../../../utils/APIUrls';
import { GET } from '../../../../utils/webAPI.service';
import * as supplierDetailsConstant from './profile.action.constant';

const getSupplierDetails = (data) => {
  return (dispatch) => {
    return GET(API_SUPPLIER_DETAILS(data))
      .then(({ data }) => {
        if (data.code === 200) {
          if (data.data) {
            dispatch({
              type: supplierDetailsConstant.SET_SUPPLIERS_DETAILS,
              data: data.data,
            });
          }
        } else if (data.code === 999 || data.code === 401) {
          alert(data?.message);
        } else {
          alert('Something went wrong');
        }
      })
      .catch((err) => {
        throw err;
      });
  };
};

export { getSupplierDetails };
