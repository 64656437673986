import axios from 'axios';
import { set, get } from 'lodash';
import moment from 'moment';
import fileDownload from 'js-file-download';
/**
 *
 * @param state: Redux State
 * @param name: name of Field to be fetch
 * @returns {*}
 * @private
 */
const _getValue = (state, name) => {
  return get(state, [name]);
};

const setMaxDate = (type = '') => {
  let dateArr = new Date().toLocaleDateString().split('/');
  let date = dateArr[1];
  let month = dateArr[0];
  let year = dateArr[2];
  if (type === 'dob') {
    year = Number(year) - 18;
  } else {
    date = Number(date) - 1;
  }
  if (date < 10) {
    date = `0${date}`;
  }
  if (month < 10) {
    month = `0${month}`;
  }
  return `${year}-${month}-${date}`;
};

const getDMY = (dateStr) => {
  let dateObj = new Date(dateStr),
    dd = dateObj.getDate(),
    mm = dateObj.getMonth() + 1,
    year = dateObj.getFullYear(),
    month = mm < 10 ? `0${mm}` : `${mm}`,
    date = dd < 10 ? `0${dd}` : `${dd}`;
  return `${date}/${month}/${year}`;
};

const rupeeCommaFormatter = (value) => {
  let val = value && Math.round(value).toString();
  if (!val) {
    return '0';
  }
  if (val.length > 3) {
    let lastThree = val.substring(val.length - 3);
    let otherNumbers = val.substring(0, val.length - 3);
    if (otherNumbers !== '') lastThree = ',' + lastThree;
    return otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ',') + lastThree;
  } else {
    return val;
  }
};

const numericInput = (value, callback) => {
  let reg = /^[0-9]*$/;
  if (reg.test(value)) {
    callback(value);
  }
};
const todayDate = () => {
  var today = new Date();
  var current_date = today.getDate();
  var current_month = today.getMonth() + 1;
  var current_year = today.getFullYear();
  return current_date + '/' + current_month + '/' + current_year;
};

function formatString(input) {
  return input
    ?.toLowerCase()
    ?.split('_')
    ?.map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize each word
    ?.join(' ');
}

const rupeeDecimalFormatter = (value) => {
  let _value = value;
  if (!_value) {
    return '₹ 0.00';
  }

  if (typeof _value === 'string') {
    _value = Number(_value);
    _value = isNaN(_value) ? 0 : _value;
  }

  const formattedValue = _value?.toLocaleString('en-In', {
    minimumFractionDigits: 2,
  });
  return `₹ ${formattedValue}`;
};

/**
 * @param state: state Values
 * @param name: name of field to be updated
 * @param value: new value
 * @returns {{form: *}}
 * @private
 */

const _updateFormValues = (state, name, value = '') => {
  const _preValue = _getValue(state, name);
  if (_preValue !== value) {
    return get(set(state, `form.values.${name}`, value), 'form');
  }
  return false;
};

const integerToWordFormatter = (value) => {
  let intValue = Math.round(value).toString();
  if (intValue === '0') {
    return '0';
  }
  if (intValue.length >= 1 && intValue.length < 5) {
    return rupeeCommaFormatter(intValue);
  }
  if (intValue.length >= 5 && intValue.length <= 7) {
    return parseFloat(intValue / 100000).toFixed(2) + ' Lakh';
  }
  if (intValue.length >= 7) {
    return parseFloat(intValue / 10000000).toFixed(2) + ' Crore';
  }
};
const integerToWordFormatterLabels = (value) => {
  if (value === 0) {
    return '0';
  }
  if (value >= 1e7) {
    // Crore
    return (value / 1e7).toFixed(2) + ' Crore';
  } else if (value >= 1e5) {
    // Lakh
    return (value / 1e5).toFixed(2) + ' Lakh';
  } else if (value >= 1e3) {
    // Thousand
    return (value / 1e3).toFixed(2) + 'K';
  }

  return value.toString();
};
const titleCase = (str) => {
  var splitStr = str.toLowerCase().split(' ');
  for (var i = 0; i < splitStr.length; i++) {
    splitStr[i] =
      splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  return splitStr.join(' ');
};

const getScrollPosition = (selector) => {
  let elem = document.getElementById(selector);
  if (elem) {
    let coords = elem.getBoundingClientRect();
    let windowHeight = document.documentElement.clientHeight;
    return coords.bottom > 0 && coords.bottom < windowHeight;
  }
  return false;
};

const setHeaderFixed = () => {
  let header = document.getElementsByClassName('credit-navs-wrapper')[0];
  let navbar = header?.children[0];
  let sticky = navbar?.offsetTop;
  if (window.pageYOffset > sticky) {
    navbar?.classList?.add('navbar-fixed-top');
  } else {
    navbar?.classList?.remove('navbar-fixed-top');
  }
};

const getMonthRange = (
  startDate,
  endDate,
  valuesInFormat = 'MM-YYYY',
  returnInFormat = 'MM-YYYY'
) => {
  const monthData = [];
  let fromDate = moment(startDate, valuesInFormat);
  let toDate = moment(endDate, valuesInFormat);
  let diff = toDate.diff(fromDate, 'M');
  for (let i = 0; i <= diff; i++) {
    monthData.push(moment(fromDate).add(i, 'month').format(returnInFormat));
  }
  return monthData;
};

const roundUpDecimalConverter = (number) => {
  if (!Number.isInteger(number)) {
    return number?.toFixed(2);
  } else {
    return number;
  }
};
//supplier dashboard Mis
const excelDownload = async (Url, anchorId, sendData, fileName, buyerId) => {
  const token = localStorage.getItem('token'),
    headers = {
      'X-CLIENT-NAME': 'PDU',
      'X-USER-ID': localStorage.getItem('userId'),
      Authorization: `Bearer ${token}`,
    };
  try {
    const response = await axios({
      url: buyerId ? `${Url(anchorId, buyerId)}` : `${Url(anchorId)}`, //check for individual buyer MIS
      method: 'POST',
      headers: headers,
      data: sendData,
      responseType: 'blob', // Important for file download
    });
    fileDownload(
      response.data,
      buyerId
        ? `${fileName}_${anchorId}_${buyerId}.xlsx`
        : `${fileName}_${anchorId}.xlsx`
    );
  } catch (error) {
    console.error('Error downloading the file', error);
  }
};

export {
  setMaxDate,
  excelDownload,
  roundUpDecimalConverter,
  getDMY,
  rupeeCommaFormatter,
  numericInput,
  todayDate,
  rupeeDecimalFormatter,
  _updateFormValues,
  integerToWordFormatter,
  integerToWordFormatterLabels,
  titleCase,
  getScrollPosition,
  setHeaderFixed,
  getMonthRange,
  formatString,
};
