import React, { Component } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import UnitLocation from '../../../../assets/images/UnitLocation.svg';
import Icon from 'react-icons-kit';
import { spinner11 } from 'react-icons-kit/icomoon/spinner11';

export default class ImagesModal extends Component {
  state = { index: 0, rotation: 0 };

  handleSelect = (index, e) => {
    this.setState({
      index,
      rotation: 0,
    });
  };

  componentDidMount() {
    let { propsData } = this.props,
      { photos } = propsData,
      { businessDocuments } = photos;
    this.setState({
      businessDocumentsData: businessDocuments,
    });
  }
  rotate = () => {
    let newRotation = this.state.rotation + 90;
    if (newRotation >= 360) {
      newRotation = -360;
    }
    this.setState({
      rotation: newRotation,
    });
  };

  rotate = (index) => {
    const currentRotation = this.state.rotation || 0;
    let newRotation = currentRotation + 90;
    if (newRotation >= 360) {
      newRotation -= 360;
    }
    let { businessDocumentsData } = this.state;
    const updatedArray = [...businessDocumentsData];
    updatedArray[index] = {
      ...updatedArray[index],
      rotation: newRotation,
    };
    this.setState({
      businessDocumentsData: updatedArray,
      rotation: newRotation,
    });
  };

  render() {
    let { index, businessDocumentsData } = this.state;
    return (
      <div className='compare-details-wrapper'>
        <h3 className='card_heading'> Business Photos </h3>
        <Carousel
          activeIndex={index}
          onSelect={this.handleSelect}
          interval={null}
        >
          {businessDocumentsData?.map((image, index) => {
            let { fileUrl, latitude, longtitude, name } = image;
            return (
              <Carousel.Item className='carousel_item' key={index}>
                <img
                  className='d-block '
                  src={fileUrl}
                  alt='First slide'
                  style={{
                    transform: `rotate(${image?.rotation || 0}deg)`,
                  }}
                />
                <Carousel.Caption>
                  <h3>
                    {index + 1}.{name}{' '}
                    <span onClick={() => this.rotate(index)}>
                      <Icon
                        icon={spinner11}
                        size={18}
                        className='rotate-icon m-2'
                      />
                    </span>
                  </h3>
                  <p>
                    <img
                      src={UnitLocation}
                      alt='location'
                      className='location_icon'
                    />{' '}
                    Latitude : {latitude}&nbsp;&nbsp; Longtitude :{longtitude}
                  </p>
                </Carousel.Caption>
              </Carousel.Item>
            );
          })}
        </Carousel>
        <div></div>
      </div>
    );
  }
}
