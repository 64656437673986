import React, { Component } from 'react';
import { POST } from '../../../../../utils/webAPI.service';
import { Button } from 'react-bootstrap';
import { getDocumentVerifiationStatus } from '../application.actions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  API_POST_DOCUMENT_VERIFY,
  API_GET_CPO_DOCUMENTS_ID,
} from '../../../../../utils/APIUrls';
import Icon from 'react-icons-kit';
import { spinner11 } from 'react-icons-kit/icomoon/spinner11';

export class EntityPreviewModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      image: null,
      docId: '',
      loan: '',
      remarks: '',
      status: '',
      url: '',
      rotation: 0,
    };
  }

  postDocumentVerify = (status) => {
    let {
      closeAction,
      docId,
      loanId,
      docType,
      actions: { getDocumentVerifiationStatus },
    } = this.props;
    const data = {
      docId: docId !== 0 ? docId : docType,
      loanId,
      remarks: '',
      status: status,
    };

    POST(API_POST_DOCUMENT_VERIFY, data).then((res) => {
      if (res.status === 200) {
        closeAction('');
        getDocumentVerifiationStatus(loanId, docId !== 0 ? docId : docType);
      }
    });
  };

  getCpoDocument = () => {
    let { docId } = this.props;
    let fileUrl = this.props.fileUrl;
    let url = API_GET_CPO_DOCUMENTS_ID(docId);
    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        // Create an object URL from the Blob
        const objectURL = URL.createObjectURL(blob);
        this.setState({
          image: fileUrl ? fileUrl : objectURL,
        });
      });
  };

  componentDidMount = () => {
    this.getCpoDocument();
  };

  rotate = () => {
    let newRotation = this.state.rotation + 90;
    if (newRotation >= 360) {
      newRotation = -360;
    }
    this.setState({
      rotation: newRotation,
    });
  };

  render() {
    let { fileName } = this.props,
      { rotation } = this.state;
    return (
      <>
        <div className='compare-details-wrapper digilocker-document-wrapper'>
          <div className='preview_file'>
            {fileName?.includes('pdf') ? (
              <iframe
                title='pdfDoc'
                className='profile_img  preview_img'
                src={this.state.image}
                style={{
                  transform: `rotate(${rotation}deg)`,
                }}
              ></iframe>
            ) : (
              <img
                src={this.state.image}
                alt='user_profile'
                className='profile_img  '
                style={{
                  transform: `rotate(${rotation}deg)`,
                }}
              />
            )}
            <span onClick={this.rotate}>
              <Icon icon={spinner11} size={18} className='rotate-icon m-2' />
            </span>
          </div>
          <div className='btn'>
            <Button
              className='btn_verify'
              onClick={() => this.postDocumentVerify('success')}
            >
              Verify
            </Button>
            <Button
              className='btn_cancel'
              onClick={() => this.postDocumentVerify('failure')}
            >
              Reject
            </Button>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = ({ ApplicationReducer }) => ({
  verificationStatus: ApplicationReducer.v,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      getDocumentVerifiationStatus,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(EntityPreviewModal);
