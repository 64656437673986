import React, { Component } from 'react';
import { rupeeDecimalFormatter } from '../../../../../../utils/utility';
export default class FailedInvoiceModal extends Component {
  render() {
    let { invoiceData } = this.props;
    return (
      <div className='compare-details-wrapper'>
        <h3 className='card_heading'>
          Invoice Number : {invoiceData?.invoiceNumber}
        </h3>
        <div className='row'>
          <div className='col-md-3 text-grey'>
            Invoice Amount
            <div className='text-bold'>
              {rupeeDecimalFormatter(invoiceData?.invoiceAmount, '₹')}
            </div>
          </div>
          <div className='col-md-3 text-grey'>
            Invoice Date
            <div className='text-bold'>{invoiceData?.invoiceDate || '-'}</div>
          </div>
          <div className='col-md-3 text-grey'>
            Subvention Amount
            <div className='text-bold'>
              {rupeeDecimalFormatter(invoiceData?.subventionAmount, '₹')}
            </div>
          </div>
          <div className='col-md-3 text-grey'>
            Payment Date
            <div className='text-bold'>{invoiceData?.paymentDate}</div>
          </div>
          <div className='col-md-3 text-grey'>
            Reason
            <div className='text-bold'>{invoiceData?.failReason}</div>
          </div>
        </div>
        <div className='d-flex justify-content-center'>
          {invoiceData?.invoiceFileUrl?.includes('pdf') ||
          invoiceData?.invoiceFileUrl?.includes('PDF') ? (
            <iframe
              title='Invoice'
              src={invoiceData?.invoiceFileUrl}
              className='profile_img  preview_img'
            ></iframe>
          ) : (
            <img
              src={invoiceData?.invoiceFileUrl}
              alt='invoice_image'
              className='invoice_img'
            />
          )}
        </div>
      </div>
    );
  }
}
