import React, { Component } from 'react';

export default class FailedTransactionRulesModal extends Component {
  getHeadingFailedTransactionData = (data) => {
    return data;
  };
  render() {
    let { FailedTransactionRules } = this.props || {};
    let { data } = FailedTransactionRules || {};
    let { failedRules } = data || [];
    return (
      <>
        <div className='compare-details-wrapper cam_wrapper'>
          <div className='row'>
            <div className='col-md-8 text-grey'>FailedTransactionRules</div>
            <div className='col-md-4 text-grey'>Bench Mark value</div>
          </div>

          {failedRules?.map((ele, i) => {
            return (
              <>
                <div className='row' key={i}>
                  <div className='col-8 ml-3 mt-3 mb-4 text-danger'>
                    {this.getHeadingFailedTransactionData(ele?.ruleKey)}
                  </div>
                  <div className='col mt-3 text-danger'>
                    {ele?.benchMarkValue}
                  </div>
                </div>
              </>
            );
          })}
        </div>
      </>
    );
  }
}
