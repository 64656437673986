import * as activePartiesConstants from './activeParties.action.constant';

const initialState = {
  activePartiesData: {},
  buyerSummaryData: {},
  paymentInvoices: {},
  invoicesList: {},
};

const activePartiesReducer = (state = initialState, action) => {
  let { type, data } = action;
  switch (type) {
    case activePartiesConstants.SET_ACTIVE_PARTIES:
      return { ...state, activePartiesData: data };
    case activePartiesConstants.SET_BUYER_SUMMARY:
      return { ...state, buyerSummaryData: data };
    case activePartiesConstants.SET_PAYMENT_INVOICES:
      return { ...state, paymentInvoices: data };
    case activePartiesConstants.SET_BUYER_INVOICES:
      return { ...state, invoicesList: data };
    default:
      return state;
  }
};

export default activePartiesReducer;
