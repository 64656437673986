import React, { Component } from 'react';
import Download from '../../../../assets/images/download-cloud.png';
import Preview from '../../../../assets/images/eye.png';
import Question from '../../../../assets/images/QuestionIcon.svg';
import Cross from '../../../../assets/images/crossnew.png';
import Greentick from '../../../../assets/images/Greentik.png';
import CompareModal from './CompareModal';
import File from '../../../../assets/images/Frame.png';
import CredoModal from '../../../../components/CredoModal';
import Pan from './OfflineModal.js/PanModal';
import AadhaarBack from './OfflineModal.js/AadharBackModal';
import AadhaarFront from './OfflineModal.js/AadharFrontModal';
import { API_GET_DOCUMENT_VERIFICATION_STATUS } from '../../../../utils/APIUrls';
import { GET } from '../../../../utils/webAPI.service';
import FileDownload from 'js-file-download';

export default class OfflineVerification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
      modalType: CompareModal,
      pan: null,
      aadharFront: null,
      aadhaarBack: null,
    };
  }

  getAadharFrontVerifyStatus = () => {
    let { propsData } = this.props;
    let { match } = propsData;
    GET(
      API_GET_DOCUMENT_VERIFICATION_STATUS(
        match?.params?.loanId,
        'AADHAR_FRONT'
      )
    ).then((data) => {
      this.setState({
        aadharFront: data?.data?.data?.status,
      });
    });
  };

  getAadharBackVerifyStatus = () => {
    let { propsData } = this.props;
    let { match } = propsData;
    GET(
      API_GET_DOCUMENT_VERIFICATION_STATUS(match?.params?.loanId, 'AADHAR_BACK')
    ).then((data) => {
      this.setState({
        aadhaarBack: data?.data?.data?.status,
      });
    });
  };

  getPanVerifyStatus = () => {
    let { propsData } = this.props;
    let { photos } = propsData;
    let { panDocuments } = photos;
    let { match } = propsData;

    GET(
      API_GET_DOCUMENT_VERIFICATION_STATUS(
        match?.params?.loanId,
        panDocuments?.type
      )
    ).then((data) => {
      this.setState({
        pan: data?.data?.data?.status,
      });
    });
  };

  componentDidMount() {
    this.getAadharFrontVerifyStatus();
    this.getAadharBackVerifyStatus();
    this.getPanVerifyStatus();
  }
  toggleModal = (name) => {
    this.setState((prevState) => ({
      modalOpen: !prevState.modalOpen,
      modalType: name,
    }));
  };
  download = async (url, name) => {
    try {
      const response = await fetch(url);
      const blob = await response.blob();
      FileDownload(blob, `${name}.jpeg`);
    } catch (error) {
      console.log(error);
    }
  };

  render() {
    let { modalType, modalOpen, aadhaarBack, aadharFront, pan } = this.state;
    let { propsData } = this.props;
    let { photos } = propsData;
    let { aadharDocuments, panDocuments } = photos;

    return (
      <div className='entity-kyc-wrapper'>
        <div className='row ckyc-wrapper offline_wrapper'>
          <div className='card__wrapper col-5'>
            <div className='card-heading '>
              Aadhaar Card-Front View
              <div className='icon-osv'>
                <h2>Osv</h2>
                <img
                  alt='icon'
                  src={
                    aadharFront === 'success'
                      ? Greentick
                      : aadharFront === 'failure'
                      ? Cross
                      : Question
                  }
                ></img>
              </div>
            </div>
            <div className='card-body'>
              <div>
                <img alt='icon' className='img cursor' src={File} />
                <span> Aadhaar data</span>
              </div>
              <div>
                <img
                  alt='icon'
                  className='img cursor'
                  onClick={() => this.toggleModal(AadhaarFront)}
                  src={Preview}
                />
                <img
                  alt='icon'
                  className='cursor'
                  onClick={() =>
                    this.download(aadharDocuments[0]?.fileUrl, 'aadharFront')
                  }
                  src={Download}
                />
              </div>
            </div>
          </div>
          <div className='card__wrapper col-5'>
            <div className='card-heading '>
              Aadhaar data-Back View
              <div className='icon-osv'>
                <h2>Osv</h2>
                <img
                  alt='icon'
                  src={
                    aadhaarBack === 'success'
                      ? Greentick
                      : aadhaarBack === 'failure'
                      ? Cross
                      : Question
                  }
                />
              </div>
            </div>
            <div className='card-body'>
              <div>
                <img alt='icon' className='img cursor' src={File}></img>
                <span> Aadhaar data</span>
              </div>

              <div>
                <img
                  alt='icon'
                  className='img cursor'
                  onClick={() => this.toggleModal(AadhaarBack)}
                  src={Preview}
                />
                <img
                  alt='icon'
                  className='cursor'
                  src={Download}
                  onClick={() =>
                    this.download(aadharDocuments[1]?.fileUrl, 'aadharBack')
                  }
                />
              </div>
            </div>
          </div>
          <div className='card__wrapper col-5'>
            <div className='card-heading '>
              Pan data
              <div className='icon-osv'>
                <h2>Osv</h2>
                <img
                  alt='icon'
                  src={
                    pan === 'success'
                      ? Greentick
                      : pan === 'failure'
                      ? Cross
                      : Question
                  }
                />
              </div>
            </div>
            <div className='card-body'>
              <div>
                <img alt='icon' className='img cursor' src={File}></img>
                <span> Pan data</span>
              </div>
              <div>
                <img
                  alt='icon'
                  className='img cursor'
                  onClick={() => this.toggleModal(Pan)}
                  src={Preview}
                ></img>
                <img
                  alt='icon'
                  className='cursor'
                  src={Download}
                  onClick={() => this.download(panDocuments?.fileUrl, 'pan')}
                ></img>
              </div>
            </div>
          </div>
        </div>
        <CredoModal
          isOpen={modalOpen}
          styles={{
            content: {
              minWidth: '80vw',
            },
          }}
          closeAction={() => this.toggleModal('')}
          RenderingComponent={modalType}
          photos={photos}
          propsData={propsData}
          aadharFront={() => this.getAadharFrontVerifyStatus()}
          aadhaarBack={() => this.getAadharBackVerifyStatus()}
          pan={() => this.getPanVerifyStatus()}
        />
      </div>
    );
  }
}
