import React, { Component } from 'react';
import Logo from '../../assets/images/logo.png';
import Phone from '../../assets/images/phoneImage.png';
import { API_POST_LOGIN } from '../../utils/APIUrls';
import { Helmet } from 'react-helmet';
import { AES, enc, mode, pad } from 'crypto-js';
import axios from 'axios';

export default class Login extends Component {
  state = {
    email: '',
    password: '',
    encryptedPassword: '',
  };

  submit = async (e) => {
    e.preventDefault();
    let { history } = this.props,
      { email, password } = this.state;
    const encryptedPassword = AES.encrypt(
      password,
      enc.Base64.parse(process.env.REACT_APP_SECRET_KEY),
      {
        pad: pad.Pkcs7,
        mode: mode.ECB,
      }
    ).toString();
    const headers = {
      'X-CLIENT-NAME': 'PDU',
    };
    let datasend = {
      email: email,
      password: encryptedPassword,
    };
    await this.setState({
      encryptedPassword: encryptedPassword,
    });

    axios
      .post(`${API_POST_LOGIN}`, datasend, { headers })
      .then(async ({ data }) => {
        if (data.code === 200) {
          localStorage.setItem('token', data?.data?.token);
          localStorage.setItem('userId', data?.data?.userId);
          history.push('/pay-app/dashboard');
        } else {
          alert(data?.message);
        }
      })
      .catch((err) => {
        throw err;
      });
  };

  render() {
    let { email, password } = this.state;
    return (
      <div className='login-wrapper'>
        <Helmet>
          <meta charSet='utf-8' />
          <title>Pemant</title>
        </Helmet>
        <div className=' row nav-bar container'>
          <img src={Logo} alt='company logo' className='logo_image' />
        </div>
        <div className='login-container'>
          <div className='login-container_left container'>
            <div className='login'>
              <h2>Welcome to</h2>
              <h2> Pemant Admin Dashboard</h2>
              <form onSubmit={(e) => this.submit(e)}>
                <div className='form-input'>
                  <input
                    placeholder=' Enter Login Id or Mobile Number'
                    value={email}
                    autoComplete='false'
                    onChange={(e) => this.setState({ email: e.target.value })}
                  />
                  <input
                    placeholder='Enter Password'
                    className='mt-3'
                    value={password}
                    autoComplete='false'
                    type='password'
                    onChange={(e) =>
                      this.setState({ password: e.target.value })
                    }
                  />
                </div>

                <button type='submit'>Login</button>
              </form>
            </div>
            <div className='login-container_right'>
              <img src={Phone} alt='phone_image' className='phone_image' />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
