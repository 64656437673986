const SET_INVOICE_SUMMARY = Symbol('setInvoiceSummary'),
  SET_CUSTOMER_LIST = Symbol('setCustomerList'),
  SET_FUNDING_SUMMARY = Symbol('setFundingSummary'),
  SET_SUPPLIER_LIST = Symbol('setSupplierList'),
  SET_VENDOR_LIMIT_DETAILS = Symbol('setVendorLimitDetails');

export {
  SET_INVOICE_SUMMARY,
  SET_CUSTOMER_LIST,
  SET_SUPPLIER_LIST,
  SET_FUNDING_SUMMARY,
  SET_VENDOR_LIMIT_DETAILS,
};
