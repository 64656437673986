import React, { Component } from 'react';
import ReactTable from 'react-table';
import { Helmet } from 'react-helmet';
import ExcelIcon from '../../../../assets/images/excel.svg';
import DownloadIcon from '../../../../assets/images/download.png';
import LedgerModal from './LedgerModal';
import CredoModal from '../../../../components/CredoModal';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getCustomerAndSupplierList } from '../../ManageUser/manageUser.actions';
import { loaderModal } from '../../../../store/actions';
import axios from 'axios';

class LedgerAnalysis extends Component {
  state = {
    modalOpen: false,
  };

  toggleModal = (supplierData) => {
    this.setState((prevState) => ({
      modalOpen: !prevState.modalOpen,
      supplierData,
    }));
  };

  onUploadDocument = async (event, supplierId) => {
    const files = event.target.files,
      token = localStorage.getItem('token');
    const formData = new FormData();
    let User = localStorage.getItem('userId');
    let { match, actions } = this.props,
      { userId } = match.params,
      { loaderModal } = actions;
    if (files && files?.length > 0) {
      formData.append('file', files[0]);
    }
    const headers = {
      Accept: 'application/json',
      Authorization: 'Bearer ' + token,
      'Content-Type': 'multipart/formData',
      'X-CLIENT-NAME': 'PDU',
      'X-USER-ID': User,
    };
    loaderModal(true);
    if (formData) {
      await axios
        .post(
          `${process.env.REACT_APP_BASE_URL_PEMANT}/assessment/v1/ledger/upload/excel?userId=${userId}&supplierId=${supplierId}`,
          formData,
          {
            headers,
          }
        )
        .then(({ data }) => {
          loaderModal(false);
          if (data.code === 200) {
            alert('Excel uploaded successfully');
          } else {
            alert(data?.message);
          }
        })
        .catch((err) => {
          console.error(err);
          loaderModal(false);
        });
    }
  };

  componentDidMount = async () => {
    let { match, actions } = this.props,
      { userId } = match.params,
      { getCustomerAndSupplierList, loaderModal } = actions,
      token = localStorage.getItem('token');
    loaderModal(true);
    if (token) {
      await getCustomerAndSupplierList(userId, 'supplier', 1);
      loaderModal(false);
    } else {
      let { history } = this.props;
      alert('Unauthorized access');
      actions.loaderModal(false);
      history.push('/');
    }
  };

  render() {
    let { supplierList } = this.props,
      { userContactList } = supplierList;
    const columns = [
      {
        Header: 'S No.',
        width: 100,
        Cell: ({ index }) => {
          return <div>{index + 1}</div>;
        },
      },
      {
        Header: 'Supplier ID',
        accessor: 'uniqueId',
      },
      {
        Header: 'Supplier Name',
        accessor: 'name',
      },
      {
        Header: 'Upload Excel',
        Cell: ({ original }) => {
          let { uniqueId } = original;
          return (
            <label className='upload-excel-button'>
              <img alt='verificationIcon' className='icon' src={ExcelIcon} />
              <span>Upload File</span>
              <input
                type={'file'}
                className='upload-file'
                multiple
                onChange={(e) => this.onUploadDocument(e, uniqueId)}
                accept='.xlsx ,.xlsm,.xls,.xlt,.xltm,.xml,.csv'
              />
              <img alt='verificationIcon' className='icon' src={DownloadIcon} />
            </label>
          );
        },
      },
      {
        Header: '',
        width: 150,
        Cell: ({ original }) => {
          return (
            <button
              className='basic-button details-button'
              onClick={() => this.toggleModal(original)}
            >
              Analysis
            </button>
          );
        },
      },
    ];
    return (
      <div className='container ledger-analysis-wrapper'>
        <Helmet>
          <meta charSet='utf-8' />
          <title>Ledger Analysis</title>
        </Helmet>
        <div className='card'>
          <h3 className='col-md-10 card_heading_secondary'>Ledger Analysis</h3>
          <ReactTable
            data={userContactList || []}
            columns={columns}
            defaultPageSize={10}
            showPagination={false}
            getTheadThProps={this.getTheadThProps}
            sortable={false}
          />
          <ul className={'datatable-pagination'}>
            <li>
              <button
                disabled={supplierList?.currentPage <= 1}
                className={'prev-btn'}
                type={'button'}
              >
                Previous
              </button>
            </li>
            <li>
              <span>
                Page No. {supplierList?.currentPage} of
                {'  '} {supplierList?.totalPage}
              </span>
            </li>
            <li>
              <button
                disabled={
                  !(supplierList?.currentPage < supplierList?.totalPage)
                }
                className={'next-btn'}
                type={'button'}
              >
                Next
              </button>
            </li>
          </ul>
        </div>
        <CredoModal
          isOpen={this.state.modalOpen}
          styles={{
            content: {
              minWidth: 'auto',
              height: '85vh',
              width: '90vw',
            },
          }}
          closeAction={() => this.toggleModal('')}
          RenderingComponent={LedgerModal}
          stateData={this.state}
          propsData={this.props}
          toggleModal={this.toggleModal}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ manageUserReducer }) => ({
  supplierList: manageUserReducer.supplierList,
});
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      getCustomerAndSupplierList,
      loaderModal,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(LedgerAnalysis);
