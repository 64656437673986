import React from 'react';
import AppCard from '../../../../../components/AppCard';
import CircularProgressbar from 'react-circular-progressbar';
import { ProgressBar } from 'react-bootstrap';
import { rupeeCommaFormatter } from '../../../../../utils/utility';

const LiveLoanAndOutstandingCard = props => {
  let { data, circularData } = props;

  return (
    <>
      <AppCard
        className='live-loan-and-outstanding mt-5'
        heading='Live Loans and outstanding Amount'
      >
        <div class='live-loan-and-outstandingrow row'>
          <div className='live-loan-and-outstandingrow col-md-8 offer_wrapper'>
            <div className='row main-row'>
              <div className='col-md-3 main-row_inner'>
                Total Disbured
                <div className='text-bold'>
                  ₹ {rupeeCommaFormatter(data?.totalDisbursedAmount)}
                </div>
              </div>
              <div className='col-md-3 main-row_inner'>
                Total Outstanding
                <div className='text-bold'>
                  ₹ {rupeeCommaFormatter(data?.totalOutstandingAmount)}
                </div>
              </div>
            </div>
            <div className='live-loan-and-outstanding-row row mx-3 mb-5'>
              <div className='container '>
                <div className='live-loan-heading-main row '>
                  <div className='live-loan-heading-col-head col-4 '>
                    Asset Creation
                  </div>
                  <div className='live-loan-heading-col-head  col-4 '>
                    Business Funding
                  </div>
                  <div className='live-loan-heading-col-head col-4 '>
                    Consumption
                  </div>
                </div>
                <div className='live-loan-heading row'>
                  <div className='col-4 '>
                    <div className='row'>
                      <span className='live-loan-heading-col text-secondary col-6 '>
                        Disbursed
                      </span>
                      <span className='live-loan-heading-col text-secondary col-6 '>
                        Outstanding
                      </span>
                    </div>
                  </div>
                  <div className='col-4'>
                    <div className='live-loan-heading row'>
                      <span className='live-loan-heading-col text-secondary col-6 '>
                        Disbursed
                      </span>
                      <span className='live-loan-heading-col text-secondary col-6 '>
                        Outstanding
                      </span>
                    </div>
                  </div>
                  <div className='col-4 '>
                    <div className=' live-loan-heading row'>
                      <span className='live-loan-heading-col text-secondary col-6 '>
                        Disbursed
                      </span>
                      <span className='live-loan-heading-col text-secondary col-6 '>
                        Outstanding
                      </span>
                    </div>
                  </div>
                </div>
                <div className='live-loan-heading row'>
                  <div className='col-4 '>
                    <div className='row'>
                      <span className='live-loan-heading-col col '>
                        ₹
                        {rupeeCommaFormatter(
                          data?.assetCreation?.disbursedAmount
                        )}
                      </span>
                      <span className='live-loan-heading-col col '>
                        ₹
                        {rupeeCommaFormatter(
                          data?.assetCreation?.outstandingAmount
                        )}
                      </span>
                    </div>
                  </div>
                  <div className='col-4'>
                    <div className='row'>
                      <span className='live-loan-heading-col col '>
                        ₹
                        {rupeeCommaFormatter(
                          data?.businessFunding?.disbursedAmount
                        )}
                      </span>
                      <span className='live-loan-heading-col col '>
                        ₹
                        {rupeeCommaFormatter(
                          data?.businessFunding?.outstandingAmount
                        )}
                      </span>
                    </div>
                  </div>
                  <div className='col-4 '>
                    <div className='row'>
                      <span className='live-loan-heading-col col '>
                        ₹
                        {rupeeCommaFormatter(data?.consumtion?.disbursedAmount)}
                      </span>
                      <span className='live-loan-heading-col col '>
                        ₹
                        {rupeeCommaFormatter(
                          data?.consumtion?.outstandingAmount
                        )}
                      </span>
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-4  py-2 px-6'>
                    <ProgressBar
                      className='progress-bar-new'
                      now={data?.assetCreation?.repaidPercentage}
                    />
                    <div className='progress_text'>
                      Repaid:{data?.assetCreation?.repaidPercentage}%
                    </div>
                  </div>
                  <div className='col-4 py-2 px-6'>
                    <ProgressBar
                      className='progress-bar-new'
                      now={data?.businessFunding?.repaidPercentage}
                    />
                    <div className='progress_text'>
                      Repaid:{data?.businessFunding?.repaidPercentage} %
                    </div>
                  </div>
                  <div className='col-4 py-2 px-6'>
                    <ProgressBar
                      className='progress-bar-new'
                      now={data?.consumtion?.repaidPercentage}
                    />
                    <div className='progress_text'>
                      Repaid:{data?.consumtion?.repaidPercentage}%
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='live-loan-and-outstandingrow-col2 col'>
            <div className='circular-chart'>
              <div
                style={{
                  position: 'relative',
                  width: '100%',
                  height: '100%'
                }}
              >
                <div style={{ position: 'absolute' }}>
                  <CircularProgressbar
                    percentage={circularData}
                    strokeWidth={8}
                    styles={{
                      path: { stroke: '#058373' },
                      text: {
                        fill: '#058373',
                        fontSize: '10px'
                      }
                    }}
                  />
                </div>
                <div
                  className='circular-text'
                  style={{
                    height: '100%',
                    display: 'flex'
                  }}
                >
                  <div>{`${circularData} % `}</div>
                  <div>Outstanding</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AppCard>
    </>
  );
};

export default LiveLoanAndOutstandingCard;
