import React, { Component } from 'react';
import ReactTable from 'react-table';
import { Helmet } from 'react-helmet';
import HeaderTop from '../../../components/HeaderTop';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { activeDashboard } from '../../../store/actions';
import SearchIcon from '../../../assets/images/SearchIcon.svg';
import { getAnchorList } from './dashboard.action';
import { loaderModal } from '../../../store/actions';
class AnchorDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorStatus: 'all',
      pageNumber: 1,
      perPage: 10,
      pageOffSet: 0,
      searchType: '',
      searchValue: '',
      searchFlag: false,
      searchanchorList: [],
      sortingRequest: {
        sortParameter: 0,
        sortingOn: '',
      },
    };
  }

  applicantTabToggle = async (item) => {
    const { actions } = this.props;
    const { getAnchorList } = actions;
    let {
      anchorStatus,
      sortingRequest: { sortParameter, sortingOn },
    } = this.state;
    actions.loaderModal(true);
    await this.setState(
      {
        pageNumber: 1,
        anchorStatus: item,
        searchType: '',
        searchValue: '',
        searchFlag: false,
      },
      () => {
        if (anchorStatus !== item) {
          getAnchorList(item, 1, '', '', {
            //page should be one  and searchType: '', searchValue: ''always when toggle switch applied
            sortParameter,
            sortingOn,
          });
        }
      }
    );
    actions.loaderModal(false);
  };

  onSearch = async (e) => {
    e.preventDefault();
    const { actions } = this.props;
    const { getAnchorList } = actions;
    await this.setState({
      pageNumber: 1,
      searchFlag: true,
    });
    let {
      anchorStatus,
      pageNumber,
      searchType,
      searchValue,
      sortingRequest: { sortParameter, sortingOn },
    } = this.state;

    if (searchType === '') {
      alert('Please select search type');
      return;
    }
    actions.loaderModal(true);
    await getAnchorList(
      anchorStatus,
      pageNumber,
      searchType,
      searchValue?.trim(),
      {
        sortParameter,
        sortingOn,
      }
    );
    let { anchorList } = this.props || {};
    let { list = [] } = anchorList || {};
    this.setState({
      searchanchorList: list,
    });
    actions.loaderModal(false);
  };

  getPageData = async (type, totalPages) => {
    const { actions } = this.props;
    let {
      anchorStatus,
      pageNumber,
      searchType,
      searchValue,
      sortingRequest: { sortParameter, sortingOn },
    } = this.state;
    const { getAnchorList } = actions;
    let updatedPageNumber = pageNumber;
    if (type === 'next' && pageNumber < totalPages) {
      updatedPageNumber = pageNumber + 1;
    } else if (type === 'prev' && pageNumber > 1) {
      updatedPageNumber = pageNumber - 1;
    }
    await this.setState({ pageNumber: updatedPageNumber });
    actions.loaderModal(true);
    getAnchorList(anchorStatus, updatedPageNumber, searchType, searchValue, {
      sortParameter,
      sortingOn,
    });
    actions.loaderModal(false);
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  getSearchPageData = (type) => {
    let { pageNumber, perPage } = this.state;
    let { anchorList } = this.props || {};
    let { list = [], totalPages = 1 } = anchorList || {};
    if (type === 'prev' && pageNumber > 1) {
      pageNumber = pageNumber - 1;
    } else if (type === 'next' && pageNumber < totalPages) {
      pageNumber = pageNumber + 1;
    }

    const pageOffSet = (pageNumber - 1) * perPage;
    const slicedAnchorData = list.slice(pageOffSet, pageOffSet + perPage);

    this.setState({
      pageNumber,
      searchanchorList: slicedAnchorData,
    });
  };

  componentDidMount = () => {
    const { actions } = this.props;
    let {
      anchorStatus,
      pageNumber,
      searchType,
      searchValue,
      sortingRequest: { sortParameter, sortingOn },
    } = this.state;
    const { getAnchorList } = actions;
    actions.loaderModal(true);
    getAnchorList(anchorStatus, pageNumber, searchType, searchValue, {
      sortParameter,
      sortingOn,
    });
    actions.loaderModal(false);
  };

  rowClicked = (state, rowInfo, column) => {
    return {
      onClick: () => {
        var win = window.open(
          `/anchor-dashboard/${rowInfo?.original?.applicationId}/downstream`
        );
        win?.focus();
      },
    };
  };

  render() {
    let { activeDashboard, anchorList } = this.props || {};
    let { list = [], totalPages = 1 } = anchorList || {};
    let { pageNumber, searchType, searchValue, searchFlag, searchanchorList } =
      this.state;
    const anchorListColumn = [
      {
        Header: 'Application Id',
        accessor: 'applicationId',
        width: 130,
      },
      {
        Header: 'Date Of Joining',
        accessor: 'dateOfJoining',
        width: 130,
      },
      {
        Header: 'Business Name',
        width: 200,
        Cell: ({ original }) => {
          return (
            <>
              <div className=''>{original?.tradeName}</div>
              <div className='gst-text mt-1'>{original?.gstin}</div>
            </>
          );
        },
      },
      {
        Header: 'POC',
        accessor: 'name',
        width: 180,
      },

      {
        Header: 'Contact Number',
        accessor: 'mobileNumber',
      },
      {
        Header: 'Email Id',
        accessor: 'email',
        width: 200,
      },
      {
        Header: () => (
          <>
            <div className='ml-5'>Status</div>
          </>
        ),
        accessor: 'status',
        Cell: ({ original }) => {
          let { status } = original,
            colorStyles;
          const obj = {
            ACTIVE: [' #39B73F', '#F4FBF4'],
            IN_ACTIVE: ['#FF7D1A', ' #FFF7EB'],
          };
          for (const property in obj) {
            if (status === property) {
              colorStyles = {
                color: obj[property][0],
                backgroundColor: obj[property][1],
                borderLeftColor: obj[property][0],
              };
            }
          }

          return (
            <>
              {status && (
                <>
                  <button className='button_common ml-5' style={colorStyles}>
                    {status === 'ACTIVE'
                      ? 'Active'
                      : status === 'IN_ACTIVE'
                      ? 'On Hold'
                      : null}
                  </button>
                </>
              )}
            </>
          );
        },
      },
    ];

    return (
      <>
        <div className='dashboard-wrapper'>
          <Helmet>
            <meta charSet='utf-8' />
            <title>Anchor Dashboard</title>
          </Helmet>
          <HeaderTop {...this.props}>
            <div className='buttons-box'>
              <div className='toggle-buttons'>
                <div
                  onClick={() => {
                    let { history, actions } = this.props;
                    actions.activeDashboard('user_dashboard');
                    history.push(`/pay-app/dashboard`);
                  }}
                  className={
                    activeDashboard === 'user_dashboard'
                      ? 'active-button button-left '
                      : 'button-left '
                  }
                >
                  User Dashboard
                </div>
                <div
                  onClick={() => {
                    let { history, actions } = this.props;
                    actions.activeDashboard('invoice_dashboard');
                    history.push(`/invoice-dashboard/all-invoices`);
                  }}
                  className={
                    activeDashboard === 'invoice_dashboard'
                      ? 'active-button button-left button-center '
                      : 'button-left button-center '
                  }
                >
                  Invoice Dashboard
                </div>
                <div
                  onClick={() => {
                    let { history, actions } = this.props;
                    actions.activeDashboard('anchor_dashboard');
                    history.push(`/anchor-dashboard`);
                  }}
                  className={
                    activeDashboard === 'anchor_dashboard'
                      ? 'active-button button-left button-right'
                      : 'button-left button-right'
                  }
                >
                  Anchor Dashboard
                </div>
              </div>
            </div>
          </HeaderTop>
          <div className='filter'>
            <div className='filter_container container'>
              <div
                onClick={() => this.applicantTabToggle('all')}
                className={
                  this.state.anchorStatus === 'all'
                    ? 'button filter_container_active-button'
                    : 'button filter_container_normal-button'
                }
              >
                ALL
              </div>
              <div
                onClick={() => this.applicantTabToggle('ACTIVE')}
                className={
                  this.state.anchorStatus === 'ACTIVE'
                    ? 'button filter_container_active-button'
                    : 'button filter_container_normal-button'
                }
              >
                ACTIVE
              </div>
              <div
                onClick={() => this.applicantTabToggle('IN_ACTIVE')}
                className={
                  this.state.anchorStatus === 'IN_ACTIVE'
                    ? 'button filter_container_active-button'
                    : 'button filter_container_normal-button'
                }
              >
                ON HOLD
              </div>
            </div>
          </div>
          <div className={' container invoices-wrapper mt-5'}>
            <div className='card'>
              <div className='row py-5'>
                <div className='col-5'>
                  <form onSubmit={(e) => this.onSearch(e)}>
                    <div className='search-form'>
                      <select
                        className='select'
                        onChange={this.handleChange}
                        name='searchType'
                        required
                        value={searchType === '' ? '' : searchType}
                      >
                        <option value=''>Search By</option>
                        <option value={'mobileNumber'}>Mobile No</option>
                        <option value={'gst'}>GST</option>
                        <option value={'legalName'}>Business Name</option>
                      </select>
                      <input
                        className='select input'
                        required={true}
                        placeholder='Search'
                        name='searchValue'
                        value={searchValue}
                        onChange={this.handleChange}
                      />
                      <button type='submit' className='submit'>
                        <img src={SearchIcon} alt='icon' />
                      </button>
                    </div>
                  </form>
                </div>
              </div>
              <div className='p-4'>
                {!searchFlag ? (
                  <>
                    <ReactTable
                      data={list}
                      columns={anchorListColumn}
                      defaultPageSize={10}
                      showPagination={false}
                      sortable={false}
                      getTdProps={this.rowClicked}
                    />
                    <ul className={'datatable-pagination'}>
                      <li>
                        <button
                          disabled={!(pageNumber > 1)}
                          className={'prev-btn'}
                          type={'button'}
                          onClick={() => this.getPageData('prev', totalPages)}
                        >
                          Previous
                        </button>
                      </li>
                      <li>
                        <span>
                          Page No. {totalPages === 0 ? 0 : pageNumber} of{' '}
                          {totalPages}
                        </span>
                      </li>
                      <li>
                        <button
                          disabled={!(totalPages > pageNumber)}
                          className={'next-btn'}
                          type={'button'}
                          onClick={() => this.getPageData('next', totalPages)}
                        >
                          Next
                        </button>
                      </li>
                    </ul>
                  </>
                ) : (
                  <>
                    <ReactTable
                      data={searchanchorList}
                      columns={anchorListColumn}
                      defaultPageSize={10}
                      showPagination={false}
                      sortable={false}
                      getTdProps={this.rowClicked}
                    />
                    <ul className={'datatable-pagination'}>
                      <li>
                        <button
                          disabled={!(pageNumber > 1)}
                          className={'prev-btn'}
                          type={'button'}
                          onClick={() => this.getSearchPageData('prev')}
                        >
                          Previous
                        </button>
                      </li>
                      <li>
                        <span>
                          Page No. {pageNumber} of {totalPages || 1}
                        </span>
                      </li>
                      <li>
                        <button
                          disabled={!(totalPages > pageNumber)}
                          className={'next-btn'}
                          type={'button'}
                          onClick={() => this.getSearchPageData('next')}
                        >
                          Next
                        </button>
                      </li>
                    </ul>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

AnchorDashboard.defaultProps = {
  active: '',
};

const mapStateToProps = ({ anchorReducer, data }) => ({
  anchorList: anchorReducer.anchorList,
  activeDashboard: data.activeDashboard,
});
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      getAnchorList,
      activeDashboard,
      loaderModal,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(AnchorDashboard);
