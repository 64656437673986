import React, { Component } from 'react';
import ReactTable from 'react-table';
import DownArrow from '../../../assets/images/downArrow.png';

export default class MarginModal extends Component {
  render() {
    let { marginDetails } = this.props;
    let { data } = marginDetails || {};
    let { paymentDetails } = data || [];
    const paymentDetailsArray = paymentDetails ? [paymentDetails] : [];

    const outstandingInvoiceColumns = [
      {
        Header: 'Account Holder',
        accessor: 'accountHolder',
      },
      {
        Header: 'Account Number',
        accessor: 'accountNumber',
      },
      {
        Header: 'IFSC Code',
        accessor: 'ifscCode',
      },
      {
        Header: 'Bank Name',
        accessor: 'bankName',
      },
      {
        Header: 'Branch',
        accessor: 'branchName',
      },
      {
        Header: 'UPI ID',
        accessor: 'upiId',
      },
      {
        Header: 'QR Ref',
        accessor: 'qrReference',
      },
    ];
    return (
      <div className='compare-details-wrapper'>
        <h3 className='card_heading'>
          Invoice Details
          <img src={DownArrow} alt='download' className='downArrow' />
        </h3>

        <div className='border-bottom'>
          <div className='row'>
            <div className='col-md-3 text-grey'>
              Invoice Status
              <div className='text-bold'>{data?.invoiceStatus || '-'}</div>
            </div>
            <div className='col-md-3 text-grey'>
              Funding Status
              <div className='text-bold'>{data?.fundingStatus || '-'}</div>
            </div>
            <div className='col-md-3 text-grey'>
              Invoice Date
              <div className='text-bold'>{data?.invoiceDate || '-'}</div>
            </div>
            <div className='col-md-3 text-grey'>
              Party
              <div className='text-bold'>{data?.partyName || '-'}</div>
            </div>
          </div>
          <div className='row'>
            <div className='col-md-3 text-grey'>
              Payment Date
              <div className='text-bold'>{data?.paymentDate || '-'}</div>
            </div>
            <div className='col-md-3 text-grey'>
              Payment Mode
              <div className='text-bold'>{data?.paymentMode || '-'}</div>
            </div>
            <div className='col-md-3 text-grey'>
              Payment Reference
              <div className='text-bold'>{data?.paymentReference || '-'}</div>
            </div>
          </div>
        </div>
        <h3 className='card_heading mt-4 mb-4'>Bank Account Details</h3>
        <ReactTable
          columns={outstandingInvoiceColumns}
          data={paymentDetailsArray}
          defaultPageSize={1}
          showPagination={false}
        />
      </div>
    );
  }
}
