import React, { Component } from 'react';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { Kyc } from './gst.config';
export default class kycComparisionModal extends Component {
  render() {
    return (
      <div className=' gst__container gst_modal_wrapper'>
        <div className=' box-wrapper'>
          <div className='box-header'>
            <h4>GstIN-NA</h4>
          </div>
          <div>
            <ReactTable columns={Kyc}></ReactTable>
          </div>
        </div>
      </div>
    );
  }
}
