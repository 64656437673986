import * as CreditBehaviourConstant from './creditBehaviour.actions.constant';
import { GET, POST } from '../../../../utils/webAPI.service';
import {
  API_FETCH_ANALYSIS,
  API_GET_APPLICANT_SUMMARY,
  API_GET_CREDIT_RULES
} from '../../../../utils/APIUrls';

const getApplicantsSummary = loanId => dispatch => {
  return GET(API_GET_APPLICANT_SUMMARY(loanId))
    .then(({ data }) => {
      if (data.code === 200) {
        dispatch({
          type: CreditBehaviourConstant.GET_APPLICANTS_SUMMARY,
          data: data.data
        });
        return data;
      }
    })
    .catch(err => {
      throw err;
    });
};

const fetchAnalysis = (loanId, data) => dispatch => {
  return POST(API_FETCH_ANALYSIS(loanId), data)
    .then(({ data }) => {
      if (data.code === 200) {
        dispatch({
          type: CreditBehaviourConstant.FETCH_ANALYSIS,
          data: data.data
        });
        dispatch({
          type: CreditBehaviourConstant.SET_INITIAL_RESPONSE,
          status: true
        });
      }
      return data;
    })
    .catch(err => {
      throw err;
    });
};

const setInitialResponse = status => ({
  type: CreditBehaviourConstant.SET_INITIAL_RESPONSE,
  status
});

const getCreditRules = loanId => dispatch => {
  return GET(API_GET_CREDIT_RULES(loanId))
    .then(({ data }) => {
      if (data.code === 200) {
        dispatch({
          type: CreditBehaviourConstant.GET_CREDIT_RULES,
          data: data.data
        });
        return data;
      }
    })
    .catch(err => {
      throw err;
    });
};

export {
  getApplicantsSummary,
  fetchAnalysis,
  setInitialResponse,
  getCreditRules
};
