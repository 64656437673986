import React, { Component } from 'react';
import { rupeeDecimalFormatter } from '../../../utils/utility';

export default class OfferHistoryModal extends Component {
  render() {
    let { propsData } = this.props,
      { offerHistory, match, promoterData } = propsData,
      { userId } = match.params;
    return (
      <div className='offer_wrapper'>
        <h3 className='modal_title'>Offer History</h3>
        {offerHistory?.map((e, index) => {
          return (
            <div key={`offer-${index}`}>
              <div className='row main-row border-grey '>
                <div className='card'>
                  <h3 className='modal_card-heading '>
                    Offer Details-{index + 1}
                  </h3>

                  <div className='row main-row border-grey '>
                    <div className='col-md-2 main-row_inner'>
                      Entity Name
                      <div className='text-bold'>
                        {promoterData?.entityName ?? '-'}
                      </div>
                    </div>
                    <div className='col-md-2 main-row_inner'>
                      User ID
                      <div className='text-bold'>{userId ?? '-'}</div>
                    </div>
                    <div className='col-md-3 main-row_inner'>
                      Offer ID
                      <div className='text-bold '>
                        {e?.offerId || '-'}
                        {e.isActive === true ? (
                          <span className=' offer-history_active px-4 mx-2'>
                            Active
                          </span>
                        ) : (
                          <span className='  offer-history_expired px-4 mx-2'>
                            Expired
                          </span>
                        )}{' '}
                      </div>
                    </div>
                    <div className='col-md-2 main-row_inner'>
                      Program
                      <div className='text-bold'>{e?.programName ?? '-'}</div>
                    </div>
                    <div className='col-md-2 main-row_inner'>
                      Sub-Program
                      <div className='text-bold'>
                        {e?.subProgramName ?? '-'}
                      </div>
                    </div>
                    <div className='col-md-2 main-row_inner'>
                      Sanction Limit
                      <div className='text-bold'>
                        {rupeeDecimalFormatter(e?.offerLimitAmt)}
                      </div>
                    </div>
                  </div>

                  <div className='row main-row border-grey '>
                    <div className='col-md-2 main-row_inner'>
                      Sanction Date
                      <div className='text-bold'>{e?.sanctionDate ?? '-'}</div>
                    </div>
                    <div className='col-md-2 main-row_inner'>
                      Limit Validity
                      <div className='text-bold'>
                        {e?.limitTenureInDays ?? '-'} days
                      </div>
                    </div>

                    <div className='col-md-2 main-row_inner'>
                      Standard Tenure
                      <div className='text-bold'>{e?.tenure ?? '-'} days</div>
                    </div>
                    <div className='col-md-1'>
                      <div className=' text-grey'>
                        Status
                        <div className='text-bold'>{e?.status ?? '-'}</div>
                      </div>
                    </div>
                  </div>

                  <div className='row main-row border-grey '>
                    <div className='col-md-2 main-row_inner'>
                      Interest Rate
                      <div className='text-bold'>
                        {e?.interestRate || '-'} %
                      </div>
                    </div>
                    <div className='col-md-2 main-row_inner'>
                      Grace Period
                      <div className='text-bold'>
                        {e?.gracePeriodInDays || '-'} days
                      </div>
                    </div>
                  </div>

                  <div className='row main-row'>
                    <div className='col-md-2 main-row_inner'>
                      Extension
                      <div className='text-bold'>
                        {e?.extension || '-'} days
                      </div>
                    </div>
                    <div className='col-md-2 main-row_inner'>
                      Extension Interest Rate
                      <div className='text-bold'>
                        {e?.extensionInterestRate || '-'} %
                      </div>
                    </div>
                    <div className='col-md-2 main-row_inner'>
                      Overdue Interest Rate
                      <div className='text-bold'>
                        {e?.overdueIntRate || '-'} %
                      </div>
                    </div>
                  </div>
                  <div className='row main-row'>
                    <div className='col-md-2 main-row_inner'>
                      Setup Fees
                      <div className='text-bold'>
                        {rupeeDecimalFormatter(e?.setupFees)}
                      </div>
                    </div>
                    <div className='col-md-2 main-row_inner'>
                      Limit Maintanence
                      <div className='text-bold'>
                        {rupeeDecimalFormatter(e?.limitMaintancefees)}
                      </div>
                    </div>
                  </div>

                  <div className='row main-row'>
                    <div className='col-md-2 main-row_inner'>
                      Subvention
                      <div className='text-bold'>
                        {e?.isSubvented ? 'Yes' : 'No'}
                      </div>
                    </div>
                    <div className='col-md-2 main-row_inner'>
                      Subvention Period
                      <div className='text-bold'>
                        {e?.subventionPeriodInDays || '-'} days
                      </div>
                    </div>
                    <div className='col-md-2 main-row_inner'>
                      Subvention Interest Rate
                      <div className='text-bold'>
                        {e?.subventionIntRate || '-'}%
                      </div>
                    </div>
                    <div className='col-md-2 main-row_inner'>
                      Subvention Bearing
                      <div className='text-bold'>
                        {e?.subventionBearing || '-'}%
                      </div>
                    </div>
                  </div>
                  <div className='row main-row'>
                    <div className='col-md-2 main-row_inner'>
                      Subvention Break Charge
                      <div className='text-bold'>
                        {e?.subventionBreakCharge ? 'Yes' : 'No'}
                      </div>
                    </div>
                    <div className='col-md-2 main-row_inner'>
                      Flat Charge
                      <div className='text-bold'>
                        {e?.isflatCharge ? 'Yes' : 'No'}
                      </div>
                    </div>

                    <div className='col-md-2 main-row_inner'>
                      Flat Charge Amount
                      <div className='text-bold'>
                        {rupeeDecimalFormatter(e?.flatChargeAmount)}
                      </div>
                    </div>
                    <div className='col-md-2 main-row_inner'>
                      Flat Charge Percentage
                      <div className='text-bold'>
                        {e?.flatChargePercentage || '-'} %
                      </div>
                    </div>
                  </div>
                  <div className='row main-row'>
                    <div className='col-md-2 main-row_inner'>
                      Margin
                      <div className='text-bold'>
                        {e?.isMargined ? 'Yes' : 'No'}
                      </div>
                    </div>
                    <div className='col-md-2 main-row_inner'>
                      Margin Percentage
                      <div className='text-bold'>
                        {e?.marginPercentage || '-'}%
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  }
}
