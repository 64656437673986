import React, { Component } from 'react';
import ReactTable from 'react-table';
import ExcelIcon from '../../../../assets/images/excel.svg';
import Button from '../../../../components/Button';
import SearchIcon from '../../../../assets/images/SearchIcon.svg';
import InvoiceButton from '../../../../assets/images/invoice-button.png';
import { getPaymentInvoicesList } from './activeParties.action';
import PaymentInvoiceModal from './PaymentModal';
import CredoModal from '../../../../components/CredoModal';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { rupeeDecimalFormatter } from '../../../../utils/utility';
import axios from 'axios';
import fileDownload from 'js-file-download';
import { API_BUYER_PAYMENT_EXCEL } from '../../../../utils/APIUrls';
import Greentick from '../../../../assets/images/green tick.svg';
import Processing from '../../../../assets/images/Processing.svg';
import File from '../../../../assets/images/form.svg';
class PaymentView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalOpen: false,
      showDatePicker: false,
      paymentModal: {},
      endDate: null,
      startDate: null,
      from: '',
      pageNumber: 1,
      searchBy: '',
      to: '',
      searchValue: '',
    };
  }

  excelDownload = async () => {
    let {
      match: { params },
    } = this.props;
    const token = localStorage.getItem('token'),
      headers = {
        'X-CLIENT-NAME': 'PDU',
        'X-USER-ID': localStorage.getItem('userId'),
        Authorization: `Bearer ${token}`,
      };
    axios({
      url: `${API_BUYER_PAYMENT_EXCEL(params?.anchorId, params?.buyerId)}`,
      method: 'GET',
      headers: headers,
      responseType: 'blob', // Important
    }).then((res) => {
      fileDownload(res.data, `payment_excel_${params?.buyerId}.xlsx`);
    });
  };

  getPageData = async (type, totalPages) => {
    const {
      actions,
      match: { params },
    } = this.props;
    let { pageNumber, searchBy, searchValue, from, to } = this.state;
    const { getPaymentInvoicesList } = actions;
    let updatedPageNumber = pageNumber;
    if (type === 'next' && pageNumber < totalPages) {
      updatedPageNumber = pageNumber + 1;
    } else if (type === 'prev' && pageNumber > 1) {
      updatedPageNumber = pageNumber - 1;
    }
    await this.setState({ pageNumber: updatedPageNumber });
    getPaymentInvoicesList(
      from,
      updatedPageNumber,
      searchBy,
      searchValue,
      to,
      params?.anchorId,
      params?.buyerId
    );
  };

  componentDidMount = () => {
    const {
      actions,
      match: { params },
    } = this.props;
    let { pageNumber, searchBy, searchValue, from, to } = this.state;
    const { getPaymentInvoicesList } = actions;
    getPaymentInvoicesList(
      from,
      pageNumber,
      searchBy,
      searchValue,
      to,
      params?.anchorId,
      params?.buyerId
    );
  };

  onResetFilter = () => {
    let { types } = this.state;
    const {
      actions,
      match: { params },
    } = this.props;
    const { getPaymentInvoicesList } = actions;
    this.setState(
      {
        from: '',
        pageNumber: 1,
        searchBy: '',
        searchValue: '',
        to: '',
      },
      () =>
        getPaymentInvoicesList(
          '',
          1,
          '',
          '',
          '',
          params?.anchorId,
          params?.buyerId
        )
    );
  };

  toggleModal = (modalType, original) => {
    this.setState({
      isModalOpen: !this.state.isModalOpen,
      modalType,
      paymentModal: original,
    });
  };

  onSearch = async (e) => {
    e.preventDefault();
    const {
      actions,
      match: { params },
    } = this.props;
    const { getPaymentInvoicesList } = actions;
    await this.setState({
      pageNumber: 1,
    });
    let { pageNumber, searchBy, searchValue, from, to } = this.state;
    if (searchBy === '') {
      alert('Please select search type');
      return;
    }
    await getPaymentInvoicesList(
      from,
      pageNumber,
      searchBy,
      searchValue,
      to,
      params?.anchorId,
      params?.buyerId
    );
  };

  onDateFilter = async (e) => {
    e.preventDefault();
    const {
      actions,
      match: { params },
    } = this.props;
    let { from, to } = this.state;
    const { getPaymentInvoicesList } = actions;
    const formattedStartDate = from ? from.split('-').reverse().join('/') : '';
    const formattedEndDate = to ? to.split('-').reverse().join('/') : '';
    await this.setState((prevState) => ({
      ...prevState,
      showDatePicker: !prevState.showDatePicker,
      from: formattedStartDate,
      to: formattedEndDate,
      pageNumber: 1,
    }));
    let { pageNumber, searchBy, searchValue } = this.state;
    getPaymentInvoicesList(
      formattedStartDate,
      pageNumber,
      searchBy,
      searchValue,
      formattedEndDate,
      params?.anchorId,
      params?.buyerId
    );
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value?.trim(),
    });
  };

  render() {
    let { showDatePicker, isModalOpen, modalType, pageNumber, paymentModal } =
      this.state;
    let {
      match: { params },
    } = this.props;

    const paymentInvoicesColumn = [
      {
        Header: () => (
          <>
            <div className='ml-4'>Party Name</div>
          </>
        ),
        accessor: 'partyName',
        width: 280,
        Cell: (props) => {
          let { original } = props;
          let { partyName } = original;
          return <>{<div>{partyName || 'NA'}</div>}</>;
        },
      },

      {
        Header: () => (
          <>
            <div className='ml-2'>Invoice Number</div>
          </>
        ),
        accessor: 'listOfInvNumber',
        Cell: ({ original }) => {
          return (
            <>
              <img className='mr-2 img-icon' src={File} />
              <span
                className='invoice_number mr-5'
                onClick={() => {
                  this.toggleModal(PaymentInvoiceModal, original);
                }}
              >
                {original?.listOfInvNumber}
              </span>
            </>
          );
        },
      },
      {
        Header: 'Payment Type',
        accessor: 'paymentType',
        width: 130,
      },

      {
        Header: 'Payment Amount',
        accessor: 'paymentAmount',
        Cell: (props) => {
          let { original } = props;
          let { paymentAmount } = original;
          return <>{rupeeDecimalFormatter(paymentAmount, '₹')}</>;
        },
      },
      {
        Header: 'Payment Date',
        accessor: 'paymentDate',
        Cell: (props) => {
          let { original } = props;
          let { paymentDate } = original;
          return <div className='ml-3'>{paymentDate}</div>;
        },
      },
      {
        Header: 'UTR',
        accessor: 'utrNumber',
        Cell: (props) => {
          let { original } = props;
          let { utrNumber } = original;
          return (
            <>
              <div className='ml-4'>{utrNumber}</div>
              {original?.status === 'processed' ? (
                <div className='d-flex ml-3 mt-2'>
                  <img className='img-icon' src={Greentick} />
                  <div className='ml-3'>Processed</div>
                </div>
              ) : original?.status === 'disbursal_pending_with_lendar' ? (
                <div className='d-flex ml-3 mt-2'>
                  <img className='img-icon' src={Processing} />
                  <div className='ml-3'>Processing</div>
                </div>
              ) : null}
            </>
          );
        },
        width: 230,
      },
    ];

    let { paymentInvoices } = this.props || {};
    let { listOfPaymentHistory, totalPage } = paymentInvoices || {};
    return (
      <>
        <div className='row interest_charges_wrapper mt-5'>
          <div className='col-10'>
            <div className='row'>
              <div className='col-6'>
                <form onSubmit={(e) => this.onSearch(e)}>
                  <div className='search-form'>
                    <select
                      className='select'
                      onChange={this.handleChange}
                      name='searchBy'
                      value={this.state.searchBy || ''}
                      required
                    >
                      <option value='' disabled selected>
                        Search By
                      </option>
                      <option value={'invoiceNumber'}>Invoice No</option>
                    </select>
                    <input
                      className='select input'
                      required={true}
                      placeholder='Search'
                      name='searchValue'
                      value={this.state.searchValue}
                      onChange={this.handleChange}
                    />
                    <button type='submit' className='submit'>
                      <img src={SearchIcon} alt='icon' />
                    </button>
                  </div>
                </form>
              </div>
              <div className='col-4'>
                <Button className='mt-1' onClick={this.onResetFilter}>
                  Reset
                </Button>
              </div>
            </div>
          </div>
          <div className='col-2 card_heading_secondary_right'>
            <a onClick={this.excelDownload} download>
              <img
                src={ExcelIcon}
                alt='icon'
                className='icon_image h-75 mr-4'
              />
            </a>
            <img
              src={InvoiceButton}
              alt='icon'
              className='icon_image '
              onClick={() =>
                this.setState((prevState) => ({
                  ...prevState,
                  showDatePicker: !prevState.showDatePicker,
                }))
              }
            />
          </div>
        </div>

        {showDatePicker ? (
          <div className='row d-flex justify-content-end'>
            <div className='card date-picker col-4'>
              <div className='row  '>
                <div className='col text-grey mt-4'>
                  Start Date
                  <input
                    className='date'
                    type='date'
                    value={this.state.from.split('/').reverse().join('-')}
                    onChange={(e) =>
                      this.setState({
                        from: e.target.value,
                      })
                    }
                  ></input>
                </div>
                <div className='col text-grey mt-4'>
                  End Date
                  <input
                    className='date'
                    type='date'
                    value={this.state.to.split('/').reverse().join('-')}
                    onChange={(e) =>
                      this.setState({
                        to: e.target.value,
                      })
                    }
                  ></input>
                </div>
              </div>
              <div className='row mt-3 ml-2'>
                <Button onClick={(e) => this.onDateFilter(e)}>OK</Button>
              </div>
            </div>
          </div>
        ) : null}
        <div className='mt-4 interest_charges_wrapper'>
          <ReactTable
            data={listOfPaymentHistory}
            columns={paymentInvoicesColumn}
            defaultPageSize={10}
            showPagination={false}
            sortable={false}
          />
          <ul className={'datatable-pagination'}>
            <li>
              <button
                disabled={!(pageNumber > 1)}
                className={'prev-btn'}
                type={'button'}
                onClick={() => this.getPageData('prev', totalPage)}
              >
                Previous
              </button>
            </li>
            <li>
              <span>
                Page No. {totalPage === 0 ? 0 : pageNumber} of {totalPage}
              </span>
            </li>
            <li>
              <button
                disabled={!(totalPage > pageNumber)}
                className={'next-btn'}
                type={'button'}
                onClick={() => this.getPageData('next', totalPage)}
              >
                Next
              </button>
            </li>
          </ul>
        </div>
        <CredoModal
          isOpen={isModalOpen}
          styles={{
            content: {
              width: '80%',
              height: '80%',
            },
          }}
          closeAction={() => this.toggleModal('')}
          RenderingComponent={modalType}
          toggleModal={this.toggleModal}
          paymentModal={paymentModal}
          supplierId={params?.anchorId}
        />
      </>
    );
  }
}

const mapStateToProps = ({ activePartiesReducer }) => ({
  paymentInvoices: activePartiesReducer?.paymentInvoices,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      getPaymentInvoicesList,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(PaymentView);
