import React, { Component } from 'react';
import {
  API_POST_NOTES,
  API_POST_REJECT_COMMENT,
} from '../../../../utils/APIUrls';
import { POST } from '../../../../utils/webAPI.service';

export class CommentModal extends Component {
  state = {
    comment: this.props.comment || '',
  };

  handleChange = (event) => {
    const value = event.target.value;
    this.setState({ comment: value });
  };

  saveCommentsHandler = async () => {
    const { loanId } = this.props;
    const userId = localStorage.getItem('userId');
    const data = {
      comment: this.state.comment?.trim(),
      serviceRequestBy: userId,
      uniqueId: loanId,
      type: 'onboarding_journey',
    };

    try {
      const response = await POST(API_POST_NOTES, data);
      if (response.data.code === 200) {
        return true;
      } else {
        alert(response.data?.message);
        return false;
      }
    } catch (err) {
      console.error('Error saving comment:', err);
      return false;
    }
  };

  handleConfirm = async () => {
    const success = await this.saveCommentsHandler();
    if (success) {
      this.props.refreshPage();
      this.props.closeAction();
    }
  };

  render() {
    return (
      <div className='modal_card services-wrapper'>
        <h3 className='modal_card-heading'>Comments</h3>
        <div className='row m-3 col-12'>
          <textarea
            className='w-100 rounded'
            placeholder='Add Your comments here....'
            value={this.state.comment}
            onChange={this.handleChange}
          />
        </div>
        <div className='row mt-5 buttons_wrapper'>
          <button
            className='col-md-2 button cancel_button mr-4'
            onClick={this.props.closeAction}
          >
            Cancel
          </button>
          <button
            className='col-md-2 button add_button'
            onClick={this.handleConfirm}
          >
            Submit
          </button>
        </div>
      </div>
    );
  }
}
