import * as invitationConstants from './invite.action.constant';
import { API_BUYER_INVITATION } from '../../../../utils/APIUrls';
import { POST } from '../../../../utils/webAPI.service';

const getInvitationList = (pageNumber, searchBy, searchValue, supplierId) => {
  let dataToSend = {
    pageNumber,
    searchBy,
    searchValue,
    supplierId,
  };
  return (dispatch) => {
    return POST(API_BUYER_INVITATION, dataToSend)
      .then(({ data }) => {
        if (data.code === 200) {
          if (data?.data) {
            dispatch({
              type: invitationConstants.SET_BUYER_INVITATION,
              data: data.data,
            });
          }
        } else if (data.code === 401) {
          alert(data?.message);
        }
        return data;
      })
      .catch((err) => {
        alert('Something went wrong');
        throw err;
      });
  };
};

export { getInvitationList };
