import React from 'react';
import AppCard from '../../../../../components/AppCard';
import Cross from '../../../../../assets/images/crossnew.png';
import 'react-table/react-table.css';
import Tick from '../../../../../assets/images/DedupeTick.png';
const PersonalInfoCard = props => {
  let { summary } = props,
    { telephoneInfo, addressInfo, dobInfo } = summary,
    { isMobileNumberMatch, mobileNumber } = telephoneInfo,
    { dateOfBirth, isDobMatch } = dobInfo,
    { userInputAddress } = addressInfo;
  return (
    <>
      <AppCard className='personal_wrapper' heading='Personal Info'>
        <AppCard
          className='personal_wrapper-row-table personal_wrapper-row'
          heading='Phone'
          color={isMobileNumberMatch ? '#058373' : '#FF6055'}
          bgcolor={isMobileNumberMatch ? '#ECF9F1' : '#FFEFEE'}
        >
          <div className='row'>
            <div className='personal-table col-6'>
              <table>
                <tbody>
                  {telephoneInfo?.telephoneInfo?.map(item => {
                    return (
                      <tr>
                        <td className='col-8 pl-3'>{item?.telephoneNumber}</td>
                        <td>Reported on:{item?.dateReported}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <div className='col-6'>
              <div className='matching'>
                <div>{mobileNumber}</div>
                <div>
                  <img
                    src={isMobileNumberMatch ? Tick : Cross}
                    alt='icon-image'
                  />
                </div>
              </div>
            </div>
          </div>
        </AppCard>
        <AppCard
          className='personal_wrapper-row-table personal_wrapper-row'
          heading='DOB ( Date Of Birth )'
          bgcolor={isDobMatch ? '#ECF9F1' : '#FFEFEE'}
          color={isMobileNumberMatch ? '#058373' : '#FF6055'}
        >
          <div className='row'>
            <div className='personal-table col-6'>
              <table>
                <tbody>
                  {dobInfo?.dobInfo?.map(item => {
                    return (
                      <tr>
                        <td className='col-8 pl-3'>{item?.userDateOfBirth}</td>
                        <td>Reported on:{item?.dateReported}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <div className='col-6'>
              <div className='matching'>
                <div>{dateOfBirth}</div>
                <div>
                  <img src={isDobMatch ? Tick : Cross} alt='icon-image' />
                </div>
              </div>
            </div>
          </div>
        </AppCard>
        <AppCard
          className='personal_wrapper-row-table personal_wrapper-row'
          heading='Address'
          bgcolor={'#ECF9F1'}
        >
          <div className='row'>
            <div className='personal-table col-6'>
              <table>
                <tbody>
                  {addressInfo?.addressInfo?.map(item => {
                    return (
                      <tr>
                        <td className='col-8 pl-3'>
                          {item?.addressLine1},{item?.addressStateCode},
                          {item?.addressPinCode}
                        </td>
                        <td>Reported on:{item?.dateReported}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <div className='col-6'>
              <div className='matching'>
                <div>{userInputAddress}</div>
              </div>
            </div>
          </div>
        </AppCard>
      </AppCard>
    </>
  );
};

export default PersonalInfoCard;
