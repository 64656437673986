import React, { Component } from 'react';
export default class UpiModal extends Component {
  render() {
    let { stateData } = this.props;
    let { original } = stateData;
    let { upiValidationResponse = {} } = original;
    let {
      customer_name,
      is_above_threshold,
      status,
      status_description,
      virtual_address
    } = upiValidationResponse || {};
    return (
      <>
        <div className='modal-wrapper manage_user-wrapper ml-3'>
          <h3 className='card_heading'>Upi Validation Status </h3>
          <div className='row'>
            <div className='col-md-3  text-grey'>NAME :</div>
            <span className='text-bold mt-3'>{customer_name || '-'}</span>
          </div>
          <div className='row'>
            <div className='col-md-3 text-grey'>UPI ID :</div>
            <span className='text-bold mt-3'>{virtual_address || '-'}</span>
          </div>
          <div className='row'>
            <div className='col-md-3 text-grey'>Status :</div>
            <span className='text-bold mt-3'>{status || '-'}</span>
          </div>
          <div className='row'>
            <div className='col-md-3 text-grey'>Description :</div>
            <span className='text-bold mt-3'>{status_description || '-'}</span>
          </div>
          <div className='row'>
            <div className='col-md-3 text-grey'>Threshold :</div>
            <span className='text-bold mt-3'>
              {is_above_threshold?.toString()?.toUpperCase() || '-'}
            </span>
          </div>
        </div>
      </>
    );
  }
}
