import * as dashboardConstants from './dashboard.action.constant';

const initialState = {
  anchorList: {},
};
const anchorReducer = (state = initialState, action) => {
  let { type, data } = action;
  switch (type) {
    case dashboardConstants.SET_ANCHOR_LIST:
      return { ...state, anchorList: data };
    default:
      return state;
  }
};

export default anchorReducer;
