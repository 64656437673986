import React, { Component } from 'react';
import InvoiceView from './InvoiceView';
import PaymentView from './PaymentView';
export default class Invoice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 'payment',
    };
  }

  invoiceHandler = async (type) => {
    await this.setState({
      activeTab: type,
    });
  };

  render() {
    let { activeTab } = this.state;
    return (
      <>
        <div className='container invoices-wrapper mt-5'>
          <div className='card'>
            <div className='row'>
              <h3 className='col-md-10 card_heading'>Invoices</h3>
            </div>
            <div className='dashboard-wrapper'>
              <div className='buttons-box'>
                <div className='toggle-buttons'>
                  <div
                    onClick={() => this.invoiceHandler('payment')}
                    className={
                      activeTab === 'payment'
                        ? 'active-button button-left '
                        : 'button-left '
                    }
                  >
                    Payment View
                  </div>
                  <div
                    onClick={() => this.invoiceHandler('invoice')}
                    className={
                      activeTab === 'invoice'
                        ? 'active-button button-left button-right '
                        : 'button-left button-right '
                    }
                  >
                    Invoice View
                  </div>
                </div>
              </div>
            </div>
            {activeTab === 'payment' ? (
              <PaymentView {...this.props} />
            ) : (
              <InvoiceView {...this.props} />
            )}
          </div>
        </div>
      </>
    );
  }
}
