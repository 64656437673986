import React, { Component } from 'react';
import ReactTable from 'react-table';
import { GET } from '../../../../utils/webAPI.service';
import { API_GET_LEDGER_INFO } from '../../../../utils/APIUrls';
import { find } from 'lodash';
import { getMonthRange } from '../../../../utils/utility';
import {
  returnSummaryColumns,
  paymentCycleColumns,
  paymentSummaryColumns,
  invoiceSummaryColumns,
  meanPaymentsColumns,
  meanInvoicesColumns,
} from './ledgerConfig';

export default class LedgerModal extends Component {
  state = {
    userData: {},
  };

  componentDidMount() {
    const { propsData, stateData } = this.props,
      { match } = propsData;
    GET(
      API_GET_LEDGER_INFO(
        match?.params?.userId,
        stateData?.supplierData?.uniqueId
      )
    ).then(({ data }) => {
      if (data.code === 200) {
        if (data.data) {
          this.setState({ userData: data?.data });
        }
      }
      return data;
    });
  }

  getMonthAppendedData = (originalArray) => {
    const { accountAnalysis } = this.state.userData,
      { txnEndMonth, txnStartMonth } = accountAnalysis || {};
    const monthsRange = getMonthRange(txnStartMonth, txnEndMonth);
    const appendedMonths = [];
    monthsRange?.forEach((month) => {
      const updatedObject = find(originalArray, { month });
      if (updatedObject) {
        appendedMonths.push(updatedObject);
      } else {
        appendedMonths.push({ month });
      }
    });
    return appendedMonths;
  };

  render() {
    const { userData } = this.state,
      { accountAnalysis, excelUploadDateTime } = userData,
      {
        repaymentWithOpBal,
        repaymentWithoutOpBal,
        ledgerTransactionAnalysis,
        monthlyAnalysis,
        centralTendencies = {},
      } = accountAnalysis || {},
      { stateData } = this.props,
      { supplierData } = stateData;
    const TheadComponent = (props) => null;
    return (
      <div className='ledger-analysis-wrapper'>
        <h3 className='card-heading'>Ledger Analysis</h3>
        <div className='card-box'>
          <h3 className='card-heading'>Supplier Details</h3>
          <div className='row'>
            <div className='col-md-2 text-grey my-0'>
              Supplier ID
              <div className='text-bold'>{supplierData?.uniqueId} </div>
            </div>
            <div className='col-md-2 text-grey my-0'>
              Supplier Name
              <div className='text-bold'>{supplierData?.name} </div>
            </div>
            <div className='col-md-2 text-grey my-0'>
              Last Uploaded Date
              <div className='text-bold'>{excelUploadDateTime || '-'} </div>
            </div>
          </div>
        </div>
        <div className='card-box'>
          <h3 className='col-md-10 card-heading'>Monthly Summary</h3>
          <div className='card-box'>
            <div className='d-flex justify-content-around'>
              <div className='card-sub-heading card-sub-heading-blue'>
                #Invoices
              </div>
              <div className='card-sub-heading card-sub-heading-green'>
                #Payments
              </div>
            </div>
            <div className='table d-flex'>
              <div className='d-flex flex-column col-md-6 px-1'>
                <ReactTable
                  data={
                    this.getMonthAppendedData(
                      monthlyAnalysis?.invoiceMonthlyAnalysis
                    ) || []
                  }
                  columns={invoiceSummaryColumns}
                  minRows={1}
                  showPagination={false}
                  sortable={false}
                />
                <div className='table-blue'>
                  <ReactTable
                    data={
                      [
                        { name: 'Mean', ...centralTendencies?.meanInvoices } ||
                          {},
                        {
                          name: 'Median',
                          ...centralTendencies?.medianInvoices,
                        } || {},
                      ] || []
                    }
                    columns={meanInvoicesColumns}
                    minRows={1}
                    showPagination={false}
                    TheadComponent={TheadComponent}
                    sortable={false}
                  />
                </div>
              </div>
              <div className='d-flex flex-column col-md-6 px-1'>
                <ReactTable
                  data={
                    this.getMonthAppendedData(
                      monthlyAnalysis?.paymentMonthlyAnalysis
                    ) || []
                  }
                  columns={paymentSummaryColumns}
                  minRows={1}
                  showPagination={false}
                  sortable={false}
                />
                <div className='table-blue'>
                  <ReactTable
                    data={
                      [
                        { name: 'Mean', ...centralTendencies?.meanPayments } ||
                          {},
                        {
                          name: 'Median',
                          ...centralTendencies?.medianPayments,
                        } || {},
                      ] || []
                    }
                    columns={meanPaymentsColumns}
                    minRows={1}
                    showPagination={false}
                    TheadComponent={TheadComponent}
                    sortable={false}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className='card-box'>
            <h3 className='card-sub-heading card-sub-heading-orange col-md-7'>
              #Returns
            </h3>
            <div className='table'>
              <div className=' d-flex flex-column col-md-6 px-1'>
                <ReactTable
                  data={
                    this.getMonthAppendedData(
                      monthlyAnalysis?.returnMonthlyAnalysis
                    ) || []
                  }
                  columns={returnSummaryColumns}
                  minRows={1}
                  showPagination={false}
                  sortable={false}
                />
                <div className='table-blue'>
                  <ReactTable
                    data={
                      [
                        { name: 'Mean', ...centralTendencies?.meanReturns } ||
                          {},
                        {
                          name: 'Median',
                          ...centralTendencies?.medianReturns,
                        } || {},
                      ] || []
                    }
                    columns={meanInvoicesColumns}
                    minRows={1}
                    showPagination={false}
                    TheadComponent={TheadComponent}
                    sortable={false}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='card-box'>
          <h3 className='col-md-10 card-heading'>
            Ledger Transaction Analysis{' '}
          </h3>
          <div className='table-wrapper '>
            <div className='table-wrapper-box w-100 '>
              <table>
                <thead>
                  <tr>
                    <th className='px-4'>Variable </th>
                    <th className='px-4'>Figures </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      # Months for which Invoice count is less than 30% of
                      Median
                    </td>
                    <td>
                      {ledgerTransactionAnalysis?.monthsWith30PerOfMedInvCount}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      # Months for which Invoice Amount is less than 30% of
                      Median
                    </td>
                    <td>
                      {ledgerTransactionAnalysis?.monthsWith30PerOfMedInvAmount}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      # Months for which Avg Invoice Amount is more than 30% of
                      Median
                    </td>
                    <td>
                      {
                        ledgerTransactionAnalysis?.monthsWith30PerOfMedAvgInvAmount
                      }
                    </td>
                  </tr>
                  <tr>
                    <td># Monthly Order Turnaround (in Days)</td>
                    <td>{ledgerTransactionAnalysis?.monthlyOrderTurnaround}</td>
                  </tr>
                  <tr>
                    <td>Invoice Return Ratio (# Returns/# Total invoices)</td>
                    <td>{ledgerTransactionAnalysis?.invoiceReturnRatio}</td>
                  </tr>
                  <tr>
                    <td>
                      Return Amount Ratio (Return Amount/ Total Invoice Amount)
                    </td>
                    <td>{ledgerTransactionAnalysis?.returnAmountRatio}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div className='card-box'>
          <h3 className='col-md-10 card-heading '>
            Invoice - Payment Analysis
          </h3>
          <div className='table-wrapper '>
            <div className='table-wrapper-box '>
              <h4 className='table-wrapper-heading table-wrapper-heading-blue '>
                With Opening Balance
              </h4>
              <table>
                <tbody>
                  <tr>
                    <td>Highest Repayment Period (Days)</td>
                    <td>{repaymentWithOpBal?.highestRepaymentPeriod}</td>
                  </tr>
                  <tr>
                    <td>Lowest Repayment Period (Days)</td>
                    <td>{repaymentWithOpBal?.lowestRepaymentPeriod}</td>
                  </tr>
                  <tr>
                    <td>Mean Repayment Period (Days)</td>
                    <td>{repaymentWithOpBal?.meanRepaymentPeriod}</td>
                  </tr>
                  <tr>
                    <td>Median Repayment Period (Days)</td>
                    <td>{repaymentWithOpBal?.medianRepaymentPeriod}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className='table-wrapper-box '>
              <h4 className='table-wrapper-heading'>Without Opening Balance</h4>
              <table>
                <tbody>
                  <tr>
                    <td>Highest Repayment Period (Days)</td>
                    <td>{repaymentWithoutOpBal?.highestRepaymentPeriod}</td>
                  </tr>
                  <tr>
                    <td>Lowest Repayment Period (Days)</td>
                    <td>{repaymentWithoutOpBal?.lowestRepaymentPeriod}</td>
                  </tr>
                  <tr>
                    <td>Mean Repayment Period (Days) </td>
                    <td>{repaymentWithoutOpBal?.meanRepaymentPeriod}</td>
                  </tr>
                  <tr>
                    <td>Median Repayment Period (Days) </td>
                    <td>{repaymentWithoutOpBal?.medianRepaymentPeriod}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className='card-box'>
          <h3 className='col-md-10 card-heading '>Payment Cycle Analysis</h3>
          <div className='table-wrapper'>
            <div className='table-wrapper-box'>
              <h4 className='table-wrapper-heading table-wrapper-heading-blue '>
                With Opening Balance
              </h4>
              <ReactTable
                data={repaymentWithOpBal?.paymentCycleAnalysis || []}
                columns={paymentCycleColumns}
                defaultPageSize={4}
                showPagination={false}
                getTheadThProps={this.getTheadThProps}
                sortable={false}
              />
            </div>
            <div className='table-wrapper-box'>
              <h4 className='table-wrapper-heading'>Without Opening Balance</h4>
              <ReactTable
                data={repaymentWithoutOpBal?.paymentCycleAnalysis || []}
                columns={paymentCycleColumns}
                defaultPageSize={4}
                showPagination={false}
                getTheadThProps={this.getTheadThProps}
                sortable={false}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
