import React, { Component } from 'react';
import GSTAnalysis from './GSTAnalysis';

export default class Gst extends Component {
  state = {
    activeButton: 'gstAnalysis'
  };
  render() {
    return (
      <div className='gst__container'>
        <GSTAnalysis {...this.props} />
      </div>
    );
  }
}
