import React from 'react';

const NoDataFound = ({ primaryMessage, secondaryMessage }) => {
  return (
    <div className='no-data-found'>
      <div className='primary-message'>{primaryMessage}</div>
      <div className='secondary-message mt-3'>{secondaryMessage}</div>
    </div>
  );
};

export default NoDataFound;
