import * as failedInvoicesConstant from './failedInvoices.action.constant';
const initialState = {
  failedInvoicesListData: {},
  failedInvoicesSummary: {}
};

const FailedInvoicesReducer = (state = initialState, action) => {
  const { type, data } = action;
  switch (type) {
    case failedInvoicesConstant.SET_Failed_INVOICES_LIST:
      return { ...state, failedInvoicesListData: data };
    case failedInvoicesConstant.SET_Failed_INVOICES_SUMMARY:
      return { ...state, failedInvoicesSummary: data };
    default:
      return state;
  }
};
export default FailedInvoicesReducer;
