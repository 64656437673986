import React, { Component } from 'react';
import ReactTable from 'react-table';
import { getInvitationList } from './invite.action';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { API_INVITATION_SENT } from '../../../../utils/APIUrls';
import { GET } from '../../../../utils/webAPI.service';
import Greentick from '../../../../assets/images/green tick.svg';
class Sent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageNumber: 1,
      searchBy: 'invitationType',
      searchValue: 'invite',
    };
  }

  onInviteAgain = (mobileNumber) => {
    let { match, history, actions } = this.props,
      { anchorId } = match.params;
    GET(API_INVITATION_SENT(anchorId, mobileNumber))
      .then(({ data }) => {
        if (data.code === 200) {
          alert('Invitation Sent Succesfully');
        }
      })
      .catch((err) => {
        throw err;
      });
  };

  componentDidMount = () => {
    let { match, history, actions } = this.props,
      { anchorId } = match.params;
    let { pageNumber, searchBy, searchValue, supplierId } = this.state;
    const { getInvitationList } = actions;
    getInvitationList(pageNumber, searchBy, searchValue, anchorId);
  };

  getPageData = async (type, totalPages) => {
    let { match, history, actions } = this.props,
      { anchorId } = match.params;
    let { pageNumber, searchBy, searchValue } = this.state;
    const { getInvitationList } = actions;
    let updatedPageNumber = pageNumber;
    if (type === 'next' && pageNumber < totalPages) {
      updatedPageNumber = pageNumber + 1; // Update page number for next page
    } else if (type === 'prev' && pageNumber > 1) {
      updatedPageNumber = pageNumber - 1; // Update page number for previous page
    }
    await this.setState({ pageNumber: updatedPageNumber });
    getInvitationList(updatedPageNumber, searchBy, searchValue, anchorId);
  };

  render() {
    let { pageNumber } = this.state;
    let { buyerInvitation } = this.props || {};
    let { buyersList, totalPages } = buyerInvitation || {};
    const INVITATION_STATUS_PARAMS = {
      PENDING: 'pending',
      ACCEPTED: 'accepted',
    };
    const buyerInvitationColumn = [
      {
        Header: 'PARTY NAME',
        accessor: 'buyerName',
        width: 330,
        Cell: (props) => {
          let { original } = props;
          let { buyerName } = original;
          return (
            <>{<div className='invoice-pointer'>{buyerName || '-'}</div>}</>
          );
        },
      },
      {
        Header: 'Mobile No',
        accessor: 'mobileNumber',
      },

      {
        Header: 'Gstin',
        accessor: 'buyerGstIn',
        Cell: (props) => {
          let { original } = props;
          let { buyerGstIn } = original;
          return <>{buyerGstIn || '-'}</>;
        },
      },
      {
        Header: 'Status',
        accessor: '	STATUS',
        Cell: (props) => {
          let { original } = props;
          let { invitationStatus, invitationDate } = original;
          return (
            <>
              <div>
                {invitationStatus === INVITATION_STATUS_PARAMS?.PENDING ? (
                  <div> Invitation sent</div>
                ) : invitationStatus === INVITATION_STATUS_PARAMS?.ACCEPTED ? (
                  <div className='d-flex'>
                    <img className='img-icon' src={Greentick} />
                    <div className='pl-3'>Onboarded</div>
                  </div>
                ) : null}
              </div>
              <div>{invitationDate || '-'}</div>
            </>
          );
        },
      },
      {
        Header: 'Invitation',
        Cell: (props) => {
          let { original } = props;
          let { supplierId } = this.state;
          let { mobileNumber } = original || {};
          return (
            <button
              className='details_button w-50'
              onClick={() => this.onInviteAgain(mobileNumber)}
            >
              Invite Again
            </button>
          );
        },
      },
    ];

    return (
      <>
        <div className='container invoices-wrapper mt-5'></div>
        <div className='mt-4'>
          <ReactTable
            data={buyersList || []}
            columns={buyerInvitationColumn}
            defaultPageSize={10}
            showPagination={false}
            sortable={false}
          />
          <ul className={'datatable-pagination'}>
            <li>
              <button
                disabled={!(pageNumber > 1)}
                className={'prev-btn'}
                type={'button'}
                onClick={() => this.getPageData('prev', totalPages)}
              >
                Previous
              </button>
            </li>
            <li>
              <span>
                Page No. {totalPages === 0 ? 0 : pageNumber} of {totalPages}
              </span>
            </li>

            <li>
              <button
                disabled={!(totalPages > pageNumber)}
                className={'next-btn'}
                type={'button'}
                onClick={() => this.getPageData('next', totalPages)}
              >
                Next
              </button>
            </li>
          </ul>
        </div>
      </>
    );
  }
}

const mapStateToProps = ({ invitationReducer }) => ({
  buyerInvitation: invitationReducer?.buyerInvitation,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      getInvitationList,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(Sent);
