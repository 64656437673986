import React, { Component } from 'react';
import Download from '../../../../assets/images/download-cloud.png';
import Preview from '../../../../assets/images/eye.png';
import File from '../../../../assets/images/Frame.png';
import CredoModal from '../../../../components/CredoModal';
import CompareModal from './CompareModal';
import EntityPreviewModal from './EntityKycModal/EntityPreviewModal';
import { API_GET_CPO_DOCUMENTS_ID } from '../../../../utils/APIUrls';
import FileDownload from 'js-file-download';
import axios from 'axios';
import EntityIcon from './EntityKycModal/EntityKycVerificatinIcon';

export default class EntityKyc extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imgSrc: '',
      modalOpen: false,
      modalType: CompareModal,
      docId: null,
      fileName: null,
      fileDownload: null,
      response: [],
      fileUrl: null,
      docType: '',
    };
  }

  downloadFile = async (docId, fileName, fileUrl) => {
    const token = localStorage.getItem('token'),
      headers = {
        'X-CLIENT-NAME': 'PDU',
        'X-USER-ID': localStorage.getItem('userId'),
        Authorization: `Bearer ${token}`,
      };
    axios({
      url: API_GET_CPO_DOCUMENTS_ID(docId),
      method: 'GET',
      headers: headers,
      responseType: 'blob', // Important
    }).then(async (res) => {
      let {
        config: { url },
      } = res;

      try {
        const response = await fetch(fileUrl ? fileUrl : url);
        const blob = await response.blob();
        if (
          fileName.includes('pdf') ||
          fileName.includes('png') ||
          fileName.includes('jpeg')
        ) {
          FileDownload(blob, ` ${fileName}`);
        } else {
          FileDownload(blob, ` ${fileName}.jpeg`);
        }
      } catch (error) {
        console.log(error);
      }
    });
  };

  toggleModal = (name, docId, fileName, fileUrl, docType) => {
    this.setState((prevState) => ({
      modalOpen: !prevState.modalOpen,
      modalType: name,
      docId: docId,
      fileName: fileName,
      fileUrl,
      docType,
    }));
  };

  onPreviewHandler = (imgSrc) => {
    this.setState({
      modalOpen: true,
      imgSrc: imgSrc,
    });
  };

  render() {
    let { modalType, modalOpen, docId, fileName, fileUrl, docType } =
      this.state;
    let { propsData } = this.props;
    let { match } = propsData;
    let loanId = match?.params?.loanId;
    let { cpoDocument } = propsData;

    return (
      <>
        <div className='entity-kyc-wrapper'>
          <h3 className='card_heading'> Entity Kyc</h3>
          <table className='m-5 width'>
            <thead>
              <th width='250'>Entity Kyc</th>
              <th width='250'>Documents</th>
              <th width='250'>Uploaded Documents</th>
              <th width='150'></th>
            </thead>
            <tbody>
              {cpoDocument?.businessDocuments?.documentResponse?.map(
                (ele, index) => {
                  return (
                    <tr key={index}>
                      <td>{ele?.docType}</td>
                      <td>
                        <div className='doc-name'>{ele?.docName}</div>
                      </td>
                      <td>
                        <img src={File}></img>
                        {ele?.fileName}
                      </td>
                      <td className='span'>
                        <span>
                          <img
                            className='cursor'
                            alt=''
                            onClick={() =>
                              this.toggleModal(
                                EntityPreviewModal,
                                ele?.docId,
                                ele?.fileName,
                                ele?.fileUrl,
                                ele?.docType
                              )
                            }
                            src={Preview}
                          ></img>
                        </span>
                        <span>
                          <img
                            className='cursor'
                            src={Download}
                            alt=''
                            onClick={() =>
                              this.downloadFile(
                                ele?.docId,
                                ele?.fileName,
                                ele?.fileUrl
                              )
                            }
                          ></img>
                        </span>
                        <span>
                          <EntityIcon
                            docId={ele?.docId}
                            loanId={loanId}
                            docType={ele?.docType}
                          />
                        </span>
                      </td>
                    </tr>
                  );
                }
              )}
            </tbody>
          </table>

          <CredoModal
            styles={{
              content: {
                minWidth: '80vw',
              },
            }}
            isOpen={modalOpen}
            closeAction={() => this.toggleModal('')}
            RenderingComponent={modalType}
            docId={docId}
            fileName={fileName}
            loanId={loanId}
            fileUrl={fileUrl}
            docType={docType}
            propsData={propsData}
          />
        </div>
      </>
    );
  }
}
