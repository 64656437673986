import React, { Component } from 'react';
export default class BankModal extends Component {
  render() {
    let { stateData } = this.props;
    let { original } = stateData;
    let { pennyDropResponse = {} } = original;
    let { beneficiary_name_with_bank, id, verified_at, verified } =
      pennyDropResponse || {};
    return (
      <>
        <div className='modal-wrapper manage_user-wrapper ml-3'>
          <h3 className='card_heading'>Penny Drop Status</h3>
          <div className='row'>
            <div className='col-md-3  text-grey'>Name :</div>
            <span className='text-bold mt-3'>
              {beneficiary_name_with_bank || '-'}
            </span>
          </div>
          <div className='row'>
            <div className='col-md-3  text-grey'>ID :</div>
            <span className='text-bold mt-3'>{id || '-'}</span>
          </div>
          <div className='row'>
            <div className='col-md-3  text-grey'>Verified :</div>
            <span className='text-bold mt-3'>
              {verified?.toString()?.toUpperCase() || '-'}
            </span>
          </div>
          <div className='row'>
            <div className='col-md-3  text-grey'>Date :</div>
            <span className='text-bold mt-3'>{verified_at || '-'}</span>
          </div>
        </div>
      </>
    );
  }
}
