import React from 'react';

export default function index({
  heading,
  children,
  className,
  bgcolor,
  family,
  style,
  weight,
  color
}) {
  return (
    <div className={`card__wrapper ${className} `}>
      <div
        className='card-heading'
        style={{
          backgroundColor: bgcolor,
          fontFamily: family,
          fontStyle: style,
          fontWeight: weight,
          color: color ? color : '#058373'
        }}
      >
        {heading}
      </div>
      <div className='card-body'>{children}</div>
    </div>
  );
}
